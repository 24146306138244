import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";

const Breadcrumb = ({ items }) => {
  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link to={"/admin/"}>Dashboard</Link>
          {items.map((item, index) => (
            <span key={index}>
              {item.link ? (
                <Link to={item.link}>{item.title}</Link>
              ) : (
                <span>{item.title}</span>
              )}
            </span>
          ))}
        </Breadcrumbs>
      </div>
    </>
  );
};

export default Breadcrumb;
