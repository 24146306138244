import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiPath from '../../apipath';
import { logout } from '../../redux/features/adminSlice';
import axiosProvider from '../../libs/axios/axiosProvider';

const useAuth = () => {

    const { adminInfo, accessToken } = useSelector((state) => state?.admin);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogOut = () => {
        try {
            const response = new Promise((resolve) => {
                setTimeout(() => resolve({ code: 200 }), 1000);
            });

            response.then((res) => {
                if (res?.code == 200) {
                    dispatch(logout())
                    navigate('/admin');
                }
            }).catch((error) => console.log(error))

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (adminInfo && accessToken && adminInfo?.id) {
            checkUserAuth();
        }
    }, []);

    const checkUserAuth = async () => {
        try {
            const response = await axiosProvider({
                method: "GET",
                endpoint: apiPath.checkUserAuth,
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            if (response.status !== 200) {
                handleLogOut();
            }
        } catch (error) {
            console.error(error);
        }
    }

    const isAuthenticated = Boolean(accessToken && accessToken && adminInfo?.id);

    return isAuthenticated;
};

export default useAuth;
