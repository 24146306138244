import { FormikProvider, useFormik } from 'formik';
import axiosProvider from '../../libs/axios/axiosProvider';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import apiPath from '../../apipath';
import useToast from '../../hooks/useToast';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import Toster from '../../components/Toster';
import { loadScript } from '../../admin/payment/Payment';
import { fetchCart } from '../../redux/features/cartSlice';
import SuccessModal from '../../components/SuccessModal';
import { useEffect } from 'react';
import { fetchUserInfo } from '../../redux/features/authSlice';
import Coupon from '../../components/Coupon';
import { calculateDiscountAmount } from '../../components/calculateDescountAmount';


const Checkout = () => {

  const [loading, setLoading] = useState(false);
  const { toast, showToast } = useToast();
  const cartItems = useSelector((state) => state?.cart?.cart);
  const { userInfo, accessToken } = useSelector((state) => state?.auth);
  const { appliedCoupon } = useSelector((state) => state?.coupon);
  const settings = useSelector((state) => state?.setting?.settings);
  const [modalShow, setModalShow] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Function to calculate the final total amount including discount and GST
  const calculateFinalTotal = () => {
    const cartTotal = cartItems?.total_price - calculateDiscountAmount(appliedCoupon, cartItems?.total_price) //cartItems?.total_price;
    const gst = settings?.GST_course;
    const gstPercentage = parseFloat(gst) / 100;
    // const discountAmount = calculateDiscountAmount(cartTotal);
    // const discountedTotal = cartTotal - discountAmount;
    return (cartTotal * (1 + gstPercentage)).toFixed(2); // Apply GST to the discounted total
  };

  const calculateGSTAmount = () => {
    const discountAmount = calculateDiscountAmount(appliedCoupon, cartItems?.total_price);
    const cartTotal = cartItems?.total_price - discountAmount;
    const gst = settings?.GST_course;
    const gstPercentage = parseFloat(gst) / 100;
    return (cartTotal * gstPercentage).toFixed(2);
  };


  const intialValues = {
    // email: userInfo?.email,
    // name: userInfo?.name,
    // phone: "",
    // address: ""
  }

  const handleSubmit = async (values, { resetForm }) => {

    const finalTotal = calculateFinalTotal();
    const discountAmount = calculateDiscountAmount(appliedCoupon, cartItems?.total_price);
    const gstAmount = calculateGSTAmount();

    const now = new Date();

    setLoading(true);

    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?')
      return
    }

    const result = await axiosProvider({
      method: "POST",
      endpoint: apiPath.createOrderCheckout,
      data: {
        amount: finalTotal
      },
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    if (result?.status === 200) {

      const couponCode = appliedCoupon?.coupon_code || cartItems?.data?.[0]?.coupon_code || '';

      values = {
        ...values,
        rzorder_id: result?.data?.rzorder_id,
        course_id: cartItems?.data?.map((item) => item?.course_id)?.join(","),
        single_price: cartItems?.data?.map((item) => item?.price)?.join(","),
        total_amount: finalTotal,
        date_time: now.toISOString(),
        coupon_code: couponCode,
        sub_total: finalTotal,
        amount_after_coupon_discount : discountAmount,
        gst_amount: gstAmount
      }

      try {

        const submitFormData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          submitFormData.append(key, value);
        });

        const res = await axiosProvider({
          method: "POST",
          endpoint: apiPath.checkout,
          data: submitFormData,
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        });

        if (res?.status === 200) {
          resetForm({ values: "" });
          const options = {
            key: settings?.rzapi_key,
            currency: "INR",
            amount: result?.data?.amount?.toString(),
            order_id: result?.data?.rzorder_id,
            name: 'TradesMate',
            description: 'Thank you for nothing. Please give us some money',
            image: 'https://stock.reflomsolutions.com/api/assets/logo/stock_logo.png',
            handler: function (response) {
              verifyPayment({
                checkout_id: res.data?.data?.checkout_id,
                rzorder_id: res.data?.data?.rzorder_id,
                transaction_id: response?.razorpay_payment_id,
                transaction_status: "Success"
              })
            },
            prefill: {
              name: values.name,
              email: values.email,
              phone_number: values.phone
            },
            theme: {
              "color": "#003366"
            },
            modal: {
              "ondismiss": function () {
                setLoading(false);
              }
            }
          }

          const paymentObject = new window.Razorpay(options);

          paymentObject.on('payment.success', function (response) {
            // Payment was successful
            // verifyPayment({
            //     checkout_id: res.data?.data?.checkout_id,
            //     rzorder_id: res.data?.data?.rzorder_id,
            //     transaction_id: response?.razorpay_payment_id,
            //     transaction_status: "Success"
            //   });
            setLoading(false);
            console.log("response", response);
          });

          paymentObject.on('payment.failed', function (response) {
            // Payment failed
            verifyPayment({
              checkout_id: res.data?.data?.checkout_id,
              rzorder_id: response?.error?.metadata?.order_id,
              transaction_id: response?.error?.metadata?.payment_id,
              transaction_status: "Failed"
            });
            setLoading(false);
          });

          paymentObject.open()

        } else {
          setLoading(false);
          // showToast("Something went wrong !", "error", 3000);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        // showToast("Something went wrong !", "error", 3000);

      }
    }

  };


  const verifyPayment = async (data) => {
    try {
      const response = await axiosProvider({
        method: "POST",
        endpoint: apiPath.Captureordercheckout,
        data: data,
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data'
        }
      });


      if (response.status === 200 && response?.data?.response_code == 200) {
        handleCart();
        dispatch(fetchUserInfo({ accessToken }));
        setModalShow(true)
        setTimeout(() => {
          setModalShow(false);
          navigate('/dashboard/my-courses');
        }, 3000);
      } else if (response.status === 200 && response?.data?.response_code == 204) {
        // showToast(response?.data?.message ? response?.data?.message : "Something went wrong !", "error", 3000);
      } else {
        // showToast("Something went wrong !", "error", 3000);
      }

      setLoading(false);



    } catch (error) {
      console.log(error);
      // showToast("Something went wrong !", "error", 3000);
      setLoading(false)
    }
  }


  const handleCart = async () => {
    const response = await axiosProvider({
      method: "DELETE",
      endpoint: `${apiPath.emptyCart}/${userInfo?.id}`,
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      }
    });
    if (response.status === 200) {
      // showToast(response?.data?.message, "success", 3000);
      if (userInfo && userInfo?.id && accessToken) {
        dispatch(fetchCart({ userId: userInfo?.id, accessToken }));
      } else {
        // showToast(response?.data?.message ? response?.data?.message : "Something went wrong !", "error", 3000);
      }
    }
  }

  const formik = useFormik({
    initialValues: intialValues,
    // validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  });


  return (
    <section className='section_spacing section_spacing-space'>
      {loading && <Loader />}
      {/* {toast?.show && (
        <Toster text={toast?.text} variation={toast?.variation} />
      )} */}
      {cartItems && cartItems?.data?.length > 0 ? (
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className='site-container'>
              <Link to={'/cart'} className='back-page flex w-fit gap-2'>
                <i className='m-icon icon_back'></i>Back to CART
              </Link>
              <h2 className='secondary-title mb-4 mt-4'>CHECKOUT</h2>
              {/* 
             
              {/* order details  */}
              <div className='checkout-order'>
                <h3 className='form-feild-title'>YOUR ORDER</h3>
                <div className='checkout-box mt-4'>
                  <div className="checkout-order-border">
                    <div className='checkout-order-wrapper'>
                      <div className='checkout-order-col'>
                        <p className='checkout-order-prdname'>Product</p>
                        {cartItems?.data?.map((item) => {
                          return (
                            <>
                              <p className='checkout-order-name' key={item?.id}>
                                {item?.course_name}
                              </p>
                            </>
                          )
                        })}
                      </div>
                      <div className='checkout-order-col'>
                        <p className='checkout-order-prdname'>Price</p>
                        {cartItems?.data?.map((item) => {
                          return (
                            <>
                              <h3
                                className='checkout-order-pricetax'
                                key={item?.id}
                              >
                                <span>₹</span>
                                {item?.price}
                              </h3>
                            </>
                          )
                        })}
                      </div>

                    </div>
                    <div className='checkout-order-wrapper'>
                      <p className='checkout-order-prdname'>Subtotal</p>
                      <h2 className='checkout-total-price mt-3'>
                        <span>₹</span>
                        {cartItems && cartItems?.total_price}
                      </h2>
                    </div>
                  </div>

                  {toast?.show && (
                    <Toster text={toast?.text} variation={toast?.variation} />
                  )}

                  <Coupon id={cartItems?.data?.length === 1 ? (cartItems?.data[0]?.course_id || appliedCoupon?.course_id) : ''} total={cartItems?.total_price} />

                  <div className='checkout-order-border'>
                    {settings && settings?.GST_course && <>
                      <div className='checkout-order-wrapper'>
                        <div className='checkout-order-col'>
                          <p className='checkout-order-prdname'>
                            GST
                          </p>
                        </div>
                        <div className='checkout-order-col'>
                          {/* <p className='checkout-order-prdname'>Amount</p> */}
                          <h3
                            className='checkout-order-pricetax'
                          >
                            {`${settings?.GST_course}%`}
                          </h3>
                        </div>
                      </div>
                      <div className='checkout-order-wrapper'>
                        <p className='checkout-order-prdname'>Subtotal</p>
                        <h2 className='checkout-total-price'>
                          <span>₹</span>
                          {calculateFinalTotal()}
                        </h2>
                      </div>
                    </>
                    }
                  </div>

                  <div className='checkout-order-wrapper'>
                    <p className='checkout-order-prdname'>Total</p>
                    <h2 className='checkout-total-price'>
                      <span>₹</span>
                      {calculateFinalTotal()}
                    </h2>
                  </div>
                </div>
                <div className='checkout-box'>
                  <div className='checkout-logo-wrapper'>
                    <img src="https://aunstore.in/wp-content/uploads/2023/04/payment-1024x478-1.jpg" alt="" width={200} />
                  </div>
                  <p className='checkbox-details mt-4'>
                    Your personal Data will be used to process your order, support
                    your experience throughout this website, and for other
                    purposes described in our{' '}
                    <span>
                      <Link
                        to={'/privacy'}
                        target='_blank'
                        className='checkout-text-change'
                      >
                        Privacy Policy.
                      </Link>
                    </span>
                  </p>
                  <button className='m-btn m-btn-hero ' type='submit'>
                    Pay ₹{calculateFinalTotal()}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </FormikProvider>)
        : !loading ? (
          <div className="empty-cart order-placed-box" >
            <i className="m-icon icon-emptycart"></i>
            <h3 className="order-placed-title cart-empty">Your cart is empty</h3>
            <p className="order-placed-details">Must add items to the cart before you proceed to checkout.</p>
            <Link to={'/course'}>
              <button className="m-btn m-btn-hero mt-6">Continue Shopping</button>
            </Link>
          </div>
        ) : null}

      <SuccessModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        msg={'Congratulations! You have successfully purchased the Course'}
      />



    </section>
  )
}

export default Checkout;