import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosProvider from '../../libs/axios/axiosProvider';
import apiPath from '../../apipath';


export const fetchCoupon = createAsyncThunk('data/fetchCoupon', async ({ course_id, accessToken }) => {
    try {
        const response = await axiosProvider({
            method: "GET",
            endpoint: `${apiPath.userCoupon}`,
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
            params: { course_id: course_id }
        });
        if (response?.status == 200) {
            if (response?.data?.response_code == 200 && response.data) {
                return response?.data?.data
            }
        }
        return []
    } catch (error) {
        throw Error('Failed to fetch coupon');
    }
});


const initialState = {
    appliedCoupon: {},
    isApplied: false,
    couponCode: '',
}

const couponSlice = createSlice({
    name: 'couponSlice',
    initialState,
    reducers: {
        setCoupon: (state, action) => {
            state.coupon = action.payload
        },

        setAppliedCoupon: (state, action) => {
            state.appliedCoupon = action.payload;
        },

        setIsApplied: (state, action) => {
            state.isApplied = action.payload;
        },

        setCouponCode: (state, action) => {
            state.couponCode = action.payload
        }


    },
    // extraReducers: {
    //     [fetchCoupon.pending]: (state, action) => {
    //         state.loading = true;
    //         state.error = null;
    //     },
    //     [fetchCoupon.fulfilled]: (state, action) => {
    //         state.loading = false;
    //         state.cart = action.payload;
    //     },
    //     [fetchCoupon.rejected]: (state, action) => {
    //         state.loading = false;
    //         state.error = action.error;
    //     },
    // },
});


export const { setCoupon, setAppliedCoupon, setIsApplied, setCouponCode } = couponSlice.actions
export const { actions: couponActions, reducer: couponReducer } = couponSlice;