import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosProvider from '../../libs/axios/axiosProvider';
import apiPath from '../../apipath';

export const fetchSetting = createAsyncThunk('data/fetchSetting', async () => {
    try {
        const response = await axiosProvider({
            method: "GET",
            endpoint: `${apiPath.getSettings}`
        });
        if (response?.status == 200) {
            if (response?.data?.response_code == 200 && response.data) {
                return response?.data?.data[0]
            }
        }
        return []
    } catch (error) {
        throw Error('Failed to fetch setting');
    }
});


const initialState = {
    settings: [],
    loading: false,
    error: null,
}

const settingSlice = createSlice({
    name: 'settingSlice',
    initialState,
    reducers: {
        setSettings: (state, action) => {
            state.settings = action.payload
        }
    },
    extraReducers: {
        [fetchSetting.pending]: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        [fetchSetting.fulfilled]: (state, action) => {
            state.loading = false;
            state.settings = action.payload;
        },
        [fetchSetting.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error;
        },
    },
});

export const { setSettings } = settingSlice.actions
export const { actions: settingActions, reducer: settingReducer } = settingSlice;