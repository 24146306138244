import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const notify = (msg) =>
  toast.success(msg, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export const notifyInfo = (msg) =>
  toast.info(msg, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export const notifyErr = (msg) =>
  toast.error(msg, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export const alertInfo = (title, text, icon, btnTxt) => {
  return Swal.fire({
    title: title,
    text: text,
    icon: icon,
    showCancelButton: true,
    confirmButtonColor: "var(--primary)",
    cancelButtonColor: "grey",
    confirmButtonText: btnTxt,
  });
};

export const alertErr = (text) => {
  return Swal.fire({
    title: "Error",
    text: text,
    icon: "error",
    confirmButtonColor: "var(--primary)",
  });
};

export const alertSuccess = (title, text) => {
  Swal.fire({
    title: title,
    text: text,
    icon: "success",
    confirmButtonColor: "var(--primary)",
  });
};
