import React from 'react';
import { Box } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridToolbar, GridColumnMenu, useGridApiContext } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import IconFilter from '@mui/icons-material/FilterAlt';



const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));

function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg
                style={{ flexShrink: 0 }}
                width="240"
                height="200"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <Box sx={{ mt: 1 }}>No Rows</Box>
        </StyledGridOverlay>
    );
}



const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
        field: 'firstName',
        headerName: 'First name',
        width: 150,
        editable: true,
        description:
            'The identification used by the person with access to the online service.',
    },
    {
        field: 'lastName',
        headerName: 'Last name',
        width: 150,
        editable: true,
    },
    {
        field: 'age',
        headerName: 'Age',
        type: 'number',
        width: 110,
        editable: true,
    },
    {
        field: 'fullName',
        headerName: 'Full name',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,
        valueGetter: (params) =>
            `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    {
        field: 'birthday',
        type: 'date',
        width: 110,
        valueGetter: ({ value }) => value && new Date(value),
        renderHeader: () => (
            <strong>
                {'Birthday '}
                <span role="img" aria-label="enjoy">
                    🎂
                </span>
            </strong>
        ),
    },
    // {
    //     field: 'birthday',
    //     headerName: 'Birthday',
    //     type: 'dateTime',
    //     width: 200,
    //     editable: true,
    //     valueGetter: ({ value }) => value && new Date(value),
    // },
    {
        field: 'isAdmin',
        headerName: 'Admin',
        type: 'boolean',
        width: 120,
        editable: true,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
            <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
            // onClick={deleteUser(params.id)}
            />,
            <GridActionsCellItem
                icon={<SecurityIcon />}
                label="Toggle Admin"
                // onClick={toggleAdmin(params.id)}
                showInMenu
            />,
            <GridActionsCellItem
                icon={<FileCopyIcon />}
                label="Duplicate User"
                // onClick={duplicateUser(params.id)}
                showInMenu
            />,
        ],
    },
];

const rows = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', age: 14, birthday: '2001-12-10', isAdmin: true },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31, birthday: '2002-12-26', isAdmin: false },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31, birthday: '2003-03-27', isAdmin: false },
    { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11, birthday: '2004-02-06', isAdmin: true },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null, birthday: '2005-08-16', isAdmin: false },
    { id: 6, lastName: 'Melisandre', firstName: null, age: 150, birthday: '2000-09-06', isAdmin: true },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44, birthday: '2002-06-22', isAdmin: false },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36, birthday: '2006-10-28', isAdmin: true },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65, birthday: '2008-11-30', isAdmin: true },
];

const UserType = {
    Regular: true,
    Admin: false,
};



// function CustomUserItem(props) {
//     const { myCustomHandler, myCustomValue } = props;
//     return (
//         <MenuItem onClick={myCustomHandler}>
//             <ListItemIcon>
//                 <SettingsApplicationsIcon fontSize="small" />
//             </ListItemIcon>
//             <ListItemText>{myCustomValue}</ListItemText>
//         </MenuItem>
//     );
// }

// function CustomColumnMenu(props) {
//     return (
//         <GridColumnMenu
//             {...props}
//             slots={{
//                 // Add new item
//                 columnMenuUserItem: CustomUserItem,
//             }}
//             slotProps={{
//                 columnMenuUserItem: {
//                     // set `displayOrder` for new item
//                     displayOrder: 15,
//                     // pass additional props
//                     myCustomValue: 'Do custom action',
//                     myCustomHandler: () => alert('Custom handler fired'),
//                 },
//             }}
//         />
//     );
// }


function CustomUserItem(props) {
    const { myCustomHandler, myCustomValue } = props;
    return (
      <MenuItem onClick={myCustomHandler}>
        <ListItemIcon>
          <SettingsApplicationsIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{myCustomValue}</ListItemText>
      </MenuItem>
    );
  }
  
  function CustomColumnMenu(props) {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          // Add new item
          columnMenuUserItem: CustomUserItem,
        }}
        slotProps={{
          columnMenuUserItem: {
            // set `displayOrder` for new item
            displayOrder: 15,
            // pass additional props
            myCustomValue: 'Do custom action',
            myCustomHandler: () => alert('Custom handler fired'),
          },
        }}
      />
    );
  }


const DataGridTable = () => {

    const [userType, setUserType] = React.useState(UserType.Regular);


    const columnVisibilityModel = React.useMemo(() => {
        if (userType === UserType.Admin) {
            return {
                id: true,
            };
        }
        return {
            id: false,
        };
    }, [userType]);


    // function CustomFilterItem(props) {
    //     const { onClick, colDef } = props;
    //     const apiRef = useGridApiContext();
    //     const handleClick = React.useCallback(
    //         (event) => {
    //             apiRef.current.showFilterPanel(colDef.field);
    //             onClick(event);
    //         },
    //         [apiRef, colDef.field, onClick],
    //     );
    //     return (
    //         <MenuItem onClick={handleClick}>
    //             <ListItemIcon>
    //                 <IconFilter fontSize="small" />
    //             </ListItemIcon>
    //             <ListItemText>Show Filters</ListItemText>
    //         </MenuItem>
    //     );
    // }

    // function CustomColumnMenu(props) {
    //     return (
    //         <GridColumnMenu
    //             {...props}
    //             slots={{
    //                 // Override slot for `columnMenuFilterItem`
    //                 columnMenuFilterItem: CustomFilterItem,
    //             }}
    //             slotProps={{
    //                 // Swap positions of filter and sort items
    //                 columnMenuFilterItem: {
    //                     displayOrder: 0, // Previously `10`
    //                 },
    //                 columnMenuSortItem: {
    //                     displayOrder: 10, // Previously `0`
    //                 },
    //             }}
    //         />
    //     );
    // }

    return (
        <Stack height="450px" width="100%">
            <FormControl sx={{ width: '200px', pb: 1 }}>
                <InputLabel id="demo-simple-select-label">User Type</InputLabel>
                <Select
                    labelId="demo-user-type-label"
                    id="demo-user-type"
                    value={userType}
                    label="User Type"
                    onChange={(event) => {
                        setUserType(event.target.value);
                    }}
                >
                    <MenuItem value={UserType.Regular}>Regular User</MenuItem>
                    <MenuItem value={UserType.Admin}>Admin</MenuItem>
                </Select>
            </FormControl>
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    // autoHeight
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    // slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                    sx={{ '--DataGrid-overlayHeight': '300px' }}
                    pageSizeOptions={[5, 10, 25]}
                    disableColumnSelector
                    columnVisibilityModel={columnVisibilityModel}
                    checkboxSelection
                    disableRowSelectionOnClick
                    slots={{
                        toolbar: GridToolbar,
                        columnMenu: CustomColumnMenu,
                        noRowsOverlay: CustomNoRowsOverlay
                    }}
                // slotProps={{
                //     columnsPanel: {
                //         disableHideAllButton: true,
                //         disableShowAllButton: true,
                //     },
                // }}
                />
            </div>
        </Stack>
    )
}

export default DataGridTable