import React, { useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5stock from "@amcharts/amcharts5/stock";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Axios, getApi } from "../helper/helper";
import Loader from "../../components/Loader";
import { useParams, useNavigate } from "react-router-dom";
import apiPath from "../../apipath";
import { useSelector } from "react-redux";
import useAuthenticated from "../../hooks/useAuthenticated";
import Toster from "../../components/Toster";
import useToast from "../../hooks/useToast";



class MyIndicator extends am5stock.Indicator {

    _editableSettings = [
        // {
        //     key: "margin",
        //     name: "Margin",
        //     type: "number"
        // },
        {
            key: "seriesColor",
            name: "Color",
            type: "color"
        }, {
            key: "seriesStyle",
            name: "Line Style",
            type: "dropdown",
            options: ["Solid", "Dashed"]
        }, {
            key: "showFill",
            name: "Show fill",
            type: "checkbox"
        }];

    _afterNew() {

        // Set default indicator name
        this._setDefault("name", "My Indicator");
        // this._setDefault("margin", 100);
        this._setDefault("seriesColor", am5.color(0x045153));
        this._setDefault("seriesStyle", "Solid");
        this._setDefault("showFill", true);

        // Setting up indicator elements
        let stockSeries = this.get("stockSeries");
        let chart = stockSeries.chart;

        if (chart) {
            let series = chart.series.push(am5xy.SmoothedXLineSeries.new(this._root, {
                valueXField: "valueX",
                valueYField: "valueY1",
                openValueYField: "valueY2",
                groupDataDisabled: true,
                calculateAggregates: true,
                xAxis: stockSeries.get("xAxis"),
                yAxis: stockSeries.get("yAxis"),
                themeTags: ["indicator"],
                name: "My indicator",
                legendLabelText: "{name}",
                legendValueText: "high: [bold]{valueY}[/] - low: [bold]{openValueY}[/]",
                legendRangeValueText: "",
                stroke: this.get("seriesColor"),
                fill: this.get("seriesColor")
            }));

            series.fills.template.setAll({
                fillOpacity: 0.3,
                visible: true
            });

            this.series = series;
            this._handleLegend(series);
        }

        // Don't forget inherited stuff
        super._afterNew();
    }

    _beforeChanged() {

        if (this.isDirty("margin")) {
            this.prepareData();
        }

        if (this.isDirty("seriesStyle")) {
            let style = this.get("seriesStyle");
            if (style == "Dashed") {
                this.series.strokes.template.set("strokeDasharray", [4, 4]);
            }
            else {
                this.series.strokes.template.remove("strokeDasharray");
            }
        }

        if (this.isDirty("showFill")) {
            this.series.fills.template.set("visible", this.get("showFill"));
        }

        // Don't forget inherited stuff
        super._beforeChanged();
    }

    prepareData() {
        // Setting up data
        let stockSeries = this.get("stockSeries");
        let dataItems = stockSeries.dataItems;
        let data = this._getDataArray(dataItems);
        let margin = this.get("margin", 0);

        am5.array.each(data, function (item, i) {
            let baseValue = dataItems[i].get("valueY", 0);
            item.valueY1 = baseValue + Math.round(Math.random() * margin);
            item.valueY2 = baseValue - Math.round(Math.random() * margin);
        });

        this.series.data.setAll(data);
    }

    _dispose() {
        this.series.dispose();
        super._dispose();
    }
}

const AmStockChart = () => {

    const isAuth = useAuthenticated();

    const { exchange } = useParams();

    const { userInfo } = useSelector((state) => state?.auth);

    const { toast, showToast } = useToast();
    const [loading, setLoading] = useState(false);
    const [intervalData, setIntervalData] = useState({
        count: 3,
        countUnit: "minute"
    })
    const navigate = useNavigate();

    useEffect(() => {
        let root = am5.Root.new("stock_");

        const myTheme = am5.Theme.new(root);

        myTheme.rule("Grid", ["scrollbar", "minor"]).setAll({
            visible: false
        });

        root.setThemes([
            am5themes_Animated.new(root),
            myTheme
        ]);

        let stockChart = root.container.children.push(am5stock.StockChart.new(root, {
            paddingRight: 0
        }));

        root.numberFormatter.set("numberFormat", "#,###.00");

        let mainPanel = stockChart.panels.push(am5stock.StockPanel.new(root, {
            wheelY: "zoomX",
            panX: true,
            panY: true
        }));

        let valueAxis = mainPanel.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
                pan: "zoom"
            }),
            extraMin: 0.1, // adds some space for for main series
            tooltip: am5.Tooltip.new(root, {}),
            numberFormat: "#,###.00",
            extraTooltipPrecision: 2
        }));

        let dateAxis = mainPanel.xAxes.push(am5xy.GaplessDateAxis.new(root, {
            // groupData: true,
            // groupCount: 150,
            baseInterval: {
                timeUnit: "minute",
                count: 3
            },
            renderer: am5xy.AxisRendererX.new(root, {
                minorGridEnabled: true
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));

        let valueSeries = mainPanel.series.push(am5xy.CandlestickSeries.new(root, {
            name: exchange,
            clustered: false,
            valueXField: "epoch",
            valueYField: "close",
            highValueYField: "high",
            lowValueYField: "low",
            openValueYField: "open",
            calculateAggregates: true,
            xAxis: dateAxis,
            yAxis: valueAxis,
            legendValueText: "open: [bold]{openValueY}[/] high: [bold]{highValueY}[/] low: [bold]{lowValueY}[/] close: [bold]{valueY}[/]",
            legendRangeValueText: ""
        }));

        stockChart.set("stockSeries", valueSeries);

        let valueLegend = mainPanel.plotContainer.children.push(am5stock.StockLegend.new(root, {
            stockChart: stockChart
        }));

        let volumeAxisRenderer = am5xy.AxisRendererY.new(root, {
            inside: true,
            pan: "zoom"
        });

        volumeAxisRenderer.labels.template.set("forceHidden", true);
        volumeAxisRenderer.grid.template.set("forceHidden", true);


        let volumeValueAxis = mainPanel.yAxes.push(am5xy.ValueAxis.new(root, {
            numberFormat: "#.#a",
            height: am5.percent(20),
            y: am5.percent(100),
            centerY: am5.percent(100),
            renderer: volumeAxisRenderer
        }));

        let volumeSeries = mainPanel.series.push(am5xy.ColumnSeries.new(root, {
            name: "volume",
            clustered: false,
            valueXField: "epoch",
            valueYField: "volume",
            xAxis: dateAxis,
            yAxis: volumeValueAxis,
            legendValueText: "[bold]{valueY.formatNumber('#,###.0a')}[/]"
        }));

        volumeSeries.columns.template.setAll({
            strokeOpacity: 0,
            fillOpacity: 0.5
        });

        volumeSeries.columns.template.adapters.add("fill", function (fill, target) {
            let dataItem = target.dataItem;
            if (dataItem) {
                return stockChart.getVolumeColor(dataItem);
            }
            return fill;
        })

        stockChart.set("volumeSeries", volumeSeries);
        valueLegend.data.setAll([valueSeries, volumeSeries]);

        mainPanel.set("cursor", am5xy.XYCursor.new(root, {
            yAxis: valueAxis,
            xAxis: dateAxis,
            snapToSeries: [valueSeries],
            snapToSeriesBy: "y!"
        }));


        let scrollbar = mainPanel.set("scrollbarX", am5xy.XYChartScrollbar.new(root, {
            orientation: "horizontal",
            height: 50
        }));
        stockChart.toolsContainer.children.push(scrollbar);

        let sbDateAxis = scrollbar.chart.xAxes.push(am5xy.GaplessDateAxis.new(root, {
            baseInterval: {
                timeUnit: "minute",
                count: 3
            },
            renderer: am5xy.AxisRendererX.new(root, {
                minorGridEnabled: true
            })
        }));

        let sbValueAxis = scrollbar.chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {})
        }));

        let sbSeries = scrollbar.chart.series.push(am5xy.LineSeries.new(root, {
            valueYField: "Close",
            valueXField: "Date",
            xAxis: sbDateAxis,
            yAxis: sbValueAxis
        }));

        sbSeries.fills.template.setAll({
            visible: true,
            fillOpacity: 0.3
        });

        // Set up main indices selector
        // -------------------------------------------------------------------------------
        // https://www.amcharts.com/docs/v5/charts/stock/toolbar/comparison-control/
        let mainSeriesControl = am5stock.DropdownListControl.new(root, {
            stockChart: stockChart,
            name: valueSeries.get("name"),
            icon: am5stock.StockIcons.getIcon("Candlestick Series"),
            fixedLabel: true,
            searchable: true,
            searchCallback: async function (query) {
                let mainSeries = stockChart.get("stockSeries");
                let mainSeriesID = mainSeries ? mainSeries.get("name") : "";
                let list = await getTicker(query);
                am5.array.each(list, function (item) {
                    if (item.subLabel == mainSeriesID) {
                        item.disabled = true;
                    }
                })
                return list;
            }
        });

        mainSeriesControl.events.on("selected", function (ev) {
            mainSeriesControl.set("name", ev.item.subLabel);
            valueSeries.set("name", ev.item.subLabel);
            loadDataFromApi(ev.item.subLabel, intervalData, [valueSeries, sbSeries])
            navigate(`/instruments/${ev.item.subLabel}`);

        });


        async function getTicker(search) {

            if (search == "") {
                return [];
            }

            try {
                const result = await Axios.get(`/api/${apiPath.getStockSearch}`, {
                    params: { search: search },
                });

                if (result) {
                    const data = result?.data?.data;
                    return Promise.resolve(data);
                }

            } catch (error) {
                console.error(error);
                return Promise.reject(error);

            } finally {
                setLoading(false);
            }

        }



        // loadData
        // Function that dynamically loads JSON data from an API using Axios
        async function loadDataFromApi(ids, intervals, series) {
            setLoading(true);
            try {
                const result = await Axios.get(`/api/getStock/${ids}`, {
                    params: intervals,
                });
                if (result) {
                    const resData = result?.data?.data;

                    // Process data (convert dates and values) if needed
                    var processor = am5.DataProcessor.new(root, {
                        dateFields: ["Date"],
                        dateFormat: "yyyy-MM-dd",
                        numericFields: ["open", "high", "low", "close"]
                    });
                    processor.processMany(resData);

                    // Set data
                    am5.array.each(series, function (item) {
                        item.data.setAll(resData);
                    });
                }

                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }

        }

        loadDataFromApi(exchange, intervalData, [valueSeries, sbSeries]);

        let seriesSwitcher = am5stock.SeriesTypeControl.new(root, {
            stockChart: stockChart
        });

        seriesSwitcher.events.on("selected", function (ev) {
            setSeriesType(ev.item.id);
        });


        function getNewSettings(series) {
            let newSettings = [];
            am5.array.each(["name", "valueYField", "highValueYField", "lowValueYField", "openValueYField", "calculateAggregates", "valueXField", "xAxis", "yAxis", "legendValueText", "stroke", "fill"], function (setting) {
                newSettings[setting] = series.get(setting);
            });
            return newSettings;
        }


        function setSeriesType(seriesType) {
            // Get current series and its settings
            let currentSeries = stockChart.get("stockSeries");
            let newSettings = getNewSettings(currentSeries);

            // Remove previous series
            let data = currentSeries.data.values;
            mainPanel.series.removeValue(currentSeries);

            // Create new series
            let series;
            switch (seriesType) {
                case "line":
                    series = mainPanel.series.push(am5xy.LineSeries.new(root, newSettings));
                    break;
                case "candlestick":
                case "procandlestick":
                    newSettings.clustered = false;
                    series = mainPanel.series.push(am5xy.CandlestickSeries.new(root, newSettings));
                    if (seriesType == "procandlestick") {
                        series.columns.template.get("themeTags").push("pro");
                    }
                    break;
                case "ohlc":
                    newSettings.clustered = false;
                    series = mainPanel.series.push(am5xy.OHLCSeries.new(root, newSettings));
                    break;
            }

            // Set new series as stockSeries
            if (series) {
                valueLegend.data.removeValue(currentSeries);
                series.data.setAll(data);
                stockChart.set("stockSeries", series);
                let cursor = mainPanel.get("cursor");
                if (cursor) {
                    cursor.set("snapToSeries", [series]);
                }
                valueLegend.data.insertIndex(0, series);
            }
        }


        // Add series type control
        let intervalControl = am5stock.IntervalControl.new(root, {
            stockChart: stockChart,
            currentItem: `${intervalData?.count} ${intervalData?.countUnit}` || '3 minute',
            items: [
                { id: "3 minute", label: "3 minutes", interval: { timeUnit: "minute", count: 3 } },
                { id: "6 minute", label: "6 minutes", interval: { timeUnit: "minute", count: 6 } },
                { id: "15 minute", label: "15 minutes", interval: { timeUnit: "minute", count: 15 } },
                { id: "30 minute", label: "30 minutes", interval: { timeUnit: "minute", count: 30 } },
                { id: "1 hour", label: "1 hour", interval: { timeUnit: "hour", count: 1 } },
                { id: "1 day", label: "1 day", interval: { timeUnit: "day", count: 1 } },
                { id: "1 week", label: "1 week", interval: { timeUnit: "week", count: 1 } },
                { id: "1 month", label: "1 month", interval: { timeUnit: "month", count: 1 } }
            ]
        });

        intervalControl.events.on("selected", function (ev) {
            setIntervalData({
                count: ev.item.interval.count,
                countUnit: ev.item.interval.timeUnit
            })

            loadDataFromApi(exchange, {
                count: ev.item.interval.count,
                countUnit: ev.item.interval.timeUnit
            }, [valueSeries, sbSeries])

            // Get series
            // let valSeries = stockChart.get("stockSeries");

            // Set up zoomout
            // valSeries.events.once("datavalidated", function () {
            //     mainPanel.zoomOut();
            // });

            dateAxis.set("baseInterval", ev.item.interval);
            sbDateAxis.set("baseInterval", ev.item.interval);

        });

        // custom indicator
        // Create indicator control
        let indicatorControl = am5stock.IndicatorControl.new(root, {
            stockChart: stockChart,
            legend: valueLegend
        });

        // Get current indicators
        let indicators = indicatorControl.get("indicators", []);

        function renderIndicatorOrMessage() {
            if (isAuth && userInfo?.membership_id !== "1") {
                // User is authenticated, render the custom indicator
                return {
                    id: "myIndicator",
                    name: "My indicator",
                    callback: function () {
                        let myIndicator = stockChart.indicators.push(MyIndicator.new(root, {
                            stockChart: stockChart,
                            stockSeries: valueSeries,
                            legend: valueLegend
                        }));
                        return myIndicator;
                    }
                };
            } else if (isAuth && userInfo?.membership_id === "1") {
                // User is not authenticated, render error message or login prompt
                return {
                    id: "myIndicator",
                    name: "My indicator",
                    callback: function () {
                        showToast("Please upgrade your membership to access this functionality", "error", 3000);
                    }
                };
            } else {
                return {
                    id: "myIndicator",
                    name: "My indicator",
                    callback: function () {
                        showToast("First login to access this functionality", "error", 3000);
                    }
                };
            }
        }

        indicators.unshift(renderIndicatorOrMessage());

        // Set indicator list back
        indicatorControl.set("indicators", indicators);

        setTimeout(() => {
            // if (data?.length > 0) {
            let toolbar = am5stock.StockToolbar.new(root, {
                container: document.getElementById("chartControls"),
                stockChart: stockChart,
                controls: [
                    mainSeriesControl,
                    am5stock.IndicatorControl.new(root, {
                        stockChart: stockChart,
                        legend: valueLegend
                    }),
                    am5stock.DateRangeSelector.new(root, {
                        stockChart: stockChart
                    }),
                    am5stock.PeriodSelector.new(root, {
                        stockChart: stockChart
                    }),
                    seriesSwitcher,
                    intervalControl,
                    am5stock.DrawingControl.new(root, {
                        stockChart: stockChart
                    }),
                    am5stock.DataSaveControl.new(root, {
                        stockChart: stockChart
                    }),
                    am5stock.ResetControl.new(root, {
                        stockChart: stockChart
                    }),
                    am5stock.SettingsControl.new(root, {
                        stockChart: stockChart
                    })
                ]
            })
            // }
        }, 1000);

        let tooltip = am5.Tooltip.new(root, {
            getStrokeFromSprite: false,
            getFillFromSprite: false
        });

        tooltip.get("background").setAll({
            strokeOpacity: 1,
            stroke: am5.color(0x000000),
            fillOpacity: 1,
            fill: am5.color(0xffffff)
        });

        // set data to all series
        // valueSeries.data.setAll(data);
        // volumeSeries.data.setAll(data);
        // sbSeries.data.setAll(data);

        return () => {
            root.dispose();
        };
    }, [])


    return (
        <>
            {loading && <Loader />}

            <div id="chartControls" className="mb"></div>
            {toast?.show && (
                <Toster text={toast?.text} variation={toast?.variation} />
            )}
            <div id="stock_" style={{ width: "100%", height: "500px" }}></div>
        </>
    )
}

export default AmStockChart