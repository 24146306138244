import React from "react";
import Breadcrumb from "../../component/common/Breadcrumb";
import MembershipPlan from "./MembershipPlan";
import MembershipHistory from "./MembershipHistory";
import apiPath from "../../../apipath";
import { ToastContainer } from "react-toastify";


const Subscription = () => {

  const breadcrumbItems = [
    { title: 'Subscription', link: null }
  ];

  return (
    <section>
      <div className="mb-5">
        <ToastContainer />
        <Breadcrumb items={breadcrumbItems} />
      </div>

      <div className="my-5">
        <MembershipPlan />
      </div>

      <div className="my-5">
        <MembershipHistory endPoint={apiPath.getMembershipHistory}  />
      </div>

      {/* <Card variant="outlined" className="pb-3">
        <div className="d-flex justify-content-end">
          <Form.Control
            className="m-3"
            style={{
              width: 'fit-content'
            }}
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={handleSearch}
          />
        </div>
        <div>
          <DataTables
            title={"Subscription"}
            data={filteredData}
            columns={columns}
            pending={pending}
          />
        </div>
      </Card> */}
    </section>
  );
};

export default Subscription;
