import React, { useEffect, useRef, useState } from 'react';
import CodeEditor, { SelectionText } from "@uiw/react-textarea-code-editor";
import { Button, Spinner } from 'react-bootstrap';

const Editor = ({ code, setCode, fetchData, pending }) => {
    const textRef = useRef();
    useEffect(() => {
        if (textRef.current) {
            const obj = new SelectionText(textRef.current);
        }
    }, [code]);
    return (
        <>
            <div className="position-relative">
                <CodeEditor
                    value={code}
                    ref={textRef}
                    language="sql"
                    placeholder="Please enter Query"
                    onChange={(evn) => setCode(evn.target.value)}
                    padding={15}
                    style={{
                        fontFamily:
                            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                        fontSize: 12,
                        minHeight: 200
                    }}
                />

                {pending && <div className='position-absolute' style={{left: '50%', top : '50%', transform: 'translate(-50%, -50%)'}}><Spinner variant="light" /></div>}

                <div className="btn-d position-absolute right-0 top-0">
                    <Button onClick={(() => fetchData())}>
                        Go
                    </Button>
                </div>
            </div >
        </>
    )
}

export default Editor