import React, { useState, useEffect } from "react";
import Breadcrumb from "../../component/common/Breadcrumb";
import DataTables from "../../datatable/DataTable";
import { FaEdit, FaTrash, FaEye } from "react-icons/fa";
import { getApi, postApi } from "../../helper/helper";
import { Button, Form, Modal } from "react-bootstrap";
import { Card } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  alert,
  alertErr,
  alertInfo,
  alertSuccess,
  notify,
  notifyErr,
} from "../../component/common/notify";
import {
  confirmDelete,
  confirmStatus,
} from "../../component/common/handleData";
import { Link, useParams } from "react-router-dom";
import apiPath from "../../../apipath";
import Loader from "../../../components/Loader";

const CourseAdvantages = () => {
  const { courseId } = useParams();

  const [data, setData] = useState([]);
  const [course, setCourse] = useState([]);
  const [pending, setPending] = useState(true);
  const [formData, setFormData] = useState({});
  const [status, setStatus] = useState('0');
  const [formDataUpdate, setFormDataUpdate] = useState({});
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setFormData({});
    reset({
      title: "",
      description: "",
    });
  };

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleShowUpdate = (row) => {
    setShowUpdate(true);
    getIndividualData(row.id);
  };

  const handleCloseUpdate = () => {
    setShowUpdate(false);
    setFormDataUpdate({});
    reset1({
      title: "",
      description: "",
      type: "",
    });
  };

  const handleInputChange1 = (field, value) => {
    setFormDataUpdate({ ...formDataUpdate, [field]: value });
  };

  const fetchTableData = async () => {
    setPending(true);
    try {
      const result = await getApi(`${apiPath.getCourseAdvantages}/${courseId}/${status}`);
      setData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  }

  useEffect(() => {
    fetchTableData();
  }, [status])


  const fetchData = async () => {
    setPending(true);
    try {
      const result = await getApi(apiPath.getChapterCourse);
      setCourse(result);
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleStatusFilter = (value) => {
    setStatus(value);
  }

  const columns = [
    {
      name: <th className="fs-6 ">#</th>,
      cell: (row, index) => index + 1,
      width: "100px",
    },

    {
      name: <th className="fs-6 ">Title</th>,
      selector: (row) => row.title,
      width: "200px",
    },
    {
      name: <th className="fs-6">Description</th>,
      selector: (row) => row.description,
      width: "300px",
    },
    {
      name: <th className="fs-6">Type</th>,
      cell: (row, index) =>
        row.type === "0" ? "Benefit" : row.type === "1" ? "Highlight" : "-",
      width: "200px",
    },
    {
      name: <th className="fs-6">Status</th>,
      cell: (row, index) =>
        row.status == 0 ? (
          <span
            className="bg-success text-white p-1 rounded cursor-pointer"
            onClick={() =>
              handleStatus("/api/updateCourseAdvantagesStatus", row.id, "1")
            }
          >
            Active
          </span>
        ) : row.status == 1 ? (
          <span
            className=" bg-warning text-white p-1 rounded cursor-pointer"
            onClick={() =>
              handleStatus("/api/updateCourseAdvantagesStatus", row.id, "0")
            }
          >
            In-Active
          </span>
        ) : (
          "-"
        ),
      width: "220px",
    },
    {
      name: <th className="fs-6 action">Action</th>,
      selector: (row) => (
        <div className="d-flex" style={{ gap: "10px" }}>


          <Button
            className="text-white border-0"
            style={{ float: "right", backgroundColor: "var(--primary)" }}
            onClick={() => handleShowUpdate(row)}
          >
            <FaEdit />
          </Button>

          <Button
            className="bg-danger text-white border-0"
            onClick={() =>
              handleDelete(`${apiPath.deleteCourseAdvantages}/${row.id}`)
            }
          >
            <FaTrash />
          </Button>
        </div>
      ),
      right: true,
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: errors },
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm();

  const onSubmit = async () => {
    setPending(true);
    try {
      const result = await postApi(apiPath.addCourseAdvantages, {
        ...formData,
        course_id: courseId,
      });

      handleClose();
      notify("Advantage Added Successfully!");
      fetchTableData();

    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  const getIndividualData = async (id) => {
    setPending(true);
    try {
      const result = await getApi(`${apiPath.getSingleCourseAdvantages}/${id}`);
      const data = result[0];
      if (data) {
        setFormDataUpdate((prevFormDataUpdate) => ({
          ...prevFormDataUpdate,
          ["id"]: data.id,
          ["title"]: data.title,
          ["description"]: data.description,
          ["course_id"]: data.course_id,
          ["type"]: data.type,
        }));
        reset1({
          title: data.title,
          description: data.description,
          type: data.type,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  const onUpdate = async () => {
    setPending(true);
    try {
      const result = await postApi(apiPath.updateCourseAdvantages, {
        ...formDataUpdate,
        course_id: courseId,
      });

      handleCloseUpdate();
      notify("Advantages Updated Successfully!");
      fetchTableData();

    } catch (error) {
      console.error(error);
      notifyErr("Something went wrong!");
    } finally {
      setPending(false);
    }
  };

  // handleDelete 
  const handleDelete = (endPoint) => {
    confirmDelete(endPoint)
      .then((data) => {
        fetchTableData();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // handleStatus 
  const handleStatus = (endpoint, id, status) => {
       confirmStatus(endpoint, { id: id, status: status})
      .then((message) => {
        fetchTableData();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <section>
      {pending && <Loader />}
      <Card variant="outlined" className="pb-3">
        <div className="btn-d flex flex-col sm:flex-row row-gap-3 justify-content-between m-3">
          <Form.Select value={status} className="filter-select  w-auto"
            onChange={(e) => handleStatusFilter(e.target.value)}
          >
            <option value="0">Active</option>
            <option value="1">In-Active</option>
            <option value="2">All</option>
          </Form.Select>
          <Button
            className="text-white border-0 default-btn"
            onClick={() => handleShow()}
          >
            Add Advantage/Highlight
          </Button>
        </div>
        <div>
          <DataTables
            title={"Advantages / Highlights"}
            data={data}
            columns={columns}
            pending={pending}
            paginationServer={false}
            // totalRows={data?.length}
          />
        </div>
      </Card>

      {/* add */}
      <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>

        <Modal.Header closeButton>
          <Modal.Title>Add Advantage/Highlight</Modal.Title>
        </Modal.Header>
        <Form
          className="row m-2"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="course">Course</Form.Label>
              <Form.Select
                readonly
                disabled
                id="course"
                placeholder="Course"
                value={courseId}
                {...register("course_id", {
                  required: false,
                  // onChange: (e) =>
                  //   handleInputChange("course_id", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                {course?.map((course, index) => (
                  <option key={index} value={course.id}>
                    {course.title}
                  </option>
                ))}
              </Form.Select>
              {errors.course_id && (
                <div className="errMsg text-danger">Please Select Course</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="type">Type</Form.Label>
              <Form.Select

                id="type"
                placeholder="Type"
                value={formData.type}
                {...register("type", {
                  required: true,
                  onChange: (e) => handleInputChange("type", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                <option value="0">Benefit</option>
                <option value="1">Highlight</option>
              </Form.Select>
              {errors.type && (
                <div className="errMsg text-danger">Please Select Type</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="title">Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                id="title"
                value={formData.title}
                {...register("title", {
                  required: true,
                  onChange: (e) => handleInputChange("title", e.target.value),
                })}
              />
              {errors.title && (
                <div className="errMsg text-danger">Please Enter Title</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="description">Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                id="description"
                placeholder="description"
                value={formData.description}
                {...register("description", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("description", e.target.value),
                })}
              />
              {errors.description && (
                <div className="errMsg text-danger">
                  Please Enter Description
                </div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="contained"
              className="text-white btn-primary"
            >
              Add
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* update */}
      <Modal
        show={showUpdate}
        onHide={handleCloseUpdate}
        style={{ zIndex: "9999" }}
      >

        <Modal.Header closeButton>
          <Modal.Title>Update Advantage/Highlight</Modal.Title>
        </Modal.Header>
        <Form
          className="row m-2"
          onSubmit={handleSubmit1(onUpdate)}
          autoComplete="off"
        >
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="courseUpdate">Course</Form.Label>
              <Form.Select
                readonly
                disabled
                type="type"
                id="courseUpdate"
                placeholder="Course"
                value={courseId}
                {...register1("course_id", {
                  required: false,
                  // onChange: (e) =>
                  //   handleInputChange1("course_id", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                {course?.map((course, index) => (
                  <option key={index} value={course.id}>
                    {course.title}
                  </option>
                ))}
              </Form.Select>
              {errors1.course_id && (
                <div className="errMsg text-danger">Please Select course </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="type">Type</Form.Label>
              <Form.Select

                id="type"
                placeholder="Type"
                value={formDataUpdate.type}
                {...register1("type", {
                  required: true,
                  onChange: (e) => handleInputChange1("type", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                <option value="0">Benefit</option>
                <option value="1">Highlight</option>
              </Form.Select>
              {errors1.type && (
                <div className="errMsg text-danger">Please Select Type</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="title">Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                id="title"
                value={formDataUpdate.title}
                {...register1("title", {
                  required: true,
                  onChange: (e) => handleInputChange1("title", e.target.value),
                })}
              />
              {errors1.title && (
                <div className="errMsg text-danger">Please Enter Title</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="description">Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                id="description"
                placeholder="Description"
                value={formDataUpdate.description}
                {...register1("description", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange1("description", e.target.value),
                })}
              />
              {errors1.description && (
                <div className="errMsg text-danger">
                  Please Enter Description
                </div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="contained"
              className="text-white btn-primary"

            >
              Update
            </Button>
            <Button variant="secondary" onClick={handleCloseUpdate}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </section>
  );
};

export default CourseAdvantages;
