import { fetchDataFromApi } from "../../../libs/AllGlobalFunctions";
import apiPath from "../../../apipath";
import Loader from "../../../components/Loader";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotFound from "../../NotFound";
import { fetchUserInfo } from "../../../redux/features/authSlice";
import { useImmer } from "use-immer";

const NotificationBar = ({ title, description }) => {
    return (
        <div className="bg-gradient-to-r from-[#073565] to-[#67A2D4] rounded-md p-4 shadow-lg mb-3">
            <div className="flex items-center justify-between">
                <div className="text-white font-bold text-lg">{title}</div>
                {/* <button className="text-white">&times;</button> */}
            </div>
            <div className="mt-2 text-white">{description}</div>
        </div>
    );
};


const UserNotification = () => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1)
    const [allDataLoaded, setAllDataLoaded] = useState(false);
    let [filterData, setFilterData] = useImmer({
        page: 1,
        per_page: 5
    });
    const { userInfo, accessToken } = useSelector((state) => state?.auth);

    const dispatch = useDispatch();

    useEffect(() => {
        if (userInfo && userInfo?.id && !allDataLoaded) {
            fetchData(filterData);
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [filterData])

    useEffect(() => {
        readNotification();
        window.scrollTo(0, 0);
    }, [])



    const fetchData = (filteredData) => {
        if (loading) return;
        setLoading(true);
        fetchDataFromApi(`${apiPath.getUserNotification}/${userInfo?.id}`, null, 'GET', filteredData).then((response) => {

            if (response) {

                const newData = response?.data?.data || [];
                if (newData?.length === 0) {
                    setAllDataLoaded(true); // Set flag when all data is loaded
                } else {
                    let updatedData = [...data, ...newData]
                    // Filter out duplicate data
                    const uniqueData = updatedData.filter((item, index, self) => {
                        return index == self.findIndex((t) => (
                            t.id == item.id // Assuming each item has a unique identifier like 'id'
                        ));
                    });
                    setData(uniqueData);
                    setPage(page + 1);
                }
            }

            setLoading(false);
        }
        ).catch((error) => {
            console.log(error);
            setLoading(false);
        })
    }

    const readNotification = () => {
        fetchDataFromApi(`${apiPath.readNotification}`, null, 'GET', {}).then((data) => {
            setLoading(false);
            dispatch(fetchUserInfo({ accessToken }));
        }
        ).catch((error) => {
            console.log(error);
            setLoading(false);
        })
    }

    const handleScroll = () => {
        // if (
        //     window.innerHeight + document.documentElement.scrollTop !==
        //     document.documentElement.offsetHeight
        // ) {
        //     return;
        // }
        const scrolledFromBottom = document.documentElement.scrollHeight - (window.innerHeight + window.scrollY);
        if (scrolledFromBottom < 1 && !allDataLoaded) {
            setFilterData({
                page: page + 1,
                per_page: 5
            })
        }
    };


    return (
        <section className='section_spacing w-full section_spacing-space'>
            {loading && <Loader />}
            {
                data?.length > 0 ? data?.map((item) => {
                    return (
                        <NotificationBar
                            title={item?.title}
                            description={item?.description}
                        />
                    )
                }) : !loading && <div className="empty-cart items-center" ><NotFound title={"No Notifications"} /></div>
            }
        </section>
    )
}

export default UserNotification;