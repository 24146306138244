import React, { useState, useEffect } from "react";
import { getApi } from "../../helper/helper";
import { Typography } from "@mui/material";
import List from '@mui/material/List';
import apiPath from "../../../apipath";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Lesson = (props) => {

  const lessonData = useSelector((state) => state?.basic?.lessonData);

  // const [data, setData] = useState([]);
  // const [status, setStatus] = useState('2');


  // const fetchTableData = async () => {
  //   try {
  //     const result = await getApi(`/api/${apiPath.getLessons}/${props.id}/${status}`);
  //     setData(result);
  //   } catch (error) {
  //     console.error(error);
  //   }

  // };

  // useEffect(() => {
  //   fetchTableData();
  // }, [props.id]);

  return (
    <section>
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        aria-labelledby="nested-list-subheader"
      >
        {lessonData?.filter(item => item.chapter_id == props.id)?.map((lesson, index) => (
          <div className="d-flex justify-between items-center p-3">
            <Typography
              sx={{ cursor: 'pointer' }}
              onClick={(e) => props.handleTabChange(e, `l-${props?.moduleId}${props?.id}${lesson?.id}${index}`)}
            >
              <a href={`#vertical-tabpanel-l-${props?.moduleId}${props?.id}${lesson?.id}${index}`} className='text-black'>{lesson.title}</a>
            </Typography>
          </div>
        ))}

      </List>

    </section>
  );
};

export default Lesson;
