import React, { memo } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Lesson from '../../../pages/lesson/Lesson';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';



const Accordions = (props) => {

    const chapterData = useSelector((state) => state?.basic?.chapterData);

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => {
        setExpanded((prevPanel) => prevPanel == panel ? false : panel);
    };

    const [expandedChapter, setExpandedChapter] = React.useState(false);

    const handleChangeChapter = (panel) => {
        setExpandedChapter((prevPanel) => prevPanel == panel ? false : panel);
    };

    return (
        <div>
            <Accordion className="shadow-none"
                expanded={expanded === `module${props.index}`}
            >
                <AccordionSummary className='p-0'
                    expandIcon={<IconButton onClick={() => handleChange(`module${props.index}`)}><ExpandMoreIcon /></IconButton>}
                >
                    <Typography className='w-100'
                        sx={{ cursor: 'pointer' }}
                        onClick={(e) => props.handleTabChange(e, `m-${props.id}${props.index}`)}
                    >
                        <a href={`#vertical-tabpanel-m-${props.id}${props.index}`} className='text-black'>{props.title}</a>
                    </Typography>

                </AccordionSummary>
                <AccordionDetails className='p-2'>

                    {chapterData?.filter(item => item.module_id === props.id)?.map((chapter, index) => (
                        <Accordion className="shadow-none"
                            expanded={expandedChapter === `chapter${index}`}
                        >
                            <AccordionSummary className='p-0'
                                expandIcon={<IconButton onClick={() => handleChangeChapter(`chapter${index}`)}><ExpandMoreIcon /></IconButton>}
                            >

                                <Typography className='w-100'
                                    sx={{ cursor: 'pointer' }}
                                    onClick={(e) => props.handleTabChange(e, `ch-${props?.id}${chapter?.id}${index}`)}
                                >
                                    <a href={`#vertical-tabpanel-ch-${props?.id}${chapter?.id}${index}`} className='text-black'>{chapter.title}</a>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className='p-0'>
                                <Lesson {...chapter} index={index} moduleId={props?.id} handleTabChange={props.handleTabChange} />
                            </AccordionDetails>
                        </Accordion>
                    ))}

                </AccordionDetails>
            </Accordion>
        </div>

    )
}

export default memo(Accordions)