import { useEffect } from "react";
import apiPath from "../apipath";
import Loader from "../components/Loader";
import bradcom from '../icons/bradcome-arrow.svg'
import { useState } from "react";
import { fetchDataFromApi } from "../libs/AllGlobalFunctions";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { _logo_ } from "../libs/ImagePath";

const PrivacyPolicy = () => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const settings = useSelector((state) => state?.setting?.settings);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, [])

    const fetchData = (id) => {
        setLoading(true);
        fetchDataFromApi(apiPath.getUserPrivacy, null, 'GET', {}).then((data) => {
            if (data) {
                setData(data?.data?.data[0]);
            } else {
                setData([]);
            }
            setLoading(false);
        }
        ).catch((error) => {
            console.log(error);
            setLoading(false);
        })
    }

    return (
        <>
            <div className='pc-details-banner' style={{ backgroundImage: `url('${_logo_}/${settings?.banner_image}')` }}>
                {loading && <Loader />}
                <h2 className='pc-details-title'>Privacy Policy</h2>
                <div className='p-couse-bradcom-wrapper'>
                    <Link to={'/'} className='p-bradcom-item'>
                        Home
                    </Link>
                    <img src={bradcom} alt='' />
                    <Link to={"/privacy"} className="p-bradcom-item">
                        Privacy Policy
                    </Link>
                </div>
            </div>
            <section className="section_spacing">
                <div className="site-container">
                    <p className='pc-overviw-contains' dangerouslySetInnerHTML={{
                        __html: ` ${data?.content || ""}`
                    }} >
                    </p>
                </div>
            </section>
        </>
    )
}

export default PrivacyPolicy;