import React, { useEffect, useState } from 'react'
import apiPath from '../apipath';
import { fetchDataFromApi } from '../libs/AllGlobalFunctions';
import { _category_ } from '../libs/ImagePath';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from 'react-router-dom';

function CategorySectionSkeleton() {
  return (
    <section className='section_spacing'>
      <div className='site-container'>
        <div className='category-section'>
          <div className='section-title-wrap'>
            <Skeleton height={30} width={210} />
            <Skeleton height={20} width={250} count={2} style={{ marginTop: '10px' }} />
          </div>
          <div className='category-wrapper'>
            {Array(4).fill(0).map((_, index) => (
              <div className='category-items skeleton' key={index}>
                <Skeleton circle={true} height={60} width={60} />
                <Skeleton height={20} width={100} style={{ marginTop: '10px' }} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default function CategorySection() {

  const [topCategories, setTopCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTopCategories();
  }, []);

  const fetchTopCategories = () => {
    setLoading(true);
    fetchDataFromApi(apiPath.getFeaturedCategory, null, 'GET', {}).then((response) => {
      if (response?.status == 200 && response?.data?.data) {
        setTopCategories(response?.data?.data)
      } else {
        setTopCategories([])
      }
      setLoading(false);
    }
    ).catch((error) => {
      setLoading(false);
      console.log(error)
    })
  }

  return (
    <section className='section_spacing bg-lightgray'>
      {loading ? (
        <CategorySectionSkeleton />
      ) : (
        <div className='site-container'>
          <div className='category-section'>
            <div className='section-title-wrap'>
              <h2 className='section-title'>Top Categories</h2>
              {/* <p className='section-title-contain'>
                    Consectetur adipiscing elit sed do eiusmod tempor incididunt ut
                    labore et dolore
                  </p> */}
            </div>
            <div className='category-wrapper'>
              {
                topCategories?.map((item) => {
                  return (
                    <Link to={`/course?category=${item?.id}`}>
                      <div className='category-items' key={item?.id}>
                        <img className='hero-corse-icon' src={`${_category_}/${item?.icon}`} />
                        <h3 className='category-item-title'>{item?.name}</h3>
                      </div>
                    </Link>
                  )
                })
              }
            </div>
          </div>
        </div>
      )}
    </section>
  )
}


