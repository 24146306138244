import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
import AllRouters from './AllRouters'
import './admin/App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
import './css/main.css'
import { useDispatch } from 'react-redux'
import { fetchCategory } from './redux/features/basicSlice'
import { useEffect } from 'react'
import { useLocation } from 'react-router'
import useAuthenticated from './hooks/useAuthenticated'
import { useNavigate } from 'react-router-dom'
import { fetchBanners } from './redux/features/bannerSlice';
import { fetchSetting } from './redux/features/settingSlice'

const App = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuth = useAuthenticated();

  useEffect(() => {
    dispatch(fetchCategory());
    dispatch(fetchBanners());
    dispatch(fetchSetting());

    if (isAuth && window.location.href.includes('/auth')) {
      navigate("/dashboard")
    }

  }, []);

  if (window.location.href.includes('/admin')) {
    return (
      <>
        <AllRouters />
      </>
    )
  }

  return (
    <>
      {!location.pathname?.includes('/dashboard') &&
        !location.pathname?.includes('/lessons') &&
        !location.pathname?.includes('/verify-email') &&
        < Header />
      }
      <main>
        <AllRouters />
      </main>
      {!location.pathname?.includes('/dashboard') &&
        !location.pathname?.includes('/lessons') &&
        !location.pathname?.includes('/verify-email') &&
        <Footer />
      }
    </>
  )
}

export default App;
