import * as Yup from 'yup';

const globalValidationSchema = {
    email: Yup.string()
        .max(30, 'Email must be at most 30 characters long')
        .matches(/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/, 'Invalid email address')
        .required('Email is required'),
    name: Yup.string().required('Full name is required').matches(/^[a-zA-Z\s]{3,}$/, "Please enter a valid name with at least 3 characters.").max(20, 'Full Name is not be exceed 20 Characters'),
    phone: Yup.string().required('Phone number is required').matches(/^[6789]\d{9}$/, "Please enter a valid 10-digit phone number starting with 6, 7, 8, or 9.")
        .min(10, "Phone number must be at least 10 digits long")
        .max(10, "Phone number must be no more than 10 digits long"),
    password: Yup.string().required("Password is required").matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+}{":;'?/>.<,])[A-Za-z\d!@#$%^&*()_+}{":;'?/>.<,]{8,}$/,
        "Password must be at least 8 characters long and include uppercase, lowercase letters, numbers, and special characters."
    ),
    confirm_password: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Re-enter password is required"),
    address: Yup.string().required("Address is required").min(10, "Address must be at least 10 characters.")
};

export default globalValidationSchema;
