import React, { useState, useEffect } from "react";
import Breadcrumb from "../../component/common/Breadcrumb";
import DataTables from "../../datatable/DataTable";
import { FaEdit, FaEye, FaEyeSlash, FaTrash } from "react-icons/fa";
import { getApi, postApi } from "../../helper/helper";
import { Button, Form, Modal } from "react-bootstrap";
import { Card } from "@mui/material";
import { ToastContainer } from "react-toastify";
import InputGroup from 'react-bootstrap/InputGroup';
import { useForm } from "react-hook-form";
import {
    alert,
    alertErr,
    alertInfo,
    alertSuccess,
    notify,
    notifyErr,
} from "../../component/common/notify";
import {
    confirmDelete,
    confirmStatus,
} from "../../component/common/handleData";
import MembershipHistory from "../subscription/MembershipHistory";
import { useParams } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import apiPath from "../../../apipath";
import Loader from "../../../components/Loader";


const UpdateUser = () => {

    const { id } = useParams();

    const [showUpdate, setShowUpdate] = useState(false);
    const [formDataUpdate, setFormDataUpdate] = useState({});
    const [pending, setPending] = useState(false);

    const handleShowUpdate = (row) => {
        setShowUpdate(true);
        getIndividualData(row.id);
    };

    const handleCloseUpdate = () => {
        setShowUpdate(false);
        setFormDataUpdate({});
        reset1({
            name: "",
            email: "",
            phone: "",
            address: "",
        });
    };

    const handleInputChange1 = (field, value) => {
        setFormDataUpdate({ ...formDataUpdate, [field]: value });
    };

    const {
        register: register1,
        handleSubmit: handleSubmit1,
        reset: reset1,
        formState: { errors: errors1 },
    } = useForm();

    const getIndividualData = async (id) => {
        setPending(true);
        try {
            const result = await getApi(`${apiPath.getSingleUser}/${id}`);
            const data = result[0];
            if (data) {
                setFormDataUpdate((prevFormData) => ({
                    ...prevFormData,
                    ["id"]: data.id,
                    ["type"]: data.type,
                    ["name"]: data.name,
                    ["phone"]: data.phone,
                    ["email"]: data.email,
                    ["address"]: data.address,
                    ["password"]: "",
                }));
                reset1({
                    name: data.name,
                    email: data.email,
                    phone: data.phone,
                    address: data.address,
                    type: data.type
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setPending(false);
        }
    };

    useEffect(() => {
        getIndividualData(id);
        window.scrollTo(0, 0);
    }, [id])



    const onUpdate = async () => {
        setPending(true);
        try {
            const result = await postApi(apiPath.updateUser, formDataUpdate);
            handleCloseUpdate();
            notify("User Updated Successfully!");
            getIndividualData();

        } catch (error) {
            console.error(error);
            notifyErr(error.response.data.res);
        } finally {
            setPending(false);
        }
    };

    const breadcrumbItems = [
        { title: 'Users', link: '/admin/users' },
        { title: formDataUpdate?.name, link: null }
    ];

    return (
        <>
            <div className="mb-5">
                {pending && <Loader />}
                <Breadcrumb items={breadcrumbItems} />
            </div>

            <Card variant="outlined" className="pb-3">


                <dvi className="m-3">

                    <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
                        <Row className="m-0 view-user">
                            <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="profile">Profile</Nav.Link>
                                    </Nav.Item>
                                    {/* {formDataUpdate?.type === '2' && <>
                                        <Nav.Item>
                                            <Nav.Link eventKey="memberships">Memberships</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="courses">Courses</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="payments">Payments</Nav.Link>
                                        </Nav.Item>
                                    </>
                                    } */}
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="profile">

                                        <Form
                                            className="row m-0"
                                            onSubmit={handleSubmit1(onUpdate)}
                                            autoComplete="off"
                                        >
                                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                                <Form.Label htmlFor="type">Type</Form.Label>
                                                <Form.Select
                                                    disabled
                                                    type="type"
                                                    id="type"
                                                    placeholder="User"
                                                    value={formDataUpdate.type}
                                                    {...register1("type", {
                                                        required: false,
                                                        // onChange: (e) => handleInputChange1("type", e.target.value),
                                                    })}
                                                >
                                                    <option value="">Select Type</option>
                                                    <option value="1">Sub Admin</option>
                                                    <option value="2">User</option>

                                                </Form.Select>
                                                {errors1.type && (
                                                    <div className="errMsg text-danger">Please Select Course</div>
                                                )}
                                            </Form.Group>

                                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                                <Form.Label htmlFor="name">Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="name"
                                                    placeholder="Name"
                                                    value={formDataUpdate.name}
                                                    {...register1("name", {
                                                        required: "Name is Required",
                                                        pattern: {
                                                            value: /^[a-zA-Z\s]{3,}$/,
                                                            message: 'Invalid name. Please enter only letters and spaces'
                                                        },
                                                        maxLength: { value: 20, message: 'Name must be at most 20 characters long' },
                                                        validate: {
                                                            fullName: (value) => {
                                                                const names = value.trim().split(' ');
                                                                return names.length >= 1 ? undefined : 'Please enter name';
                                                            }
                                                        },
                                                        onChange: (e) => handleInputChange1("name", e.target.value),
                                                    })}
                                                />
                                                {errors1.name && (
                                                    <div className="errMsg text-danger">Please Enter Name</div>
                                                )}
                                            </Form.Group>
                                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                                <Form.Label htmlFor="email">Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    id="email"
                                                    placeholder="Email"
                                                    value={formDataUpdate.email}
                                                    {...register1("email", {
                                                        required: "Email is required",
                                                        maxLength: { value: 30, message: 'Email must be at most 30 characters long' },
                                                        pattern: { value: /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/, message: 'Invalid email address' },
                                                        onChange: (e) => handleInputChange1("email", e.target.value),
                                                    })}
                                                />
                                                {errors1.email && (
                                                    <div className="errMsg text-danger">{errors1.email.message}</div>
                                                )}
                                            </Form.Group>
                                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                                <Form.Label htmlFor="phone">Phone</Form.Label>
                                                <Form.Control
                                                    type="tel"
                                                    id="phone"
                                                    placeholder="Phone"
                                                    value={formDataUpdate.phone}
                                                    {...register1("phone", {
                                                        required: 'Phone number is required',
                                                        pattern: {
                                                            value: /^[6789]\d{9}$/,
                                                            message: 'Invalid phone number'
                                                        },
                                                        onChange: (e) => handleInputChange1("phone", e.target.value),
                                                    })}
                                                />
                                                {errors1.phone && (
                                                    <div className="errMsg text-danger">{errors1.phone.message}</div>
                                                )}
                                            </Form.Group>
                                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                                <Form.Label htmlFor="address">Address</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={4}
                                                    type="text"
                                                    id="address"
                                                    placeholder="address"
                                                    value={formDataUpdate.address}
                                                    {...register1("address", {
                                                        required: true,
                                                        onChange: (e) => handleInputChange1("address", e.target.value),
                                                    })}
                                                />
                                                {errors1.address && (
                                                    <div className="errMsg text-danger">Please Enter Address</div>
                                                )}
                                            </Form.Group>



                                            <div className="btn-d d-flex justify-content-center">
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    className="text-white btn-primary"
                                                >
                                                    Update
                                                </Button>
                                            </div>

                                        </Form>

                                    </Tab.Pane>

                                    {/* {formDataUpdate?.type === '2' && <>
                                        <Tab.Pane eventKey="memberships">
                                            <div className="">
                                                <MembershipHistory endPoint={`${apiPath.getUserMembership}/${id}`} />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="courses">
                                            <div className="">

                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="payments">

                                        </Tab.Pane>
                                    </>
                                    } */}


                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>



                </dvi>
            </Card >


        </>
    )
}

export default UpdateUser