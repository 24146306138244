import React from "react";
import { Navigate } from "react-router-dom";
import Login from "../login/Login";
import { useSelector } from "react-redux";
import useAuth from "../hooks/useAuth";


export default function ProtectedRoute({ children }) {

  const isAuth = useAuth();

  const { adminInfo, accessToken } = useSelector((state) => state?.admin);
  // let getAdmin = JSON.parse(localStorage.getItem("adminData"));



  if (!adminInfo && !accessToken && !isAuth) {
    return (
      <>
        <Login />
        <Navigate to={"/admin"} replace />
      </>
    );
  }
  return children;
}
