import React, { useState, useEffect } from "react";
import DataTables from "../../datatable/DataTable";
import { Axios, getApi, postApi } from "../../helper/helper";
import { Card } from "@mui/material";
import { useImmer } from "use-immer";
import Loader from "../../../components/Loader";
import { confirmStatus } from "../../component/common/handleData";
import { notify, notifyErr } from "../../component/common/notify";
import apiPath from "../../../apipath";
import { useForm } from "react-hook-form";
import { Button, Form, Modal } from "react-bootstrap";


const MembershipHistory = ({ endPoint }) => {
    const [data, setData] = useState([]);
    const [refundAmount, setRefundAmount] = useState('');
    const [transitionId, setTransitionId] = useState('');
    const [totalRows, setTotalRows] = useState(0);
    const [pending, setPending] = useState(true);
    let [filterData, setFilterData] = useImmer({
        page: 1,
        per_page: 10
    });
    const [show, setShow] = useState(false);

    const handleShow = (transaction_id, refund_amount) => {
        setShow(true);
        setRefundAmount(refund_amount);
        setTransitionId(transaction_id);
        reset({
            refund_amount: refund_amount
        });
    };

    const handleClose = () => {
        setShow(false);
        setRefundAmount("");
        setTransitionId("");
        reset({
            refund_amount: ""
        });
    };



    const fetchData = async (filteredData) => {
        setPending(true);
        try {
            const result = await Axios.get(endPoint, {
                params: filteredData,
            });
            if (result) {
                setData(result?.data?.data);
                setTotalRows(result?.data?.pagination?.total_data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setPending(false);
        }
    };

    useEffect(() => {
        fetchData(filterData);
    }, [endPoint, filterData]);

    const handlePageChange = page => {
        fetchData({ ...filterData, page: page });
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        // fetchData({ page: page, per_page: newPerPage });
        setFilterData({
            page: page,
            per_page: newPerPage
        })
    }


    const columns = [
        {
            name: <th className="fs-6 ">#</th>,
            cell: (row, index) => index + 1,
            width: "100px",
        },
        {
            name: <th className="fs-6">Status</th>,
            cell: (row, index) =>
                row.status == 0 ? (
                    <span
                        className="bg-success text-white p-1 rounded cursor-pointer"
                    // onClick={() =>
                    //     handleStatus("/api/updateMembershipStatus", row.id, "1")
                    // }
                    >
                        Active
                    </span>
                ) : row.status == 1 ? (
                    <span
                        className=" bg-danger text-white p-1 rounded cursor-pointer"
                    // onClick={() =>
                    //     handleStatus("/api/updateMembershipStatus", row.id, "0")
                    // }
                    >
                        Expired
                    </span>
                ) : (
                    "-"
                ),
            width: "220px",
        },
        {
            name: <th className="fs-6">Refund Request</th>,
            cell: (row, index) =>
                row.refund_status == 'requested' ? (
                    <>
                        <span
                            className="bg-info text-white p-1 rounded cursor-pointer mr-3"
                            onClick={() =>
                                handleShow(row.transaction_id, row.refund_amount)
                            }
                        >
                            Accept
                        </span>
                        <span
                            className="bg-danger text-white p-1 rounded cursor-pointer"
                            onClick={() =>
                                handleStatus("/api/membershipRefund", row.transaction_id, "reject")
                            }
                        >
                            Reject
                        </span>
                    </>
                ) : row.refund_status ? (
                    <span
                        className={`${row.refund_status == "reject" ? 'bg-danger' : 'bg-success'} text-white p-1 text-capitalize rounded cursor-pointer`}
                    >
                        {row.refund_status}
                    </span>
                ) : (
                    "-"
                ),
            width: "220px",
        },
        {
            name: <th className="fs-6 ">Refund amount</th>,
            selector: (row) => row.refund_amount,
            width: "200px",
        },
        {
            name: <th className="fs-6 ">User Name</th>,
            selector: (row) => row.user_name,
            width: "200px",
        },
        {
            name: <th className="fs-6">Membership Name</th>,
            selector: (row) => row.membership_name,
            width: "200px",
        },
        {
            name: <th className="fs-6">Amount</th>,
            selector: (row) => row.total_amount,
            width: "200px",
        },
        {
            name: <th className="fs-6">Transaction Id</th>,
            selector: (row) => row.transaction_id,
            width: "200px",
        },
        {
            name: <th className="fs-6">Start Date</th>,
            selector: (row) => row.start_date_time,
            width: "200px",
        },
        {
            name: <th className="fs-6">End Date</th>,
            selector: (row) => row.end_date_time,
            width: "200px",
        },

    ];


    const [searchText, setSearchText] = useState('');

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = data?.filter((item) =>
        ['user_name', 'membership_name', 'transaction_id', 'status'].some(
            (key) =>
                String(item[key]).toLowerCase().includes(searchText.toLowerCase())
        )
    );


    const {
        register,
        handleSubmit,
        reset,
        formState: { errors: errors },
    } = useForm();

    const onSubmit = async () => {
        setPending(true);
        try {
            const result = await postApi(`/api/${apiPath.paymentRefund}`, {
                transaction_id: transitionId,
                refund_amount: refundAmount
            });
            if (result) {
                handleClose();
                notify("Refund processed successfully!")
            }

        } catch (error) {
            console.error(error);
            notifyErr("Something went wrong!");
        } finally {
            handleClose();
            setPending(false);
        }
    };


    // const verifyRefund = async (data) => {
    //     // CaptureMembershipRefund
    //     try {
    //         const result = await postApi(`/api/${apiPath.createRefund}`, data);
    //         if (result) {
    //             console.log(result);
    //         }

    //     } catch (error) {
    //         console.error(error);
    //         notifyErr("Something went wrong!");
    //     } finally {
    //         setPending(false);
    //     }
    // }



    // handleStatus 
    const handleStatus = (endpoint, id, status) => {
        confirmStatus(endpoint, { transaction_id: id, refund_status: status })
            .then((message) => {
                fetchData(filterData);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <section>
            {pending && <Loader />}
            <Card variant="outlined" className="pb-3">
                <div className="d-flex justify-content-end">
                    <Form.Control
                        className="m-3"
                        style={{
                            width: 'fit-content'
                        }}
                        type="text"
                        placeholder="Search..."
                        value={searchText}
                        onChange={handleSearch}
                    />
                </div>
                <div>
                    <DataTables
                        title={"Subscription History"}
                        data={filteredData}
                        columns={columns}
                        pending={pending}
                        paginationServer={true}
                        totalRows={totalRows}
                        handlePerRowsChange={handlePerRowsChange}
                        handlePageChange={handlePageChange}
                    />
                </div>
            </Card>


            <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>
                <Modal.Header closeButton className="border-0">
                    {/* <Modal.Title>Upgrade Membership</Modal.Title> */}
                </Modal.Header>
                <Form
                    className="row m-2"
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete="off"
                >
                    <Modal.Body>
                        <h1 className="text-2xl mb-2">
                            Do you want to sure to Refund ?
                        </h1>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="refund_amount">Refund Amount</Form.Label>
                            <Form.Control
                                type="number"
                                id="refund_amount"
                                min="1"
                                placeholder="Refund Amount"
                                value={refundAmount}
                                {...register("refund_amount", {
                                    required: true,
                                    onChange: (e) => setRefundAmount(e.target.value),
                                })}
                            />
                            {errors.refund_amount && (
                                <div className="errMsg text-danger">Please Enter Refund Amount</div>
                            )}
                        </Form.Group>
                    </Modal.Body>
                    <div className="m-3 text-end">
                        <Button
                            type="submit"
                            variant="contained"
                            className="text-white btn-primary mr-3"
                        >
                            Yes, Refund
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </div>
                </Form>
            </Modal>


        </section >
    );
}

export default MembershipHistory