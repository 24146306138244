import React from "react";
import DataTable from "react-data-table-component";
import Spinner from "react-bootstrap/Spinner";

const DataTables = ({ data, columns, title, pending, customStyles, paginationServer, totalRows, handlePerRowsChange, handlePageChange }) => {
  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        // noHeader
        defaultSortField="id"
        defaultSortAsc={false}
        pagination
        highlightOnHover
        // dense
        title={title}
        responsive
        paginationServer={paginationServer}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        customStyles={customStyles}
        progressPending={pending}
        progressComponent={
          <Spinner animation="border" className={"mySpinner"} />
        }
        noDataComponent="No data found"
      />
      {/* <style>
        {`
          .rdt_TableCol:last-child,
          .rdt_TableCell:last-child {
            position: sticky;
            right: 0;
            background: #fff;
            z-index: 2;
          }
        `}
      </style> */}
    </>
  );
};

export default DataTables;
