import React, { useState, useEffect } from "react";
import Breadcrumb from "../../component/common/Breadcrumb";
import DataTables from "../../datatable/DataTable";
import { FaEdit, FaTrash } from "react-icons/fa";
import { getApi, postApi } from "../../helper/helper";
import { Button, Form, Modal } from "react-bootstrap";
import { Card } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import {
    notify,
    notifyErr,
} from "../../component/common/notify";
import {
    confirmDelete,
    confirmStatus,
} from "../../component/common/handleData";
import TextEditor from "../../editor/TextEditor";
import apiPath from "../../../apipath";
import Loader from "../../../components/Loader";


const Privacy = () => {
    const [data, setData] = useState([]);
    const [pending, setPending] = useState(true);
    const [privacyContent, setPrivacyContent] = useState("")
    const [termsConditionContent, setTermsConditionContent] = useState("")
    const [formDataPrivacy, setFormDataPrivacy] = useState({
        id: "",
        content: ""
    });
    const [formDataTermsConditions, setFormDataTermsConditions] = useState({
        id: "",
        content: ""
    });
    // const [show, setShow] = useState(false);
    // const [showUpdate, setShowUpdate] = useState(false);

    // const handleShow = () => {
    //     setShow(true);
    // };

    // const handleClose = () => {
    //     setShow(false);
    //     setFormData({});
    //     reset({
    //         content: "",
    //         type: "",
    //     });
    // };


    const handleInputChange = (field, value) => {
        setFormDataPrivacy({ ...formDataPrivacy, [field]: value });
    };

    const handleInputChange1 = (field, value) => {
        setFormDataTermsConditions({ ...formDataTermsConditions, [field]: value });
    };


    useEffect(() => {
        handleInputChange("content", privacyContent);
        handleInputChange1("content", termsConditionContent);
    }, [privacyContent, termsConditionContent])




    const fetchData = async () => {
        setPending(true);
        try {
            const [result] = await getApi(apiPath.getPrivacy);
            // setData(result);
            setFormDataPrivacy((prevFormData) => ({
                ...prevFormData,
                ["id"]: result.id,
                ["content"]: result.content,
            }))
            setPrivacyContent(result.content)
        } catch (error) {
            console.error(error);
        } finally {
            setPending(false);
        }
        try {
            const [result] = await getApi(apiPath.getTAndC);
            // setData(result);
            setFormDataTermsConditions((prevFormData) => ({
                ...prevFormData,
                ["id"]: result.id,
                ["content"]: result.content,
            }))
            setTermsConditionContent(result.content)
        } catch (error) {
            console.error(error);
        } finally {
            setPending(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        {
            name: <th className="fs-6 ">#</th>,
            cell: (row, index) => index + 1,
            width: "100px",
        },

        {
            name: <th className="fs-6 ">Content</th>,
            selector: (row) => row.content,
            width: "200px",
        },
        {
            name: <th className="fs-6">Type</th>,
            cell: (row, index) =>
                row.type === "1" ? "Privacy" : row.type === "2" ? "Disclaimer" : "-",
            width: "200px",
        },
        {
            name: <th className="fs-6 action">Action</th>,
            selector: (row) => (
                <div className="d-flex" style={{ gap: "10px" }}>
                    <Button
                        className="text-white border-0"
                        style={{ float: "right", backgroundColor: "var(--primary)" }}
                    >
                        <FaEdit />
                    </Button>

                    {/* <Button
                        className="bg-danger text-white border-0"
                     
                    >
                        <FaTrash />
                    </Button> */}
                </div>
            ),
            right: true,
        },
    ];

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors: errors },
    } = useForm();

    const {
        register: register1,
        handleSubmit: handleSubmit1,
        reset: reset1,
        formState: { errors: errors1 },
    } = useForm();


    const onUpdate = async (formData) => {
        setPending(true);
        try {
            const result = await postApi(apiPath.updatePrivacy, formData);
            notify("Content Updated Successfully!");
            fetchData()

        } catch (error) {
            console.error(error);
            notifyErr("Something went wrong!");
        } finally {
            setPending(false);
        }
    };

    const breadcrumbItems = [
        { title: 'Privacy', link: null }
    ];


    return (
        <section>

            <ToastContainer />

            {pending && <Loader />}

            <div className="mb-5">
                <Breadcrumb items={breadcrumbItems} />
            </div>

            <Card variant="outlined" className="pb-3">
                {/* <div className="btn-d d-flex justify-content-end m-3">
                    <Button
                        className="text-white border-0 default-btn"
                        onClick={() => handleShow()}
                    >
                        Add Privacy
                    </Button>
                </div> */}
                {/* <div>
                    <DataTables
                        title={"Privacy"}
                        data={data}
                        columns={columns}
                        pending={pending}
                    />
                </div> */}


                <Form
                    className="row m-2"
                    onSubmit={handleSubmit(() => onUpdate(formDataPrivacy))}
                    autoComplete="off"
                >
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="content">Privacy Content</Form.Label>
                        <TextEditor data={privacyContent} setUpdatedContent={setPrivacyContent} />
                    </Form.Group>
                    <div className="btn-d d-flex justify-content-center">
                        <Button
                            type="submit"
                            className="text-white border-0 default-btn btn btn-primary"
                        >
                            Update
                        </Button>
                    </div>
                </Form>

                <Form
                    className="row m-2"
                    onSubmit={handleSubmit(() => onUpdate(formDataTermsConditions))}
                    autoComplete="off"
                >
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="content">Terms & Conditions Content</Form.Label>
                        <TextEditor data={termsConditionContent} setUpdatedContent={setTermsConditionContent} />
                    </Form.Group>
                    <div className="btn-d d-flex justify-content-center">
                        <Button
                            type="submit"
                            className="text-white border-0 default-btn btn btn-primary"
                        >
                            Update
                        </Button>
                    </div>
                </Form>
            </Card>

            {/* add */}
            {/* <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>
               
                <Modal.Header closeButton>
                    <Modal.Title>Add Content</Modal.Title>
                </Modal.Header>
                <Form
                    className="row m-2"
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete="off"
                >
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="type">Type</Form.Label>
                            <Form.Select
                                type="type"
                                id="type"
                                placeholder="type"
                                value={formData.type}
                                {...register("type", {
                                    required: true,
                                    onChange: (e) => handleInputChange("type", e.target.value),
                                })}
                            >
                                <option value="">Select Type</option>
                                <option value="1">Privacy</option>
                                <option value="2">Disclaimer</option>
                            </Form.Select>
                            {errors.type && (
                                <div className="errMsg text-danger">Please Select Type</div>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="content">Content</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="type"
                                id="content"
                                value={formData.content}
                                {...register("content", {
                                    required: true,
                                    onChange: (e) => handleInputChange("content", e.target.value),
                                })}
                            />
                            {errors.content && (
                                <div className="errMsg text-danger">Please Enter Content</div>
                            )}
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            type="submit"
                            variant="contained"
                            className="text-white btn-primary"
                        >
                            Add
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal> */}

            {/* update */}
            {/* <Modal
                show={showUpdate}
                onHide={handleCloseUpdate}
                style={{ zIndex: "9999" }}
            >
                
                <Modal.Header closeButton>
                    <Modal.Title>Update Content</Modal.Title>
                </Modal.Header>
                <Form
                    className="row m-2"
                    onSubmit={handleSubmit1(onUpdate)}
                    autoComplete="off"
                >
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="type">Type</Form.Label>
                            <Form.Select
                                disabled
                                readonly
                                type="type"
                                id="type"
                                placeholder="type"
                                value={formDataUpdate.type}
                                {...register1("type", {
                                    required: true,
                                    // onChange: (e) => handleInputChange1("type", e.target.value),
                                })}
                            >
                                <option value="">Select Type</option>
                                <option value="1">Privacy</option>
                                <option value="2">Disclaimer</option>
                            </Form.Select>
                            {errors1.type && (
                                <div className="errMsg text-danger">Please Select Type</div>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="content">Content</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Content"
                                id="content"
                                value={formDataUpdate.content}
                                {...register1("content", {
                                    required: true,
                                    onChange: (e) => handleInputChange1("content", e.target.value),
                                })}
                            />
                            {errors1.content && (
                                <div className="errMsg text-danger">Please Enter Content</div>
                            )}
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            type="submit"
                            variant="contained"
                            className="text-white btn-primary"

                        >
                            Update
                        </Button>
                        <Button variant="secondary" onClick={handleCloseUpdate}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal> */}
        </section>
    )
}

export default Privacy