import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import bradcom from "../icons/bradcome-arrow.svg";
import { fetchDataFromApi } from "../libs/AllGlobalFunctions";
import apiPath from "../apipath";
import Loader from "../components/Loader";
import { _banner_, _page_ } from "../libs/ImagePath";
import { useSelector } from "react-redux";

const AboutCatalog = () => {

  const [loading, setLoading] = useState(false);
  const [aboutData, setAboutData] = useState([]);
  const [banner, setBanner] = useState({})

  const banners = useSelector((state) => state?.banner?.banners);
  const settings = useSelector((state) => state?.setting?.settings);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchTopCategories();
    if(banners){
      let [bannerData] = banners?.filter(i => i.page_id == 3);
       setBanner(bannerData);
     }
  }, [banners]);


  const fetchTopCategories = () => {
    setLoading(true);
    fetchDataFromApi(`${apiPath.getPageContent}/${3}`, null, 'GET', {}).then((response) => {
      if (response?.status == 200 && response?.data?.data) {
        setAboutData(response?.data?.data[0]);
      } else {
        setAboutData([])
      }
      setLoading(false);
    }
    ).catch((error) => {
      setLoading(false);
      console.log(error)
    })
  }

  return (
    <>
      {loading && <Loader />}
      <div className='pc-details-banner' style={{ backgroundImage: `url('${_banner_}/${banner?.banner_image}')` }}>
        <h2 className='pc-details-title'>A Large Range of Course Learning Paths</h2>
        <div className='p-couse-bradcom-wrapper'>
          <Link to={'/'} className='p-bradcom-item'>
            Home
          </Link>
          <img src={bradcom} alt='' />
          <Link to={"/about"} className="p-bradcom-item">
            About Us
          </Link>
        </div>
      </div>
      <section className="section_spacing">
        <div className="site-container">
          <div className="h-aboutus-wrapper">
            <div className="h-aboutus-right">
              <h5 className="h-about-title">ABOUT US</h5>
              <div className="section-title-wrap h-about-title-wrap">
                <h1 className="section-title ">
                  {aboutData?.title}
                </h1>
                <p className="section-title-contain">
                  {aboutData?.short_description}
                </p>
              </div>
              <ul className="h-about-item">
                <ul className='h-about-item'>
                  {
                    aboutData?.description?.split('\n')?.map((item) => {
                      return (
                        <li className='h-about-item-list'>{item}</li>
                      )
                    })
                  }
                </ul>
              </ul>
            </div>
            <div className="h-aboutus-left-img">
              <img src={`${_page_}/${aboutData?.image}`} alt='about' width={420} height={370} />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="section_spacing about-grid">
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-1 px-4 sm:px-8 md:px-24">
        <div className="col-span-1 m-auto">
            <img src={"https://demo.edublink.co/wp-content/uploads/2023/05/brand-01.png"} width={120} height={120} alt="" className="rounded-lg" />
        </div>
        <div className="col-span-1 m-auto">
            <img src={"https://demo.edublink.co/wp-content/uploads/2023/05/brand-02.png"} width={120} height={120} alt="" className="rounded-lg" />
        </div>
        <div className="col-span-1 m-auto">
            <img src={"https://demo.edublink.co/wp-content/uploads/2023/05/brand-03.png"} width={120} height={120} alt="" className="rounded-lg" />
        </div>
        <div className="col-span-1 m-auto">
            <img src={"https://demo.edublink.co/wp-content/uploads/2023/05/brand-04.png"} width={120} height={120} alt="" className="rounded-lg" />
        </div>
        <div className="col-span-1 m-auto">
            <img src={"https://demo.edublink.co/wp-content/uploads/2023/05/brand-05.png"} width={120} height={120} alt="" className="rounded-lg" />
        </div>
        <div className="col-span-1 m-auto">
            <img src={"https://demo.edublink.co/wp-content/uploads/2023/05/brand-06.png"} width={120} height={120} alt="" className="rounded-lg" />
        </div>
    </div>
</section> */}




      {/* <section className=" ">
        <div className="site-container">
          <div className="popular-courses">
            <div className="section-title-wrap mx-24">
              <div className="popular-title ">
                <h5 className="h-about-title ">WHY CHOOSE EDUBLINK</h5>
              </div>
              <div className="section-title-wrap h-about-title-wrap mt-6 flex justify-center">
                <h1 className="section-title leading-snug mx-48 text-center">
                  The Best <span className="text-lg">Beneficial</span> Side of EduBlink
                </h1>
              </div>

            </div>
          </div>
        </div>
      </section> */}





      {/* <section className="section_spacing about-cards">
        <div className="grid gap-2 lg:grid-cols-3 gap-4 mx-24">
          {cardData.map((card, index) => (
            <div className="col-span-1 h-auto" key={index}>
              <div className="about-grid-div">
                <img src={"https://img.icons8.com/?size=80&id=8Mv1uTg8hqAJ&format=png"} width={60} height={0} alt="" className="rounded-lg mx-auto" />
                <div className="px-6 py-4">
                  <div className="font-Spartan text-xl mb-2">
                    <h2 className="font-sans">{card.title}</h2>
                  </div>
                  <p className="section-title-contain pt-3 text-left">{card.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section> */}


      {/* <section className="section_spacing about-spacing">
        <div className="site-container">
          <div className="h-aboutus-wrapper">
            <div className="h-aboutus-right">
              <h5 className="h-about-title">TESTIMONIALS</h5>
              <div className="section-title-wrap h-about-title-wrap">
                <h1 className="section-title">
                  What Our Students Have To Say
                </h1>
                <p className="section-title-contain">
                  Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor incididunt labore dolore magna aliquaenim ad minim.
                </p>

                <Link to='/' className='m-btn m-btn-hero abt-btn-hero  mt-4 py-3'>
                  View All <i className='m-icon btn-icon-arrow'></i>
                </Link>
              </div>

            </div>
            <div className="h-aboutus-left">
              <div className="col-span-1  h-auto ">
                <div class="about-grid-div">
                  <img src={"https://demo.edublink.co/wp-content/uploads/2023/06/testimonial-04.jpg"} className="aboutus-image" />
                  <div class="px-6 py-4">

                    <p className="section-title-contain pt-3">
                      Lorem ipsum dolor sit amet conset ur elit sed eiusmod ex tempor inc labore dolore magna.    </p>

                    <div class="aboutus-left-div"><h2 className="font-sans ">Expert Instructors</h2></div>
                    <p className="section-title-contain text-left">Developer</p>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}




      {/* <section className="section_spacing about-cards">
        <div className="grid gap-2 lg:grid-cols-4 mx-24">
          {contentData.map((content, index) => (
            <div className="col-span-1 h-auto" key={index}>
              <div className="about-grid-div px-4">
                <img src={content.icon} width={60} height={0} alt="" className="rounded-lg mx-auto" />
                <div className="px-6 py-4">
                  <div className="font-Spartan text-4xl mb-2">
                    <h2 className="font-sans font-bold">{content.value}</h2>
                  </div>
                  <p className="section-title-contain pt-3">
                    {content.title}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section> */}


      {/* <section className=" ">
        <div className="site-container">
          <div className="popular-courses">
            <div className="section-title-wrap mx-24">
              <div className="popular-title ">
                <h5 className="h-about-title ">INSTRUCTORS</h5>
              </div>
              <div className="section-title-wrap h-about-title-wrap mt-6 flex justify-center">
                <h1 className="section-title leading-snug mx-48">
                  Course Instructors
                </h1>
              </div>

            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="section_spacing about-cards">
        <div className="grid gap-4 lg:grid-cols-3 mx-24">
          {posts.map((post, index) => (
            <div className="max-w-sm rounded overflow-hidden  text-center items-center px-4" key={index}>
              <img
                className=" h-auto w-full rounded-r "
                src={post.img}
                alt="image"
              />
              <div class="px-6 py-4 text-center items-center">
                <div className="font-Spartan  text-xl mb-2">
                  <h2 className="font-sans ">
                    {post.title}
                  </h2>
                  <p className="section-title-contain pt-2">
                    {post.heading}
                  </p>

                </div>
                <p className="section-title-contain text-sm pt-3">
                  {post.content}</p>
              </div>
            </div>
          ))}
        </div>

      </section> */}

      <section className="section_spacing pt-0">
        <div className="site-container">
          <div className="call-to-action-wrapper">
            <div className="cta-items">
              <h5>Get In Touch:</h5>
              <a
                className="cta-items-mail"
                href={`mailto:${settings?.enq_email}`}
              >
                {settings?.enq_email}
              </a>
            </div>
            <div className=" cta-items cta-or-ditem">
              <h4 className="cta-or-title">or</h4>
            </div>

            <div className="cta-items">
              <h5>Get In Touch:</h5>
              <Link to={`tel:${settings?.phone}`} className="cta-items-mail">
                {settings?.phone}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutCatalog;