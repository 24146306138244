import React, { useState, useEffect } from "react";
import Breadcrumb from "../../component/common/Breadcrumb";
import DataTables from "../../datatable/DataTable";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Axios, getApi } from "../../helper/helper";
import { Button, Form } from "react-bootstrap";
import { Card } from "@mui/material";
import { Link } from "react-router-dom";
import apiPath from "../../../apipath";
import { useImmer } from "use-immer";
import Loader from "../../../components/Loader";

const Payment = () => {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  let [filterData, setFilterData] = useImmer({
    page: 1,
    per_page: 10
  });

  const fetchTableData = async (filteredData) => {
    setPending(true);
    try {
      const result = await Axios.get(`/api/getCheckout`, {
        params: filteredData,
      });
      if (result) {
        setData(result?.data?.data);
        setTotalRows(result?.data?.pagination?.total_data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    fetchTableData(filterData);
  }, [filterData])

  const handlePageChange = page => {
    fetchTableData({ ...filterData, page: page });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    // fetchTableData({ page: page, per_page: newPerPage });
    setFilterData({
      page: page,
      per_page: newPerPage
    })
  }

  const columns = [
    {
      name: <th className="fs-6 ">#</th>,
      cell: (row, index) => index + 1,
      width: "100px",
    },

    {
      name: <th className="fs-6 ">Name</th>,
      selector: (row) => row.name,
      width: "200px",
    },
    {
      name: <th className="fs-6">Transaction Status</th>,
      cell: (row, index) => (
        <span className={`${row.transaction_status == "Pending" ? 'bg-info' : row.transaction_status == "Complete" ? 'bg-success' : 'bg-danger'} text-white p-1 rounded cursor-pointer`}>{row.transaction_status}</span>
      ),
      width: "220px",
    },
    {
      name: <th className="fs-6">Email</th>,
      selector: (row) => row.email,
      width: "300px",
    },
    {
      name: <th className="fs-6">Checkout Id</th>,
      selector: (row) => row.checkout_id,
      width: "300px",
    },
    {
      name: <th className="fs-6">Order Id</th>,
      selector: (row) => row.rzorder_id,
      width: "200px",
    },
    {
      name: <th className="fs-6">Amount</th>,
      selector: (row) => row.total_amount,
      width: "200px",
    },
    {
      name: <th className="fs-6">Amount After Coupon Discount</th>,
      selector: (row) => row.amount_after_coupon_discount,
      width: "200px",
    },
    {
      name: <th className="fs-6">Gst Amount</th>,
      selector: (row) => row.gst_amount,
      width: "200px",
    },
    {
      name: <th className="fs-6">Total Amount</th>,
      selector: (row) => row.sub_total,
      width: "200px",
    },
    {
      name: <th className="fs-6">Transaction Id</th>,
      selector: (row) => row.transaction_id,
      width: "200px",
    },
    {
      name: <th className="fs-6">Course Name</th>,
      selector: (row) => row.course_name,
      width: "200px",
    },
    {
      name: <th className="fs-6">Start Date</th>,
      selector: (row) => row.start_date_time,
      width: "200px",
    },
    {
      name: <th className="fs-6">End Date</th>,
      selector: (row) => row.end_date_time,
      width: "200px",
    },

    // {
    //   name: <th className="fs-6 action">Action</th>,
    //   selector: (row) => (
    //     <div className="d-flex" style={{ gap: "10px" }}>
    //       <Link to={`/admin/update-plan/${row._id}`}>
    //         <Button
    //           className="text-white border-0"
    //           style={{ float: "right", backgroundColor: "var(--primary)" }}
    //         >
    //           <FaEdit />
    //         </Button>
    //       </Link>
    //       <Button className="bg-danger text-white border-0">
    //         <FaTrash />
    //       </Button>
    //     </div>
    //   ),
    //   right: true,
    // },
  ];


  const [searchText, setSearchText] = useState('');

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data?.filter((item) =>
    ['billing_name', 'txn_id'].some(
      (key) =>
        String(item[key]).toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const breadcrumbItems = [
    { title: 'Payment', link: null }
  ];

  return (
    <section>

      {pending && <Loader />}

      <div className="mb-5">
        <Breadcrumb items={breadcrumbItems} />
      </div>

      <Card variant="outlined" className="pb-3">
        <div className="d-flex justify-content-end">
          <Form.Control
            className="m-3"
            style={{
              width: 'fit-content'
            }}
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={handleSearch}
          />
        </div>
        <div>
          <DataTables
            title={"Payment"}
            data={filteredData}
            columns={columns}
            pending={pending}
            paginationServer={true}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
          />
        </div>
      </Card>
    </section>
  );
};

export default Payment;
