import React, { useState, useEffect } from "react";
import { getApi, postApi } from "../../helper/helper";
import { Form } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { Card, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  notify,
  notifyErr,
} from "../../component/common/notify";
import { useParams } from "react-router-dom";
import TextEditor from "../../editor/TextEditor";
import Breadcrumb from "../../component/common/Breadcrumb";
import apiPath from "../../../apipath";
import Loader from "../../../components/Loader";


const UpdatePortfolio = () => {

  const { id } = useParams();

  const [formDataUpdate, setFormDataUpdate] = useState({});
  const [pending, setPending] = useState(true);
  const [updatedContent, setUpdatedContent] = useState('');

  const handleClose = () => {
    setFormDataUpdate({});
    reset({
      title: "",
      description: "",
      short_description: "",
      category_type: "",
      research_type: "",
    });
  };

  const handleInputChange = (field, value) => {
    setFormDataUpdate({ ...formDataUpdate, [field]: value });
  };

  useEffect(() => {
    handleInputChange('description', updatedContent)
  }, [updatedContent])

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: errors },
  } = useForm();

  const getIndividualData = async (id) => {
    setPending(true);
    try {
      const result = await getApi(`${apiPath.getSinglePortfolio}/${id}`);
      const data = result[0];
      if (data) {
        setFormDataUpdate((prevFormDataUpdate) => ({
          ...prevFormDataUpdate,
          ["id"]: data.id,
          ["title"]: data.title,
          ["description"]: data.description
        }));
        reset({
          title: data.title,
          description: data.description
        });
        setUpdatedContent(data.description)
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getIndividualData(id)
  }, [id]);


  const onUpdate = async () => {
    setPending(true);
    try {
      const result = await postApi(apiPath.updatePortfolio, {
        ...formDataUpdate,
      });

      handleClose();
      notify("Portfolio Updated Successfully!");
      getIndividualData(id);

    } catch (error) {
      console.error(error);
      notifyErr("Something went wrong!");
    }
  };

  const breadcrumbItems = [
    { title: 'Portfolio', link: '/admin/portfolio' },
    { title: 'Update Portfolio', link: null }
  ];

  return (
    <>
      <ToastContainer />
      {pending && <Loader />}
      <section>

        <div className="mb-5">
          <Breadcrumb items={breadcrumbItems} />
        </div>

        <Card variant="outlined" className="pb-3">
          <div className="m-3">
            <Form
              className="row"
              onSubmit={handleSubmit(onUpdate)}
              autoComplete="off"
            >
              <Form.Group className="col-lg-6 col-12 mb-3">
                <Form.Label htmlFor="title">Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Title"
                  id="title"
                  value={formDataUpdate.title}
                  {...register("title", {
                    required: true,
                    onChange: (e) => handleInputChange("title", e.target.value),
                  })}
                />
                {errors.title && (
                  <div className="errMsg text-danger">Please Enter Title</div>
                )}
              </Form.Group>
              <Form.Group className="col-12 mb-3">
                <Form.Label>Content</Form.Label>
                <TextEditor data={updatedContent} setUpdatedContent={setUpdatedContent} />

                {errors.description && (
                  <div className='errMsg text-danger'>description is required</div>
                )}
              </Form.Group>
              <div className='btn-d mt-4 text-end'>
                <Button
                  type="submit"
                  variant="contained"
                  className="text-white btn-primary"
                >
                  Update
                </Button>
              </div>
            </Form>
          </div>
        </Card>

      </section>
    </>
  )
}

export default UpdatePortfolio