import React from 'react'
import popularitem from '../../images/course-img.jpg'
import staricon from '../../icons/star.svg'
import { Link } from 'react-router-dom'
import { _courseImg_ } from '../../libs/ImagePath'
import { replaceSpacesWithHyphens } from '../../libs/AllGlobalFunctions'
import { Rating } from '@mui/material'
import { useSelector } from 'react-redux'

const StarRating = ({ rating }) => {
  const stars = Array.from({ length: rating }, (_, index) => (
    <i key={index} className='star-icon'>
      <img src={staricon} alt='' />
    </i>
  ));
  return <div className='h-pc-raitting-items tst-raitting'>{stars}</div>;
};

export default function CourseCard({ course }) {

  const { userInfo } = useSelector((state) => state?.auth);

  return (
    <div className='h-pc-items max-w-sm'>
      <div className='h-pc-items-list h-full'>
        <div className='h-pc-item-img'>
          <img src={`${_courseImg_}/${course?.image}`} alt='' />{' '}
        </div>
        <div className='h-pc-item-details'>
          <span className='h-pc-brand-name'>{course?.difficulty_level}</span>
          <h3 className='h-pc-item-title'>
            {course?.title}
          </h3>
          {/* {course?.average_rating > 0 && */}
          <div className='h-pc-raiting'>
            {/* <StarRating rating={course?.average_rating} /> */}
            <Rating name="half-rating-read" defaultValue={course?.average_rating} precision={0.5} readOnly />
            <p className='h-pc-raitting-name'>({course?.average_rating} / {course?.reviews_count} Reviews)</p>
          </div>
          {/* } */}
          <h4 className='h-pc-price'>₹ {course?.price}</h4>
          <div className='h-pc-lesson-wrap'>
            <p className='h-pc-lesson-item'>
              <i>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='#a7a7a7'
                  height='20'
                  viewBox='0 -960 960 960'
                  width='20'
                >
                  <path d='m590-488 160-92-160-92-160 92 160 92Zm0 122 110-64v-84l-110 64-110-64v84l110 64ZM480-480Zm320 320H600q0-20-1.5-40t-4.5-40h206v-480H160v46q-20-3-40-4.5T80-680v-40q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160Zm-720 0v-120q50 0 85 35t35 85H80Zm200 0q0-83-58.5-141.5T80-360v-80q117 0 198.5 81.5T360-160h-80Zm160 0q0-75-28.5-140.5t-77-114q-48.5-48.5-114-77T80-520v-80q91 0 171 34.5T391-471q60 60 94.5 140T520-160h-80Z' />
                </svg>
              </i>
              {course?.total_lesson} Lessons
            </p>
            <p className='h-pc-lesson-item'>
              <i>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='#a7a7a7'
                  height='20'
                  viewBox='0 -960 960 960'
                  width='20'
                >
                  <path d='M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z' />
                </svg>
              </i>{' '}
              {course?.total_student}  Students
            </p>
          </div>
        </div>
      </div>

      {/* after hover view  */}
      <div className='h-pc-item-hover'>
        {/* <div className='h-pc-wishlist'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            height='24'
            fill='white'
            viewBox='0 -960 960 960'
            width='24'
          >
            <path d='m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z' />
          </svg>
        </div> */}
        <span className='h-pc-brand-name h-pc-brand-name_white'>
          {course?.difficulty_level}
        </span>
        <h3 className='h-pc-item-title h-pc-item-title_white'>
          {course?.title}
        </h3>
        <div className='h-pc-raiting'>
          {/* {<StarRating rating={course?.rating} />} */}
          <Rating name="half-rating-read" defaultValue={course?.average_rating} precision={0.5} readOnly />
          <p className='h-pc-raitting-name'>({course?.average_rating} / {course?.reviews_count} Reviews)</p>
        </div>

        <h4 className='h-pc-price h-pc-price_white'></h4>
        <p className='h-pc-hover-contains'>
          {course?.description}
        </p>
        <div className='h-pc-lesson-wrap'>
          <p className='h-pc-lesson-item'>
            <i>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='#fff'
                height='20'
                viewBox='0 -960 960 960'
                width='20'
              >
                <path d='m590-488 160-92-160-92-160 92 160 92Zm0 122 110-64v-84l-110 64-110-64v84l110 64ZM480-480Zm320 320H600q0-20-1.5-40t-4.5-40h206v-480H160v46q-20-3-40-4.5T80-680v-40q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160Zm-720 0v-120q50 0 85 35t35 85H80Zm200 0q0-83-58.5-141.5T80-360v-80q117 0 198.5 81.5T360-160h-80Zm160 0q0-75-28.5-140.5t-77-114q-48.5-48.5-114-77T80-520v-80q91 0 171 34.5T391-471q60 60 94.5 140T520-160h-80Z' />
              </svg>
            </i>
            {course?.total_lesson} Lessons
          </p>
          <p className='h-pc-lesson-item'>
            <i>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='#fff'
                height='20'
                viewBox='0 -960 960 960'
                width='20'
              >
                <path d='M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z' />
              </svg>
            </i>{' '}
            {course?.total_student} Students
          </p>
        </div>
        <div className='h-pc-explore-btn'>
          <Link to={`/course/${course?.id}/${course?.title && replaceSpacesWithHyphens(course?.title)}`} className='explore-btn'> {userInfo?.course_ids?.includes(course?.id) ? 'View Course' : 'Buy Now'}<i className='m-icon btn-icon-arrow'></i></Link>
        </div>
      </div>

      {/* hover ifect  */}
    </div>
  )
}
