import { api } from "./Interceptors"

const axiosProvider = async (data) => {

    let response = null

    let config = {
        ...data,
        headers: {
            ...data.headers,
        }
    }

    let apiURL = `/api/${config.endpoint}`

    if (config && config.queryString) {
        apiURL += config.queryString
    }

    try {
        switch (config.method) {
            case 'GET':
                response = await api.get(apiURL, {
                    params: config.params || {},
                    headers: config.headers || {}
                })
                break
            case 'POST':
                response = await api.post(apiURL, config.data, {
                    params: config.params,
                    headers: config.headers
                })
                break
            case 'PUT':
                response = await api.put(apiURL, config.data, {
                    params: config.params,
                    headers: config.headers
                })
                break
            case 'PATCH':
                response = await api.patch(apiURL, config.data, {
                    params: config.params,
                    headers: config.headers
                })
                break
            case 'DELETE':
                response = await api.delete(apiURL, {
                    params: config.params,
                    headers: config.headers
                })
                break
            default:
                break

        }

        return response ? response : []
    } catch (error) {
        return []
    }
}

export default axiosProvider;