import { Route, Routes } from "react-router-dom";
import React from "react";
import Home from "./pages/Home";
import ProtectedRoute from "./admin/route/ProtectedRoute";
import DashboardLayout from "./admin/layout/DashboardLayout";
import Enquiry from "./admin/pages/enquiry/Enquiry";
import Profile from "./admin/pages/profile/Profile";
import Setting from "./admin/pages/setting/Setting";
import Login from "./admin/login/Login";
import Dashboard from "./admin/pages/dashboard/Dashboard";
import Users from "./admin/pages/users/Users";
import Course from "./admin/pages/course/Course";
import Portfolio from "./admin/pages/portfolio/Portfolio";
import Category from "./admin/pages/category/Category";
import Instructor from "./admin/pages/instructor/Instructor";
import Subscription from "./admin/pages/subscription/Subscription";
import PageContent from "./admin/pages/pagecontent/PageContent";
import CourseDetails from "./admin/pages/course/CourseDetails";
import SEO from "./admin/pages/seo/SEO";
import StockSymbol from "./admin/pages/stocksymbol/StockSymbol";
import Privacy from "./admin/pages/privacy/Privacy";
import CourseCatalog from "./pages/CourseCatalog";
import CourseDetail from "./pages/course/CourseDetail";
import AddPortfolio from "./admin/pages/portfolio/AddPortfolio";
import UpdatePortfolio from "./admin/pages/portfolio/UpdatePortfolio";
import UserLogin from "./pages/auth/UserLogin";
import AboutCatalog from "./pages/AboutCatalog";
import ContactDetails from "./pages/ContactDetails";
import Cart from "./pages/Cart";
import SignUp from "./pages/auth/SignUp";
import Checkout from "./pages/user/checkout";
import ForgetPassword from "./pages/auth/ForgetPassword";
import UserDashboard from "./pages/user/UserDashboard";
import UserProtectedRoute from "./libs/UserProtectedRoute";
import UserProfile from "./pages/user/dashboard/UserProfile";
import CourseLessons from "./pages/course/CourseLessons";
import LessonsContent from "./pages/course/LessonsContent";
import UserPortfolio from "./pages/portfolio/UserPortfolio";
import UpdateUser from "./admin/pages/users/UpdateUser";
import StockHoliday from "./admin/pages/holiday/StockHoliday";
import MyCourses from "./pages/user/dashboard/MyCourses";
import UserMembership from "./pages/user/dashboard/UserMembership";
import ChangePassword from "./pages/user/dashboard/ChangePassword";
import Banner from "./admin/pages/banner/Banner";
import ActiveLogs from "./pages/user/dashboard/ActiveLogs";
import Transaction from "./pages/user/dashboard/Transaction";
import ResetPassword from "./pages/ResetPassword";
import TermsCondition from "./pages/TermsCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NotFoundPage from "./NotFoundPage";
import UserStockGraph from "./pages/UserStockGraph";
import UserNotification from "./pages/user/dashboard/UserNotification";
import VerifyAccount from "./pages/VerifyAccount";
import VerifyComponent from "./pages/VerifyComponent";
import Coupon from "./admin/pages/coupon/Coupon";
import Payment from './admin/pages/payment/Payment';

const AllRouters = () => {
  return (
    <Routes>
      <Route
        path="/admin"
        element={
          <ProtectedRoute>
            <DashboardLayout />
          </ProtectedRoute>
        }
      >
        <Route exact path="/admin" element={<Dashboard />} />

        <Route exact path="/admin/category" element={<Category />} />

        <Route exact path="/admin/course" element={<Course />} />

        <Route exact path="/admin/course/:courseId" element={<CourseDetails />} />

        <Route exact path="/admin/enquiry" element={<Enquiry />} />

        <Route exact path="/admin/instructor" element={<Instructor />} />

        <Route exact path="/admin/banner" element={<Banner />} />

        <Route exact path="/admin/page-content" element={<PageContent />} />

        <Route exact path="/admin/payment" element={<Payment />} />
        
        <Route exact path="/admin/coupon" element={<Coupon />} />

        <Route exact path="/admin/profile" element={<Profile />} />

        <Route exact path="/admin/Portfolio" element={<Portfolio />} />

        <Route exact path="/admin/Portfolio/add-Portfolio" element={<AddPortfolio />} />

        <Route exact path="/admin/Portfolio/update-Portfolio/:id" element={<UpdatePortfolio />} />

        <Route exact path="/admin/setting" element={<Setting />} />

        <Route exact path="/admin/seo" element={<SEO />} />

        <Route exact path="/admin/stock-symbol" element={<StockSymbol />} />

        <Route exact path="/admin/stock-holiday" element={<StockHoliday />} />

        <Route exact path="/admin/subscription" element={<Subscription />} />

        <Route exact path="/admin/users" element={<Users />} />

        <Route exact path="/admin/users/:id" element={<UpdateUser />} />

        <Route exact path="/admin/enquiry" element={<Enquiry />} />

        <Route exact path="/admin/privacy" element={<Privacy />} />
      </Route>

      <Route path="/admin" element={<Login />} />

      <Route path="/" element={<Home />} />

      {/*--------------------------- All User Panel Routers------------------------------------------- */}

      {/* all public routes */}
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/auth/login" element={<UserLogin />} />
      <Route path="/auth/signup" element={<SignUp />} />
      <Route path="/auth/forget-password" element={<ForgetPassword />} />
      <Route path="/course" element={<CourseCatalog />} />
      <Route path="/course/:id/:title" element={<CourseDetail />} />
      <Route path="auth/reset-password" element={<ResetPassword />} />

      <Route path="/about" element={<AboutCatalog />} />
      <Route path="/contact" element={<ContactDetails />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/terms-condition" element={<TermsCondition />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/instruments/:exchange" element={<UserStockGraph />} />

      <Route path="/lessons/:id" element={<CourseLessons />}>
        <Route path=":lessonId" element={<LessonsContent />} />
      </Route>

      <Route path="/verify-email" element={<VerifyComponent />}>
        <Route index element={<VerifyAccount />} />
      </Route>

      {/* All Protected Routes */}
      <Route element={<UserProtectedRoute />} >
        <Route path="/dashboard" element={<UserDashboard />}>
          <Route index element={<UserProfile />} />
          <Route path="my-courses" element={<MyCourses />} />
          <Route path="membership" element={<UserMembership />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="active-logs" element={<ActiveLogs />} />
          <Route path="transaction-history" element={<Transaction />} />
          <Route path="notification" element={<UserNotification />} />
        </Route>
        <Route path="/portfolio" element={<UserPortfolio />} />
        <Route path="/checkout" element={<Checkout />} />
      </Route>
    </Routes >
  );
};

export default AllRouters;
