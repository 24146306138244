import { useState } from "react";
import apiPath from "../../../apipath";
import { fetchDataFromApi } from "../../../libs/AllGlobalFunctions";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { handlePageClick } from "../../../libs/AllGlobalFunctions";
import { useImmer } from "use-immer";
import Loader from "../../../components/Loader";
import DataTables from "../../../admin/datatable/DataTable";
import Modal from 'react-bootstrap/Modal';
import axiosProvider from "../../../libs/axios/axiosProvider";
import { useSelector } from "react-redux";
import useToast from "../../../hooks/useToast";
import Toster from "../../../components/Toster";

const Transaction = () => {

    const [loading, setLoading] = useState(false);
    const [transactionList, setTransactionList] = useState([]);
    const [tnx_id, setTnx_id] = useState('');
    const [totalRows, setTotalRows] = useState(0);
    let [filterData, setFilterData] = useImmer({
        page: 1,
        per_page: 10
    });
    const [show, setShow] = useState(false);
    const { toast, showToast } = useToast();

    const { userInfo, accessToken } = useSelector((state) => state?.auth);

    const handleClose = () => {
        setShow(false);
        setTnx_id('');
    };
    const handleShow = (tnx) => {
        setShow(true)
        setTnx_id(tnx);
    };

    useEffect(() => {
        fetchTransaction(filterData);
        window.scrollTo(0, 0);
    }, [filterData]);

    const fetchTransaction = (filteredData) => {
        setLoading(true);
        fetchDataFromApi(apiPath.getTransactionHistory, null, 'GET', filteredData).then((data) => {
            if (data) {
                setTransactionList(data?.data?.data);
                setTotalRows(data?.data?.pagination?.total_data);
            } else {
                setTransactionList([]);
            }
            setLoading(false);
        }
        ).catch((error) => {
            console.log(error);
            setLoading(false);
        })
    }

    const handlePageChange = page => {
        fetchTransaction({ ...filterData, page: page });
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        // fetchTransaction({ page: page, per_page: newPerPage });
        setFilterData({
            page: page,
            per_page: newPerPage
        })
    }

    const customStyles = {
        headRow: {
            style: {
                border: 'none',
            },
        },
        headCells: {
            style: {
                color: '#202124',
                fontSize: '14px',
            },
        },
        rows: {
            highlightOnHoverStyle: {
                backgroundColor: 'rgb(230, 244, 244)',
                borderBottomColor: '#FFFFFF',
                borderRadius: '25px',
                outline: '1px solid #FFFFFF',
            },
        },
        pagination: {
            style: {
                border: 'none',
            },
        },
    };


    const columns = [
        {
            name: <th className="fs-6 ">#</th>,
            cell: (row, index) => index + 1,
            width: "100px",
        },
        {
            name: <th className="fs-6 ">Name</th>,
            selector: (row) => row.name,
            width: "200px",
        },
        {
            name: <th className="fs-6 ">Email</th>,
            selector: (row) => row.email,
            width: "200px",
        },
        {
            name: <th className="fs-6">Price</th>,
            selector: (row) => row.total_amount,
            width: "200px",
        },
        {
            name: <th className="fs-6">Amount After Coupon Discount</th>,
            selector: (row) => row.amount_after_coupon_discount,
            width: "200px",
        },
        {
            name: <th className="fs-6">Gst Amount</th>,
            selector: (row) => row.gst_amount,
            width: "200px",
        },
        {
            name: <th className="fs-6">Transaction Id</th>,
            selector: (row) => row.transaction_id,
            width: "200px",
        },
        // {
        //     name: <th className="fs-6">Date</th>,
        //     selector: (row) => row.date_time,
        //     width: "200px",
        // },
        {
            name: <th className="fs-6">Type</th>,
            selector: (row) => {
                if (row.type === 'checkout') {
                    return `Checkout (${row.course_name})`;
                } else if (row.type === 'membership') {
                    return `Membership (${row.membership_name})`;
                } else {
                    return row.type;
                }
            },
            width: "250px",
        },
        {
            name: <th className="fs-6">Start Date</th>,
            selector: (row) => row.start_date_time,
            width: "220px",
        },
        {
            name: <th className="fs-6">End Date</th>,
            selector: (row) => row.end_date_time,
            width: "220px",
        },
        {
            name: <th className="fs-6">Status</th>,
            selector: (row) => row.transaction_status,
            width: "220px",
        },
        {
            name: <th className="fs-6 action">Action</th>,
            selector: (row) => (
                <div className="d-flex" style={{ gap: "10px" }}>
                    {(row.canRefund == true && row.refund_status == '') &&
                        <button
                            onClick={() => handleShow(row.transaction_id)}
                            className="bg-teal text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Refund
                        </button>}
                    {row.refund_status &&
                        <button
                            disabled
                            className=" text-teal font-semibold text-capitalize py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            {row.refund_status}
                        </button>
                    }
                </div>
            ),
            right: true,
        },
        // canRefund
    ];


    const [searchText, setSearchText] = useState('');

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = transactionList?.filter((item) =>
        ['name', 'email', 'transaction_id', 'transaction_status', 'type', 'total_amount', 'date_time'].some(
            (key) =>
                String(item[key]).toLowerCase().includes(searchText.toLowerCase())
        )
    );


    const refundRequest = async () => {
        try {
            const response = await axiosProvider({
                method: "POST",
                endpoint: apiPath.membershipRefund,
                data: { transaction_id: tnx_id, refund_status: "requested" },
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200 && response?.data?.response_code == 200) {
                fetchTransaction(filterData);
                handleClose();
                showToast(response?.data?.message ? response?.data?.message : "Refund Request Submitted!", "success", 3000);
            } else if (response.status === 200 && response?.data?.response_code == 204) {
                showToast(response?.data?.message ? response?.data?.message : "Something went wrong !", "error", 3000);
            } else {
                showToast("Something went wrong !", "error", 3000);
            }

            window.scrollTo(0, 0);
            setLoading(false);

        } catch (error) {
            console.log(error);
            showToast("Something went wrong !", "error", 3000);
            setLoading(false)
        }
    }

    return (
        <section className='section_spacing w-full section_spacing-space'>
            <div className=''>
                <h2 className="mb-3 text-2xl">Transaction History</h2>
                {loading && <Loader />}
                {toast?.show && (
                    <Toster text={toast?.text} variation={toast?.variation} />
                )}
                <div className="d-flex justify-content-end">
                    <input
                        className="m-3"
                        style={{
                            width: 'fit-content'
                        }}
                        type="text"
                        placeholder="Search..."
                        value={searchText}
                        onChange={handleSearch}
                    />
                </div>
                <DataTables
                    data={filteredData}
                    columns={columns}
                    paginationServer={true}
                    customStyles={customStyles}
                    totalRows={totalRows}
                    handlePerRowsChange={handlePerRowsChange}
                    handlePageChange={handlePageChange}
                // pending={loading}
                />
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className="border-0">
                </Modal.Header>

                <Modal.Body>
                    <h1 className="text-2xl mb-2">
                        Do you want to cancel membership and refund ?
                    </h1>
                </Modal.Body>

                <div className="m-3 text-end">
                    <button className='text-white font-Spartan font-medium text-18 px-6 py-[0.75rem] rounded-lg bg-[#58647B] text-center mr-3' type='button' onClick={() => handleClose()}>
                        Cancel
                    </button>
                    <button className='m-btn bg-danger text-center' type='button'
                        onClick={() => refundRequest()}
                    >
                        Yes, Refund
                    </button>
                </div>
            </Modal>

        </section >
    )
}

export default Transaction;