import React, { useState, useEffect } from "react";
import Breadcrumb from "../../component/common/Breadcrumb";
import DataTables from "../../datatable/DataTable";
import { FaEdit, FaTrash } from "react-icons/fa";
import { getApi, postApi } from "../../helper/helper";
import { Button, Form, Modal } from "react-bootstrap";
import { Card } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import { notify, notifyErr } from "../../component/common/notify";
import apiPath from "../../../apipath";
import Loader from "../../../components/Loader";

const PageContent = () => {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
  const [formData, setFormData] = useState({
    image: "",
  });
  const [formDataUpdate, setFormDataUpdate] = useState({
    image: "",
  });
  const [image, setImage] = useState("");
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setFormData({
      image: "",
    });
    reset({
      title: "",
      short_description: "",
      description: "",
      image: "",
      page_id: ""
    });
  };

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleShowUpdate = (row) => {
    setShowUpdate(true);
    getIndividualData(row.id);
  };

  const handleCloseUpdate = () => {
    setShowUpdate(false);
    setFormDataUpdate({
      image: "",
    });
    reset1({
      title: "",
      short_description: "",
      description: "",
      image: "",
      page_id: ""
    });
  };

  const handleInputChange1 = (field, value) => {
    setFormDataUpdate({ ...formDataUpdate, [field]: value });
  };

  const fetchData = async () => {
    setPending(true);
    try {
      const result = await getApi(`/api/${apiPath.getPageContentList}`);
      setData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      name: <th className="fs-6 ">#</th>,
      cell: (row, index) => index + 1,
      width: "100px",
    },

    {
      name: <th className="fs-6 ">Page name</th>,
      cell: (row) => {
        switch (row.page_id) {
          case '1':
            return 'Home'

          case '2':
            return 'Course'

          case '3':
            return 'About'

          case '4':
            return 'Contact'

        }
      },
      width: "200px",
    },
    {
      name: <th className="fs-6 ">Title</th>,
      selector: (row) => row.title,
      width: "200px",
    },
    {
      name: <th className="fs-6">Description</th>,
      selector: (row) => row.description,
      width: "300px",
    },
    {
      name: <th className="fs-6">Image</th>,
      selector: (row) => <img src={`/api/assets/page/${row.image}`} width="60px" height="60px" className="p-2" alt='Image' />,
      width: "200px",
    },
    // {
    // name: <th className="fs-6">Status</th>,
    // cell: (row, index) =>
    // row.status == 0 ? (
    // <span className="bg-info text-white p-1 rounded cursor-pointer">Pending</span>
    // ) : row.status == 1 ? (
    // <span className=" bg-success text-white p-1 rounded cursor-pointer">Complete</span>
    // ) : (
    // "-"
    // ),
    // width: "220px",
    // },
    {
      name: <th className="fs-6">Short Description</th>,
      selector: (row) => row.short_description,
      width: "300px",
    },
    {
      name: <th className="fs-6 action">Action</th>,
      selector: (row) => (
        <div className="d-flex" style={{ gap: "10px" }}>
          <Button
            className="text-white border-0"
            style={{ float: "right", backgroundColor: "var(--primary)" }}
            onClick={() => handleShowUpdate(row)}
          >
            <FaEdit />
          </Button>

          {/* <Button
 className="bg-danger text-white border-0"
 >
 <FaTrash />
 </Button> */}
        </div>
      ),
      right: true,
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: errors },
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm();

  const onSubmit = async () => {
    setPending(true);
    try {
      const result = await postApi(apiPath.addPageContent, formData);
      handleClose()
      notify("Page Added Successfully!");
      fetchData();

    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  const getIndividualData = async (id) => {
    setPending(true);
    try {
      const result = await getApi(`/api/${apiPath.getPageContentList}/${id}`);
      const data = result[0];
      if (data) {
        setFormDataUpdate((prevFormDataUpdate) => ({
          ...prevFormDataUpdate,
          ["id"]: data.id,
          ["title"]: data.title,
          ["short_description"]: data.short_description,
          ["description"]: data.description,
          ["page_id"]: data.page_id
        }));
        reset1({
          title: data.title,
          short_description: data.short_description,
          description: data.description,
          image: "",
          page_id: data.page_id
        });
        setImage(data.image);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  const onUpdate = async () => {
    setPending(true);
    try {
      const result = await postApi(apiPath.updatePageContent, formDataUpdate);
      handleCloseUpdate();
      notify("Page Updated Successfully!");
      fetchData();

    } catch (error) {
      console.error(error);
      notifyErr("Something went wrong!");
    } finally {
      setPending(false);
    }
  };

  const breadcrumbItems = [
    { title: 'Page Content', link: null }
  ];

  return (
    <section>

      {pending && <Loader />}

      <ToastContainer />
      <div className="mb-5">
        <Breadcrumb items={breadcrumbItems} />
      </div>

      <Card variant="outlined" className="pb-3">
        {/* <div className="btn-d d-flex justify-content-end m-3">
          <Button
            className="text-white border-0 default-btn"
            onClick={() => handleShow()}
          >
            Add Page Content
          </Button>
        </div> */}
        <div>
          <DataTables
            title={"Page Content"}
            data={data}
            columns={columns}
            pending={pending}
            paginationServer={false}
            // totalRows={data?.length}
          />
        </div>
      </Card>

      {/* add */}
      <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>

        <Modal.Header closeButton>
          <Modal.Title>Add Page Content</Modal.Title>
        </Modal.Header>
        <Form
          className="row m-2"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="page_id">Page Name</Form.Label>
              <Form.Select
                type="type"
                id="page_id"
                placeholder="page"
                value={formData.page_id}
                {...register("page_id", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("page_id", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                <option value="1">Home</option>
                <option value="2">Course</option>
                <option value="3">About</option>
                <option value="4">Contact</option>
              </Form.Select>
              {errors.page_id && (
                <div className="errMsg text-danger">Please Select Course</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="title">Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                id="title"
                value={formData.name}
                {...register("title", {
                  required: true,
                  onChange: (e) => handleInputChange("title", e.target.value),
                })}
              />
              {errors.title && (
                <div className="errMsg text-danger">Please Enter Title</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="shortDescription">
                Short Description
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                id="shortDescription"
                placeholder="Short Description"
                value={formData.short_description}
                {...register("short_description", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("short_description", e.target.value),
                })}
              />
              {errors.short_description && (
                <div className="errMsg text-danger">
                  Please Enter Description
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="description">Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                id="description"
                placeholder="description"
                value={formData.description}
                {...register("description", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("description", e.target.value),
                })}
              />
              {errors.description && (
                <div className="errMsg text-danger">
                  Please Enter Description
                </div>
              )}
            </Form.Group>
            <Form.Group className="mt-2">
              <div className="row">
                <div className="col-12">
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    {...register("image", {
                      required: true,
                      onChange: (e) =>
                        handleInputChange("image", e.target.files[0]),
                    })}
                  />

                  {errors.image && (
                    <div className="errMsg text-danger">
                      Please Select Image
                    </div>
                  )}
                </div>

                <div className="col-12 text-center mb-3 mt-3">
                  <Form.Label>Image Preview</Form.Label>
                  <div className="p-3">
                    <img
                      src={
                        formData.image == ""
                          ? "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/330px-No-Image-Placeholder.svg.png?20200912122019"
                          : URL.createObjectURL(formData.image)
                      }
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        margin: "auto",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="contained"
              className="text-white btn-primary"
            >
              Add
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* update */}
      <Modal
        show={showUpdate}
        onHide={handleCloseUpdate}
        style={{ zIndex: "9999" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Page Content</Modal.Title>
        </Modal.Header>
        <Form
          className="row m-2"
          onSubmit={handleSubmit1(onUpdate)}
          autoComplete="off"
        >
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="page_id">Page Name</Form.Label>
              <Form.Select
                disabled
                type="type"
                id="page_id"
                placeholder="page"
                value={formDataUpdate.page_id}
                {...register1("page_id", {
                  required: true,
                  // onChange: (e) =>
                  //   handleInputChange1("page_id", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                <option value="1">Home</option>
                <option value="2">Course</option>
                <option value="3">About</option>
                <option value="4">Contact</option>
              </Form.Select>
              {errors1.page_id && (
                <div className="errMsg text-danger">Please Select Course</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="title">Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                id="title"
                value={formDataUpdate.title}
                {...register1("title", {
                  required: true,
                  onChange: (e) => handleInputChange1("title", e.target.value),
                })}
              />
              {errors1.title && (
                <div className="errMsg text-danger">Please Enter Title</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="short_description">
                Short Description
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                id="short_description"
                placeholder="Short Description"
                value={formDataUpdate.short_description}
                {...register1("short_description", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange1("short_description", e.target.value),
                })}
              />
              {errors1.short_description && (
                <div className="errMsg text-danger">
                  Please Enter Short Description
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="Description">Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                id="Description"
                placeholder="Description"
                value={formDataUpdate.description}
                {...register1("description", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange1("description", e.target.value),
                })}
              />
              {errors1.description && (
                <div className="errMsg text-danger">
                  Please Enter Description
                </div>
              )}
            </Form.Group>
            <Form.Group className="mt-2">
              <div className="row">
                <div className="col-12">
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    {...register1("image", {
                      required: false,
                      onChange: (e) =>
                        handleInputChange1("image", e.target.files[0]),
                    })}
                  />

                  {errors1.image && (
                    <div className="errMsg text-danger">
                      Please Select Image
                    </div>
                  )}
                </div>

                <div className="col-12 text-center mb-3 mt-3">
                  <Form.Label>Image Preview</Form.Label>
                  <div className="p-3">
                    <img
                      src={
                        formDataUpdate.image == ""
                          ? image != ""
                            ? `/api/assets/page/${image}`
                            : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/330px-No-Image-Placeholder.svg.png?20200912122019"
                          : URL.createObjectURL(formDataUpdate.image)
                      }
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        margin: "auto",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="contained"
              className="text-white btn-primary"

            >
              Update
            </Button>
            <Button variant="secondary" onClick={handleCloseUpdate}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </section>
  );
};

export default PageContent;
