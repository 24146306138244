import React, { useState, useEffect } from "react";
import Breadcrumb from "../../component/common/Breadcrumb";
import DataTables from "../../datatable/DataTable";
import { FaEdit, FaTrash, FaEye } from "react-icons/fa";
import { getApi, postApi } from "../../helper/helper";
import { Button, Form, Modal } from "react-bootstrap";
import { Card } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  alert,
  alertErr,
  alertInfo,
  alertSuccess,
  notify,
  notifyErr,
} from "../../component/common/notify";
import apiPath from "../../../apipath";
import Loader from "../../../components/Loader";

const SEO = () => {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
  const [formDataUpdate, setFormDataUpdate] = useState({});
  const [showUpdate, setShowUpdate] = useState(false);

  const handleShowUpdate = (row) => {
    setShowUpdate(true);
    getIndividualData(row.id);
  };

  const handleCloseUpdate = () => {
    setShowUpdate(false);
    setFormDataUpdate({});
    reset({
      seo_title: "",
      seo_description: "",
      seo_keyword: "",
      seo_follow: "",
      script: "",
      canonical: "",
      feature_image: "",
    });
  };

  const handleInputChange = (field, value) => {
    setFormDataUpdate({ ...formDataUpdate, [field]: value });
  };

  const fetchData = async () => {
    setPending(true);
    try {
      const result = await getApi(apiPath.getAdminSeo);
      setData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      name: <th className="fs-6 ">#</th>,
      cell: (row, index) => index + 1,
      width: "100px",
    },
    {
      name: <th className="fs-6 ">Page</th>,
      selector: (row) => row.pageName,
      width: "200px",
    },
    {
      name: <th className="fs-6 ">Title</th>,
      selector: (row) => row.seo_title,
      width: "200px",
    },
    {
      name: <th className="fs-6">Description</th>,
      selector: (row) => row.seo_description,
      width: "300px",
    },
    {
      name: <th className="fs-6">canonical</th>,
      selector: (row) => row.canonical,
      width: "200px",
    },
    // {
    //   name: <th className="fs-6">Status</th>,
    //   cell: (row, index) =>
    //     row.status == 0 ? (
    //       <span className="bg-info text-white p-1 rounded cursor-pointer">Active</span>
    //     ) : row.status == 1 ? (
    //       <span className=" bg-success text-white p-1 rounded cursor-pointer">IN-Active</span>
    //     ) : (
    //       "-"
    //     ),
    //   width: "220px",
    // },
    {
      name: <th className="fs-6 action">Action</th>,
      selector: (row) => (
        <div className="d-flex" style={{ gap: "10px" }}>
          <Button
            className="text-white border-0"
            style={{ float: "right", backgroundColor: "var(--primary)" }}
            onClick={() => handleShowUpdate(row)}
          >
            <FaEdit />
          </Button>
        </div>
      ),
      right: true,
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: errors },
  } = useForm();

  const getIndividualData = async (id) => {
    setPending(true);
    try {
      const result = await getApi(`${apiPath.singleSeo}/${id}`);
      const data = result[0];
      if (data) {
        setFormDataUpdate((prevFormDataUpdate) => ({
          ...prevFormDataUpdate,
          ["id"]: data.id,
          ["seo_title"]: data.seo_title,
          ["seo_description"]: data.seo_description,
          ["seo_keyword"]: data.seo_keyword,
          ["seo_follow"]: data.seo_follow,
          ["script"]: data.script,
          ["canonical"]: data.canonical,
          ["feature_image"]: data.feature_image,
          ["robot"]: data.robot,
        }));
        reset({
          seo_title: data.seo_title,
          seo_description: data.seo_description,
          seo_keyword: data.seo_keyword,
          seo_follow: data.seo_follow,
          script: data.script,
          canonical: data.canonical,
          feature_image: data.feature_image,
          robot: data.robot,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  const onUpdate = async () => {
    setPending(true);
    try {
      const result = await postApi(apiPath.updateSeo, formDataUpdate);
      notify("SEO Updated Successfully!");
      handleCloseUpdate();
      fetchData();

    } catch (error) {
      console.error(error);
      notifyErr("Something went wrong!");
    } finally {
      setPending(false);
    }
  };

  const breadcrumbItems = [
    { title: 'SEO', link: null }
  ];

  return (
    <section>
      <ToastContainer />
      {pending && <Loader />}
      <div className="mb-5">
        <Breadcrumb items={breadcrumbItems} />
      </div>

      <Card variant="outlined" className="pb-3">
        <div>
          <DataTables
            title={"SEO"}
            data={data}
            columns={columns}
            pending={pending}
            paginationServer={false}
            // totalRows={data?.length}
          />
        </div>
      </Card>

      {/* update */}
      <Modal
        show={showUpdate}
        onHide={handleCloseUpdate}
        style={{ zIndex: "9999" }}
      >

        <Modal.Header closeButton>
          <Modal.Title>Update SEO</Modal.Title>
        </Modal.Header>
        <Form
          className="row m-2"
          onSubmit={handleSubmit(onUpdate)}
          autoComplete="off"
        >
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="seo_title">SEO Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="SEO Title"
                id="seo_title"
                value={formDataUpdate.seo_title}
                {...register("seo_title", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("seo_title", e.target.value),
                })}
              />
              {errors.seo_title && (
                <div className="errMsg text-danger">Please Enter SEO Title</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="seo_description">SEO Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                id="seo_description"
                placeholder="SEO Description"
                value={formDataUpdate.seo_description}
                {...register("seo_description", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("seo_description", e.target.value),
                })}
              />
              {errors.seo_description && (
                <div className="errMsg text-danger">
                  Please Enter SEO Description
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="canonical">Canonical</Form.Label>
              <Form.Control
                type="text"
                placeholder="Canonical"
                id="canonical"
                value={formDataUpdate.canonical}
                {...register("canonical", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("canonical", e.target.value),
                })}
              />
              {errors.canonical && (
                <div className="errMsg text-danger">Please Enter Canonical</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="seo_keyword">SEO Keyword</Form.Label>
              <Form.Control
                type="text"
                placeholder="SEO Keyword"
                id="seo_keyword"
                value={formDataUpdate.seo_keyword}
                {...register("seo_keyword", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("seo_keyword", e.target.value),
                })}
              />
              {errors.seo_keyword && (
                <div className="errMsg text-danger">
                  Please Enter SEO Keyword
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="robot">Robot</Form.Label>
              <Form.Select
                type="type"
                id="robot"
                placeholder="robot"
                value={formDataUpdate.robot}
                {...register("robot", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("robot", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                <option value="1">index, follow</option>
                <option value="2">index, no-follow</option>
                <option value="3">no-index, follow</option>
                <option value="4">no-index, no-follow</option>
              </Form.Select>
              {errors.robot && (
                <div className="errMsg text-danger">Please Select robot type </div>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="script">Script</Form.Label>
              <Form.Control
                type="text"
                placeholder="Script"
                id="script"
                value={formDataUpdate.script}
                {...register("script", {
                  required: true,
                  onChange: (e) => handleInputChange("script", e.target.value),
                })}
              />
              {errors.script && (
                <div className="errMsg text-danger">Please Enter Script</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="feature_image">Feature image</Form.Label>
              <Form.Control
                type="text"
                placeholder="Feature Image"
                id="feature_image"
                value={formDataUpdate.feature_image}
                {...register("feature_image", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("feature_image", e.target.value),
                })}
              />
              {errors.feature_image && (
                <div className="errMsg text-danger">
                  Please Enter Feature Image
                </div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="contained"
              className="text-white btn-primary"

            >
              Update
            </Button>
            <Button variant="secondary" onClick={handleCloseUpdate}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </section>
  );
};

export default SEO;
