import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { basicReducer } from './features/basicSlice';
import { thunk } from 'redux-thunk';
import { authReducer } from './features/authSlice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import { cartReducer } from './features/cartSlice';
import { adminReducer } from './features/adminSlice';
import { bannerReducer } from './features/bannerSlice';
import { settingReducer } from './features/settingSlice';
import { couponReducer } from './features/couponSlice';


const rootReducer = combineReducers({
    basic: basicReducer,
    auth: authReducer,
    cart: cartReducer,
    admin: adminReducer,
    banner: bannerReducer,
    setting: settingReducer,
    coupon: couponReducer
});

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
    devTools: true
});

const persistor = persistStore(store)

export { store, persistor }

















