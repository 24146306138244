import React, { useState, useEffect } from 'react';
import Breadcrumb from '../../component/common/Breadcrumb';
import { Card } from "@mui/material";
import Editor from '../../editor/Editor';
import DataGridTable from '../../datatable/DataGridTable';
import { postApi } from '../../helper/helper';
import AgGrid from '../../datatable/AgGrid';
import { getApi } from '../../helper/helper';
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import TextEditor from '../../editor/TextEditor';
import { notify, notifyErr } from '../../component/common/notify';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import apiPath from '../../../apipath';
import Loader from '../../../components/Loader';





const AddPortfolio = () => {

    const navigate = useNavigate();

    const [pending, setPending] = useState(false);
    const [code, setCode] = useState('');
    const [data, setData] = useState([]);
    const [updatedContent, setUpdatedContent] = useState('');
    const [isCreate, setIsCreate] = useState(false);
    const [formData, setFormData] = useState({
        title: "",
        description: ""
    })

    const fetchData = async () => {
        setPending(true);
        try {
            const result = await postApi(apiPath.dynamicQuery, { sql_query: code });
            setData(result);
        } catch (error) {
            console.error(error);
            if (error.response.data.message) {
                notifyErr(error.response.data.message);
            } else {
                notifyErr("Invalid query!");
            }

            setData([]);
        } finally {
            setPending(false);
        }
    };


    const handleInputChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
    };

    useEffect(() => {
        handleInputChange('description', updatedContent)
    }, [updatedContent])


    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
        reset
    } = useForm();
    const imageValue = getValues("image");


    const onSubmit = async (data) => {
        setPending(true);
        try {
            const result = await postApi(apiPath.addPortfolio, formData);

            notify("Portfolio added Successfully!");
            navigate('/admin/portfolio');

        } catch (error) {
            console.error(error);
            notifyErr("Something went wrong!");
        } finally {
            setPending(false);
        }
    };

    const breadcrumbItems = [
        { title: 'Portfolio', link: '/admin/portfolio' },
        { title: 'Add Portfolio', link: null }
    ];

    return (
        <section>
            <ToastContainer />
            {pending && <Loader />}
            <div className="mb-5">
                <Breadcrumb items={breadcrumbItems} />
            </div>

            <Card variant="outlined" className="pb-3">
                <div className="p-2">
                    <div className='d-flex justify-start gap-3 btn-d mb-3'>
                        <Button className={code == `SELECT * FROM tbl_stocks;` ? "btn-primary" : "btn-outline"} onClick={() => setCode(`SELECT * FROM tbl_stocks;`)}> 3 Minutes</Button>
                        <Button className={code == `SELECT * FROM tbl_stocks_6min;` ? "btn-primary" : "btn-outline"} onClick={() => setCode(`SELECT * FROM tbl_stocks_6min;`)}> 6 Minutes</Button>
                        <Button className={code == `SELECT * FROM tbl_stocks_15min;` ? "btn-primary" : "btn-outline"} onClick={() => setCode(`SELECT * FROM tbl_stocks_15min;`)}> 15 Minutes</Button>
                        <Button className={code == `SELECT * FROM tbl_stocks_30min;` ? "btn-primary" : "btn-outline"} onClick={() => setCode(`SELECT * FROM tbl_stocks_30min;`)}> 30 Minutes</Button>
                        <Button className={code == `SELECT * FROM tbl_stocks_60min;` ? "btn-primary" : "btn-outline"} onClick={() => setCode(`SELECT * FROM tbl_stocks_60min;`)}> 60 Minutes</Button>
                        <Button className={code == `SELECT * FROM tbl_stocks_closure;` ? "btn-primary" : "btn-outline"} onClick={() => setCode(`SELECT * FROM tbl_stocks_closure;`)}>Closure Time</Button>
                    </div>
                    <div className=''>
                        <div>
                            <Editor code={code} setCode={setCode} fetchData={fetchData} pending={pending} />
                        </div>
                        <div className='mt-4'>
                            No. of Records: {data?.length}
                            {data?.length > 0 &&
                                <AgGrid data={data} />
                            }
                        </div>
                    </div>

                    < div className='post-d'>
                        {data?.length > 0 &&
                            <div className='d-flex justify-content-end'>
                                <div className='btn-d mt-4'>
                                    <Button onClick={() => setIsCreate(true)}>Create Post</Button>
                                </div>
                            </div>
                        }
                        {(data?.length > 0 && isCreate) &&
                            <div className='text-editor'>
                                <Form
                                    className="row"
                                    onSubmit={handleSubmit(onSubmit)}
                                    autoComplete="off"
                                >
                                    <Form.Group className="col-lg-6 col-12 mb-3">
                                        <Form.Label htmlFor="title">Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Title"
                                            id="title"
                                            value={formData.title}
                                            {...register("title", {
                                                required: true,
                                                onChange: (e) => handleInputChange("title", e.target.value),
                                            })}
                                        />
                                        {errors.title && (
                                            <div className="errMsg text-danger">Please Enter Title</div>
                                        )}
                                    </Form.Group>

                                    <Form.Group className="col-12 mb-3">
                                        <Form.Label htmlFor="doc_name">Content</Form.Label>
                                        <TextEditor data={updatedContent} setUpdatedContent={setUpdatedContent} />
                                    </Form.Group>
                                    <div className='btn-d mt-4 text-end'>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            className="text-white btn-primary"
                                        >
                                            Create
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        }
                    </div>


                    {/* <div className="col-lg-4 col-md-6 col-xs-12 col-12">

                    </div> */}
                </div>
            </Card>

        </section >
    )
}

export default AddPortfolio