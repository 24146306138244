import React, { useState, useEffect } from "react";
import Breadcrumb from "../../component/common/Breadcrumb";
import DataTables from "../../datatable/DataTable";
import { FaEdit, FaEye, FaEyeSlash, FaTrash } from "react-icons/fa";
import { Axios, getApi, postApi } from "../../helper/helper";
import { Button, Form, Modal } from "react-bootstrap";
import { Card } from "@mui/material";
import { ToastContainer } from "react-toastify";
import InputGroup from 'react-bootstrap/InputGroup';
import { useForm } from "react-hook-form";
import {
  alert,
  alertErr,
  alertInfo,
  alertSuccess,
  notify,
  notifyErr,
} from "../../component/common/notify";
import {
  confirmDelete,
  confirmStatus,
} from "../../component/common/handleData";
import { Link } from "react-router-dom";
import apiPath from "../../../apipath";
import Loader from "../../../components/Loader";
import { useImmer } from "use-immer";
import SuccessModal from "../../../components/SuccessModal";

const Users = () => {
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [type, setType] = useState('');
  const [pending, setPending] = useState(true);
  const [isShow, setIsShow] = useState(false);
  const [status, setStatus] = useState('0');
  const [formData, setFormData] = useState({});
  const [show, setShow] = useState(false)
  const [modalShow, setModalShow] = useState(false);
  const [err, setErr] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  let [filterData, setFilterData] = useImmer({
    page: 1,
    per_page: 10
  });

  const handleErr = () => {
    setModalShow(true)
    setTimeout(() => {
      setModalShow(false)
    }, 3000);
  }


  const handleShow = (row) => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setFormData({});
    reset({
      type: "",
      name: "",
      email: "",
      phone: "",
      address: "",
      password: ""
    });
  };

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const fetchTableData = async (filteredData) => {
    setPending(true);
    try {
      const result = await Axios.get(`${apiPath.getUser}/${status}`, {
        params: filteredData,
      });
      if (result) {
        setData(result?.data?.data);
        setNewData(result?.data?.data);
        setType('');
        setTotalRows(result?.data?.pagination?.total_data);
      }
      //  getApi(`${apiPath.getUser}/${status}`);
      // setData(result);

    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    fetchTableData(filterData);
  }, [status, filterData]);

  const handlePageChange = page => {
    fetchTableData({ ...filterData, page: page });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    // fetchData({ page: page, per_page: newPerPage });
    setFilterData({
      page: page,
      per_page: newPerPage
    })
  }


  const handleStatusFilter = (value) => {
    setStatus(value);
  }


  const handleUserFilter = (type) => {
    setType(type);
    let updateData = [...newData];
    updateData = updateData.filter(data => data.type == type);
    setData(updateData);
  }

  const columns = [
    {
      name: <th className="fs-6 ">#</th>,
      cell: (row, index) => index + 1,
      width: "100px",
      responsive: true,
    },

    {
      name: <th className="fs-6 ">Name</th>,
      selector: (row) => row.name,
      width: "200px",
      responsive: true,
    },

    {
      name: <th className="fs-6 ">User Type</th>,
      cell: (row, index) =>
        row.type == 1 ? (
          <span
            className="bg-success text-white p-1 rounded cursor-pointer"
          >
            Sub Admin
          </span>
        ) : row.type == 2 ? (
          <span
            className=" bg-info text-white p-1 rounded cursor-pointer"
          >
            User
          </span>
        ) : (
          "-"
        ),
      responsive: true,
      width: "200px",
    },

    {
      name: <th className="fs-6 ">Membership</th>,
      selector: (row) => row.membership_name,
      // cell: (row, index) =>
      //   row.membership_id == 1 ? (
      //     <span
      //       className="bg-success text-white p-1 rounded cursor-pointer"
      //     >
      //       Sub Admin
      //     </span>
      //   ) : row.type == 2 ? (
      //     <span
      //       className=" bg-info text-white p-1 rounded cursor-pointer"
      //     >
      //       User
      //     </span>
      //   ) : (
      //     "-"
      //   ),
      responsive: true,
      width: "200px",
    },

    {
      name: <th className="fs-6">Email</th>,
      selector: (row) => row.email,
      width: "300px",
      responsive: true,
    },
    {
      name: <th className="fs-6">Phone</th>,
      selector: (row) => row.phone,
      width: "200px",
      responsive: true,
    },
    {
      name: <th className="fs-6">Verified</th>,
      cell: (row, index) =>
        row.verified === '0' ? (
          <span
            className="bg-success text-white p-1 rounded cursor-pointer"
          >
            Verified
          </span>
        ) : (
          <span
            className=" bg-warning text-white p-1 rounded cursor-pointer"
          >
            Not-verified
          </span>
        ),
      width: "200px",
      responsive: true,
    },
    {
      name: <th className="fs-6">Status</th>,
      cell: (row, index) =>
        row.status == 0 ? (
          <span
            className="bg-success text-white p-1 rounded cursor-pointer"
            onClick={() => row.verified === '0' ? handleStatus(apiPath.updateUserStatus, row.id, "1") : handleErr()}
          >
            Active
          </span>
        ) : row.status == 1 ? (
          <span
            className=" bg-warning text-white p-1 rounded cursor-pointer"
            onClick={() => row.verified === '0' ? handleStatus(apiPath.updateUserStatus, row.id, "0") : handleErr()}
          >
            In-Active
          </span>
        ) : row.status == 2 ? (
          <span
            className=" bg-danger text-white p-1 rounded cursor-pointer"
            // onClick={() => row.verified === '0' ? handleStatus(apiPath.updateUserStatus, row.id, "0") : handleErr()}
          >
            Deleted
          </span>
        ) : (
          "-"
        ),
      responsive: true,
      width: "220px",
    },
    {
      name: <th className="fs-6 action">Action</th>,
      selector: (row) => (
        <div className="d-flex" style={{ gap: "10px" }}>

          <Link to={`/admin/users/${row.id}`}>
            <Button
              className="text-white border-0 btn-view"
              style={{
                float: "right",
              }}
            >
              <FaEye />
            </Button>
          </Link>

          <Button
            className="bg-danger text-white border-0"
            onClick={() => handleDelete(`${apiPath.deleteUser}/${row.id}`)}
          >
            <FaTrash />
          </Button>
        </div>
      ),
      responsive: true,
      right: true,
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: errors },
  } = useForm();


  const onSubmit = async () => {
    setPending(true)
    try {
      const result = await postApi(apiPath.addUser,
        { ...formData, password_show: formData.password }
      );
      handleClose();
      notify("User Added Successfully!");
      fetchTableData();

    } catch (error) {
      notifyErr(error.response.data.message);
    } finally {
      setPending(false);
    }
  };


  const [searchText, setSearchText] = useState('');

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data?.filter((item) =>
    ['name', 'email', 'phone'].some(
      (key) =>
        String(item[key]).toLowerCase().includes(searchText.toLowerCase())
    )
  );


  // handleDelete 
  const handleDelete = (endPoint) => {
    confirmDelete(endPoint)
      .then((data) => {
        fetchTableData();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // handleStatus 
  const handleStatus = (endpoint, id, status) => {
    confirmStatus(endpoint, { id: id, status: status })
      .then((message) => {
        fetchTableData();
      })
      .catch((error) => {
        console.error(error);
      });
  }




  // props.err

  const breadcrumbItems = [
    { title: 'Users', link: null }
  ];

  return (
    <section>
      <ToastContainer />
      {pending && <Loader />}
      <div className="mb-5">
        <Breadcrumb items={breadcrumbItems} />
      </div>

      <Card variant="outlined" className="pb-3">
        <div className="btn-d flex flex-col sm:flex-row row-gap-3 justify-content-between m-3">
          <div className='d-flex gap-3'>
            <Form.Select value={status} className="filter-select  w-auto"
              onChange={(e) => handleStatusFilter(e.target.value)}
            >
              <option value="0">Active</option>
              <option value="1">In-Active</option>
              <option value="2">Deleted</option>
            </Form.Select>
            <Form.Select value={type} className="filter-select  w-auto"
              onChange={(e) => handleUserFilter(e.target.value)}
            >
              <option value="" selected disabled>Select User Type</option>
              <option value="1">Sub Admin</option>
              <option value="2">User</option>
            </Form.Select>
          </div>
          <Button
            className="text-white border-0 default-btn"
            onClick={() => handleShow()}
          >
            Add User
          </Button>
        </div>
        <div className="d-flex justify-content-end">
          <Form.Control
            className="m-3"
            style={{
              width: 'fit-content'
            }}
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={handleSearch}
          />
        </div>
        <div>
          <DataTables
            title={"Users"}
            data={filteredData}
            columns={columns}
            pending={pending}
            paginationServer={true}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
          />
        </div>
      </Card>


      {/* add */}
      <Modal
        show={show}
        onHide={handleClose}
        style={{ zIndex: "9999" }}
      >

        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Form
          className="row m-2"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <Modal.Body>


            <Form.Group className="mb-3">
              <Form.Label htmlFor="type">Type</Form.Label>
              <Form.Select
                type="type"
                id="type"
                placeholder="course"
                value={formData.type}
                {...register("type", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("type", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                <option value="1">Sub Admin</option>
                <option value="2">User</option>

              </Form.Select>
              {errors.type && (
                <div className="errMsg text-danger">Please Select Type</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="name">Name</Form.Label>
              <Form.Control
                type="text"
                id="name"
                placeholder="Name"
                value={formData.name}
                {...register("name", {
                  required: "Name is Required",
                  pattern: {
                    value: /^[a-zA-Z\s]{3,}$/,
                    message: 'Invalid name. Please enter only letters and spaces'
                  },
                  maxLength: { value: 20, message: 'Name must be at most 20 characters long' },
                  validate: {
                    fullName: (value) => {
                      const names = value.trim().split(' ');
                      return names.length >= 1 ? undefined : 'Please enter name';
                    }
                  },
                  onChange: (e) => handleInputChange("name", e.target.value),
                })}
              />
              {errors.name && (
                <div className="errMsg text-danger">{errors.name.message}</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="email">Email</Form.Label>
              <Form.Control
                autoComplete="new-email"
                type="email"
                id="email"
                placeholder="Email"
                value={formData.email}
                {...register("email", {
                  required: "Email is required",
                  maxLength: { value: 30, message: 'Email must be at most 30 characters long' },
                  pattern: { value: /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/, message: 'Invalid email address' },
                  onChange: (e) => handleInputChange("email", e.target.value),
                })}
              />
              {errors.email && (
                <div className="errMsg text-danger">{errors.email.message}</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="phone">Phone</Form.Label>
              <Form.Control
                type="tel"
                id="phone"
                placeholder="Phone"
                value={formData.phone}
                {...register("phone", {
                  required: 'Phone number is required',
                  pattern: {
                    value: /^[6789]\d{9}$/,
                    message: 'Invalid phone number'
                  },
                  onChange: (e) => handleInputChange("phone", e.target.value),
                })}
              />
              {errors.phone && (
                <div className="errMsg text-danger">{errors.phone.message}</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="address">Address</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                id="address"
                placeholder="address"
                value={formData.address}
                {...register("address", {
                  required: false,
                  minLength: { value: 10, message: 'Address must be at least 10 characters.' },
                  onChange: (e) => handleInputChange("address", e.target.value),
                })}
              />
              {errors.address && (
                <div className="errMsg text-danger">Please Enter Address</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="password">Password</Form.Label>
              <InputGroup>
                <Form.Control
                  autoComplete="new-password"
                  type={isShow ? "text" : "password"}
                  placeholder="Password"
                  id="password"
                  value={formData.password}
                  {...register("password", {
                    required: true,
                    pattern: {
                      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+}{":;'?/>.<,])[A-Za-z\d!@#$%^&*()_+}{":;'?/>.<,]{8,}$/,
                      message: 'Password must be at least 8 characters long and include uppercase, lowercase letters, numbers, and special characters.'
                    },
                    onChange: (e) => handleInputChange("password", e.target.value),
                  })}
                />
                <InputGroup.Text onClick={() => setIsShow(!isShow)}>{isShow ? <FaEyeSlash /> : <FaEye />}</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="contained"
              className="text-white btn-primary"

            >
              Add
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <SuccessModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        msg={err && 'This user is not verified!'}
        err={err}
      />

    </section>
  );
};

export default Users;
