const apiPath = {

    login: "login",
    logout: "logout",
    signUp: "addUser",
    forgetPassword: "forgotPass",
    getTestimonials: "getadmintestimonial/1",
    getCourses: 'getCourse/0',
    getCategory: 'getCategory/0',
    getSingleCourse: 'getSingleCourse',
    getCourseCategory: 'getCourseCategory',
    getHomeBanner: 'getBanner/1',
    getFeaturedCourse: 'getFeaturedCourse',
    getFeaturedCategory: 'getFeaturedCategory',
    getSettings: 'getSettings',
    getCourseTestimonials: 'getTestimonialcourse',
    getCourseAdvantage: 'getadvantagestype',
    getPageContent: 'getPagecontent',
    Checkout: "Checkout",
    addPageContent: '/api/addPagecontent',
    updatePageContent: '/api/updatePagecontent',
    getPageContentList: 'getPagecontentList',
    createOrderCheckout: 'Createordercheckout',
    getFaqs: 'getCourseFaq',
    getPortfolio: 'getResearch',
    addToCart: '/api/addCart',
    getCart: 'getCart',
    deleteCart: '/api/deleteCart',
    emptyCart: 'deleteCart/0',
    getSingleCart: 'getUsercart',
    getLessons: 'getLesson',
    getAdminLesson: 'getadminLesson',
    getSingleLesson: 'getSingleLesson',
    getLessonsContent: 'getLessoncontent',
    contactUs: "addEnquiry",
    resetPassword: "resetPassword",
    getActivityLogs: "ActivityLogs",
    getUserPrivacy: 'getPrivacy/1',
    getUserTAndC: 'getPrivacy/2',
    getUserBanners: "getBanner",
    getUserInfo: "getUserInfo",
    getUserStockGraph: "getStock",
    getUserNotification: "getUsernotification",
    membershipRefund: 'membershipRefund',
    readNotification: 'readNotification',
    paymentRefund: 'paymentRefund',

    //User Dashboard apis
    getMyCourses: "getUserCourse",
    getMembership: "getMembership",
    getMyMembership: "getUserMembership",
    Captureordermembership: "Captureordermembership",
    updateMyMembership: "",
    updatePassword: "updatePassword",
    updateUserProfile: "updateProfile",
    getUserProfile: "getProfile",
    getTransactionHistory: "transactionHistory",
    upgradeMembership: 'upgradeMembership',
    getStockSearch: 'getStockSearch',
    emailVerify: 'Emailverify',
    //Checkout
    checkout: "Checkout",
    Captureordercheckout: 'Captureordercheckout',
    updateBanner: '/api/updateBanner',
    getBanner: '/api/getBanner',
    getLesson: '/api/getLesson',
    adminLogin: '/api/adminlogin',
    forgotPass: '/api/forgotPass',
    getClientId: '/api/getClientid',
    updateCode: '/api/updateCode',
    stockHistoryInfo: '/api/stockHistoryInfo',
    getStockToken: '/api/getStocktoken',
    getDashboardCount: '/api/getdashboardCount',
    getAbout: '/api/getAbout',
    addCategory: '/api/addCategory',
    getAdminCategory: '/api/getCategory',
    getSingleCategory: '/api/getSingleCategory',
    updateCategory: '/api/updateCategory',
    updateCategoryStatus: '/api/updateCategoryStatus',
    deleteCategory: '/api/deleteCategory',
    getCourse: '/api/getCourse',
    getCourseInstructor: '/api/getCourseInstructor',
    deleteCourse: '/api/deleteCourse',
    getCourseFaq: '/api/getCourseFaq',
    addFaq: '/api/addFaq',
    updateFaq: '/api/updateFaq',
    deleteFaq: '/api/deleteFaq',
    getSingleFaq: '/api/getSingleFaq',
    addUser: '/api/addUser',
    getUser: '/api/getUser',
    getUserCourse: '/api/getUserCourse',
    addCourse: '/api/addCourse',
    updateCourse: '/api/updateCourse',
    getSingleUser: '/api/getSingleUser',
    updateUser: '/api/updateUser',
    updateUserStatus: '/api/updateUserStatus',
    deleteUser: '/api/deleteUser',
    getUserMembership: '/api/getUserMembership',
    getMembershipHistory: '/api/getMembershipHistory',
    updateModuleStatus: '/api/updateModuleStatus',
    updateChapterStatus: '/api/updateChapterStatus',
    getChapterCourse: '/api/getChapterCourse',
    getChapter: '/api/getChapter',
    addChapter: '/api/addChapter',
    getSingleChapter: '/api/getSingleChapter',
    updateChapter: '/api/updateChapter',
    getChapterModule: '/api/getChapterModule',
    deleteChapter: '/api/deleteChapter',
    getModuleCourse: '/api/getModuleCourse',
    getLessonId: '/api/getLessonId',
    getCourseLesson: 'getCourseLesson',
    addLesson: '/api/addLesson',
    updateLesson: '/api/updateLesson',
    deleteLesson: '/api/deleteLesson',
    updateLessonStatus: '/api/updateLessonStatus',
    updateLessonType: '/api/updateLessonType',
    addModule: '/api/addModule',
    getSingleModule: '/api/getSingleModule',
    updateModule: '/api/updateModule',
    deleteModule: '/api/deleteModule',
    allCoupon: '/api/allCoupon',
    addCoupon: '/api/addCoupon',
    updateCoupon: '/api/updateCoupon',
    deleteCoupon: '/api/deleteCoupon',
    userCoupon: 'userCoupon',
    couponStatus: '/api/couponStatus',
    singleCoupon: '/api/singleCoupon',
    getCourseAdvantages: '/api/getCourseAdvantages',
    getSingleCourseAdvantages: '/api/getSingleCourseAdvantages',
    updateCourseAdvantages: '/api/updateCourseAdvantages',
    deleteCourseAdvantages: '/api/deleteCourseAdvantages',
    addCourseAdvantages: '/api/addCourseAdvantages',
    getTestimonialCourse: '/api/getTestimonialcourse',
    addTestimonial: '/api/addTestimonial',
    userTestimonial: 'addTestimonial',
    getSingleTestimonial: '/api/getSingletestimonial',
    checkUserAuth: 'checkuserAuth',
    rePostReview: 'updateTestimonial',
    updateTestimonial: '/api/updateTestimonial',
    deleteTestimonial: '/api/deleteTestimonial',
    addMembership: '/api/addMembership',
    getAdminMembership: '/api/getMembership',
    getSingleMembership: '/api/getSingleMembership',
    updateMembership: '/api/updateMembership',
    deleteMembership: '/api/deleteMembership',
    dynamicQuery: '/api/dynamicquery',
    addPortfolio: '/api/addResearch',
    getSinglePortfolio: '/api/getSingleResearch',
    getPortfolioList: '/api/getResearchList',
    updatePortfolio: '/api/updateResearch',
    getAdminProfile: '/api/getadminProfile',
    updateAdminProfile: '/api/updateadminProfile',
    getPaymentsList: '/api/getPaymentsList',
    getEnquiry: '/api/getEnquiry',
    getSingleEnquiry: '/api/getSingleEnquiry',
    enquiryReply: '/api/enquiryReply',
    getAdminSeo: '/api/getAdminseo',
    singleSeo: '/api/singleseo',
    updateSeo: '/api/updateseo',
    deletePortfolio: '/api/deleteResearch',
    addInstructor: '/api/addInstructor',
    getInstructor: '/api/getInstructor',
    getSingleInstructor: '/api/getSingleInstructor',
    updateInstructor: '/api/updateInstructor',
    deleteInstructor: '/api/deleteInstructor',
    getPrivacy: '/api/getPrivacy/1',
    getTAndC: '/api/getPrivacy/2',
    updatePrivacy: '/api/updatePrivacy',
    addStockSymbol: '/api/addStockSymbol',
    getStockSymbol: '/api/getStockSymbol',
    stockSymbolsList: '/api/stockSymbolsList',
    updateStockSymbol: '/api/updateStockSymbol',
    stockSymbolsList: '/api/stockSymbolsList',
    updateSetting: '/api/updateSetting'

}

export default apiPath;