import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import IconButton from '@mui/material/IconButton';
import { BiLock } from "react-icons/bi";
import { TiTick } from "react-icons/ti";
import DraftsIcon from '@mui/icons-material/Drafts';
import { useState, useEffect } from 'react';
import { fetchDataFromApi } from '../../libs/AllGlobalFunctions';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import apiPath from '../../apipath';
import { useSelector } from 'react-redux';

const AccordionSkeleton = ({ count = 5 }) => {
    return (
        <div>
            {Array.from({ length: count }).map((_, index) => (
                <div key={index} className="border border-gray-200 rounded-md p-4 mb-4">
                    <Skeleton height={30} width={`100%`} />
                    <div className="space-y-2 mt-2">
                        {Array.from({ length: 3 }).map((_, i) => (
                            <div key={i} className="flex items-center space-x-4">
                                <Skeleton circle={true} height={40} width={40} />
                                <Skeleton height={20} width={`60%`} />
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};


const CurriculumAccordion = ({ chapters, findPrevNext }) => {

    const [lessons, setLessons] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id, lessonId } = useParams();
    const { userInfo, accessToken } = useSelector((state) => state?.auth);

    useEffect(() => {
        if (chapters && chapters?.length > 0) {
            fetchLessons();
        }
    }, [chapters]);

    const fetchLessons = () => {
        fetchDataFromApi(`${apiPath.getCourseLesson}/${id}/0`, setLoading, 'GET', {}).then((response) => {
            if (response?.status == 200 && response?.data) {
                setLessons(response?.data?.data)
            } else {
                setLessons([]);
            }
        }
        ).catch((error) => {
            setLoading(false);
            console.log(error)
        })
    }

    useEffect(() => {
        if(lessonId && lessons){
        findPrevNext(lessonId, lessons);
        }
    }, [lessons, lessonId]);

    

    return (
        <div>
            {chapters && chapters?.length > 0 ? chapters?.map((chapter, index) => {
                const filteredLessons = lessons?.filter(lesson => lesson.chapter_id === chapter.id);
                return (
                    <Accordion defaultExpanded className="mb-4">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                            style={{
                                position: 'sticky',
                                top: 0,
                                backgroundColor: 'white',
                                zIndex: 2
                            }}
                        >
                            <Typography>{chapter.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {filteredLessons?.map((item, i) => (
                                <>
                                    <Link to={`/lessons/${id}/${item?.id}`}>
                                        <List>
                                            <ListItem disablePadding
                                            // secondaryAction={<><span className="text-white rounded-sm p-1 bg-primary">Preview</span><BiLock /></>}
                                            >
                                                <ListItemButton className={"hover:text-teal"}>
                                                    <ListItemIcon>
                                                        <DraftsIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={item.title} />
                                                    <ListItemIcon className="items-center">
                                                        <span className={`rounded-sm px-1 ${(item.access_type === "free" || userInfo?.course_ids?.includes(id)) ? "bg-teal text-white" : "bg-red-50 text-red-400"} mx-2`}>
                                                            {(item.access_type === "free" || userInfo?.course_ids?.includes(id)) && "Preview"}
                                                        </span>
                                                        {item.access_type === "free" || userInfo?.course_ids?.includes(id) ? <TiTick /> : <BiLock />}
                                                    </ListItemIcon>
                                                </ListItemButton>
                                            </ListItem >
                                        </List ></Link>
                                    {filteredLessons?.length - 1 != i && <hr></hr>}
                                </>
                            ))}
                        </AccordionDetails>
                    </Accordion >
                )
            }) : <AccordionSkeleton />}
        </div >
    )
}

export default CurriculumAccordion;