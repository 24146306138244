import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ pdf }) => {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const nextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    };

    const prevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    return (
        <div className="m-5">
            <div className="flex justify-center space-x-2 mb-4">
                <button
                    onClick={prevPage}
                    disabled={pageNumber === 1}
                    className={`bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed transition ease-in-out duration-150`}
                >
                    Prev
                </button>
                <button
                    onClick={nextPage}
                    disabled={pageNumber === numPages}
                    className={`bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed transition ease-in-out duration-150`}
                >
                    Next
                </button>
            </div>

            <div className="w-full max-w-3xl mx-auto overflow-hidden">
                <Document
                    file={pdf}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onContextMenu={(e) => e.preventDefault()}
                    className="select-none"
                >
                    <Page pageNumber={pageNumber} />
                </Document>
            </div>
        </div>

    );
};

export default PdfViewer;