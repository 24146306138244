import { postApi, getApi } from "../../helper/helper";
import { alertInfo, alertSuccess, alertErr } from "./notify";

// const handleDelete = async (endpoint) => {
//   try {
//     const result = await getApi(endpoint);
//     alertSuccess("Deleted!", "Your data has been deleted.");
//     return result;

//   } catch (error) {
//     console.error(error);
//     alertErr("An error occurred while deleting the data.");
//   }
// };

export const confirmDelete = (endpoint) => {
  return new Promise((resolve, reject) => {
    alertInfo(
      "Are you sure to Delete?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!"
    ).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await getApi(endpoint);

          alertSuccess("Deleted!", "Your data has been deleted.");
          resolve("Deletion successful");  // Resolve with the data

        } catch (error) {
          console.error(error);
          alertErr("An error occurred while deleting the data.");
          reject(error); // Reject with the error object
        }
      }
    });
  });
};


export const confirmStatus = (endpoint, formData) => {
  return new Promise((resolve, reject) => {
    alertInfo(
      "Are you sure to Update Status?",
      "",
      "warning",
      "Yes, change it!"
    ).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await postApi(endpoint, formData);

          alertSuccess("Updated!", "Your data has been updated.");
          resolve("Status update successful"); // Resolve with a custom success message

        } catch (error) {
          console.error(error);
          alertErr("An error occurred while updating the data.");
          reject(error); // Reject with the error object
        }
      }
    });
  });
};



// export const confirmDelete = (endpoint) => {
//   alertInfo(
//     "Are you sure to Delete?",
//     "You won't be able to revert this!",
//     "warning",
//     "Yes, delete it!"
//   ).then(async (result) => {
//     if (result.isConfirmed) {
//       try {
//         const result = await getApi(endpoint);
//         alertSuccess("Deleted!", "Your data has been deleted.");
//         return result;

//       } catch (error) {
//         console.error(error);
//         alertErr("An error occurred while deleting the data.");
//       }
//     }
//   });
// };

// const handleStatus = async (endpoint, id, status) => {
//   try {
//     const result = await postApi(endpoint, {
//       id: id,
//       status: status,
//     });
//     alertSuccess("Updated!", "Your data has been updated.");
//     return result;

//   } catch (error) {
//     console.error(error);
//     alertErr("An error occurred while updating the data.");
//   }
// };

// export const confirmStatus = (endpoint, id, status) => {
//   alertInfo(
//     "Are you sure to Update Status?",
//     "",
//     "warning",
//     "Yes, change it!"
//   ).then(async (result) => {
//     if (result.isConfirmed) {
//       try {
//         const result = await postApi(endpoint, {
//           id: id,
//           status: status,
//         });
//         alertSuccess("Updated!", "Your data has been updated.");
//         return result;

//       } catch (error) {
//         console.error(error);
//         alertErr("An error occurred while updating the data.");
//       }
//     }
//   });
// };
