import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import LoginForm from "./LoginForm";
import logo from '../assets/Tradesmate-Logo.png'
import { _logo_ } from "../../libs/ImagePath";
import { useSelector } from "react-redux";

const Login = () => {

  const settings = useSelector((state) => state?.setting?.settings);

  const StyledContent = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
  }));

  return (
    <>
      <div className="w-100 main-d">
        <StyledContent>
          <Paper
            sx={{
              borderRadius: 2,
            }}
            elevation={8}
            className="login_paper mx-4"
          >
            <div className="logo-d mx-auto mb-4">
              <img
                src={`${_logo_}${settings?.logo}`}
                width={100}
              />
            </div>
            <LoginForm />
          </Paper>
        </StyledContent>
      </div>
    </>
  );
};

export default Login;
