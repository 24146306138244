import React, { useEffect, useState } from "react";
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Input,
  Container,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { forgotPassword, postApi } from "../helper/helper";
import { notify, notifyErr } from "../component/common/notify";
import CircularProgress from '@mui/material/CircularProgress';
import { Axios } from '../helper/helper';
import { useDispatch } from "react-redux";
import { setAdmin } from "../../redux/features/adminSlice";
import { setCookie } from "nookies";
import apiPath from "../../apipath";


const LoginForm = () => {

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [fEmail, setFEmail] = useState("");
  const [pending, setPending] = useState(false);
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setFEmail("");
    reset({
      fEmail: "",
    });
  };

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: errors },
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
  } = useForm();

  const onSubmit = async () => {
    setPending(true);
    try {
      const { data, status } = await Axios.post(apiPath.adminLogin, formData, {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (status === 200 && data?.response_code === 200 && (data?.data?.type === "0" || data?.data?.type === "1")) {
        notify("Login Successfully!");
        // token
        setCookie(null, 'adminToken', data?.token, {
          maxAge: 1 * 24 * 60 * 60,
          path: '/admin'
        })
        dispatch(setAdmin({ currentAdmin: data?.data, accessToken: data?.token }))
        localStorage.setItem("adminData", JSON.stringify(data?.data));

        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
        
      } else {
        notifyErr(data?.message);
      }

    } catch (error) {
      console.error(error);
      notifyErr(error?.response?.data?.message);
    } finally {
      setPending(false);
    }
  };

  const onForget = async () => {
    try {
      const result = await postApi(apiPath.forgotPass, { email: fEmail });
      handleClose();
      notify("Password Reset Successfully!");


    } catch (error) {
      console.error(error);
      notifyErr(error.response.data.message);
    } finally {
      setPending(false);
    }
  };

  return (
    <>
      <div className="position: relative">
        <ToastContainer />

        {pending && (
          <CircularProgress
            size={35}
            sx={{
              color: '#003366',
              position: 'absolute',
              top: '40%',
              left: '45%',
              zIndex: 1,
            }}
          />
        )}
        <div style={{ opacity: pending ? 0.5 : 1, position: 'relative' }}>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <label>Email</label>
              <TextField
                name="email"
                type="email"
                value={formData.email}
                autoComplete="off"
                placeholder="email"
                {...register("Email", {
                  required: "Email is required",
                  maxLength: { value: 30, message: 'Email must be at most 30 characters long' },
                  pattern: { value: /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/, message: 'Invalid email address' },
                  onChange: (e) => handleInputChange("email", e.target.value),
                })}
              />

              {errors.Email && (
                <div className="errMsg text-danger">{errors.Email.message}</div>
              )}
              <label>Password</label>
              <TextField
                name="password"
                placeholder="password"
                type={showPassword ? "text" : "password"}
                value={formData.password}
                autoComplete="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register("Password", {
                  required: true,
                  onChange: (e) => handleInputChange("password", e.target.value),
                })}
              />

              {errors.Password && (
                <div className="errMsg text-danger">Password is Required</div>
              )}
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ my: 2 }}
            >
              <Link
                variant="subtitle2"
                underline="hover"
                style={{ cursor: "pointer" }}
                onClick={handleShow}
              >
                Forgot password?
              </Link>
            </Stack>

            <div className="d-flex justify-content-center align-items-center">
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                className="default-btn"
              >
                Login
              </LoadingButton>
            </div>
          </form>
          {pending && <div className="overlay position-absolute w-100 h-100 top-0 left-0 z-10"></div>}
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        className=""

      >
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Form
          className="row m-2"
          onSubmit={handleSubmit1(onForget)}
          autoComplete="off"
        >
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Your Email"
                value={fEmail}
                {...register1("Email", {
                  required: "Email is required",
                  maxLength: { value: 30, message: 'Email must be at most 30 characters long' },
                  pattern: { value: /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/, message: 'Invalid email address' },
                })}
                onChange={(e) => setFEmail(e.target.value)}
              />
              {errors1.Email && (
                <div className="errMsg text-danger">
                  {errors1.Email.message}
                </div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              type="submit"
              className="text-white btn-primary"
              variant="contained"

            >
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default LoginForm;
