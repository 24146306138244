import React, { useState, useEffect } from "react";
import Breadcrumb from "../../component/common/Breadcrumb";
import DataTables from "../../datatable/DataTable";
import { FaEdit, FaTrash } from "react-icons/fa";
import { getApi, postApi } from "../../helper/helper";
import { Button, Form, Modal } from "react-bootstrap";
import { Card } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  alert,
  alertErr,
  alertInfo,
  alertSuccess,
  notify,
  notifyErr,
} from "../../component/common/notify";
import {
  confirmDelete,
  confirmStatus,
} from "../../component/common/handleData";
import { Link } from "react-router-dom";
import apiPath from "../../../apipath";
import Loader from "../../../components/Loader";

const Instructor = () => {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
  const [formData, setFormData] = useState({});
  const [formDataUpdate, setFormDataUpdate] = useState({});
  const [status, setStatus] = useState('0');
  const [show, setShow] = useState(false);

  const [showUpdate, setShowUpdate] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setFormData({});
    reset({
      instructor_name: "",
      boi: "",
    });
  };

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleShowUpdate = (row) => {
    setShowUpdate(true);
    getIndividualData(row.id);
  };

  const handleCloseUpdate = () => {
    setShowUpdate(false);
    setFormDataUpdate({});
    reset1({
      instructor_name: "",
      bio: "",
    });
  };

  const handleInputChange1 = (field, value) => {
    setFormDataUpdate({ ...formDataUpdate, [field]: value });
  };

  const fetchTableData = async () => {
    setPending(true);
    try {
      const result = await getApi(`${apiPath.getInstructor}/${status}`);
      setData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    fetchTableData();
  }, [status]);

  const handleStatusFilter = (value) => {
    setStatus(value);
  }



  const columns = [
    {
      name: <th className="fs-6 ">#</th>,
      cell: (row, index) => index + 1,
      width: "100px",
    },

    {
      name: <th className="fs-6 ">Name</th>,
      selector: (row) => row.instructor_name,
      width: "200px",
    },
    {
      name: <th className="fs-6">Bio</th>,
      selector: (row) => row.bio,
      width: "300px",
    },
    {
      name: <th className="fs-6">Status</th>,
      cell: (row, index) =>
        row.status == 0 ? (
          <span
            className="bg-success text-white p-1 rounded cursor-pointer"
            onClick={() =>
              handleStatus("/api/updateInstructorStatus", row.id, "1")
            }
          >
            Active
          </span>
        ) : row.status == 1 ? (
          <span
            className=" bg-warning text-white p-1 rounded cursor-pointer"
            onClick={() =>
              handleStatus("/api/updateInstructorStatus", row.id, "0")
            }
          >
            In-Active
          </span>
        ) : (
          "-"
        ),
      width: "220px",
    },
    {
      name: <th className="fs-6 action">Action</th>,
      selector: (row) => (
        <div className="d-flex" style={{ gap: "10px" }}>
          <Button
            className="text-white border-0"
            style={{ float: "right", backgroundColor: "var(--primary)" }}
            onClick={() => handleShowUpdate(row)}
          >
            <FaEdit />
          </Button>
          <Button
            className="bg-danger text-white border-0"
            onClick={() => handleDelete(`${apiPath.deleteInstructor}/${row.id}`)}
          >
            <FaTrash />
          </Button>
        </div>
      ),
      right: true,
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: errors },
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm();

  const onSubmit = async () => {
    setPending(true);
    try {
      const result = await postApi(apiPath.addInstructor, formData);

      handleClose();
      notify("Instructor Added Successfully!");
      fetchTableData();

    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  const getIndividualData = async (id) => {
    setPending(true);
    try {
      const result = await getApi(`${apiPath.getSingleInstructor}/${id}`);
      const data = result[0];
      if (data) {
        setFormDataUpdate((prevFormDataUpdate) => ({
          ...prevFormDataUpdate,
          ["id"]: data.id,
          ["instructor_name"]: data.instructor_name,
          ["bio"]: data.bio,
        }));
        reset1({
          instructor_name: data.instructor_name,
          bio: data.bio,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  const onUpdate = async () => {
    setPending(true);
    try {
      const result = await postApi(apiPath.updateInstructor, formDataUpdate);

      handleCloseUpdate();
      notify("Instructor Updated Successfully!");
      fetchTableData();

    } catch (error) {
      console.error(error);
      notifyErr("Something went wrong!");
    } finally {
      setPending(false);
    }
  };

  // handleDelete 
  const handleDelete = (endPoint) => {
    confirmDelete(endPoint)
      .then((data) => {
        fetchTableData();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // handleStatus 
  const handleStatus = (endpoint, id, status) => {
       confirmStatus(endpoint, { id: id, status: status})
      .then((message) => {
        fetchTableData();
      })
      .catch((error) => {
        console.error(error);
      });
  }


  const breadcrumbItems = [
    { title: 'Instructor', link: null }
  ];

  return (
    <section>

      {pending && <Loader />}

      <ToastContainer />
      <div className="mb-5">
        <Breadcrumb items={breadcrumbItems} />
      </div>

      <Card variant="outlined" className="pb-3">
        <div className="btn-d flex flex-col sm:flex-row row-gap-3 justify-content-between m-3">
          <Form.Select value={status} className="filter-select  w-auto"
            onChange={(e) => handleStatusFilter(e.target.value)}
          >
            <option value="0">Active</option>
            <option value="1">In-Active</option>
            <option value="2">All</option>
          </Form.Select>
          <Button
            className="text-white border-0 default-btn"
            onClick={() => handleShow()}
          >
            Add Instructor
          </Button>
        </div>
        <div>
          <DataTables
            title={"Instructor"}
            data={data}
            columns={columns}
            pending={pending}
            paginationServer={false}
            // totalRows={data?.length}
          />
        </div>
      </Card>

      {/* add */}
      <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>

        <Modal.Header closeButton>
          <Modal.Title>Add Instructor</Modal.Title>
        </Modal.Header>
        <Form
          className="row m-2"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="instructor_name">Instructor Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Instructor Name"
                id="instructor_name"
                value={formData.name}
                {...register("instructor_name", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("instructor_name", e.target.value),
                })}
              />
              {errors.instructor_name && (
                <div className="errMsg text-danger">
                  Please Enter Instructor Name
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="bio">Bio</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                id="bio"
                placeholder="Bio"
                value={formData.bio}
                {...register("bio", {
                  required: true,
                  onChange: (e) => handleInputChange("bio", e.target.value),
                })}
              />
              {errors.bio && (
                <div className="errMsg text-danger">Please Enter Bio</div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="contained"
              className="text-white btn-primary"
            >
              Add
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* update */}
      <Modal
        show={showUpdate}
        onHide={handleCloseUpdate}
        style={{ zIndex: "9999" }}
      >

        <Modal.Header closeButton>
          <Modal.Title>Update Instructor</Modal.Title>
        </Modal.Header>
        <Form
          className="row m-2"
          onSubmit={handleSubmit1(onUpdate)}
          autoComplete="off"
        >
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="instructor_name">Instructor Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Instructor Name"
                id="instructor_name"
                value={formDataUpdate.instructor_name}
                {...register1("instructor_name", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange1("instructor_name", e.target.value),
                })}
              />
              {errors1.instructor_name && (
                <div className="errMsg text-danger">
                  Please Enter Instructor Name
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="bio">Bio</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                id="bio"
                placeholder="Bio"
                value={formDataUpdate.bio}
                {...register1("bio", {
                  required: true,
                  onChange: (e) => handleInputChange1("bio", e.target.value),
                })}
              />
              {errors1.bio && (
                <div className="errMsg text-danger">Please Enter Bio</div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="contained"
              className="text-white btn-primary"

            >
              Update
            </Button>
            <Button variant="secondary" onClick={handleCloseUpdate}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </section>
  );
};

export default Instructor;
