import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchDataFromApi } from "../../libs/AllGlobalFunctions";
import Loader from "../../components/Loader";
import apiPath from "../../apipath";
import { getEmbeddedUrlFromYouTubeUrl } from "../../libs/AllGlobalFunctions";
import { _lesson_ } from "../../libs/ImagePath";
import PdfViewer from "../../components/PdfViewer";
import Accordion from 'react-bootstrap/Accordion';
import useAuthenticated from "../../hooks/useAuthenticated";

const LessonsContent = () => {
    const { userInfo, accessToken } = useSelector((state) => state?.auth);
    const [lessonsContent, setLessonsContent] = useState(null);
    const [loading, setLoading] = useState(false);
    const { lessonId } = useParams();
    const isAuth = useAuthenticated();

    useEffect(() => {
        if (lessonId) {
            fetchLessonsContent(lessonId);
        }
    }, [lessonId]);

    const fetchLessonsContent = async (id) => {
        setLoading(true);
        const apiUrl = userInfo && accessToken ? apiPath?.getLessons : apiPath.getSingleLesson;
        try {
            const response = await fetchDataFromApi(`${apiUrl}/${id}`, setLoading, 'GET', {});
            if (response?.status === 200 && Array.isArray(response?.data?.data) && response?.data?.data.length > 0) {
                setLessonsContent(response?.data?.data[0]);
            } else {
                setLessonsContent(null);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='site-container'>
            {loading && <Loader />}
            {!loading && lessonsContent ? (
                <div className='course-details-seo'>
                    <h1 className='pc-dp-title'>{lessonsContent?.title}</h1>
                    <p className='pc-overviw-contains' dangerouslySetInnerHTML={{ __html: lessonsContent?.description }}></p>
                    {lessonsContent?.video_url && (
                        <div className='corse-video mt-4'>
                            <h6 className="section-title mb-4 mt-4">Videos Section</h6>
                            <iframe
                                width='560'
                                height='500'
                                src={getEmbeddedUrlFromYouTubeUrl(lessonsContent?.video_url)}
                                title='YouTube video player'
                                frameBorder='0'
                                allow='accelerometer; autoplay; clipboard-write; gyroscope; picture-in-picture; web-share'
                                allowFullScreen
                            ></iframe>
                        </div>
                    )}
                    {lessonsContent?.video && (
                        <div className='corse-video mt-4'>
                        <video width="70%" controls controlsList="nodownload">
                            <source src={`${_lesson_}/${lessonsContent?.video}`} type="video/mp4" />
                        </video>
                    </div>
                    )}
                    {lessonsContent?.pdf && lessonsContent?.pdf.split(",").length > 0 && (
                        <div>
                            <h6 className="section-title mb-4 mt-5">PDF Section</h6>
                            <Accordion defaultActiveKey={['0']} alwaysOpen>
                                {lessonsContent?.pdf.split(",").map((item, index) => (
                                    <Accordion.Item eventKey={`${index}`} key={index}>
                                        <Accordion.Header>{`Learning PDF ${index + 1}`}</Accordion.Header>
                                        <Accordion.Body>
                                            <PdfViewer pdf={`${_lesson_}/${item}`} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </div>
                    )}
                </div>
            ) : !loading && isAuth ? (
                <div className="empty-cart order-placed-box">
                    <i className="m-icon icon-emptycart"></i>
                    <h3 className="order-placed-title cart-empty">Not purchased Course Yet!</h3>
                    <Link to={'/course'}>
                        <button className="m-btn m-btn-hero mt-6">Courses</button>
                    </Link>
                </div>
            ) : (
                !loading && (
                    <div className="w-full p-4 border-t-4 border-red-500 bg-gray-100 text-center rounded-b-lg">
                        <p>This content is protected, please
                            <Link to={'/auth/login'} className="text-blue-500"> login </Link>
                            and enroll in the course to view this content!
                        </p>
                    </div>
                )
            )}
        </div>
    );
};

export default LessonsContent;