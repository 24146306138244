import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Input from '../components/Form/Input';
import { useState, useEffect } from 'react';
import axiosProvider from '../libs/axios/axiosProvider';
import apiPath from '../apipath';
import Toster from '../components/Toster';
import useToast from '../hooks/useToast';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { _logo_ } from '../libs/ImagePath';
import SuccessModal from '../components/SuccessModal';

const VerifyAccount = () => {

  const navigate = useNavigate();
  const { toast, showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [err, setErr] = useState(false);
  const settings = useSelector((state) => state?.setting?.settings);
  let token = searchParams.get('token');
  let userId = searchParams.get('user_id');

  useEffect(() => {
    handleSubmit();
  }, [])


  const handleSubmit = async () => {
    setLoading(true);
    try {

      const response = await axiosProvider({
        method: "POST",
        endpoint: `${apiPath.emailVerify}?token=${token}&user_id=${userId}`,
      });

      if (response.status === 200 && response?.data?.response_code == 200) {
        setModalShow(true);
        showToast(response?.data?.message, "success", 3000);

      } else {
        // showToast(response?.data?.message, "error", 3000);
        setModalShow(true);
        setErr(true);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      showToast("Something went wrong !", "error", 3000);
      setLoading(false);
    }
  };

  if (!(token && userId)) {
    return (
      <section className='section_spacing'>
        <div className='site-container'>
          <div className="w-full p-4 border-t-4 border-red-500 bg-gray-100 text-center rounded-b-lg">
            <p>Invalid! Verify Link
              <Link to={'/auth/signup'} className="text-blue-500">  Create Account</Link></p>
          </div>
        </div>
      </section>
    )
  }

  return (
    <>

      <section className='section_spacing'>
        {loading && <Loader />}

        {/* {toast?.show && (
          <Toster text={toast?.text} variation={toast?.variation} />
        )} */}
        <div className='site-container'>
          <SuccessModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            msg={err ? 'Verify link has expired.' : `Congratulations! Your account created successfully`}
            err={err}
          />
        </div>
      </section>
    </>
  );
}

export default VerifyAccount;