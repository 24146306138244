import { useEffect } from "react";
import { fetchDataFromApi } from "../../../libs/AllGlobalFunctions";
import Loader from "../../../components/Loader";
import { useState } from "react";
import apiPath from "../../../apipath";
import CourseCard from "../../../components/Course/CourseCard";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const MyCourses = () => {

    const [loading, setLoading] = useState(false);
    const [courseData, setCourseData] = useState([]);
    const { userInfo } = useSelector((state) => state?.auth);

    useEffect(() => {
        if (userInfo && userInfo?.id) {
            fetchCourses();
        }
        window.scrollTo(0, 0);
    }, [])

    const fetchCourses = () => {
        setLoading(true);
        fetchDataFromApi(`${apiPath.getMyCourses}/${userInfo?.id}`, null, 'GET', {}).then((data) => {
            if (data) {
                setCourseData(data)
            } else {
                setCourseData([]);
            }
            setLoading(false);
        }
        ).catch((error) => {
            console.log(error);
            setLoading(false);
        })
    }

    return (
        <section className='section_spacing w-full section_spacing-space'>
            <div className=''>
                <h2 className="mb-3 text-2xl">My Courses</h2>
                <div className={courseData?.data?.data?.length > 0 ? 'pc-courses-list-wrapper pc-my-courses-list-wrapper' : ''}>
                    {loading ? (
                        <Loader />
                    ) : courseData?.data?.data?.length > 0 ? (
                        courseData?.data?.data?.map((course) => (
                            <CourseCard key={course.id} course={course} />
                        ))
                    ) : (
                        !loading && <div className="empty-cart order-placed-box" >
                            <i className="m-icon icon-emptycart"></i>
                            <h3 className="order-placed-title cart-empty">Not purchased Course Yet !</h3>
                            {/* <p className="order-placed-details">Must add items to the cart before you proceed to checkout.</p> */}
                            <Link to={'/course'}>
                                <button className="m-btn m-btn-hero mt-6">Courses</button>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export default MyCourses;