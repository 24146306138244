import axios from "axios";
import { getNoAuthToken } from "../AllGlobalFunctions";

export const api = axios.create();

api.interceptors.request.use(
    (config) => {
        const token = getNoAuthToken()
        if (token) {
            config.headers['token'] = token;
            return config
        } else {
            // handleLogout()
        }
    },
    (error) => {
        return Promise.reject(error)
    }
)

















