import React, { useState, useEffect } from "react";
import Breadcrumb from "../../component/common/Breadcrumb";
import DataTables from "../../datatable/DataTable";
import { FaEdit, FaTrash } from "react-icons/fa";
import { getApi, postApi } from "../../helper/helper";
import { Button, Form, Modal } from "react-bootstrap";
import { Card } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import { notify, notifyErr } from "../../component/common/notify";
import apiPath from "../../../apipath";
import Loader from "../../../components/Loader";

const Banner = () => {

    // page_name , banner_title, banner_description, btn_text , btn_slug

    const [data, setData] = useState([]);
    const [pending, setPending] = useState(true);
    const [formDataUpdate, setFormDataUpdate] = useState({
        banner_image: "",
    });
    const [image, setImage] = useState("");
    const [showUpdate, setShowUpdate] = useState(false);

    const handleShowUpdate = (row) => {
        setShowUpdate(true);
        getIndividualData(row.id);
    };

    const handleCloseUpdate = () => {
        setShowUpdate(false);
        setFormDataUpdate({
            banner_image: "",
        });
        reset1({
            page_name: "",
            banner_title: "",
            banner_description: "",
            btn_text: "",
            btn_slug: "",
            banner_image: ""
        });
    };

    const handleInputChange1 = (field, value) => {
        setFormDataUpdate({ ...formDataUpdate, [field]: value });
    };

    const fetchData = async () => {
        setPending(true);
        try {
            const result = await getApi(apiPath.getBanner);
            setData(result);
        } catch (error) {
            console.error(error);
        } finally {
            setPending(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        {
            name: <th className="fs-6 ">#</th>,
            cell: (row, index) => index + 1,
            width: "100px",
        },

        {
            name: <th className="fs-6 ">Page name</th>,
            selector: (row) => row.page_name,
            width: "200px",
        },
        {
            name: <th className="fs-6 ">Banner Title</th>,
            selector: (row) => row.banner_title,
            width: "200px",
        },
        {
            name: <th className="fs-6">Banner Description</th>,
            selector: (row) => row.banner_description,
            width: "300px",
        },
        {
            name: <th className="fs-6">Image</th>,
            selector: (row) => <img src={`/api/assets/banner/${row.banner_image}`} width="60px" height="60px" className="p-2" alt='Image' />,
            width: "200px",
        },
        {
            name: <th className="fs-6 ">Button Text</th>,
            selector: (row) => row.btn_text,
            width: "200px",
        },
        {
            name: <th className="fs-6">Button Slug</th>,
            selector: (row) => row.btn_slug,
            width: "300px",
        },
        {
            name: <th className="fs-6 action">Action</th>,
            selector: (row) => (
                <div className="d-flex" style={{ gap: "10px" }}>
                    <Button
                        className="text-white border-0"
                        style={{ float: "right", backgroundColor: "var(--primary)" }}
                        onClick={() => handleShowUpdate(row)}
                    >
                        <FaEdit />
                    </Button>
                </div>
            ),
            right: true,
        },
    ];

    const {
        register: register1,
        handleSubmit: handleSubmit1,
        reset: reset1,
        formState: { errors: errors1 },
    } = useForm();

    const getIndividualData = async (id) => {
        setPending(true);
        try {
            const result = await getApi(`${apiPath.getBanner}/${id}`);
            const data = result[0];
            if (data) {
                setFormDataUpdate((prevFormDataUpdate) => ({
                    ...prevFormDataUpdate,
                    ["id"]: data.id,
                    ["page_name"]: data.page_name,
                    ["banner_title"]: data.banner_title,
                    ["banner_description"]: data.banner_description,
                    ["btn_text"]: data.btn_text,
                    ["btn_slug"]: data.btn_slug,
                    // ["banner_image"]: data.banner_image
                }));
                reset1({
                    page_name: data.page_name,
                    banner_title: data.banner_title,
                    banner_description: data.banner_description,
                    btn_text: data.btn_text,
                    btn_slug: data.btn_slug,
                    // banner_image: data.banner_image
                });
                setImage(data.banner_image);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setPending(false);
        }
    };

    const onUpdate = async () => {
        setPending(true);
        try {
            const result = await postApi(apiPath.updateBanner, formDataUpdate);
            handleCloseUpdate();
            notify("Banner Updated Successfully!");
            fetchData();

        } catch (error) {
            console.error(error);
            notifyErr("Something went wrong!");
        } finally {
            setPending(false);
        }
    };

    const breadcrumbItems = [
        { title: 'Banner', link: null }
    ];

    return (
        <>
            <section>

                {pending && <Loader />}

                <ToastContainer />
                <div className="mb-5">
                    <Breadcrumb items={breadcrumbItems} />
                </div>

                <Card variant="outlined" className="pb-3">
                    <div>
                        <DataTables
                            title={"Banner"}
                            data={data}
                            columns={columns}
                            pending={pending}
                            paginationServer={false}
                            // totalRows={data?.length}
                        />
                    </div>
                </Card>

                {/* update */}
                <Modal
                    show={showUpdate}
                    onHide={handleCloseUpdate}
                    style={{ zIndex: "9999" }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Update Banner</Modal.Title>
                    </Modal.Header>
                    <Form
                        className="row m-2"
                        onSubmit={handleSubmit1(onUpdate)}
                        autoComplete="off"
                    >
                        <Modal.Body>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="page_name">Page Name</Form.Label>
                                <Form.Select
                                    disabled
                                    type="type"
                                    id="page_name"
                                    placeholder="page"
                                    value={formDataUpdate.page_name}
                                    {...register1("page_name", {
                                        required: true,
                                        // onChange: (e) =>
                                        //   handleInputChange1("page_id", e.target.value),
                                    })}
                                >
                                    <option value="">Select Type</option>
                                    <option value={formDataUpdate.page_name}>{formDataUpdate.page_name}</option>
                                </Form.Select>
                                {errors1.page_name && (
                                    <div className="errMsg text-danger">Please Select Course</div>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="banner_title">Banner Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Title"
                                    id="banner_title"
                                    value={formDataUpdate.banner_title}
                                    {...register1("banner_title", {
                                        required: true,
                                        onChange: (e) => handleInputChange1("banner_title", e.target.value),
                                    })}
                                />
                                {errors1.banner_title && (
                                    <div className="errMsg text-danger">Please Enter Banner Title</div>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="banner_description">
                                    Banner Description
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={4}
                                    type="text"
                                    id="banner_description"
                                    placeholder="Short banner_description"
                                    value={formDataUpdate.banner_description}
                                    {...register1("banner_description", {
                                        required: false,
                                        onChange: (e) =>
                                            handleInputChange1("banner_description", e.target.value),
                                    })}
                                />
                                {errors1.banner_description && (
                                    <div className="errMsg text-danger">
                                        Please Enter Banner Description
                                    </div>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="btn_text">Button</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="btn_text"
                                    id="btn_text"
                                    value={formDataUpdate.btn_text}
                                    {...register1("btn_text", {
                                        required: false,
                                        onChange: (e) => handleInputChange1("btn_text", e.target.value),
                                    })}
                                />
                                {errors1.btn_text && (
                                    <div className="errMsg text-danger">Please Enter Button Text</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="btn_slug">Button Slug</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="slug"
                                    id="btn_slug"
                                    value={formDataUpdate.btn_slug}
                                    {...register1("btn_slug", {
                                        required: false,
                                        onChange: (e) => handleInputChange1("btn_slug", e.target.value),
                                    })}
                                />
                                {errors1.btn_slug && (
                                    <div className="errMsg text-danger">Please Enter Button Slug</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mt-2">
                                <div className="row">
                                    <div className="col-12">
                                        <Form.Label>Banner Image</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="file"
                                            {...register1("banner_image", {
                                                required: false,
                                                onChange: (e) =>
                                                    handleInputChange1("banner_image", e.target.files[0]),
                                            })}
                                        />

                                        {errors1.banner_image && (
                                            <div className="errMsg text-danger">
                                                Please Select Image
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-12 text-center mb-3 mt-3">
                                        <Form.Label>Image Preview</Form.Label>
                                        <div className="p-3">
                                            <img
                                                src={
                                                    formDataUpdate.banner_image == ""
                                                        ? image != ""
                                                            ? `/api/assets/banner/${image}`
                                                            : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/330px-No-Image-Placeholder.svg.png?20200912122019"
                                                        : URL.createObjectURL(formDataUpdate.banner_image)
                                                }
                                                style={{
                                                    maxWidth: "100px",
                                                    maxHeight: "100px",
                                                    margin: "auto",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="submit"
                                variant="contained"
                                className="text-white btn-primary"
                            >
                                Update
                            </Button>
                            <Button variant="secondary" onClick={handleCloseUpdate}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </section>
        </>
    )
}

export default Banner