import React, { useState, useEffect } from "react";
import { Axios, getApi } from "../../helper/helper";
import { Box, Button, Card, CardContent } from "@mui/material";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { postApi } from "../../helper/helper";
import { notify, notifyErr } from "../../component/common/notify";
import DataTables from "../../datatable/DataTable";
import { ToastContainer } from "react-toastify";
import { Form, Modal } from "react-bootstrap";
import apiPath from "../../../apipath";
import Loader from "../../../components/Loader";



const Dashboard = () => {

  const [codeData, setCodeData] = useState({
    link: "",
    stock_code: "",
    stock_last_update: ""
  })
  const [stockHistory, setStockHistory] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  let [filterData, setFilterData] = useState({
    page: 1,
    per_page: 10
  });
  const [countData, setCountData] = useState({

  });
  const [pending, setPending] = useState(true);
  const [code, setCode] = useState('');
  const [formData, setFormData] = useState({
    stock_code: "",
  });
  const [formDataUpdate, setFormDataUpdate] = useState({
    stock_code: "",
  });

  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setFormData({
      stock_code: "",
    });
    reset({
      stock_code: "",

    });
  };

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    //if (field === 'type' && value == 0) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      //price: 0,
    }));
    //}
  };

  const handleShowUpdate = (row) => {
    setShowUpdate(true);
    //getIndividualData(row.id);
  };

  const handleCloseUpdate = () => {
    setShowUpdate(false);
    setFormDataUpdate({
      image: "",
      stock_code: "",
    });
    reset1({
      stock_code: "",

    });
  };

  const handleInputChange1 = (field, value) => {
    setFormDataUpdate({ ...formDataUpdate, [field]: value });
    // setFormDataUpdate((prevFormData) => ({
    //   ...prevFormData,

    // }));

  };

  const formateDate = (timestamp) => {
    // Create a new Date object with the timestamp
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds

    // Define an array of month names
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Define an array of day names
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // Get the day, month, year, hour, minute, and second from the Date object
    const day = dayNames[date.getDay()];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    // Format the date string
    const formattedDate = `${day}, ${date.getDate()} ${month} ${year} ${hour}:${minute}:${second}`;
    return formattedDate;
  }


  const columns = [
    {
      name: <th className="fs-6 ">#</th>,
      cell: (row, index) => index + 1,
      width: "100px",
    },

    {
      name: <th className="fs-6 ">Unique Id</th>,
      selector: (row) => row.unique_id,
      width: "200px",
    },
    {
      name: <th className="fs-6">Timestamp</th>,
      selector: (row) => row.timestamp,
      width: "200px",
    },
    {
      name: <th className="fs-6 ">Requested Stocks</th>,
      selector: (row) => row.requested_stocks,
      width: "200px",
    },
    {
      name: <th className="fs-6">Completed Stocks</th>,
      selector: (row) => row.completed_stocks,
      width: "200px",
    },
    {
      name: <th className="fs-6">Remaining Stocks</th>,
      cell: (row) => (
        <span>
          {row.remaning_stocks ? row.remaning_stocks : '-'}
        </span>
      ),
      width: "200px",
    }
  ]

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: errors },
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm();

  const onUpdate = async () => {
    setPending(true);
    try {
      const result = await postApi(apiPath.updateCode, formDataUpdate);

      handleCloseUpdate();
      notify("Code Updated Successfully!");
      getData();


    } catch (error) {
      console.error(error);
      notifyErr("Something went wrong!");
    } finally {
      setPending(false);
    }
  };


  // try {
  //   let { status } = verifyOTP({ email, code: otp.join('') });
  //   if (status === 201) {
  //     toast.success('Verify Successfully!');
  //     return navigate('/resetPassword');
  //   }
  // } catch (error) {
  //   return toast.error('Wront OTP! Check email again!');
  // }


  const getData = async () => {
    try {
      const { data, status } = await Axios.get(apiPath.getClientId);
      if (status === 200 && data.response_code === 200) {
        setCodeData({
          link: data.data,
          stock_code: data.stock_code,
          stock_last_update: data.stock_last_update
        })
        setFormDataUpdate({
          stock_code: data.stock_code
        })
        reset1({
          stock_code: data.stock_code
        });
      }
    } catch (error) {
      console.error(error);
      notifyErr(error.response.data.message);
    }


  }

  useEffect(() => {
    // getData();
  }, []);

  const fetchTableData = async (filteredData) => {
    setPending(true);
    try {
      const { data, status } = await Axios.get(apiPath.stockHistoryInfo, {
        params: filteredData
      });
      if (status === 200 && data.response_code === 200) {
        setStockHistory(data.data);
        setTotalRows(data?.pagination?.total_data);
      }
      setPending(false)
    } catch (error) {
      console.error(error);
      setPending(false)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      fetchTableData(filterData);
    }, 1000);
  }, [filterData]);


  const handlePageChange = page => {
    fetchTableData({ ...filterData, page: page });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    // fetchTableData({ page: page, per_page: newPerPage });
    setFilterData({
      page: page,
      per_page: newPerPage
    })
  }

  const verifyToken = async () => {
    try {
      const { data, status } = await Axios.get(apiPath.getStockToken);
      if (status === 200 && data.response_code === 200) {
        notify(data.message);
      }
    } catch (error) {
      console.error(error);
      notifyErr(error.response.data.message);
    }
  }

  const fetchData = async () => {
    setPending(true);
    try {
      const result = await getApi(apiPath.getDashboardCount);
      if (result) {
        setCountData((prevCountData) => ({
          ...prevCountData,
          ["Course"]: result.Course,
          ["Users"]: result.User,
          ["Enquiry"]: result.Enquiry,
          ["Testimonials"]: result.Testimonial,

        }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }

    try {
      const { data, status } = await Axios.get(apiPath.getClientId);
      if (status === 200 && data.response_code === 200) {
        setCodeData({
          link: data.data,
          stock_code: data.stock_code,
          stock_last_update: data.stock_last_update
        })
        setFormDataUpdate({
          stock_code: data.stock_code
        })
        reset1({
          stock_code: data.stock_code
        });
      }
    } catch (error) {
      console.error(error);
      notifyErr(error.response.data.message);
    }

  };

  useEffect(() => {
    setTimeout(() => {
      fetchData();
    }, 1000);
   
  }, []);

  const statusMap = {
    'Course': 'Active',
    'Users': 'Active',
    'Enquiry': 'Pending',
    'Testimonials': 'Pending',
  };

  function getStatus(key) {
    return statusMap[key] || null;
  }

  // const handleButtonClick = () => {
  //   if (link) {
  //     window.open(link, '_blank');
  //   }
  // };
  // const handleButtonClick = () => {
  //   // Show popup with message
  //   alert(message);
  // }





  return <section>
    {pending && <Loader />}
    <div className="d-flex align-center gap-4 mb-5 flex-wrap px-3">

      <div className="d-flex flex-column">
        <Form.Label htmlFor="title">Check Token</Form.Label>
        <div className="btn-d">
          <Button
            variant="contained"
            className="text-white bg-success"
            onClick={() => verifyToken()}
          >
            Check Token
          </Button>
        </div>
      </div>

      <Form
        onSubmit={handleSubmit1(onUpdate)}
        autoComplete="off"
      >
        <Form.Group>
          <Form.Label htmlFor="title">Stock code ( <span className="text-success">Last Generated: {codeData?.stock_last_update}</span>)</Form.Label>
          <div className="d-flex justify-start align-center gap-3">
            <Form.Control
              type="text"
              placeholder="Stock code"
              id="stock_code"
              value={formDataUpdate.stock_code}
              {...register1("stock_code", {
                required: true,
                onChange: (e) => handleInputChange1("stock_code", e.target.value),
              })}
              style={{ width: '250px' }}
            />
            <Button
              type="submit"
              variant="contained"
              className="text-white btn-primary"
            >
              Update
            </Button>
          </div>
          {errors1.stock_code && (
            <div className="errMsg text-danger">Please Enter stock code</div>
          )}
        </Form.Group>
      </Form>

      <div className="d-flex flex-column">
        <Form.Label htmlFor="title">Generate Code</Form.Label>
        <div>
          <a href={codeData?.link} target="_blank">
            <Button
              variant="contained"
              className="text-white "
              style={{ background: '#320615' }}
            >
              Generate Code
            </Button>
          </a>
        </div>

      </div>

    </div>


    {/* <Button
          variant="contained"
          color="primary"
          component="a"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Generate Code
        </Button> */}
    <Box className="row m-0 row-gap-3"
      sx={{
        width: '100%',
        // display: 'flex',
        // flexWrap: 'wrap',
        // // gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
        // gap: 2,
      }}
    >

      {Object.keys(countData).map((key, index) => (
        <Link to={key.toLocaleLowerCase()} className="text-decoration-none col-lg-3 col-md-6 col-sm-12 col-xs-12" >
          <Card elevation={4}
            className="p-3 relative bg-cover bg-no-repeat
          "
            style={{
              backgroundImage: 'url(https://us.123rf.com/450wm/chingraph/chingraph1610/chingraph161000216/64276280-blue-layer-background-3d-render.jpg?ver=6)'
            }}
          >
            <CardContent>
              <h1 className="text-white font-bold">{countData[key]}</h1>
              <p className="text-white font-bold m-0">{key}</p>
              <span className="text-white">({getStatus(key)})</span>
            </CardContent>
            {/* <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className="absolute right-0 bottom-0 h-32 w-32 -mr-8 -mb-8 text-green-600 opacity-50">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg> */}
          </Card>
        </Link>
      ))}

    </Box>

    <Card variant="outlined" className="mt-5 pb-3 mx-2">
      <div className="btn-d flex flex-col sm:flex-row row-gap-3 justify-content-between m-3">
        {/* <Form.Select value={status} className="filter-select  w-auto"
          onChange={(e) => handleStatusFilter(e.target.value)}
        >
          <option value="0">Active</option>
          <option value="1">In-Active</option>
          <option value="2">All</option>
        </Form.Select>
        <Button
          className="text-white border-0 default-btn"
          onClick={() => handleShow()}
        >
          Add Advantage/Highlight
        </Button> */}
      </div>
      <div>
        <DataTables
          title={"Stock History"}
          data={stockHistory}
          columns={columns}
          paginationServer={true}
          totalRows={totalRows}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
          pending={pending}
        />
      </div>
    </Card>

    {/* stockHistory */}


    {/* <Button
      variant="contained"
      // color="primary"
      component="a"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        //color: '#fff', // Set text color to white
        backgroundColor: "var(--primary)", // Set background color to #212529
        margin: '1rem 0', // Add margin
      }}
    >
      Generate Code=
    </Button> */}

    {/* <Button
      variant="contained"
      // color="primary"
      //rel="noopener noreferrer"
      sx={{
        //color: '#fff', // Set text color to white
        backgroundColor: "var(--primary)", // Set background color to #212529
        margin: '1rem 0',
        marginLeft: '80px'
      }}
      onClick={handleButtonClick}
    >
      check token
    </Button> */}

    <ToastContainer />

    {/* <Form
      className="row m-2"
      onSubmit={handleSubmit1(onUpdate)}
      autoComplete="off"
    >
      <Modal.Body>



        <Form.Group className="mb-2">
          <Form.Label htmlFor="title">Stock code</Form.Label>
          <Form.Control
            type="text"
            placeholder="Stock code"
            id="stock_code"
            value={formDataUpdate.stock_code}
            {...register1("stock_code", {
              required: true,
              onChange: (e) => handleInputChange1("stock_code", e.target.value),
            })}
          />
          {errors1.stock_code && (
            <div className="errMsg text-danger">Please Enter stock code</div>
          )}
        </Form.Group>


      </Modal.Body>
      <Modal.Footer>
        <Button
          type="submit"
          variant="contained"
          className="text-white btn-primary"
        >
          Update

        </Button> */}
    {/* <Button variant="secondary" onClick={handleCloseUpdate}>
              Close
            </Button> */}
    {/* </Modal.Footer>
    </Form> */}
    {/* chart */}
    <div className="mt-5">
      {/* <AmStockChart /> */}
      {/* <AmStockIndicator /> */}
      {/* <AmStockLive /> */}

    </div>
    <div className="mt-5">
      {/* <AmStockIndicator /> */}
    </div>

    {/* <div className="mt-5">
      <DataGridTable />
    </div> */}
  </section >;
};

export default Dashboard;