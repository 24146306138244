import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import bradcom from '../../icons/bradcome-arrow.svg'
import CourseCard from '../../components/Course/CourseCard';
import Slider from '../../components/Slider';
import { fetchDataFromApi, handlePageClick } from '../../libs/AllGlobalFunctions';
import Loader from '../../components/Loader';
import ReactPaginate from 'react-paginate'
import apiPath from '../../apipath';
import { useEffect } from 'react';
import axios from 'axios'
import { _banner_, _courseImg_ } from '../../libs/ImagePath';
import CurriculumAccordion from '../../components/Accordion/CurriculumAccordion';
import useToast from '../../hooks/useToast';
import { useDispatch, useSelector } from 'react-redux';
import Toster from '../../components/Toster';
import useAuthenticated from '../../hooks/useAuthenticated';
import { useNavigate } from 'react-router-dom/dist';
import { getNoAuthToken } from '../../libs/AllGlobalFunctions';
import { CourseItemSkeleton } from '../CourseCatalog';
import { fetchCart } from '../../redux/features/cartSlice';
import { Rating } from '@mui/material';
import { useImmer } from 'use-immer';
import Accordion from 'react-bootstrap/Accordion';
import Coupon from '../../components/Coupon';

export default function CourseDetail() {

  const { userInfo, accessToken } = useSelector((state) => state?.auth);
  const categoryList = useSelector((state) => state?.basic?.categories);
  const banners = useSelector((state) => state?.banner?.banners);

  const [activeTab, setActiveTab] = useState('Overview');
  const [loading, setLoading] = useState(false);
  const [courseDetail, setCourseDetail] = useState({});
  const [testimonials, setTestimonials] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [relatedCourse, setRelatedCourse] = useState([]);
  const [courseAdvantage, setCourseAdvantage] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [banner, setBanner] = useState({})
  const { toast, showToast } = useToast();
  const isAuth = useAuthenticated();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [filterData, setFilterData] = useImmer({
    page: 1,
    per_page: 5
  });

  const { id } = useParams();


  const handleTabClick = (tabName) => {
    setActiveTab(tabName)
  }

  useEffect(() => {

    window.scrollTo(0, 0);
    if (id) {
      fetchSingleCourse();
      fetchChapters();
      fetchCourseFaqs();
      fetchCourseAdvantage();
    }

    if (banners) {
      let [bannerData] = banners?.filter(i => i.page_id == 3);
      setBanner(bannerData);
    }

  }, [id, banners]);

  useEffect(() => {

    if (courseDetail?.category_id) {
      fetchRelatedCourse(courseDetail?.category_id);
    }

  }, [courseDetail]);

  useEffect(() => {
    fetchTestimonials(filterData);
  }, [filterData])


  const addToCart = async (item) => {
    setLoading(true);
    try {

      const submitFormData = new FormData();
      Object.entries(item).forEach(([key, value]) => {
        submitFormData.append(key, value);
      });

      const response = await axios.post(`${apiPath.addToCart}`, submitFormData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'token': `${getNoAuthToken()}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200 && response?.data?.response_code == 200) {
        showToast(response?.data?.message, "success", 3000);


        if (userInfo && userInfo?.id) {
          dispatch(fetchCart({ userId: userInfo?.id, accessToken }))
        }

      } else {
        showToast(response?.data?.message, "error", 3000);
      }
      setLoading(false);

    } catch (error) {
      console.log(error);
      showToast("Something went wrong !", "error", 3000);
      setLoading(false)
    }

  };

  const fetchSingleCourse = () => {

    fetchDataFromApi(`getSingleCourse/${id}`, setLoading, 'GET', {}).then((response) => {
      if (response?.status == 200 && response?.data) {
        setCourseDetail(response?.data?.data[0])
      } else {
        setCourseDetail({});
      }
    }
    ).catch((error) => {
      setLoading(false);
      console.log(error)
    })
  };

  const fetchChapters = () => {
    fetchDataFromApi(`getChapter/${id}/0/0`, setLoading, 'GET', {}).then((response) => {
      if (response?.status == 200 && response?.data) {
        setChapters(response?.data?.data)
      } else {
        setChapters([]);
      }
    }
    ).catch((error) => {
      setLoading(false);
      console.log(error)
    })
  };

  const fetchTestimonials = (filteredData) => {

    fetchDataFromApi(`${apiPath.getCourseTestimonials}/${id}/1`, setLoading, 'GET', filteredData).then((response) => {
      if (response?.status == 200 && response?.data) {
        setTestimonials(response?.data)

      } else {
        setTestimonials([]);
      }
    }
    ).catch((error) => {
      setLoading(false);
      console.log(error)
    })

  };

  const fetchRelatedCourse = (categoryId) => {
    fetchDataFromApi(apiPath.getCourses, null, 'GET', { category_id: categoryId }).then((response) => {
      if (response?.status == 200 && response?.data) {
        const filterCourse = response?.data?.data?.filter((item) => item?.id !== id);
        setRelatedCourse(filterCourse)
      } else {
        setRelatedCourse([]);
      }
    }
    ).catch((error) => {
      console.log(error);
    })
  };

  const fetchCourseAdvantage = () => {
    fetchDataFromApi(`${apiPath.getCourseAdvantage}/${id}/2`, setLoading, 'GET', {}).then((response) => {
      if (response?.status == 200 && response?.data) {
        setCourseAdvantage(response?.data?.data)
      } else {
        setCourseAdvantage([]);
      }
    }
    ).catch((error) => {
      setLoading(false);
      console.log(error)
    })
  };

  const fetchCourseFaqs = () => {
    fetchDataFromApi(`${apiPath.getFaqs}/${id}/2`, setLoading, 'GET', {}).then((response) => {
      if (response?.status == 200 && response?.data) {
        setFaqs(response?.data?.data)
      } else {
        setFaqs([]);
      }
    }
    ).catch((error) => {
      setLoading(false);
      console.log(error)
    })
  };

  return (
    <>
      {loading && <Loader />}

      <div className='pc-details-banner' style={{ backgroundImage: `url('${_banner_}/${banner?.banner_image}')` }}>
        <h2 className='pc-details-title'>
          {courseDetail?.title}
        </h2>
        <div className='p-couse-bradcom-wrapper'>
          <Link to={'/'} className='p-bradcom-item'>
            Home
          </Link>
          <img src={bradcom} alt='' />
          <Link to={'/course'} className='p-bradcom-item'>
            Course
          </Link>
          <img src={bradcom} alt='' />
          <p className='p-bradcom-item'>
            {courseDetail?.title}
          </p>
        </div>
      </div>
      <section className='section_spacing  '>
        <div className='site-container'>
          <div className='pc-details-wrapper'>
            <div className='pc-details-items'>
              <div className='pc-details-section'>
                <h2 className='pc-dl-title mb-3'>
                  {courseDetail?.title}
                </h2>
                <div className='flex flex-col justify-start gap-2 sm:flex-row'>
                  <p className='mr-8'>
                    {categoryList?.filter(i => i.id === courseDetail?.category_id)?.map(item => item.name)}
                  </p>
                  {courseDetail?.average_rating > 0 &&
                    <div className='flex items-center justify-start gap-2'>
                      <Rating className='mb-1' name="size-medium" defaultValue={courseDetail?.average_rating} precision={0.5} readOnly />
                      <p>({courseDetail?.reviews_count} Reviews)</p>
                    </div>}
                </div>
                <div className='pc-dl-img'>
                  <img src={`${_courseImg_}/${courseDetail?.image}`} alt='' className='mt-3' />
                </div>
              </div>
              <div className='pc-details-tab'>
                <ul className='pcd-tab-items'>
                  <li
                    className={`pcd-tab-items-list ${activeTab === 'Overview'
                      ? 'pcd-tab-items-list active'
                      : ''
                      }`}
                    onClick={() => handleTabClick('Overview')}
                  >
                    Overview
                  </li>
                  {
                    chapters && chapters?.length > 0 && <li
                      className={`pcd-tab-items-list ${activeTab === 'Curriculum'
                        ? 'pcd-tab-items-list active'
                        : ''
                        }`}
                      onClick={() => handleTabClick('Curriculum')}
                    >
                      Curriculum
                    </li>
                  }
                  {
                    courseAdvantage && courseAdvantage?.length > 0 && <li
                      className={`pcd-tab-items-list ${activeTab === 'Course Highlights'
                        ? 'pcd-tab-items-list active'
                        : ''
                        }`}
                      onClick={() => handleTabClick('Course Highlights')}
                    >
                      Course Highlights
                    </li>
                  }
                  {
                    testimonials && testimonials?.data?.length > 0 &&
                    <li
                      className={`pcd-tab-items-list ${activeTab === 'Reviews' ? 'pcd-tab-items-list active' : ''
                        }`}
                      onClick={() => handleTabClick('Reviews')}
                    >
                      Reviews
                    </li>
                  }
                  {
                    faqs && faqs?.length > 0 &&
                    <li
                      className={`pcd-tab-items-list ${activeTab === 'Faqs' ? 'pcd-tab-items-list active' : ''
                        }`}
                      onClick={() => handleTabClick('Faqs')}
                    >
                      Faqs
                    </li>
                  }
                </ul>
                <div className='pcd-tabs-details'>
                  {activeTab === 'Overview' && (
                    <div className='pcd-tabs-details-item'>
                      <h4 className='pc-dp-title'>Course Description</h4>
                      <p className='pc-overviw-contains'>
                        {courseDetail?.description}</p>
                    </div>
                  )}
                  {activeTab === 'Curriculum' && (
                    <div className='pcd-tabs-details-item'>
                      <h4 className='pc-dp-title'>Course Curriculum</h4>
                      <CurriculumAccordion chapters={chapters} courseId={id} />
                    </div>
                  )}
                  {activeTab === 'Instructor' && (
                    <div className='pcd-tabs-details-item'>
                      <h5 className='pc-dp-title mb-2'>{courseDetail[0]?.instructor_name}</h5>
                      <p>{courseDetail[0]?.bio}</p>
                    </div>
                  )}
                  {activeTab === 'Reviews' && (
                    <div className='pcd-tabs-details-item'>
                      <h4 className='pc-dp-title'>Reviews</h4>
                      {
                        testimonials && testimonials?.data?.length > 0 &&
                        testimonials?.data?.map((item) => {
                          return (
                            <div className='pc-review-wrapper'>
                              {/* <div className='pc-review-img'>
                                <img src={testimonialuser} alt='' />
                              </div> */}
                              <div className='pc-review-details'>
                                <div className='h-pc-raitting-items'>
                                  {/* <StarRating rating={item?.stars} /> */}
                                  <Rating name="half-rating-read" defaultValue={item?.stars} precision={0.5} size="small" readOnly />
                                </div>
                                <h3 className='h-pc-rv-name'>{item?.name}</h3>
                                <h5 className='h-pc-rv-subject'>{item?.title}</h5>
                                <p className='h-pc-review-contains'>
                                  {item?.content}
                                </p>
                              </div>
                            </div>
                          )
                        })
                      }
                      {
                        testimonials && testimonials?.data?.length > 0 && <div className='p-pagenation'>
                          <ReactPaginate
                            previousLabel={
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                height='24'
                                viewBox='0 -960 960 960'
                                width='24'
                              >
                                <path d='M360-200 80-480l280-280 56 56-183 184h647v80H233l184 184-57 56Z' />
                              </svg>
                            }
                            nextLabel={
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                height='24'
                                viewBox='0 -960 960 960'
                                width='24'
                              >
                                <path d='m600-200-57-56 184-184H80v-80h647L544-704l56-56 280 280-280 280Z' />
                              </svg>
                            }
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={testimonials?.pagination?.page}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={(event) => handlePageClick(event, setFilterData)}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                            forcePage={filterData?.page - 1}
                          />
                        </div>
                      }
                    </div>

                  )}
                  {
                    activeTab === 'Course Highlights' && (
                      <div className='pcd-tabs-details-item'>
                        <h4 className='pc-dp-title'>Course Highlights</h4>
                        {
                          courseAdvantage && courseAdvantage?.length > 0 && courseAdvantage?.map((item) => {
                            return (
                              <>
                                <h1 className='pc-dp-item-title'>{item?.title}</h1>
                                <p className='pc-overviw-contain'>{item?.description}</p></>
                            )
                          })
                        }
                      </div>
                    )
                  }
                  {
                    activeTab === 'Faqs' && (
                      <div className='pcd-tab-items-list'>
                        <h4 className="pc-dp-title">Faqs</h4>
                        <Accordion defaultActiveKey={0}>
                          {faqs && faqs?.length > 0 && faqs?.map((item, index) => (
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header>
                                <h4 className="text-2xl">{item.title}</h4>
                              </Accordion.Header>
                              <Accordion.Body>
                                <p>
                                  {item.description}
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      </div>
                    )
                  }

                </div>
              </div>
            </div>
            {/* right section  */}

            <div className='pc-details-items'>
              <div className='pc-details-price-section max-w-[400px] lg:max-w-full'>
                <h4 className='pc-dp-title'>Course Includes:</h4>
                <ul className="checkout-order-border">
                  <li className='pc-dp-item'>
                    <p className='pc-dp-item-title'>Price:</p>
                    <h4 className='pc-dp-price'>₹ {courseDetail?.price}</h4>
                  </li>
                  <li className='pc-dp-item'>
                    <p className='pc-dp-item-title'>Instructor:</p>
                    <h4 className='pc-dp-item-contain'>{courseDetail?.instructor_name}</h4>
                  </li>
                  <li className='pc-dp-item'>
                    <p className='pc-dp-item-title'>Lessons:</p>
                    <h4 className='pc-dp-item-contain'>{courseDetail?.total_lesson}</h4>
                  </li>
                  <li className='pc-dp-item'>
                    <p className='pc-dp-item-title'>Students:</p>
                    <h4 className='pc-dp-item-contain'>{courseDetail?.total_student}</h4>
                  </li>
                </ul>

                <Coupon id={id} total={courseDetail?.price} />

                {toast?.show && (
                  <Toster text={toast?.text} variation={toast?.variation} />
                )}

                {userInfo && userInfo?.course_ids?.includes(id) ? null :
                  <div className='btn-bynow'>
                    <button onClick={() => {
                      if (userInfo && userInfo?.id && isAuth) {
                        addToCart({
                          user_id: userInfo?.id,
                          course_name: courseDetail?.title,
                          image: courseDetail?.image,
                          price: courseDetail?.price,
                          course_id: id
                        })
                      } else {
                        showToast('Please log in before adding Course to your cart', 'error', 4000);
                        setTimeout(() => navigate('/auth/login'), 3000);
                      }
                    }} className=' m-btn m-btn-hero'>
                      Add To Cart
                    </button>
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </section>
      {
        relatedCourse?.length > 0 && <section className='section_spacing pt-0'>
          <div className='site-container'>
            <h4 className='pc-dp-title'>Courses You May Like</h4>
            <div className='course-details-slider'>
              <Slider
                spaceBetween={20}
                slidesPerView={1}
                loop={true}
                autoplay={true}
                navigation={false}
                pagination={false}
                breakpoints={{
                  768: {
                    slidesPerView: 2
                  },
                  1024: {
                    slidesPerView: 3
                  }
                }}
              >
                {
                  loading ? (
                    Array.from({ length: 3 }).map((_, index) => (
                      <CourseItemSkeleton key={index} />
                    ))
                  ) : (
                    relatedCourse?.map((course) => (
                      <CourseCard course={course} key={course.id} />
                    ))
                  )
                }
              </Slider>
            </div>
          </div>
        </section>
      }
    </>
  )
}