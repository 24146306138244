import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosProvider from '../../libs/axios/axiosProvider';
import apiPath from '../../apipath';
import { getApi } from '../../admin/helper/helper';

export const fetchCategory = createAsyncThunk('data/fetchCategoryData', async () => {
    try {
        const response = await axiosProvider({
            method: "GET",
            endpoint: apiPath.getCategory,
            // queryString: `?&pageIndex=0&pageSize=0&Status=active&IsDeleted=false`,
        });
        if (response?.status == 200) {
            if (response?.data?.response_code == 200 && response.data) {
                return response?.data?.data
            }
        }
        return []
    } catch (error) {
        throw Error('Failed to fetch categories');
    }
});

export const fetchChapterData = createAsyncThunk(
    'data/fetchChapterData',
    async (courseId) => {
        try {
            const result = await getApi(`${apiPath.getChapter}/${courseId}/0/2`);
            return result;
        } catch (error) {
            console.error(error);
        }

    }
);

export const fetchLessonData = createAsyncThunk(
    'data/fetchLessonData',
    async (courseId) => {
        try {
            const result = await getApi(`${apiPath.getLessonId}/${courseId}/2`);
            return result;
        } catch (error) {
            console.error(error);
        }
    }
);

const initialState = {
    categories: [],
    loading: false,
    error: null,
    chapterData: [],
    lessonData: []
}

const basicSlice = createSlice({
    name: 'basicSlice',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchCategory.pending]: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        [fetchCategory.fulfilled]: (state, action) => {
            state.loading = false;
            state.categories = action.payload;
        },
        [fetchCategory.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error;
        },
        // fetchChapterData
        [fetchChapterData.pending]: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        [fetchChapterData.fulfilled]: (state, action) => {
            state.loading = false;
            state.chapterData = action.payload;
        },
        [fetchChapterData.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error;
        },
        // fetchLessonData
        [fetchLessonData.pending]: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        [fetchLessonData.fulfilled]: (state, action) => {
            state.loading = false;
            state.lessonData = action.payload;
        },
        [fetchLessonData.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error;
        },
    },
});

export const { actions: basicActions, reducer: basicReducer } = basicSlice;