import React from 'react';
import { Link } from 'react-router-dom';
import { fetchDataFromApi } from '../../libs/AllGlobalFunctions';
import { useState, useEffect } from 'react';
import apiPath from '../../apipath';
import { _logo_ } from '../../libs/ImagePath';
import { useSelector } from 'react-redux';

const Footer = () => {

  const settings = useSelector((state) => state?.setting?.settings);
  const { userInfo } = useSelector((state) => state?.auth);


  return (
    <footer className='p-footer'>
      <div className='site-container'>
        <div className='footer-wrapper'>
          <div className='footer-col_logo'>
            <div className='footer-logo m_logo'>
              <h3 className='footer-title'>About</h3>
              <p className='footer-contains'>
                {settings?.description || ""}
              </p>
            </div>
          </div>
          <ul className='footer-menu-wrapper footer-menu-one'>
            <h3 className='footer-title'>Online Platform</h3>
            <li className='footer-menu_list'>
              <Link to='/' className='footer-menu_link'>
                HOME
              </Link>
            </li>
            <li className='footer-menu_list'>
              <Link to='/course' className='footer-menu_link'>
                Course
              </Link>
            </li>
            <li className="footer-menu_list">
              <Link to="/portfolio" className="footer-menu_link">
                Portfolio
              </Link>
            </li>
            <li className='footer-menu_list'>
              <Link to='/contact' className='footer-menu_link'>
                Contact
              </Link>
            </li>
            <li className='footer-menu_list'>
              <Link to='/about' className='footer-menu_link'>
                About
              </Link>
            </li>
          </ul>
          <ul className='footer-menu-wrapper footer-menu-two'>
            <h3 className='footer-title'>More Links</h3>

            <li className='footer-menu_list'>
              <Link to='/privacy' className='footer-menu_link'>
                privacy policy
              </Link>
            </li>
            <li className='footer-menu_list'>
              <Link to='/terms-condition' className='footer-menu_link'>
                terms of service
              </Link>
            </li>
          </ul>

          {/* footer contact */}
          <div className='footer-col_contact'>
            <h3 className='footer-title'>Contacts</h3>
            <div className='footer-contact-wrap'>
              <span>Call : </span>
              <Link to={`tel:${settings?.phone}`} className='footer-contact_wrapper'>
                {settings?.phone}
              </Link>
            </div>
            <div className='footer-contact-wrap'>
              <span>Mail : </span>
              <a
                href={`mailto:${settings?.enq_email}`}
                className='footer-contact_wrapper'
              >
                {settings?.enq_email}
              </a>
            </div>
            {/* footer social media */}
            <div className='f-social_wrapper'>
              <Link
                to={settings?.insta_url}
                target=' '
                className='m-icon f-social-item social-intra'
              ></Link>
              <Link
                to={settings?.fb_url}
                target=' '
                className='m-icon f-social-item social-fb'
              ></Link>
              <Link
                to={settings?.twtr_url}
                target=' '
                className='m-icon f-social-item social-twitter'
              ></Link>
            </div>
          </div>
        </div>
        {/* footer copyright */}
        <div className='footer-bottom_wrapper'>
          <p className='footer-botom_item'>
            Copyright © {new Date().getFullYear()} TradesMate.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
