import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Input from '../components/Form/Input';
import { useState } from 'react';
import axiosProvider from '../libs/axios/axiosProvider';
import apiPath from '../apipath';
import Toster from '../components/Toster';
import useToast from '../hooks/useToast';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { _logo_ } from '../libs/ImagePath';

const ResetPassword = () => {

    const navigate = useNavigate();
    const { toast, showToast } = useToast();
    const [loading, setLoading] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    const settings = useSelector((state) => state?.setting?.settings);
    let token = searchParams.get('token');
    let userId = searchParams.get('user_id');

    const handleSubmit = async (values, { resetForm }) => {

        setLoading(true);

        try {
            const submitFormData = new FormData();
            Object.entries(values).forEach(([key, value]) => {
                submitFormData.append(key, value);
            });

            const response = await axiosProvider({
                method: "POST",
                endpoint: `${apiPath.resetPassword}?token=${token}&user_id=${userId}`,
                data: submitFormData,
            });

            if (response.status === 200 && response?.data?.response_code == 200) {
                showToast(response?.data?.message, "success", 3000);
                resetForm({ values: "" });
                setTimeout(() => navigate('/auth/login'), 2000);
            } else {
                showToast(response?.data?.message, "error", 3000);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            showToast("Something went wrong !", "error", 3000);
            setLoading(false)
        }
    };

    const initialValues = {
        new_password: '',
        confirm_password: '',
    };

    const validationSchema = Yup.object({
        new_password: Yup.string().required('New Password is Required').matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+}{":;'?/>.<,])[A-Za-z\d!@#$%^&*()_+}{":;'?/>.<,]{8,}$/,
            "Password must be at least 8 characters long and include uppercase, lowercase letters, numbers, and special characters."
        ),
        confirm_password: Yup.string().oneOf([Yup.ref("new_password"), null], "Passwords must match")
            .required("Confirm password is required").matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+}{":;'?/>.<,])[A-Za-z\d!@#$%^&*()_+}{":;'?/>.<,]{8,}$/,
                "Password must be at least 8 characters long and include uppercase, lowercase letters, numbers, and special characters."
            ),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema,
        onSubmit: handleSubmit
    });

    if (!(token && userId)) {
        return (
            <section className='section_spacing'>
                <div className='site-container'>
                    <div className="w-full p-4 border-t-4 border-red-500 bg-gray-100 text-center rounded-b-lg">
                        <p> Reset Password Link is Invalid
                            <Link to={'/auth/login'} className="text-blue-500">   forget Password</Link></p>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <> <div className='pc-details-banner' style={{ backgroundImage: `url('${_logo_}/${settings?.banner_image}')` }}>

            <h2 className='pc-details-title'>Reset Password</h2>
        </div>

            <section className='section_spacing'>
                {loading && <Loader />}

                <div className='site-container'>
                    {/* <PageTitle PageTitle={"Sign Up"} /> */}
                    <div className='flex items-center justify-center'>
                        <FormikProvider value={formik}>
                            <form onSubmit={formik.handleSubmit} id="signUpForm">
                                <div className='registration-wrapper'>
                                    <div className='registration-col'>
                                        <img src="https://assets.website-files.com/62011da2e1be285c04bfb6c3/632c5ab7e323127dd9f37c1c_62bedf15afd45b365d3566a4_Frame%201-p-800.webp" alt="" width={450} />
                                    </div>
                                    <div className='registration-col'>
                                        <div className='input-wrap'>
                                            <Input
                                                label="New Password"
                                                name="new_password"
                                                placeholder="New Password"
                                                autoComplete="new-password"
                                                isRequired={true}
                                                onChange={formik.handleChange}
                                                type="password"
                                            />
                                        </div>
                                        <div className='input-wrap'>
                                            <Input
                                                label="Confirm Password"
                                                name="confirm_password"
                                                placeholder="Confirm Password"
                                                autoComplete="new-password"
                                                isRequired={true}
                                                onChange={formik.handleChange}
                                                type="password"
                                            />
                                        </div>



                                        <div className='login-btn_wrapper'>
                                            <button className='m-btn m-btn-hero justify-center text-center' type='submit'>
                                                Reset Password
                                            </button>
                                        </div>

                                        {toast?.show && (
                                            <Toster text={toast?.text} variation={toast?.variation} />
                                        )}
                                    </div>
                                </div>
                            </form>
                        </FormikProvider>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ResetPassword;
