// Function to calculate the discount amount based on the discount type
export const calculateDiscountAmount = (appliedCoupon, cartTotal) => {

    if (!appliedCoupon) {
        return 0; // Return 0 if no coupon is applied
    }

    if (appliedCoupon.discount_type === "0") {
        // Percentage discount
        return (parseFloat(appliedCoupon.discount_amount) / 100) * cartTotal;
    } else if (appliedCoupon.discount_type === "1") {
        // Fixed discount amount
        return parseFloat(appliedCoupon.discount_amount);
    }

    return 0; // Default to no discount
};