import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosProvider from '../../libs/axios/axiosProvider';
import apiPath from '../../apipath';

export const fetchCart = createAsyncThunk('data/fetchCart', async ({ userId, accessToken }) => {
    try {
        const response = await axiosProvider({
            method: "GET",
            endpoint: `${apiPath.getSingleCart}/${userId}`,
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        });
        if (response?.status == 200) {
            if (response?.data?.response_code == 200 && response.data) {
                return response?.data
            }
        }
        return []
    } catch (error) {
        throw Error('Failed to fetch cart');
    }
});

const initialState = {
    cart: [],
    loading: false,
    error: null,
}

const cartSlice = createSlice({
    name: 'cartSlice',
    initialState,
    reducers: {
        setCart: (state, action) => {
            state.cart = action.payload
        }
    },
    extraReducers: {
        [fetchCart.pending]: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        [fetchCart.fulfilled]: (state, action) => {
            state.loading = false;
            state.cart = action.payload;
        },
        [fetchCart.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error;
        },
    },
});


export const { setCart } = cartSlice.actions
export const { actions: cartActions, reducer: cartReducer } = cartSlice;

