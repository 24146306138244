import { useEffect, useState } from "react";
import apiPath from "../../../apipath";
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { fetchDataFromApi, getNoAuthToken } from "../../../libs/AllGlobalFunctions";
import Loader from "../../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { loadScript } from "../../../admin/payment/Payment";
import axiosProvider from "../../../libs/axios/axiosProvider";
import useToast from "../../../hooks/useToast";
import Modal from 'react-bootstrap/Modal';
import SuccessModal from "../../../components/SuccessModal";
import { TiTick } from "react-icons/ti";
import { fetchUserInfo, setUser } from "../../../redux/features/authSlice";
import globalValidationSchema from "../../../validations/globalValidations";
import Toster from "../../../components/Toster";

const MembershipCard = ({ item, userMId, curMembership }) => {

    const { duration, name, price, id } = item;
    const { userInfo, accessToken } = useSelector((state) => state?.auth);
    const settings = useSelector((state) => state?.setting?.settings);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [coupons, setCoupons] = useState([]);
    const [couponCode, setCouponCode] = useState('');
    const [isApplied, setIsApplied] = useState(false);
    const [appliedCoupon, setAppliedCoupon] = useState(null);
    const [modalShow, setModalShow] = useState(false);

    const dispatch = useDispatch();

    const handleClose = () => {
        setShow(false);
        setAppliedCoupon(null);
        setIsApplied(false);
        setCouponCode('');
        setCoupons('');
    };
    const handleShow = (m_id) => {
        setShow(true)
        fetchCoupon(m_id)
    };

    const { toast, showToast } = useToast();

    const initialVal = {
        name: userInfo?.name,
        email: userInfo?.email
    };


    useEffect(() => {
        // fetchCoupon(id);
    }, [id]);

    const fetchCoupon = (m_id) => {
        fetchDataFromApi(apiPath.userCoupon, null, 'GET', {
            membership_id: m_id,
        }).then((response) => {
            if (response && response?.data?.data) {
                setCoupons(response?.data?.data);
            }
        }
        ).catch((error) => {
            console.log(error)
        })
    }


    const handleChangeInput = (val) => {
        setCouponCode(val);
        setAppliedCoupon(null);
        setIsApplied(false);
    }


    const handleCouponChange = (val) => {
        setCouponCode(val);
        if (coupons?.length > 0) {
            // Find the coupon in the coupon data list
            const coupon = coupons?.find(coupon => coupon.coupon_code === val);
            if (coupon && parseFloat(coupon.min_amount) <= parseFloat(price)) {
                // Set coupon data if the coupon code is found
                setAppliedCoupon({
                    coupon_code: coupon.coupon_code,
                    discount_type: coupon.type,
                    discount_amount: coupon.discount_amount,
                    discount_amount: coupon.discount_amount,
                    end_date: coupon.end_date
                });
                setIsApplied(true);
                showToast(`Coupon applied successfully`, "success", 3000);
            } else if (coupon && parseFloat(coupon.min_amount) > parseFloat(price)) {
                setAppliedCoupon(null);
                setIsApplied(false);
                showToast(`Amount must be grater then ₹${coupon.min_amount}`, "error", 3000);
            } else if (!coupon) {
                // Handle case when the coupon code is not found
                setAppliedCoupon(null);
                setIsApplied(false);
                showToast(`Invalid coupon code!`, "error", 3000);
            } else {
                setAppliedCoupon(null);
                setIsApplied(false);
                showToast(`Something went wrong!`, "error", 3000);
            }
        } else {
            setAppliedCoupon(null);
            setIsApplied(false);
            showToast(`Invalid coupon code!`, "error", 3000);
        }
    };

    // Function to calculate the discount amount based on the discount type
    const calculateDiscountAmount = (cartTotal) => {
        if (!appliedCoupon) {
            return 0; // Return 0 if no coupon is applied
        }

        if (appliedCoupon.discount_type === "0") {
            // Percentage discount
            return (parseFloat(appliedCoupon.discount_amount) / 100) * cartTotal;
        } else if (appliedCoupon.discount_type === "1") {
            // Fixed discount amount
            return parseFloat(appliedCoupon.discount_amount);
        }

        return 0; // Default to no discount
    };

    const calculateGSTAmount = (discountedTotal) => {
        const gst = settings?.GST_course;
        const gstPercentage = parseFloat(gst) / 100;
        return (discountedTotal * gstPercentage).toFixed(2);
    };

    // Function to calculate the final total amount including discount and GST
    const calculateFinalTotal = (price) => {
        const cartTotal = price;
        const gst = settings?.GST_course;
        const gstPercentage = parseFloat(gst) / 100;
        const discountAmount = calculateDiscountAmount(cartTotal);
        const discountedTotal = cartTotal - discountAmount;
        return (discountedTotal * (1 + gstPercentage)).toFixed(2); // Apply GST to the discounted total
    };



    const handleSubmit = async (values, { resetForm }) => {

        const finalTotal = calculateFinalTotal(price);
        const discountAmount = calculateDiscountAmount(price);
        const gstAmount = calculateGSTAmount(price);

        const now = new Date();

        setLoading(true);
        handleClose();

        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?')
            return
        }

        const result = await axiosProvider({
            method: "POST",
            endpoint: apiPath.createOrderCheckout,
            data: {
                amount: finalTotal
            },
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'multipart/form-data'
            }
        });

        if (result?.status === 200) {

            values = {
                rzorder_id: result?.data?.rzorder_id,
                total_amount: finalTotal,
                amount_after_coupon_discount : discountAmount,
                gst_amount: gstAmount,
                membership_id: id,
                coupon_code: couponCode,
            }

            try {

                const submitFormData = new FormData();
                Object.entries(values).forEach(([key, value]) => {
                    submitFormData.append(key, value);
                });

                const res = await axiosProvider({
                    method: "POST",
                    endpoint: apiPath.upgradeMembership,
                    data: submitFormData,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                if (res?.status === 200) {
                    resetForm({ values: "" });
                    const options = {
                        key: settings?.rzapi_key,
                        currency: "INR",
                        amount: result?.data?.amount?.toString(),
                        order_id: result?.data?.rzorder_id,
                        name: 'TradesMate',
                        description: 'Thank you for nothing. Please give us some money',
                        image: 'https://stock.reflomsolutions.com/api/assets/logo/stock_logo.png',
                        handler: function (response) {
                            console.log("success");
                            verifyPayment({
                                rzorder_id: res?.data?.data?.rzorder_id,
                                checkout_id: res?.data?.data?.checkout_id,
                                transaction_id: response?.razorpay_payment_id,
                                membership_id: id,
                                transaction_status: "Success"
                            })
                        },
                        prefill: {
                            name: values.name,
                            email: values.email
                        },
                        theme: {
                            "color": "#003366"
                        },
                        modal: {
                            "ondismiss": function () {
                                setLoading(false);
                            }
                        }
                    }

                    const paymentObject = new window.Razorpay(options);

                    paymentObject.on('payment.failed', function (response) {
                        // Payment failed
                        verifyPayment({
                            checkout_id: res?.data?.data?.checkout_id,
                            rzorder_id: response?.error?.metadata?.order_id,
                            transaction_id: response?.error?.metadata?.payment_id,
                            transaction_status: "Failed"
                        });
                        setLoading(false);
                    });

                    paymentObject.open()

                } else {
                    setLoading(false);
                    showToast("Something went wrong !", "error", 3000);
                }
            } catch (error) {
                setLoading(false);
                console.log(error);
                showToast("Something went wrong !", "error", 3000);

            }
        }

    };


    const verifyPayment = async (data) => {
        try {
            const response = await axiosProvider({
                method: "POST",
                endpoint: apiPath.Captureordermembership,
                data: data,
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200 && response?.data?.response_code == 200) {

                setModalShow(true)
                dispatch(fetchUserInfo({ accessToken }));
                setTimeout(() => {
                    setModalShow(false);
                }, 3000);

            } else if (response.status === 200 && response?.data?.response_code == 204) {
                showToast(response?.data?.message ? response?.data?.message : "Something went wrong !", "error", 3000);
            } else {
                showToast("Something went wrong !", "error", 3000);
            }

            setLoading(false);

        } catch (error) {
            console.log(error);
            showToast("Something went wrong !", "error", 3000);
            setLoading(false)
        }
    }


    const validationSchema = Yup.object({
        email: globalValidationSchema.email,
        name: globalValidationSchema.name
    });

    const formik = useFormik({
        initialValues: initialVal,
        validationSchema,
        enableReinitialize: true,
        onSubmit: handleSubmit
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {loading && <Loader />}
            {/* {toast?.show && (
                <Toaster text={toast?.text} variation={toast?.variation} />
            )} */}
            <div className="max-w-sm rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 bg-white border border-white">
                <div className={`px-6 py-4 ${id === userMId ? 'bg-[#198754]' : id < userMId ? "bg-[#58647B]" : 'bg-[#073565]'}`}>
                    <div className="text-2xl mb-2 text-white">{name}</div>
                    <p className="text-white text-lg">
                        Price: ₹ {price} / month
                    </p>
                </div>
                <div className="px-6 py-4 bg-white text-white">
                    <div className="font-bold text-dark">Details</div>
                    <p className="text-dark text-base">
                        Duration: {duration} month(s)
                    </p>
                    <div className="pt-4 flex justify-center">
                        <button className={`${id === userMId ? 'bg-[#198754] hover:bg-[#198754c4]' : id < userMId ? "bg-[#58647B]" : 'm-btn m-btn-hero'} text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out`}
                            disabled={id <= userMId}
                            onClick={() => id > userMId && handleShow(id)}
                        >
                            {id === userMId ? "Active" : id < userMId ? "In-Active" : "Upgrade"}
                        </button>
                    </div>
                </div>
            </div>

            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton className="border-0">
                    {/* <Modal.Title>Upgrade Membership</Modal.Title> */}
                </Modal.Header>
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit} id="signUpForm">
                        <Modal.Body>
                            <h1 className="text-2xl mb-2">
                                Do you want to upgrade {curMembership?.name} to {name} ?
                            </h1>

                            <div className='checkout-box mt-4'>
                                <div className="checkout-order-border">
                                    <div className='checkout-order-wrapper'>
                                        <div className='checkout-order-col'>
                                            <p className='checkout-order-prdname'>Product</p>
                                            {name}
                                        </div>
                                        <div className='checkout-order-col'>
                                            <p className='checkout-order-prdname'>Price</p>
                                            ₹ {price} / month
                                        </div>

                                    </div>
                                    <div className='checkout-order-wrapper'>
                                        <p className='checkout-order-prdname'>Subtotal</p>
                                        <h2 className='checkout-total-price mt-3'>
                                            <span>₹</span>
                                            {price}
                                        </h2>
                                    </div>
                                </div>


                                <div className='coupon-wrapper'>
                                    <p className='checkout-order-prdname'>Coupon</p>
                                    <div class="coupon-card">
                                        <di class="coupon-row mb-3">
                                            <span id="cpnCode">
                                                <input
                                                    className="coupon-input bg-transparent w-full"
                                                    name="coupon_code"
                                                    placeholder="Enter coupon code"
                                                    value={couponCode}
                                                    onChange={(e) => handleChangeInput(e.target.value)}
                                                />
                                            </span>
                                            <button id="cpnBtn" class="m-btn" type='button' disabled={isApplied}
                                                onClick={() => handleCouponChange(couponCode)}
                                            >{isApplied ? 'Applied' : 'Apply'}</button>
                                        </di>

                                        {toast?.show && (
                                            <Toster text={toast?.text} variation={toast?.variation} />
                                        )}

                                        {coupons && coupons?.length > 0 && <span>Available coupons for you</span>}

                                        <div className="flex flex-wrap gap-3 mt-2">
                                            {coupons && coupons?.length > 0 && coupons?.map((coupon, index) => (
                                                <div className="relative">
                                                    <button key={index} className='coupon_code m-btn py-1 px-4 mb-3 bg-transparent text-black' type='button'
                                                        disabled={coupon.coupon_code == appliedCoupon?.coupon_code || toast?.show}
                                                        onClick={() => handleCouponChange(coupon.coupon_code)}
                                                    >
                                                        {coupon.coupon_code}
                                                    </button>
                                                    {coupon.coupon_code == appliedCoupon?.coupon_code &&
                                                        <span className="tick_"><TiTick /></span>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className='checkout-order-border'>
                                    {appliedCoupon && <>
                                        <div className='checkout-order-wrapper'>
                                            <div className='checkout-order-col'>

                                            </div>
                                            <div className='checkout-order-col'>
                                                <p className='checkout-order-prdname'>Discount Amount</p>
                                                <h3
                                                    className='checkout-order-pricetax text-success'
                                                >
                                                    <span>-{appliedCoupon?.discount_type == 0 ? `${appliedCoupon?.discount_amount}%` : `₹ ${appliedCoupon?.discount_amount}`}</span>

                                                </h3>
                                            </div>
                                        </div>
                                        <div className='checkout-order-wrapper'>
                                            <p className='checkout-order-prdname'>Subtotal</p>
                                            <h2 className='checkout-total-price'>
                                                <span>₹</span>
                                                {price - calculateDiscountAmount(price)}
                                            </h2>
                                        </div>
                                    </>}
                                </div>

                                <div className='checkout-order-border'>
                                    {settings && settings?.GST_course && <>
                                        <div className='checkout-order-wrapper'>
                                            <div className='checkout-order-col'>
                                                <p className='checkout-order-prdname'>
                                                    GST
                                                </p>
                                            </div>
                                            <div className='checkout-order-col'>
                                                {/* <p className='checkout-order-prdname'>Amount</p> */}
                                                <h3
                                                    className='checkout-order-pricetax'
                                                >
                                                    {`${settings?.GST_course}%`}
                                                </h3>
                                            </div>
                                        </div>
                                        <div className='checkout-order-wrapper'>
                                            <p className='checkout-order-prdname'>Subtotal</p>
                                            <h2 className='checkout-total-price'>
                                                <span>₹</span>
                                                {calculateFinalTotal(price)}
                                            </h2>
                                        </div>
                                    </>
                                    }
                                </div>

                                <div className='checkout-order-wrapper'>
                                    <p className='checkout-order-prdname'>Total</p>
                                    <h2 className='checkout-total-price'>
                                        <span>₹</span>
                                        {calculateFinalTotal(price)}
                                    </h2>
                                </div>
                            </div>


                        </Modal.Body>
                        {/* <Modal.Footer> */}
                        <div className="m-3 text-end">
                            <button className='text-white font-Spartan font-medium text-18 px-6 py-[0.75rem] rounded-lg bg-[#58647B] text-center mr-3' type='button' onClick={() => handleClose()}>
                                Cancel
                            </button>
                            <button className='m-btn m-btn-hero text-center' type='submit' >
                                Pay ₹{calculateFinalTotal(price)}
                            </button>
                        </div>
                        {/* </Modal.Footer> */}
                    </form>
                </FormikProvider>
            </Modal>
            <SuccessModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                msg={'Congratulations! You have successfully purchased the Membership'}
            />

        </>
    );
};



const UserMembership = () => {

    const [membershipList, setMembershipList] = useState([]);
    const [loading, setLoading] = useState(false);
    const { userInfo } = useSelector((state) => state?.auth);
    const [curMembership, setCurMembership] = useState({})

    useEffect(() => {
        fetchMembership();
    }, []);

    const fetchMembership = () => {
        setLoading(true);
        fetchDataFromApi(`${apiPath.getMembership}/0`, null, 'GET', {}).then((data) => {
            if (data) {
                setMembershipList(data)
                const [curData] = data?.data?.data?.filter(i => i.id === userInfo?.membership_id);
                setCurMembership(curData);
            } else {
                setMembershipList([]);
            }
            setLoading(false);
        }
        ).catch((error) => {
            console.log(error);
            setLoading(false);
        })
    }

    return (
        <section className='section_spacing w-full section_spacing-space'>
            <div className=''>
                <h2 className="mb-3 text-2xl">Membership</h2>
                {loading && <Loader />}

                <div className="pc-courses-list-wrapper">
                    {
                        membershipList && membershipList?.data?.data?.map((item) => {
                            return (
                                <MembershipCard item={item} userMId={userInfo?.membership_id} curMembership={curMembership} key={item.id} />
                            )
                        })
                    }
                </div>
            </div>

        </section>
    )
}

export default UserMembership;