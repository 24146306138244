import React, { useState } from "react";
import { Link } from "react-router-dom";
import useAuthenticated from "../../hooks/useAuthenticated";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/features/authSlice";
import useToast from "../../hooks/useToast";
import Toster from '../../components/Toster';
import Loader from "../Loader";
import { useNavigate } from "react-router-dom/dist";
import apiPath from "../../apipath";
import { fetchDataFromApi } from "../../libs/AllGlobalFunctions";
import { _logo_ } from "../../libs/ImagePath";
import { setCart } from "../../redux/features/cartSlice";
import { FaUserCircle } from "react-icons/fa";
import { BsCart2 } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import SearchDrawer from "../SearchDrawer";
import { FiUser } from "react-icons/fi";
import { FiBell } from "react-icons/fi";
import Badge from '@mui/material/Badge';


const Header = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toast, showToast } = useToast();
  const isAuth = useAuthenticated()
  const [isToggleActive, setIsToggleActive] = useState(false);
  const [open, setOpen] = useState(false);
  // const [invisible, setInvisible] = useState(false);
  const [headerTitle, setHeaderTitle] = useState({
    headerText: '',
    headerLink: ''
  });
  const [loading, setLoading] = useState(false);
  const cartList = useSelector((state) => state?.cart?.cart);
  const { userInfo } = useSelector((state) => state?.auth);
  const settings = useSelector((state) => state?.setting?.settings);

  useEffect(() => {

    if (isAuth) {
      setHeaderTitle({ headerText: 'My Profile', headerLink: ' ' })
    } else {
      setHeaderTitle({
        headerText: 'Login',
        headerLink: '/auth/login'
      })
    }

  }, [isAuth]);


  const handleToggleClick = () => {
    setIsToggleActive(!isToggleActive);
    document.body.classList.toggle("overflow-hidden", !isToggleActive);
  };

  const handleMenuItemClick = () => {
    setIsToggleActive(false);
    document.body.classList.remove("overflow-hidden");
  };


  const handleLogout = () => {

    setLoading(true);
    try {

      const response = new Promise((resolve) => {
        setTimeout(() => resolve({ code: 200 }), 1000);
      });

      response.then((res) => {
        if (res?.code == 200) {

          dispatch(logOut());
          dispatch(setCart([]));
          setLoading(false);
          handleMenuItemClick();
          showToast("Logout Successfully !", "success", 3000);
          navigate('/');
        }
      }).catch((error) => {
        console.log(error)
        setLoading(false);
      })

    } catch (error) {
      console.log(error);
      setLoading(false);
      showToast("Something went wrong !", "error", 3000);
    }

  }

  return (
    <>
      {loading && <Loader />}
      {/* {toast?.show && (
        <Toster text={toast?.text} variation={toast?.variation} />
      )} */}
      <header className="main-header">
        <div className="m-header">
          <div className="site-container">
            <nav className="nav_wrapper">
              {/* header logo */}
              <div className="m_logo">
                <Link
                  to="/"
                  className="m_logo-link"
                  onClick={handleMenuItemClick}
                >
                  {/* StockSmart <span>Academy</span> */}
                  <img src={`${_logo_}${settings?.logo}`} alt='' width={120} />
                </Link>
              </div>

              {/* menu */}
              <div className="menu-item-wrapper">
                <nav
                  className={`nav-list_wrapper ${isToggleActive ? "active" : ""}`}
                >
                  <ul className="h-menu_wrapper">
                    <li className="h-menu_list">
                      <Link
                        to="/"
                        className="h-menu_link"
                        onClick={handleMenuItemClick}
                      >
                        Home
                      </Link>
                    </li>
                    <li className="h-menu_list" onClick={handleMenuItemClick}>
                      <Link to="/course" className="h-menu_link">
                        Course
                      </Link>
                    </li>
                    <li className="h-menu_list" onClick={handleMenuItemClick}>
                      <Link to="/portfolio" className="h-menu_link">
                        Portfolio
                      </Link>
                    </li>
                    <li className="h-menu_list" onClick={handleMenuItemClick}>
                      <Link to="/about" className="h-menu_link">
                        About
                      </Link>
                    </li>
                    <li className="h-menu_list" onClick={handleMenuItemClick}>
                      <Link to="/contact" className="h-menu_link">
                        Contact
                      </Link>
                    </li>

                    {isAuth ? (
                      <>
                        <li className="h-menu_list block md:hidden" onClick={handleMenuItemClick}>
                          <Link to="/dashboard" className="h-menu_link">
                            My Profile
                          </Link>
                        </li>
                        <li className="h-menu_list block md:hidden" onClick={handleMenuItemClick}>
                          <Link to="/dashboard/membership" className="h-menu_link">
                            Membership
                          </Link>
                        </li>
                        <li className="h-menu_list block md:hidden" onClick={handleMenuItemClick}>
                          <Link to="/dashboard/my-courses" className="h-menu_link">
                            My Course
                          </Link>
                        </li>
                        <li className='h-subaccount-list block md:hidden'>
                          <button className='m-btn-hero m-btn' onClick={handleLogout}>
                            Logout
                          </button>
                        </li>
                      </>
                    ) : <li className="h-menu_list block" onClick={handleMenuItemClick}>
                      <Link
                        to={headerTitle?.headerLink}
                        className='h-menu_link'
                        onClick={handleMenuItemClick}
                      >
                        {headerTitle?.headerText}
                      </Link>
                    </li>}

                  </ul>
                </nav>
                {/* cart   */}

                <div className="h-cart_wrapper">
                  <div className='account_wrapper-main hidden md:block'>
                    <div className='h-account_wrapper'>
                      <Link
                        to={headerTitle?.headerLink}
                        className='h-account_link'
                        onClick={handleMenuItemClick}
                      >
                        {/* <i className=' m-icon h-profile_icon'></i> */}
                        {isAuth && (<FiUser className=' m-icon h-profile_icon' />)}
                        {/* <span className='h-menu_list pt-1'>
                        {headerTitle?.headerText}
                      </span> */}
                      </Link>
                      {isAuth ? (
                        <div className='h-account-submenu'>
                          <ul className='h-subaccount-items'>
                            <li className='h-subaccount-list'>
                              <span className='h-subaccount-name'>Welcome</span>
                              <span className='h-subaccount-name'>
                                {userInfo?.email}
                              </span>
                            </li>
                          </ul>
                          <ul className='h-subaccount-items'>
                            <li className='h-subaccount-list'>
                              <Link
                                to={'/dashboard'}
                                className='h-subaccount-item'
                              >
                                Edit Profile
                              </Link>
                              <Link to={'/dashboard/membership'} className='h-subaccount-item'>
                                Membership
                              </Link>
                              <Link to={'/dashboard/my-courses'} className='h-subaccount-item'>
                                My Courses
                              </Link>
                            </li>
                          </ul>
                          <ul className='h-subaccount-items'>
                            <li className='h-subaccount-list'>
                              <button className='m-btn-hero m-btn' onClick={handleLogout}>
                                Logout
                              </button>
                            </li>
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  { !window.location.href.includes('/instruments') && <FiSearch className="text-[24px]" onClick={() => setOpen(true)} />}
                  
                  {isAuth && (
                    <div className='account_wrapper-main'>
                      <div className='h-account_wrapper'>
                        <Link
                          to={'/dashboard/notification'}
                          className='h-account_link'
                          onClick={handleMenuItemClick}
                        >
                          <Badge color="danger" badgeContent={userInfo?.notification_count} invisible={userInfo?.notification_count > 0 ? false : true}>
                            <FiBell className="text-[24px]" />
                          </Badge>
                        </Link>
                        {/* <div className='h-account-submenu'>
                        <ul className='h-subaccount-items'>
                          <li className='h-subaccount-list'>
                            <span className='h-subaccount-name'>Welcome</span>
                          </li>
                        </ul>
                      </div> */}
                      </div>
                    </div>)}
                  {/* <div className='h-account-submenu'>
                    <ul className='h-subaccount-items'>
                      <li className='h-subaccount-list'>
                        <span className='h-subaccount-name'>Welcome</span>
                        <span className='h-subaccount-name'>
                          {userInfo?.email}
                        </span>
                      </li>
                    </ul>
                    <ul className='h-subaccount-items'>
                      <li className='h-subaccount-list'>
                        <Link
                          to={'/dashboard'}
                          className='h-subaccount-item'
                        >
                          Edit Profile
                        </Link>
                        <Link to={'/dashboard/membership'} className='h-subaccount-item'>
                          Membership
                        </Link>
                        <Link to={'/dashboard/my-courses'} className='h-subaccount-item'>
                          My Courses
                        </Link>
                      </li>
                    </ul>
                    <ul className='h-subaccount-items'>
                      <li className='h-subaccount-list'>
                        <button className='m-btn-hero m-btn' onClick={handleLogout}>
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div> */}

                  <Link
                    to="/cart"
                    className="m-icon h-cart_icon"
                    onClick={handleMenuItemClick}
                  >
                    <BsCart2 />
                  </Link>
                  <span className="h-cart-number">{cartList && cartList?.data?.length || 0}</span>
                </div>
              </div>
              {/* toggle  */}
              <div className="m_toggle-wrapper">
                <button
                  className={`m_toggle ${isToggleActive ? "active" : ""}`}
                  onClick={handleToggleClick}
                >
                  <span className="m_toggle-items"></span>
                  <span className="m_toggle-items"></span>
                  <span className="m_toggle-items"></span>
                </button>
              </div>
            </nav>
            {/* menu list  */}
          </div>
        </div >
      </header >
      <SearchDrawer open={open} setOpen={setOpen} logo={settings?.logo} />
    </>
  );
};

export default Header;
