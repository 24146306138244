import { useSelector } from "react-redux";
import Sidebar from "../../components/Sidebar/sidebar";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";

const UserDashboard = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full bg-darkgray md:bg-transparent">
        <Sidebar />
      </div>
      <div className="site-container w-full ml-0 md:ml-80 overflow-x-hidden flex justify-center min-h-screen">
        <Outlet />
      </div>
      <div className="w-full ml-0 md:ml-80">
        <center className="pb-5">
          <p className="copy-right">
            Copyright © {new Date().getFullYear()} Stock Trading - All rights
            reserved
          </p>
        </center>
      </div>
    </div>
  );
}

export default UserDashboard;
