import { useDispatch, useSelector } from 'react-redux';
import apiPath from '../apipath';
import { fetchDataFromApi } from '../libs/AllGlobalFunctions';
import { useEffect, useState } from 'react';
import { fetchUserInfo, logOut } from '../redux/features/authSlice';
import { fetchCart, setCart } from '../redux/features/cartSlice';
import { useNavigate } from 'react-router-dom';

const useAuthenticated = () => {

    const { userInfo, accessToken } = useSelector((state) => state?.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        try {

            const response = new Promise((resolve) => {
                setTimeout(() => resolve({ code: 200 }), 1000);
            });

            response.then((res) => {
                if (res?.code == 200) {
                    dispatch(logOut());
                    dispatch(setCart([]));
                    navigate('/');
                }

            }).catch((error) => {
                console.log(error)
            })

        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {
        if (userInfo && accessToken && userInfo?.id) {
            checkAuth();
        }
    }, []);

    const checkAuth = () => {
        fetchDataFromApi(apiPath.checkUserAuth, null, 'GET', {}).then((response) => {
            if (response && response?.data) {
                dispatch(fetchUserInfo({ accessToken }));
                dispatch(fetchCart({ userId: userInfo?.id, accessToken }));
            } else {
                handleLogout();
            }
        }
        ).catch((error) => {
            console.log(error)
        })
    }

    const isAuthenticated = Boolean(userInfo && accessToken && userInfo?.id);

    return isAuthenticated;
};

export default useAuthenticated;
