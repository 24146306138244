import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { destroyCookie } from "nookies"
import apiPath from "../../apipath";
import axiosProvider from "../../libs/axios/axiosProvider";

export const fetchUserInfo = createAsyncThunk('data/fetchUserInfo', async ({ accessToken }) => {
    try {
        const response = await axiosProvider({
            method: "GET",
            endpoint: `${apiPath.getUserInfo}`,
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        });
        if (response?.status == 200) {
            if (response?.data?.response_code == 200 && response.data) {
                return ({ userInfo: response?.data?.data, accessToken })
            } else {
                return ({ userInfo: null, accessToken: null })
            }
        }
    } catch (error) {
        console.log(error);
    }
});

const initialState = {
    userInfo: "",
    accessToken: ""
}

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setUser: (state, action) => {
            state.userInfo = action.payload.currentUser
            state.accessToken = action.payload.accessToken
        },
        logOut: (state) => {
            destroyCookie(null, 'userToken')
            localStorage.removeItem('currentUser')
            state.userInfo = null
            state.accessToken = null
        }
    },
    extraReducers: {
        [fetchUserInfo.pending]: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        [fetchUserInfo.fulfilled]: (state, action) => {
            state.loading = false;
            state.userInfo = action?.payload?.userInfo;
            state.accessToken = action?.payload?.accessToken;
            localStorage.setItem("currentUser", JSON.stringify(action?.payload?.userInfo));

        },
        [fetchUserInfo.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error;
        },
    },
})

export const { setUser, logOut } = authSlice.actions
export const { reducer: authReducer } = authSlice;

export const selectCurrentUser = (state) => state.auth.userInfo;
