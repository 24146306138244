import Box from '@mui/material/Box';

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== `${other.type}-${index}`}
            id={`vertical-tabpanel-${other.type}-${index}`}
            aria-labelledby={`vertical-tab-${other.type}-${index}`}
            {...other}
        >
            {value === `${other.type}-${index}` && (
                <Box sx={{ pl: 0 }}>
                    {children}
                    {/* {renderTabPanel(other.type, index, children)} */}
                    {/* <Typography>{children}</Typography> */}
                </Box>
            )}
        </div>
    );
}