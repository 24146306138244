import React, { useState, useEffect } from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
    notify,
    notifyErr,
} from '../notify';
import { getApi, postApi } from '../../../helper/helper';
import { useParams } from 'react-router-dom';
import { confirmDelete, confirmStatus } from '../handleData';
import TextEditor from '../../../editor/TextEditor';
import apiPath from '../../../../apipath';




const UpdateContent = (props) => {

    const [showChapter, setShowChapter] = useState(false);
    const [formDataChapter, setFormDataChapter] = useState({})
    // module
    const [formDataUpdate, setFormDataUpdate] = useState({});
    const [mediaType, setMediaType] = useState('');

    const [showLesson, setShowLesson] = useState(false);
    const [formDataLesson, setFormDataLesson] = useState({
        video_url: ""
    });

    const [updatedContent, setUpdatedContent] = useState('');




    const { courseId } = useParams();

    const getIndividualData = async (id) => {
        try {
            const [result] = await getApi(`/api/${props.getPath}/${id}`);
            const data = result;
            if (data) {
                setFormDataUpdate((prevFormDataUpdate) => ({
                    ...prevFormDataUpdate,
                    ["id"]: data.id,
                    ["title"]: data.title
                }));
                reset({
                    title: data.title
                });
            }
        } catch (error) {
            console.error(error);
        }
        // finally {
        // }
    };

    useEffect(() => {
        if (props.id) {
            getIndividualData(props.id);
        }
    }, [props.id]);

    const handleShowChapter = () => {
        setShowChapter(true);
    };

    const handleCloseChapter = () => {
        setShowChapter(false);
        setFormDataChapter({});
        reset1({
            title: "",
            pdf: "",
            video: "",
            video_url: ""
        });
    };


    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const handleInputChange = (field, value) => {
        setFormDataUpdate({ ...formDataUpdate, [field]: value });
    };

    const onUpdate = async () => {
        try {
            const result = await postApi(`/api/${props.updatePath}`, {
                ...formDataUpdate,
                course_id: courseId,
                module_id: props.moduleId
            });

            notify(`${props.heading} Updated Successfully!`);
            props?.fetchData();
            getIndividualData(props.id);

        } catch (error) {
            console.error(error);
            notifyErr("Something went wrong!");
        }
    };

    const {
        register: register1,
        handleSubmit: handleSubmit1,
        reset: reset1,
        formState: { errors: errors1 },
    } = useForm();

    const handleInputChange1 = (field, value) => {
        setFormDataChapter({ ...formDataChapter, [field]: value });
    };

    const onSubmit = async () => {
        try {
            const result = await postApi(`/api/${props.addPath}`, {
                ...formDataChapter,
                course_id: courseId,
                module_id: props.id,
            });

            handleCloseChapter();
            props?.fetchData();
            notify("Chapter Added Successfully!");

        } catch (error) {
            console.error(error);
        }
    }

    const handleShowLesson = () => {
        setShowLesson(true);
    };

    const handleCloseLesson = () => {
        setShowLesson(false);
        setFormDataLesson({});
        reset2({
            title: "",
            type: "",
            pdf: "",
            video: "",
            video_url: ""
        });
    };

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        reset: reset2,
        formState: { errors: errors2 },
    } = useForm();

    const handleInputChange2 = (field, value) => {
        setFormDataLesson({ ...formDataLesson, [field]: value });
    };


    useEffect(() => {
        handleInputChange2('description', updatedContent);
    }, [updatedContent])

    const onSubmitLesson = async () => {
        try {
            const result = await postApi(`/api/${props.addLesson}`, {
                ...formDataLesson,
                course_id: courseId,
                chapter_id: props.id
            });

            handleCloseLesson();
            props?.fetchData();
            notify("Lesson Added Successfully!");


        } catch (error) {
            console.error(error);
        }
    }


    // handleDelete 
    const handleDelete = (endPoint) => {
        confirmDelete(endPoint)
            .then((data) => {
                props?.fetchData();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    // handleStatus 
    const handleStatus = (endpoint, id, status) => {
        confirmStatus(endpoint, { id: id, status: status})
            .then((message) => {
                props?.fetchData();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <>

            <section>
                {props.addPath &&
                    <>
                        <div className="btn-d flex flex-col sm:flex-row row-gap-3 justify-content-between items-center">
                            {props.status == 0 ? (
                                <span
                                    className="h6 m-0 bg-success text-white px-2 py-1 rounded cursor-pointer"
                                    onClick={() =>
                                        handleStatus(apiPath.updateModuleStatus, props.id, "1")
                                    }
                                >
                                    Active
                                </span>
                            ) : props.status == 1 ? (
                                <span
                                    className="h6 m-0 bg-warning text-white px-2 py-1 rounded cursor-pointer"
                                    onClick={() =>
                                        handleStatus(apiPath.updateModuleStatus, props.id, "0")
                                    }
                                >
                                    In-Active
                                </span>
                            ) : null}
                            <Button
                                variant="contained"
                                className="text-white btn-primary"
                                onClick={() => handleShowChapter()}
                            >
                                Add New Chapter
                            </Button>
                        </div>
                        <hr className=" my-3"></hr>
                    </>
                }


                {props.addLesson &&
                    <>
                        <div className="btn-d flex flex-col sm:flex-row row-gap-3 justify-content-between items-center">
                            {props.status == 0 ? (
                                <span
                                    className="h6 m-0 bg-success text-white px-2 py-1 rounded cursor-pointer"
                                    onClick={() =>
                                        handleStatus(apiPath.updateChapterStatus, props.id, "1")
                                    }
                                >
                                    Active
                                </span>
                            ) : props.status == 1 ? (
                                <span
                                    className="h6 m-0 bg-warning text-white px-2 py-1 rounded cursor-pointer"
                                    onClick={() =>
                                        handleStatus(apiPath.updateChapterStatus, props.id, "0")
                                    }
                                >
                                    In-Active
                                </span>
                            ) : null}
                            <Button
                                variant="contained"
                                className="text-white btn-primary"
                                onClick={() => handleShowLesson()}
                            >
                                Add New Lesson
                            </Button>
                        </div>
                        <hr className=" my-3"></hr>
                    </>
                }

                <h2 className="h5">Update {props.heading}</h2>

                <Form
                    className=""
                    onSubmit={handleSubmit(onUpdate)}
                    autoComplete="off"
                >
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="title">Title</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Title"
                            id="title"
                            value={formDataUpdate.title}
                            {...register("title", {
                                required: true,
                                onChange: (e) => handleInputChange("title", e.target.value),
                            })}
                        />
                        {errors.title && (
                            <div className="errMsg text-danger">Please Enter Title</div>
                        )}
                    </Form.Group>
                    <div className="btn-d d-flex justify-content-end gap-2">
                        <Button
                            type="submit"
                            variant="contained"
                            className="text-white btn-primary"
                        >
                            Update
                        </Button>
                        <Button variant="secondary" className="text-white bg-danger border-0"
                            onClick={() => handleDelete(`/api/${props.deletePath}/${props.id}`)}
                        >
                            Delete
                        </Button>
                    </div>
                </Form>

            </section>

            {/* add chapter */}
            <Modal show={showChapter} onHide={handleCloseChapter} style={{ zIndex: "9999" }}>

                <Modal.Header closeButton>
                    <Modal.Title>Add Chapter</Modal.Title>
                </Modal.Header>
                <Form
                    className="row m-2"
                    onSubmit={handleSubmit1(onSubmit)}
                    autoComplete="off"
                >
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="title">Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Title"
                                id="title"
                                value={formDataChapter.title}
                                {...register1("title", {
                                    required: true,
                                    onChange: (e) => handleInputChange1("title", e.target.value),
                                })}
                            />
                            {errors1.title && (
                                <div className="errMsg text-danger">Please Enter Title</div>
                            )}
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            type="submit"
                            variant="contained"
                            className="text-white btn-primary"
                        >
                            Add
                        </Button>
                        <Button variant="secondary" onClick={handleCloseChapter}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* add lesson */}
            <Modal show={showLesson} onHide={handleCloseLesson} style={{ zIndex: "9999" }}>

                <Modal.Header closeButton>
                    <Modal.Title>Add Lesson</Modal.Title>
                </Modal.Header>
                <Form
                    className="row m-2"
                    onSubmit={handleSubmit2(onSubmitLesson)}
                    autoComplete="off"
                >
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="title">Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Title"
                                id="title"
                                value={formDataLesson.title}
                                {...register2("title", {
                                    required: true,
                                    onChange: (e) => handleInputChange2("title", e.target.value),
                                })}
                            />
                            {errors2.title && (
                                <div className="errMsg text-danger">Please Enter Title</div>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="description">Description</Form.Label>
                            <TextEditor data={updatedContent} setUpdatedContent={setUpdatedContent} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="type">Type</Form.Label>
                            <Form.Select
                                type="type"
                                id="type"
                                placeholder="type"
                                value={formDataLesson.type}
                                {...register2("type", {
                                    required: true,
                                    onChange: (e) => handleInputChange2("type", e.target.value),
                                })}
                            >
                                <option value="">Select Type</option>
                                <option value="0">Free</option>
                                <option value="1">Paid</option>
                            </Form.Select>
                            {errors2.type && (
                                <div className="errMsg text-danger">Please Select Type</div>
                            )}
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor="pdf">Pdf</Form.Label>
                            <Form.Control
                                type="file" accept=".pdf"
                                placeholder="Pdf"
                                id="pdf"
                                multiple
                                {...register2("pdf", {
                                    required: false,
                                    onChange: (e) =>
                                        handleInputChange2("pdf", e.target.files),
                                })}
                            />
                            {errors2.pdf && (
                                <div className="errMsg text-danger">Please Select Pdf</div>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="media_type">Media Type</Form.Label>
                            <Form.Select
                                type="media_type"
                                id="media_type"
                                placeholder="Media Type"
                                value={mediaType}
                                onChange={(e) => setMediaType(e.target.value)}
                            >
                                <option value="">Select Media Type</option>
                                <option value="video">Video Upload</option>
                                <option value="video_url">Video URL</option>
                            </Form.Select>
                        </Form.Group>

                        {mediaType === 'video' && (
                            <Form.Group className='mb-3'>
                                <Form.Label htmlFor="video">Video</Form.Label>
                                <Form.Control
                                    type="file" accept="video/mp4,video/x-m4v,video/*"
                                    placeholder="Video"
                                    id="video"
                                    multiple
                                    {...register2("video", {
                                        required: false,
                                        onChange: (e) =>
                                            handleInputChange2("video", e.target.files),
                                    })}
                                />
                                {errors2.video && (
                                    <div className="errMsg text-danger">Please Select Video</div>
                                )}
                            </Form.Group>
                        )}

                        {mediaType === 'video_url' && (
                            <Form.Group className='mb-3'>
                                <Form.Label htmlFor="video_url">Video Url</Form.Label>
                                <Form.Control
                                    type="url"
                                    placeholder="Video Url"
                                    id="video_url"
                                    value={formDataLesson.video_url}
                                    {...register2("video_url", {
                                        required: false,
                                        onChange: (e) =>
                                            handleInputChange2("video_url", e.target.value),
                                    })}
                                />
                                {errors2.video_url && (
                                    <div className="errMsg text-danger">Please Enter Video Url</div>
                                )}
                            </Form.Group>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            type="submit"
                            variant="contained"
                            className="text-white btn-primary"
                        >
                            Add
                        </Button>
                        <Button variant="secondary" onClick={handleCloseLesson}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>


        </>
    )
}

export default UpdateContent;