import { AiFillDashboard } from "react-icons/ai";
import { FaProductHunt } from "react-icons/fa";
import { IoIosImages } from "react-icons/io";
import { RiCoupon3Fill } from "react-icons/ri";
import {
  MdMarkEmailUnread,
  MdOutlinePayment,
} from "react-icons/md";
import { BiUserCheck } from "react-icons/bi";
import { BiCategoryAlt } from "react-icons/bi";
import { GiArchiveResearch } from "react-icons/gi";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { TbSeo } from "react-icons/tb";
import { RiStockFill } from "react-icons/ri";
import { FaCalendarDay } from "react-icons/fa";
import { RiPagesFill } from "react-icons/ri";


export const SidebarItems = [
  {
    title: "Dashboard",
    url: "/admin/",
    icon: <AiFillDashboard />,
  },
  {
    title: "Users",
    url: "/admin/users",
    icon: <BiUserCheck />,
  },
  {
    title: "Category",
    url: "/admin/category",
    icon: <BiCategoryAlt />,
  },
  {
    title: "Course",
    url: "/admin/course",
    icon: <FaProductHunt />,
  },
  {
    title: "Subscription",
    url: "/admin/subscription",
    icon: <BiUserCheck />,
  },
  {
    title: "Payment",
    url: "/admin/payment",
    icon: <MdOutlinePayment />,
  },
  {
    title: "Coupon",
    url: "/admin/coupon",
    icon: <RiCoupon3Fill />,
  },
  {
    title: "Instructor",
    url: "/admin/instructor",
    icon: <BiUserCheck />,
  },
  {
    title: "Portfolio",
    url: "/admin/portfolio",
    icon: <GiArchiveResearch />,
  },
  {
    title: "Banners",
    url: "/admin/banner",
    icon: <IoIosImages />,
  },
  {
    title: "Page Content",
    url: "/admin/page-content",
    icon: <RiPagesFill />,
  },
  {
    title: "SEO",
    url: "/admin/seo",
    icon: <TbSeo />,
  },
  {
    title: "Stock Symbol",
    url: "/admin/stock-symbol",
    icon: <RiStockFill />,
  },
  {
    title: "Stock Holiday",
    url: "/admin/stock-holiday",
    icon: <FaCalendarDay />,
  },
  {
    title: "Enquiry",
    url: "/admin/enquiry",
    icon: <MdMarkEmailUnread />,
  },
  // {
  //   title: "About",
  //   url: "/admin/about",
  //   icon: <MdMarkEmailUnread />,
  // },
  {
    title: "Privacy",
    url: "/admin/privacy",
    icon: <MdOutlinePrivacyTip />,
  },
];
