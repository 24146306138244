import { parseCookies } from "nookies";
import axiosProvider from "./axios/axiosProvider";
import { useSelector } from 'react-redux';

export const getBaseUrl = () => {
    return "https://stock.reflomsolutions.com/api"
};

export const getNoAuthToken = () => {
    return "1fbf405e5f4af57b726f0c00b762d95a62c4930c"
};

//@ Implement HandleChange for only Charc fileds

export const _alphabetRegex_ = /^[A-Za-z\s]+$/;

export const handleCharInputChange = (e, formik) => {
    const inputValue = e?.target?.value;
    const fieldName = e?.target?.name;
    const isValid = _alphabetRegex_.test(inputValue);

    if (isValid || !inputValue) {
        formik.setFieldValue(fieldName, inputValue);
    }
};

//@Implementation of Globally API Fetch function
export const fetchDataFromApi = async (endpoint, setLoading, method = "GET", queryParams = {}) => {

    const { userToken } = parseCookies();

    if (setLoading) {
        setLoading(true);
    }

    try {

        let queryString = ""
        if (!isEmptyObject(queryParams)) {
            queryString = "?" + Object.keys(queryParams)
                .map((key) => `${key}=${queryParams[key]}`)
                .join("&");
        }

        let providerObj = { method, endpoint, queryString };

        if (userToken) {
            providerObj = {
                ...providerObj, headers: {
                    'Authorization': `Bearer ${userToken}`,
                }
            }
        }

        const response = await axiosProvider(providerObj);

        if (response?.status === 200) {
            if (response?.data) {
                if (setLoading) {
                    setLoading(false);
                }
                return response;
            } else {
                throw new Error("Failed to fetch data");
            }
        }

    } catch (err) {
        if (setLoading) {
            setLoading(false);
        }
        console.error(err);
        throw err;
    }
};

export function replaceSpacesWithHyphens(inputString) {
    if (typeof inputString !== 'string') {
        throw new Error('Input must be a string');
    }

    return inputString.replace(/\s+/g, '-');
}


const courseCategoryDifficulty = [
    { id: 1, name: "Beginner", value: 0 },
    { id: 2, name: "Intermediate", value: 1 },
    { id: 3, name: "Expert", value: 2 },
]


export const difficultyOptions = [
    { label: "Beginner", value: 0 },
    { label: "Intermediate", value: 1 },
    { label: "Expert", value: 2 },
]

export const setPageIndexOne = (searchQuery, callback, label) => {
    const searchString = searchQuery || "";

    if (label) {
        callback((draft) => {
            draft[label] = searchString;
        });
    } else {
        callback((draft) => {
            draft.searchString = searchString;
        });
    }
};

export const encodedSearchText = (searchText) => {
    return encodeURIComponent(searchText.replace(/"/g, ""));
};


export function isEmptyObject(obj) {
    if (obj === undefined || obj === null) {
        return true
    }
    return Object.keys(obj).length === 0
}

export const handlePageClick = (event, callback) => {
    callback((draft) => {
        draft.page = event.selected + 1;
    });
};


export const getEmbeddedUrlFromYouTubeUrl = (youtubeUrl) => {
    if (youtubeUrl.length === 11) {
        return `https://www.youtube.com/embed/${youtubeUrl}`
    }

    const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
    const match = youtubeUrl.match(regExp)

    if (match && match[2].length === 11) {
        return `https://www.youtube.com/embed/${match[2]}`
    } else {
        return null
    }
}

















