import { Link, useParams } from "react-router-dom";
import AmStockChart from "../admin/charts/AmStockChart";
import bradcom from '../icons/bradcome-arrow.svg';


const UserStockGraph = () => {

    const { exchange } = useParams();

    return (
        <>
            <div className='site-container p-couse-bradcom-wrapper justify-content-start mt-4'>
                <Link to={'/'} className='p-bradcom-item'>
                    Home
                </Link>
                <img src={bradcom} alt='' />
                <Link className="p-bradcom-item">
                    Instruments
                </Link>
                <img src={bradcom} alt='' />
                <Link className="p-bradcom-item">
                    {exchange}
                </Link>
            </div>
            <section className='section_spacing section_spacing-space'>
                <div className='site-container'>
                    <AmStockChart />
                </div>
            </section>
        </>
    )
}

export default UserStockGraph;