import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Input from '../../../components/Form/Input';
import apiPath from '../../../apipath';
import useToast from '../../../hooks/useToast';
import { useEffect, useState } from 'react';
import axiosProvider from '../../../libs/axios/axiosProvider';
import Loader from '../../../components/Loader';
import Toster from '../../../components/Toster';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataFromApi, getNoAuthToken } from '../../../libs/AllGlobalFunctions';
import globalValidationSchema from '../../../validations/globalValidations';
import { handleCharInputChange } from '../../../libs/AllGlobalFunctions';
import { setUser } from "../../../redux/features/authSlice";

const initialVal = {
    name: '',
    phone: '',
    address: "",
    email: ""
};

const UserProfile = () => {

    const { toast, showToast } = useToast();
    const [loading, setLoading] = useState(false);
    const [intialvalue, setIntialValues] = useState(initialVal);
    const { userInfo, accessToken } = useSelector((state) => state?.auth);

    const dispatch = useDispatch();

    useEffect(() => {
        if (userInfo && userInfo?.id) {
            fetchUser();
        }
        window.scrollTo(0, 0);
    }, [userInfo])

    const fetchUser = () => {
        setLoading(true);
        fetchDataFromApi(apiPath.getUserProfile, null, 'GET', {}).then((data) => {
            if (data && data?.data?.data[0]) {
                setIntialValues(data?.data?.data[0])
            } else {
                setIntialValues(initialVal);
            }
            setLoading(false);
        }
        ).catch((error) => {
            console.log(error);
            setLoading(false);
        })
    }

    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true);
        try {

            const submitFormData = new FormData();
            Object.entries(values).forEach(([key, value]) => {
                submitFormData.append(key, value);
            });

            const response = await axiosProvider({
                method: "POST",
                endpoint: apiPath.updateUserProfile,
                data: submitFormData,
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (response.status === 200 && response?.data?.response_code == 200) {
                showToast(response?.data?.message, "success", 3000);
               
                fetchUser();


                fetchDataFromApi(`${apiPath.getUserInfo}`, null, 'GET', {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'token': `${getNoAuthToken()}`
                    }
                }).then((response) => {
                    if (response) {
                        localStorage.setItem("currentUser", JSON.stringify(response?.data?.data));
                        dispatch(setUser({ currentUser: response?.data?.data, accessToken: accessToken }))
                    }
                }
                ).catch((error) => {
                    console.log(error);
                })


            } else {
                showToast(response?.data?.message, "error", 3000);
    
            }
            setLoading(false);

        } catch (error) {
            console.log(error);
            showToast("Something went wrong !", "error", 3000);
            setLoading(false)
        }
    };

    const validationSchema = Yup.object({
        email: globalValidationSchema.email,
        name: globalValidationSchema.name,
        phone: globalValidationSchema.phone,
        address: globalValidationSchema.address
    });

    const formik = useFormik({
        initialValues: intialvalue,
        validationSchema,
        enableReinitialize: true,
        onSubmit: handleSubmit
    });

    return (
        <>

            <section className='section_spacing w-full'>

                {loading && <Loader />}
                <div className=''>
                    {/* <PageTitle PageTitle={"Sign Up"} /> */}
                    <div className='flex flex-col justify-center'>
                        <h2 className="mb-3 text-2xl">My Profile</h2>
                        <FormikProvider value={formik}>
                            <form onSubmit={formik.handleSubmit} id="signUpForm">
                                <div className='registration-wrapper'>
                                    <div className='registration-col'>
                                        <img src="https://img.freepik.com/free-vector/privacy-policy-concept-illustration_114360-7853.jpg" alt="" width={450} />
                                    </div>
                                    <div className='registration-col'>
                                        <div className='input-wrap'>
                                            <Input
                                                label="Full Name"
                                                name="name"
                                                placeholder="Full Name"
                                                isRequired={true}
                                                onChange={(e) => handleCharInputChange(e, formik)}
                                            />
                                        </div>
                                        <div className='input-wrap'>
                                            <Input
                                                label="Phone Number"
                                                name="phone"
                                                placeholder="Phone Number"
                                                isRequired={true}
                                                onChange={formik.handleChange}
                                                type="tel"
                                            />
                                        </div>
                                        <div className='input-wrap'>
                                            <Input
                                                label="Email Id"
                                                name="email"
                                                placeholder="Email Id"
                                                isRequired={true}
                                                onChange={formik.handleChange}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className='input-wrap'>
                                            <Input
                                                label="Address"
                                                name="address"
                                                placeholder="Address"
                                                isRequired={true}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className='login-btn_wrapper'>
                                            <button className='text-white font-Spartan font-medium text-18 px-6 py-[0.75rem] rounded-lg bg-[#58647B] text-center' type='button' onClick={() => formik.resetForm()}>
                                                Cancel
                                            </button>
                                            <button className='m-btn m-btn-hero text-center' type='submit'>
                                                Save Changes
                                            </button>
                                        </div>
                                        {toast?.show && (
                                            <Toster text={toast?.text} variation={toast?.variation} />
                                        )}
                                    </div>
                                </div>
                            </form>
                        </FormikProvider>
                    </div>
                </div>
            </section>
        </>
    )
}

export default UserProfile;

