import React, { useState, useEffect } from "react";
import Breadcrumb from "../../component/common/Breadcrumb";
import CourseAdvantages from "./CourseAdvantages";
import { Link, useParams } from "react-router-dom";
import { Rating } from "@mui/material";
import { FaPlus } from "react-icons/fa";
import Accordions from "../../component/common/Accordion/Accordions";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { getApi, postApi } from "../../helper/helper";
import { Button, Form, Modal } from "react-bootstrap";
import { Card } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  notify,
  notifyErr,
} from "../../component/common/notify";
import { TabPanel } from "../../component/common/TabPanel/TabPanel";
import UpdateContent from "../../component/common/TabPanel/UpdateContent";
import Chapter from "../chapter/Chapter";
import AdminTestimonials from '../testimonials/Testimonials'
import Faqs from "../faqs/Faqs";
import Skeleton from '@mui/material/Skeleton';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import apiPath from "../../../apipath";
import { useDispatch, useSelector } from "react-redux";
import { fetchChapterData, fetchLessonData } from "../../../redux/features/basicSlice";
import { ToastContainer } from "react-toastify";
import Loader from "../../../components/Loader";


const CourseDetails = () => {

  const { courseId } = useParams();

  const lessonData = useSelector((state) => state?.basic?.lessonData);

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState(lessonData?.filter(i => i.type == 0)?.map(item => item.id));
  const [course, setCourse] = useState({});
  const [pending, setPending] = useState(true);
  const [formData, setFormData] = useState({});
  const [formDataUpdate, setFormDataUpdate] = useState({});
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showLesson, setShowLesson] = useState(false);
  const [status, setStatus] = useState('2');
  const [value, setValue] = React.useState('c-0');



  const fetchData = async () => {
    setPending(true);
    try {
      const result = await getApi(`${apiPath.getModuleCourse}/${courseId}/${status}`);
      setData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }

    try {
      const [result] = await getApi(`/api/${apiPath.getSingleCourse}/${courseId}`);
      setCourse(result);
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }

    dispatch(fetchChapterData(courseId));

    dispatch(fetchLessonData(courseId));

  };


  useEffect(() => {
    fetchData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: errors },
  } = useForm();

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setFormData({});
    reset({
      title: ""
    });
  };


  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm();

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleShowUpdate = (row) => {
    setShowUpdate(true);
    getIndividualData(row.id);
  };

  const handleCloseUpdate = () => {
    setShowUpdate(false);
    setFormDataUpdate({});
    reset1({
      title: ""
    });
  };

  const handleInputChange1 = (field, value) => {
    setFormDataUpdate({ ...formDataUpdate, [field]: value });
  };


  const onSubmit = async () => {
    setPending(true);
    try {
      const result = await postApi(apiPath.addModule, {
        ...formData,
        course_id: courseId,
      });

      handleClose();
      notify("Module Added Successfully!");
      fetchData();


    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  const getIndividualData = async (id) => {
    setPending(true);
    try {
      const result = await getApi(`${apiPath.getSingleModule}/${id}`);
      const data = result[0];
      if (data) {
        setFormDataUpdate((prevFormDataUpdate) => ({
          ...prevFormDataUpdate,
          ["id"]: data.id,
          ["title"]: data.title
        }));
        reset1({
          title: data.title
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  const onUpdate = async () => {
    setPending(true);
    try {
      const result = await postApi(apiPath.updateModule, {
        ...formDataUpdate,
        course_id: courseId,
      });

      handleCloseUpdate();
      notify("Module Updated Successfully!");
      fetchData();

    } catch (error) {
      console.error(error);
      notifyErr("Something went wrong!");
    } finally {
      setPending(false);
    }
  };


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLesson(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const manageLesson = async () => {
    const data = selectedLesson?.toString();
    setPending(true);
    try {
      const result = await postApi(apiPath.updateLessonType, {
        id: data,
        type: 0,
        course_id: courseId,
      });
      setShowLesson(false);
      notify("Lesson Updated Successfully!");
      fetchData();

    } catch (error) {
      console.error(error);
      setShowLesson(false);
      notifyErr("Something went wrong!");
    } finally {
      setPending(false);
    }
  }

  const breadcrumbItems = [
    { title: 'Course', link: "/admin/course" },
    { title: course?.title, link: null }
  ];



  return (
    <section className="course-details-s">

      <ToastContainer />

      {pending && <Loader />}

      <div className="mb-5">
        <Breadcrumb items={breadcrumbItems} />
      </div>

      <Card variant="outlined" className="p-3">
        {/* <Box
          sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', flex: 1 }}
        > */}

        <div className="flex flex-col md:flex-row">

          <div className="left-d1" >
            <div className="d-flex justify-between items-center">

              <h4 className="h4 m-0" style={{ cursor: 'pointer' }} onClick={(e) => handleTabChange(e, 'c-0')} >
                <Link to={`#vertical-tabpanel-c-0`}>{course?.title}</Link>
              </h4>
              <div className="d-flex" style={{ gap: "10px" }}>

                <Tooltip title="Add A Module">
                  <IconButton onClick={handleShow}>
                    {/* <Button
                    className="text-white border-0 btn-view"
                    style={{
                      float: "right",
                    }}
                  > */}
                    <FaPlus />
                    {/* </Button> */}
                  </IconButton>
                </Tooltip>
              </div>
            </div>

            {data?.map((moduleData, index) => (
              <Accordions {...moduleData} handleTabChange={handleTabChange} index={index} handleShowUpdate={handleShowUpdate} />

            ))}
          </div>

          <div className="right-d1 pl-4">

            <Card variant="outlined" className="p-3">

              {pending && course ? <>
                <Skeleton width="30%" height={50} />
                <Skeleton width="50%" height={40} />
                <Skeleton width="75%" />
                <Skeleton width="75%" />
                <Skeleton width="60%" />
                <Skeleton variant="rectangular" height={418} />
              </> :
                <TabPanel value={value} index={0} type={'c'}>
                  <div className="right-d position-relative">
                    <div>
                      <h2 className="h2">{course?.title}</h2>
                      <div className="d-flex items-center gap-5">
                        <p><span></span>{course?.instructor_name}</p>
                        {course?.price && <p><span>Price: </span>{course?.price}</p>}
                        {course?.average_rating && <p><Rating name="read-only" value={parseInt(course?.average_rating)} readOnly /></p>}
                      </div>
                    </div>
                    <div className="content-d mt-4">
                      <p>
                        {course?.description}
                      </p>
                    </div>
                    <div className="mt-4">
                      <img src={`/api/assets/course/${course?.image}`} className="w-100" />
                    </div>

                    <div className="position-absolute top-0 right-0">
                      <Button onClick={() => setShowLesson(true)}>
                        Manage Lesson
                      </Button>
                    </div>
                  </div>
                </TabPanel>}

              {data?.map((moduleData, index) => (
                <TabPanel value={value} index={`${moduleData.id}${index}`} type={'m'}>
                  <UpdateContent {...moduleData} id={moduleData.id} heading={"Module"} getPath={"getSingleModule"} addPath={"addChapter"} updatePath={"updateModule"} deletePath={"deleteModule"}
                    fetchData={fetchData}
                  />
                </TabPanel>
              ))}

              {data?.map((moduleData, index) => (
                <Chapter moduleId={moduleData.id} value={value} />
              ))}

            </Card>

            <div className="my-5">
              <CourseAdvantages />
            </div>


            <div className="my-5">
              <AdminTestimonials course_id={courseId} />
            </div>

            <div className="my-5">
              <Faqs course_id={courseId} />
            </div>
          </div>

        </div>

        {/* </Box> */}
      </Card>


      {/* add */}
      <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>

        <Modal.Header closeButton>
          <Modal.Title>Add Module</Modal.Title>
        </Modal.Header>
        <Form
          className="row m-2"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="title">Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                id="title"
                value={formData.title}
                {...register("title", {
                  required: true,
                  onChange: (e) => handleInputChange("title", e.target.value),
                })}
              />
              {errors.title && (
                <div className="errMsg text-danger">Please Enter Title</div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="contained"
              className="text-white btn-primary"
            >
              Add
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* update */}
      <Modal
        show={showUpdate}
        onHide={handleCloseUpdate}
        style={{ zIndex: "9999" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Module</Modal.Title>
        </Modal.Header>
        <Form
          className="row m-2"
          onSubmit={handleSubmit1(onUpdate)}
          autoComplete="off"
        >
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="title">Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                id="title"
                value={formDataUpdate.title}
                {...register1("title", {
                  required: true,
                  onChange: (e) => handleInputChange1("title", e.target.value),
                })}
              />
              {errors1.title && (
                <div className="errMsg text-danger">Please Enter Title</div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="contained"
              className="text-white btn-primary"

            >
              Update
            </Button>
            <Button variant="secondary" onClick={handleCloseUpdate}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showLesson} onHide={() => setShowLesson(false)} style={{ zIndex: "9999" }}>
        <Modal.Header closeButton>
          <Modal.Title>Manage Lesson</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="row m-2"
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <Form.Group className="mb-3 p-0">
              <Form.Label htmlFor="title">Free Lesson</Form.Label>
            </Form.Group>
            <Select
              multiple
              displayEmpty
              value={selectedLesson}
              onChange={handleChange}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected?.length === 0) {
                  return <em>Placeholder</em>;
                }
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {lessonData?.filter((item) => selected.includes(item.id))?.map((value) => {
                      return (
                        <Chip key={value.id} label={value.title} />
                      )
                    })}
                  </Box>
                )
              }}
            >
              <MenuItem disabled value="">
                <em>Placeholder</em>
              </MenuItem>
              {lessonData?.map((lesson) => (
                <MenuItem
                  key={lesson.id}
                  value={lesson.id}
                >
                  {lesson.title}
                </MenuItem>
              ))}
            </Select>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            className="text-white btn-primary"
            onClick={() => manageLesson()}
          >
            Update
          </Button>
          <Button variant="secondary" onClick={() => setShowLesson(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


    </section>

  );
};

export default CourseDetails;
