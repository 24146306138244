import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Input from '../../../components/Form/Input';
import apiPath from '../../../apipath';
import useToast from '../../../hooks/useToast';
import { useEffect, useState } from 'react';
import axiosProvider from '../../../libs/axios/axiosProvider';
import Loader from '../../../components/Loader';
import Toster from '../../../components/Toster';
import { useSelector } from 'react-redux';

const ChangePassword = () => {

    const { toast, showToast } = useToast();
    const [loading, setLoading] = useState(false);
    const { accessToken } = useSelector((state) => state?.auth);

    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true);
        try {

            const submitFormData = new FormData();
            Object.entries(values).forEach(([key, value]) => {
                submitFormData.append(key, value);
            });

            const response = await axiosProvider({
                method: "POST",
                endpoint: apiPath.updatePassword,
                data: submitFormData,
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (response.status === 200 && response?.data?.response_code == 200) {
                showToast(response?.data?.message, "success", 3000);
                resetForm({ values: "" });
            } else {
                showToast(response?.data?.message, "error", 3000);
            }
            setLoading(false)
        } catch (error) {
            console.log(error);
            showToast("Something went wrong !", "error", 3000);
            setLoading(false)
        }
    };

    const initialValues = {
        newPassword: '',
        oldPassword: '',
        confirmPassword: ""
    };

    const validationSchema = Yup.object({
        oldPassword: Yup.string().required('Current Password is Required'),
        newPassword: Yup.string()
            .notOneOf(
                [Yup.ref("oldPassword"), null],
                "New password must be different from the current password"
            )
            .required("New password is required").matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+}{":;'?/>.<,])[A-Za-z\d!@#$%^&*()_+}{":;'?/>.<,]{8,}$/,
                "Password must be at least 8 characters long and include uppercase, lowercase letters, numbers, and special characters."
            ),
        confirmPassword: Yup.string().oneOf([Yup.ref("newPassword"), null], "Passwords must match")
            .required("Confirm password is required")
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema,
        onSubmit: handleSubmit
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    return (
        <>
            <section className='section_spacing w-full section_spacing-space'>
                <div className=''>
                    <h2 className="mb-3 text-2xl">Update Password</h2>
                    <div className='w-fit mx-auto'>
                        <FormikProvider value={formik}>
                            {loading && <Loader />}

                            <form onSubmit={formik.handleSubmit} id="loginForm" className='border p-5'>
                                <div className='grid grid-cols-1 gap-2'>
                                    <div className="flex flex-col">
                                        <Input
                                            label="Current Password"
                                            name="oldPassword"
                                            placeholder="Current Password"
                                            autoComplete="new-password"
                                            isRequired={true}
                                            onChange={formik.handleChange}
                                            type="password"
                                        />
                                    </div>
                                    <div className="flex flex-col">
                                        <Input
                                            label="New Password"
                                            name="newPassword"
                                            placeholder="New Password"
                                            autoComplete="new-password"
                                            isRequired={true}
                                            onChange={formik.handleChange}
                                            type="password"
                                        />
                                    </div>
                                    <div className="flex flex-col">
                                        <Input
                                            label="Confirm Password"
                                            name="confirmPassword"
                                            placeholder="Confirm Password"
                                            autoComplete="new-password"
                                            isRequired={true}
                                            onChange={formik.handleChange}
                                            type="password"
                                        />
                                    </div>


                                    <div>
                                        <button className='m-btn m-btn-hero justify-center text-center w-full' type='submit'>
                                            Change Password
                                        </button>
                                    </div>

                                    {toast?.show && (
                                        <Toster text={toast?.text} variation={toast?.variation} />
                                    )}
                                </div>
                            </form>
                        </FormikProvider>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ChangePassword;
