import React, { useState, useEffect, memo } from 'react';
import { getApi, postApi } from "../../helper/helper";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
    notify,
    notifyErr,
} from "../../component/common/notify";
import TextEditor from '../../editor/TextEditor';
import { confirmDelete, confirmStatus } from '../../component/common/handleData';
import { useParams } from 'react-router-dom';
import apiPath from '../../../apipath';
import { useDispatch } from 'react-redux';
import { fetchLessonData } from '../../../redux/features/basicSlice';




const UpdateLesson = (props) => {
    const { courseId } = useParams();

    const [formDataUpdate, setFormDataUpdate] = useState({});
    const [updatedContent, setUpdatedContent] = useState('');
    const [inputType, setInputType] = useState('');
    const [existingVideoFileName, setExistingVideoFileName] = useState('');

    const dispatch = useDispatch();

    const getIndividualData = async (id) => {
        try {
            const result = await getApi(`/api/${props.getPath}/${id}`);
            const data = result[0];
            if (data) {
                setFormDataUpdate((prevFormDataUpdate) => ({
                    ...prevFormDataUpdate,
                    id: data.id,
                    title: data.title,
                    description: data.description,
                    type: data.type,
                    pdf: data.pdf,
                    video: data.video,
                    video_url: data.video_url,
                }));
                setUpdatedContent(data.description);
                reset({
                    title: data.title,
                    description: data.description,
                    type: data.type,
                    pdf: data.pdf,
                    video: data.video,
                    video_url: data.video_url,
                });
                setInputType(data.type === 'video' ? 'video' : 'url');
                setExistingVideoFileName(data.video ? data.video : '');
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getIndividualData(props.id);
    }, [props.id, props.chapterId]);

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const handleCloseUpdate = () => {
        setFormDataUpdate({});
        reset({
            title: "",
            type: "",
            pdf: "",
            video: "",
            video_url: ""
        });
        setInputType('');
        setExistingVideoFileName('');
    };

    const handleInputChange = (field, value) => {
        setFormDataUpdate({ ...formDataUpdate, [field]: value });
        if (field === 'type') {
            setInputType(value);
        }
    };

    useEffect(() => {
        handleInputChange('description', updatedContent);
    }, [updatedContent]);

    const onUpdate = async () => {
        try {
            const result = await postApi(`/api/${props.updatePath}`, {
                ...formDataUpdate,
                chapter_id: props.chapterId,
                course_id: courseId,
            });

            handleCloseUpdate();
            notify("Lesson Updated Successfully!");
            getIndividualData(props.id);
            dispatch(fetchLessonData(courseId));
        } catch (error) {
            console.error(error);
            notifyErr("Something went wrong!");
        }
    };

    const handleDelete = (endPoint) => {
        confirmDelete(endPoint)
            .then((data) => {
                getIndividualData(props.id);
                dispatch(fetchLessonData(courseId));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleStatus = (endpoint, id, status) => {
        confirmStatus(endpoint, { id: id, status: status })
            .then((message) => {
                getIndividualData(props.id);
                dispatch(fetchLessonData(courseId));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <section>
            <div className="mb-2">
                {props?.status == 0 ? (
                    <span
                        className="h6 m-0 bg-success text-white px-2 py-1 rounded cursor-pointer"
                        onClick={() =>
                            handleStatus(apiPath.updateLessonStatus, props.id, "1")
                        }
                    >
                        Active
                    </span>
                ) : props?.status == 1 ? (
                    <span
                        className="h6 m-0 bg-warning text-white px-2 py-1 rounded cursor-pointer"
                        onClick={() =>
                            handleStatus(apiPath.updateLessonStatus, props.id, "0")
                        }
                    >
                        In-Active
                    </span>
                ) : null}
            </div>

            <Form
                className="row m-0"
                onSubmit={handleSubmit(onUpdate)}
                autoComplete="off"
            >
                <h2 className="h5 p-0">Update Lesson</h2>

                <Form.Group className="mb-3 p-0">
                    <Form.Label htmlFor="title">Title</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Title"
                        id="title"
                        value={formDataUpdate.title}
                        {...register("title", {
                            required: true,
                            onChange: (e) => handleInputChange("title", e.target.value),
                        })}
                    />
                    {errors.title && (
                        <div className="errMsg text-danger">Please Enter Title</div>
                    )}
                </Form.Group>

                <Form.Group className="mb-3 p-0">
                    <Form.Label htmlFor="description">Description</Form.Label>
                    <TextEditor data={updatedContent} setUpdatedContent={setUpdatedContent} />
                </Form.Group>

                <Form.Group className="mb-3 p-0">
                    <Form.Label htmlFor="type">Type</Form.Label>
                    <Form.Select
                        type="type"
                        id="type"
                        placeholder="type"
                        value={formDataUpdate.type}
                        {...register("type", {
                            required: true,
                            onChange: (e) => handleInputChange("type", e.target.value),
                        })}
                    >
                        <option value="">Select Type</option>
                        <option value="0">Free</option>
                        <option value="1">Paid</option>
                    </Form.Select>
                    {errors.type && (
                        <div className="errMsg text-danger">Please Select Type</div>
                    )}
                </Form.Group>

                <Form.Group className='mb-3 p-0'>
                    <Form.Label htmlFor="pdf">Pdf</Form.Label>
                    <Form.Control
                        type="file" accept=".pdf"
                        placeholder="Pdf"
                        id="pdf"
                        multiple
                        {...register("pdf", {
                            required: false,
                            onChange: (e) =>
                                handleInputChange("pdf", e.target.files),
                        })}
                    />
                    {errors.pdf && (
                        <div className="errMsg text-danger">Please Select Pdf</div>
                    )}
                </Form.Group>

                <Form.Group className="mb-3 p-0">
                    <Form.Label htmlFor="contentType">Media Type</Form.Label>
                    <Form.Select
                        id="contentType"
                        value={inputType}
                        {...register("contentType", {
                            required: true,
                            onChange: (e) => setInputType(e.target.value),
                        })}
                    >
                        <option value="">Select Media Type</option>
                        <option value="video">Upload Video</option>
                        <option value="url">Video URL</option>
                    </Form.Select>
                    {errors.contentType && (
                        <div className="errMsg text-danger">Please Select Media Type</div>
                    )}
                </Form.Group>

                {inputType === 'video' && (
                    <Form.Group className="mb-3 p-0">
                        <Form.Label htmlFor="video">Video</Form.Label>
                        <Form.Control
                            type="file"
                            accept="video/mp4,video/x-m4v,video/*"
                            placeholder="Video"
                            id="video"
                            multiple
                            {...register("video", {
                                required: false,
                                onChange: (e) => handleInputChange("video", e.target.files),
                            })}
                        />
                        {errors.video && (
                            <div className="errMsg text-danger">Please Select Video</div>
                        )}
                        {existingVideoFileName && (
                            <div className="existing-file-name mt-2">
                                Current Video: {existingVideoFileName}
                            </div>
                        )}
                    </Form.Group>
                )}

                {inputType === 'url' && (
                    <Form.Group className="mb-3 p-0">
                        <Form.Label htmlFor="video_url">Video Url</Form.Label>
                        <Form.Control
                            type="url"
                            placeholder="Video Url"
                            id="video_url"
                            value={formDataUpdate.video_url}
                            {...register("video_url", {
                                required: false,
                                onChange: (e) => handleInputChange("video_url", e.target.value),
                            })}
                        />
                        {errors.video_url && (
                            <div className="errMsg text-danger">Please Enter Video Url</div>
                        )}
                    </Form.Group>
                )}

                <div className="btn-d d-flex justify-content-end gap-2">
                    <Button
                        type="submit"
                        variant="contained"
                        className="text-white btn-primary"
                    >
                        Update
                    </Button>
                    <Button variant="secondary" className="text-white bg-danger border-0"
                        onClick={() => handleDelete(`/api/${props.deletePath}/${props.id}`)}
                    >
                        Delete
                    </Button>
                </div>
            </Form>
        </section>
    );
};

export default memo(UpdateLesson);

