import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import apiPath from '../../apipath';
import { fetchDataFromApi } from '../../libs/AllGlobalFunctions';
import CurriculumAccordion from '../../components/Accordion/CurriculumAccordion';
import { Outlet } from 'react-router-dom';
import { useFormik, FormikProvider, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import axiosProvider from '../../libs/axios/axiosProvider';
import useToast from '../../hooks/useToast';
import Input from '../../components/Form/Input';
import Toaster from '../../components/Toster';
import { useSelector } from 'react-redux';
import { Rating } from '@mui/material';
import useAuthenticated from '../../hooks/useAuthenticated';
import { FiEdit } from "react-icons/fi";
import Loader from '../../components/Loader';
import { _logo_ } from '../../libs/ImagePath';

const drawerWidth = 440;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const initialValue = {
    content: '',
    stars: ''
}

const CourseLessons = () => {
    // const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    const { toast, showToast } = useToast();
    const [loading, setLoading] = useState(false);
    const [isReviewed, setIsReviewed] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [chapters, setChapters] = useState([]);
    const [review, setReview] = useState(initialValue);
    const [prevId, setPrevId] = useState('');
    const [nextId, setNextId] = useState('');

    const isAuth = useAuthenticated();

    const { userInfo, accessToken } = useSelector((state) => state?.auth);
    const settings = useSelector((state) => state?.setting?.settings);
    const { id, lessonId } = useParams();

    const theme = useTheme();
    const [open, setOpen] = useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const styles = {
        backgroundColor: "var(--dark-gray)",
        color: "#2e2d3d",
    };


    useEffect(() => {
        window.scrollTo(0, 0);
        if (isAuth) {
            fetchData();
        }
        if (id) {
            fetchChapters();
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            handleDrawerClose();
        }, 1000);
    }, [lessonId]);


    // const toggleSidebar = () => {
    //     setIsSidebarVisible(!isSidebarVisible);
    // };

    const fetchData = () => {
        fetchDataFromApi(`getTestimonial/${id}`, setLoading, 'GET', {}).then((response) => {
            if (response?.status == 200 && response?.data) {
                const curData = response?.data?.data[0];
                if (curData) {
                    setReview((prevFormData) => ({
                        ...prevFormData,
                        ["id"]: curData.id,
                        ["content"]: curData.content,
                        ["stars"]: curData.stars,
                    }
                    ));
                    setIsReviewed(true);
                } else {
                    setReview(initialValue);
                }
            }
        }
        ).catch((error) => {
            setLoading(false);
            console.log(error)
        })
    }

    const fetchChapters = () => {
        fetchDataFromApi(`getChapter/${id}/0/0`, setLoading, 'GET', {}).then((response) => {
            if (response?.status == 200 && response?.data) {
                setChapters(response?.data?.data)
            } else {
                setChapters([]);
            }
        }
        ).catch((error) => {
            setLoading(false);
            console.log(error)
        })
    };

    // Calculate marginLeft based on sidebar visibility
    // const contentMarginLeft = isSidebarVisible ? '500px' : '0px';

    const findPrevNext = (ids, lessons) => {
        const currentIndex = lessons?.findIndex(lesson => lesson.id === ids);
        let pId = null;
        let nId = null;
    
        if (currentIndex > 0) {
            for (let i = currentIndex - 1; i >= 0; i--) {
                if (lessons[i]) {
                    pId = lessons[i].id;
                    break;
                }
            }
        }
    
        if (currentIndex < lessons.length - 1) {
            for (let i = currentIndex + 1; i < lessons.length; i++) {
                if (lessons[i]) {
                    nId = lessons[i].id;
                    break;
                }
            }
        }
        
        setPrevId(pId);
        setNextId(nId);
        //return { prevId, nextId };
    };
    

    const handleSubmit = async (values, { resetForm }) => {

        setLoading(true);

        try {

            values = {
                ...values,
                course_id: id,
                user_id: userInfo?.id
            }

            const submitFormData = new FormData();
            Object.entries(values).forEach(([key, value]) => {
                submitFormData.append(key, value);
            });

            const apiUrl = userInfo && accessToken && isReviewed ? apiPath?.rePostReview : apiPath?.userTestimonial;

            const response = await axiosProvider({
                method: "POST",
                endpoint: apiUrl,
                data: submitFormData,
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (response.status === 200 && response?.data?.response_code == 200) {
                showToast("Thanks for review", "success", 3000);

                resetForm({ values: "" });
                fetchData();
            } else {
                showToast(response?.data?.message, "error", 3000);
            }
            setLoading(false)

        } catch (error) {
            console.log(error);
            showToast("Something went wrong !", "error", 3000);
            setLoading(false)
        }

    };

    const validationSchema = Yup.object({
        content: Yup.string().required("Content is Required"),
        stars: Yup.string().required("Rating is Required"),
    });

    const formik = useFormik({
        initialValues: review,
        validationSchema,
        enableReinitialize: true,
        onSubmit: handleSubmit
    });

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                {loading && <Loader />}
                <CssBaseline />
                <AppBar position="fixed" open={open} style={styles}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon className="text-white" />
                        </IconButton>
                        <div className="text-white">
                            <img src={`${_logo_}${settings?.logo}`} className="logo mx-auto" width={120} alt="logo" />
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>
                    {/* <Divider /> */}

                    <CurriculumAccordion chapters={chapters} courseId={id} findPrevNext={findPrevNext} />

                </Drawer>
                <Main open={open} className="admin_main overflow-hidden">
                    <DrawerHeader />

                    <div className="flex justify-between">
                        <Link to={"/course"} className="back-page flex w-fit explore-btn gap-2 mb-3">
                            <i className="m-icon icon_back invert"></i>Back to Course
                        </Link>

                        <div className='flex w-fit gap-4'>
                            <Link to={prevId ? `/lessons/${id}/${prevId}` : `/lessons/${id}/${lessonId}`} className="back-page flex w-fit explore-btn gap-2 mb-3">
                                <i className="m-icon icon_back invert"></i>Prev
                            </Link>
                            <Link to={nextId ? `/lessons/${id}/${nextId}` : `/lessons/${id}/${lessonId}`} className="back-page flex w-fit explore-btn gap-2 mb-3">
                                Next<i className="m-icon icon_back rotate-180 invert"></i>
                            </Link>

                        </div>
                    </div>
                    <Outlet />

                    {(isAuth && userInfo?.course_ids?.includes(id)) &&
                        <div className='site-container'>
                            <FormikProvider value={formik}>
                                <form onSubmit={formik.handleSubmit} id="signUpForm" className='mt-5'>
                                    <div className=''>
                                        <div className='registration-col contact-form_'>
                                            <div className="flex items-center justify-between mb-3">
                                                <h2 class="contact-form-head">Leave a Review</h2>

                                                {isReviewed && <button onClick={() => setIsEdit(true)} type='button'>
                                                    <FiEdit className="text-3xl" />
                                                </button>}

                                            </div>
                                            <div className='input-wrap'>
                                                <label className="form-label required">Content</label>
                                                <textarea
                                                    className="form-control"
                                                    rows="7"
                                                    name="content"
                                                    disabled={!isEdit && isReviewed}
                                                    placeholder='Content'
                                                    onChange={formik?.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.content}
                                                />
                                                <ErrorMessage
                                                    name="content"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </div>

                                            <div className="input-wrap">
                                                <label className="form-label required">Rating</label>
                                                <div>
                                                    <Rating
                                                        name="stars"
                                                        disabled={!isEdit && isReviewed}
                                                        value={formik.values.stars}
                                                        onChange={formik?.handleChange}
                                                    />
                                                    <ErrorMessage
                                                        name="stars"
                                                        component="div"
                                                        className="text-danger"
                                                    />
                                                </div>
                                            </div>
                                            <div className=''>
                                                <button className='m-btn m-btn-hero text-center flex items-center gap-3' type='submit'
                                                    disabled={!isEdit && isReviewed}
                                                >
                                                    Submit Review <i className="m-icon btn-icon-arrow"></i>
                                                </button>
                                                {isEdit &&
                                                    <button
                                                        onClick={() => {
                                                            formik.resetForm();
                                                            setIsEdit(false)
                                                        }}
                                                        className='text-white font-Spartan font-medium text-18 px-6 py-[0.75rem] rounded-lg bg-[#58647B] text-center ml-3' type='button'>
                                                        Cancel
                                                    </button>}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </FormikProvider>
                        </div>
                    }
                    <center className="pt-5">
                        <p className="copy-right">
                            Copyright © {new Date().getFullYear()} TRADESMATE - All rights
                            reserved
                        </p>
                    </center>
                </Main>
            </Box >
            {/* <div className="flex">
                {loading && <Loader />}
                <div className={`course-seo-wrapper ${isSidebarVisible ? '' : 'deactivate'}`}>
                    {isSidebarVisible &&
                        <div className="w-85 h-screen bg-white text-white fixed overflow-y-auto">
                            <CurriculumAccordion chapters={chapters} courseId={id} />
                        </div>
                    }
                    <div className='corse-side-btn z-20 fixed top-0 left-0' onClick={toggleSidebar}>
                        <span className='m-icon arrow-sidebar'></span>
                    </div>
                </div>
                <div className="flex-1 p-10 transition-all duration-300 ease-in-out" style={{ marginLeft: contentMarginLeft }}>
                    <Outlet />
                </div>
            </div> */}
        </>
    )
}

export default CourseLessons;