import PageTitle from "../../css/PageTitle";
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Input from '../../components/Form/Input';
import apiPath from "../../apipath";
import axiosProvider from "../../libs/axios/axiosProvider";
import { useNavigate } from 'react-router';
import { useState } from "react";
import Loader from "../../components/Loader";
import useToast from "../../hooks/useToast";
import Toster from '../../components/Toster';
import globalValidationSchema from "../../validations/globalValidations";

const ForgetPassword = () => {

    const navigate = useNavigate();
    const { toast, showToast } = useToast();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values, { resetForm }) => {

        setLoading(true);

        try {
            const submitFormData = new FormData();
            Object.entries(values).forEach(([key, value]) => {
                submitFormData.append(key, value);
            });

            const response = await axiosProvider({
                method: "POST",
                endpoint: apiPath.forgetPassword,
                data: submitFormData,
            });

            if (response.status === 200 && response?.data?.response_code == 200) {
                showToast("Please check your email and reset password !", "success", 3000);
                setLoading(false)
                resetForm({ values: "" });
            } else {
                showToast(response?.data?.message, "error", 3000);
                setLoading(false)
            }

        } catch (error) {
            console.log(error);
            showToast("Something went wrong !", "error", 3000);
            setLoading(false)
        }
    };

    const initialValues = {
        email: '',
    };

    const validationSchema = Yup.object({
        email: globalValidationSchema.email,
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema,
        onSubmit: handleSubmit
    })

    return (
        <section className='section_spacing'>
            <PageTitle PageTitle={"Forget Password"} />
            <div className='flex items-center justify-center site-container'>

                <FormikProvider value={formik}>
                    {loading && <Loader />}

                    <form onSubmit={formik.handleSubmit} id="loginForm" className='min-w-[80%] sm:min-w-[24rem]'>
                        <div className="flex flex-col space-y-4">
                            <div className="flex flex-col ">
                                <Input
                                    label="Email Id"
                                    name="email"
                                    placeholder="Email Id"
                                    isRequired={true}
                                    onChange={formik.handleChange}
                                />
                            </div>

                            <button className='m-btn m-btn-hero justify-center text-center' type='submit'>
                                Reset
                            </button>
                        </div>
                        <div className="mt-4 flex justify-between max-w-md">
                            <p>
                                <a href="#" className="text-blue-500" onClick={() => navigate("/auth/login")}>
                                    Sign In
                                </a>
                            </p>
                            <p>
                                New user?{' '}
                                <a href="#" className="text-blue-500" onClick={() => navigate("/auth/signup")}>
                                    Sign Up
                                </a>
                            </p>

                        </div>

                        {toast?.show && (
                            <Toster text={toast?.text} variation={toast?.variation} />
                        )}
                    </form>
                </FormikProvider>

            </div>
        </section>
    )
}

export default ForgetPassword;