import React from "react";
import { TabPanel } from "../../component/common/TabPanel/TabPanel";
import UpdateLesson from "./UpdateLesson";
import apiPath from "../../../apipath";
import { useSelector } from "react-redux";

const LessonDetails = (props) => {

  const lessonData = useSelector((state) => state?.basic?.lessonData);

  return (
    <section>

      {lessonData?.filter(item => item.chapter_id == props.id)?.map((lesson, index) => (
        <TabPanel value={props.value} index={`${props?.moduleId}${props?.chapterId}${lesson?.id}${index}`} type={'l'} >
          <UpdateLesson {...lesson} id={lesson.id} chapterId={props.chapterId} heading={"Chapter"} getPath={apiPath.getAdminLesson} updatePath={"updateLesson"} deletePath={"deleteLesson"}

          />
        </TabPanel>
      ))}

    </section>
  );
};

export default LessonDetails;
