import CourseCard from './Course/CourseCard'
import { Link } from 'react-router-dom'
import { fetchDataFromApi } from '../libs/AllGlobalFunctions';
import apiPath from '../apipath';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function CourseCardSkeleton() {
  return (
    <div className="course-card-skeleton">
      <Skeleton height={200} />
      <Skeleton height={20} style={{ margin: '10px 0', width: '80%' }} />
      <Skeleton height={15} count={2} style={{ width: '90%' }} />
    </div>
  );
}

export default function PopularCourses() {

  const [featuredCourseList, setFeaturedCourseList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchFeaturedCourse();
  }, []);

  const fetchFeaturedCourse = () => {
    setLoading(true);
    fetchDataFromApi(apiPath.getFeaturedCourse, null, 'GET', {}).then((response) => {
      if (response && response?.data?.data) {
        setFeaturedCourseList(response?.data?.data)
        setLoading(false);
      }
    }
    ).catch((error) => {
      setLoading(false);
      console.log(error)
    })
  }

  return (
    <section className='section_spacing popular-corse-section'>
      {
        loading ? <CourseCardSkeleton /> :
          <div className='site-container'>
            <div className='popular-courses'>
              <div className='section-title-wrap'>
                <div className='popular-title'>
                  <span class='pre-heading'>POPULAR COURSES</span>
                </div>
                <h2 className='section-title'>Pick A Course To Get Started</h2>
              </div>

              {/* popular course items  */}
              <div className='h-pc-wrapper'>
                {
                  featuredCourseList?.map((item) => {
                    return (
                      <CourseCard course={item} key={item?.id} />
                    )
                  })
                }
              </div>
              <div className='more-corse-btn'>
                <Link to='/course' className='m-btn m-btn-hero'>
                  Browse more courses <i className='m-icon btn-icon-arrow'></i>
                </Link>
              </div>
            </div>
          </div>
      }
    </section>
  )
}
