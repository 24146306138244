import React, { useState, useEffect } from "react";
import "./dashboardlayout.css";
import { Outlet } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { NavLink } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { SidebarItems } from "../sidebar/Sidebar";
import logo from '../assets/Tradesmate-Logo.png';
import { logout } from "../../redux/features/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { _logo_ } from "../../libs/ImagePath";
import apiPath from "../../apipath";



const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
    ...(window.innerWidth < 767 && {
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open &&
    window.innerWidth > 767 && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));


const DashboardLayout = () => {

  const dispatch = useDispatch();

  const { adminInfo, accessToken } = useSelector((state) => state?.admin);

  const theme = useTheme();
  const [open, setOpen] = React.useState(
    window.innerWidth > 767 ? true : false
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openCollapse, setOpenCollapse] = React.useState(false);
  const settings = useSelector((state) => state?.setting?.settings);
  const [greeting, setGreeting] = useState('');

  const styles = {
    backgroundColor: "var(--dark-gray)",
    color: "#2e2d3d",
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  const handleCollapse = (collapse) => {
    setOpenCollapse((PrevOpenCollapse) =>
      PrevOpenCollapse == collapse ? false : collapse
    );
  };

  //   let authenticated = localStorage.getItem("auth");
  //   if (authenticated) {
  //     const decoded = jwtDecode(authenticated);

  //     const checkTokenAndLogout = async () => {
  //       var currentTime = new Date();

  //       var currentOffset = currentTime.getTimezoneOffset();

  //       var ISTOffset = 330; // IST offset UTC +5:30

  //       var currentDate = new Date(
  //         currentTime.getTime() + (ISTOffset + currentOffset) * 60000
  //       );

  //       // Create a Date object from the ISO date string
  //       const date = new Date(currentDate);

  //       // Convert the Date object to a Unix timestamp in seconds
  //       const unixTimestamp = Math.floor(date.getTime() / 1000);

  //       if (decoded && decoded.exp < unixTimestamp) {
  //         handleLogOut();
  //       }
  //     };

  //     setInterval(checkTokenAndLogout, 60000);
  //   }
  const checkUserAuth = async () => {
    try {
      const result = await axios.get(`${apiPath.checkUserAuth}`, {
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          token: "1fbf405e5f4af57b726f0c00b762d95a62c4930c",
          'Authorization': `Bearer ${accessToken}`,
        },
      });
    } catch (error) {
      console.error(error);
      handleLogOut();
    }
  }

  const handleLogOut = () => {
    try {
      const response = new Promise((resolve) => {
        setTimeout(() => resolve({ code: 200 }), 1000);
      });

      response.then((res) => {
        if (res?.code == 200) {
          dispatch(logout())
          navigate('/admin');
        }
      }).catch((error) => console.log(error))

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {

    const currentDate = new Date();
    const currentHour = currentDate.getHours();

    let message = '';
    if (currentHour < 12) {
      message = `Good morning ${adminInfo?.name}`;
    } else if (currentHour < 18) {
      message = `Good afternoon ${adminInfo?.name}`;
    } else {
      message = `Good evening ${adminInfo?.name}`;
    }

    setGreeting(message);

    // if (accessToken) {
    //   checkUserAuth();
    // }

  }, []);


  // let authenticated = localStorage.getItem("auth");
  // if (adminToken) {
  //   try {
  //     const decoded = jwtDecode(adminToken);

  //     const checkTokenAndLogout = async () => {
  //       var currentTime = new Date();

  //       var currentOffset = currentTime.getTimezoneOffset();

  //       var ISTOffset = 330;   // IST offset UTC +5:30 

  //       var currentDate = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000);

  //       // Create a Date object from the ISO date string
  //       const date = new Date(currentDate);

  //       // Convert the Date object to a Unix timestamp in seconds
  //       const unixTimestamp = Math.floor(date.getTime() / 1000);

  //       if (decoded && (decoded.exp < unixTimestamp)) {
  //         handleLogOut();
  //       }
  //     }
  //     setInterval(checkTokenAndLogout, 60000);
  //   } catch (error) {
  //     handleLogOut();
  //   }
  // }

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} className="header" style={styles}>
          <Toolbar className="justify-content-between">
            <div className="left_h d-flex align-items-center gap-3">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                {/* <h1 className="text-center text-2xl text-[#ffffff]">Trades Mate</h1> */}
                {open ? null : <img src={`${_logo_}${settings?.logo}`} width={120} alt="logo" />}

              </Typography>
              <h4 className="text-white pl-4">{greeting}</h4>
            </div>

            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={openMenu ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }}>A</Avatar>
              </IconButton>
            </Tooltip>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openMenu}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <Link
                to="/admin/profile"
                style={{ textDecoration: "none", color: "black" }}
              >
                <MenuItem>
                  <Avatar /> Profile
                </MenuItem>
              </Link>

              <Divider />
              {/* {(getAdmin?.type === "0" && localStorage.getItem('auth')) && */}
              <Link
                to="/admin/setting"
                style={{ textDecoration: "none", color: "black" }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <Settings fontSize="small" className="" />
                  </ListItemIcon>
                  Settings
                </MenuItem>
              </Link>
              {/* } */}

              <MenuItem onClick={handleLogOut}>
                <ListItemIcon>
                  <Logout fontSize="small" className="text-black" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              background: "var(--dark-gray)",
            },
          }}
          variant={window.innerWidth > 767 ? "persistent" : "temporary"}
          anchor="left"
          open={open}
          className="scroll_"
        >
          <DrawerHeader className="justify-content-between">
            {/* <h1 className="text-center text-2xl text-[#ffffff]">Trades Mate</h1> */}
            <img src={`${_logo_}${settings?.logo}`} className="logo" width={120} alt="logo" />

            <IconButton onClick={handleDrawerClose} className="nav_bar">
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon style={{ color: "#fff" }} />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List style={styles}>
            {SidebarItems?.map((data, index) => (
              <div key={index}>

                <NavLink end={data.title == "Dashboard" && true}
                  to={`${data.url}`}
                  onClick={() => window.innerWidth < 767 && handleDrawerClose()}
                  // activeClassName="active"
                  className={"text-decoration-none"}
                >
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon className="icon_ bg-transparent">
                        {data.icon}
                      </ListItemIcon>
                      <ListItemText className="" primary={data.title} />
                    </ListItemButton>
                  </ListItem>
                </NavLink>

              </div>
            ))}
          </List>
        </Drawer>
        <Main open={open} className="admin_main overflow-hidden">
          <DrawerHeader />
          <Outlet />
          <center className="pt-5">
            <p className="copy-right">
              Copyright © {new Date().getFullYear()} Stock Trading - All rights
              reserved
            </p>
          </center>
        </Main>
      </Box>
    </>
  );
};

export default DashboardLayout;
