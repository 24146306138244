import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import bradcom from "../icons/bradcome-arrow.svg";
import Loader from "../components/Loader";
import axiosProvider from "../libs/axios/axiosProvider";
import useToast from "../hooks/useToast";
import apiPath from "../apipath";
import { useNavigate } from "react-router-dom";
import { FaFacebook, FaTwitter, FaLinkedin, FaShareAlt, FaInstagram } from "react-icons/fa";
import { useFormik, FormikProvider, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Input from '../components/Form/Input';
import Toster from '../components/Toster';
import { fetchDataFromApi } from "../libs/AllGlobalFunctions";
import { useSelector } from "react-redux";
import { _banner_ } from "../libs/ImagePath";
import globalValidationSchema from "../validations/globalValidations";
import { handleCharInputChange } from "../libs/AllGlobalFunctions";


const ContactDetails = () => {

  const { toast, showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState({})

  const banners = useSelector((state) => state?.banner?.banners);
  const settings = useSelector((state) => state?.setting?.settings)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (banners) {
      let [bannerData] = banners?.filter(i => i.page_id == 4);
      setBanner(bannerData);
    }
  }, [banners]);


  const handleSubmit = async (values, { resetForm }) => {

    setLoading(true);

    try {
      const submitFormData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        submitFormData.append(key, value);
      });

      const response = await axiosProvider({
        method: "POST",
        endpoint: apiPath.contactUs,
        data: submitFormData,
      });

      if (response.status === 200 && response?.data?.response_code == 200) {
        showToast(response?.data?.message, "success", 3000);
        resetForm({ values: "" });
      } else {
        showToast(response?.data?.message, "error", 3000);
      }
      setLoading(false)
    } catch (error) {
      console.log(error);
      showToast("Something went wrong !", "error", 3000);
      setLoading(false)
    }

  };

  const initialValues = {
    name: '',
    email: '',
    message: '',
    phone: ""
  };

  const validationSchema = Yup.object({
    email: globalValidationSchema.email,
    name: globalValidationSchema.name,
    message: Yup.string().required("Message is Required"),
    phone: globalValidationSchema.phone,
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: handleSubmit
  });

  return (
    <>
      {loading && <Loader />}
      <div className='pc-details-banner' style={{ backgroundImage: `url('${_banner_}/${banner?.banner_image}')` }}>
        <h2 className='pc-details-title'>Contact Us</h2>
        <div className='p-couse-bradcom-wrapper'>
          <Link to={'/'} className='p-bradcom-item'>
            Home
          </Link>
          <img src={bradcom} alt='' />
          <Link to={"/contact"} className="p-bradcom-item">
            Contact Us
          </Link>
        </div>
      </div>
      <section className="section_spacing pb-0">
        <div className="site-container">
          <section>
            {loading && <Loader />}


            {/* <PageTitle PageTitle={"Sign Up"} /> */}

            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit} id="signUpForm">
                <div className='registration-wrapper'>
                  <div className='registration-col w-full md:w-[90%]'>
                    <div className="h-aboutus-right">
                      <div className="section-title-wrap h-contact-title-wrap p-0">
                        <h1 className="section-title ">
                          We're Always Eager to Hear From You!
                        </h1>
                      </div>
                      {settings?.name && (
                        <div>
                          <h3 className="section-address">Contact</h3>
                          <p className="mb-3">{settings.name}</p>
                        </div>
                      )}
                      {settings?.address && (
                        <div>
                          <h3 className="section-address">Address</h3>
                          <p className="mb-3">{settings.address}</p>
                        </div>
                      )}
                      <h3 className="section-address">Email</h3>
                      <p className=" mb-3">   {settings?.enq_email}</p>
                      <h3 className="section-address">Phone</h3>
                      <p className=" ">{settings?.phone}</p>
                      <div className="flex items-center mt-4 gap-4">
                        <Link to={settings?.fb_url} target=" " className="text-neutral-500" >
                          <FaFacebook />
                        </Link>
                        <Link to={settings?.twtr_url} target=" " className="text-neutral-500" >
                          <FaTwitter />
                        </Link>
                        <Link to={settings?.insta_url} target=" " className="text-neutral-500" >
                          <FaInstagram />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className='registration-col contact-form_'>
                    <div>
                      <h2 class="contact-form-head">Get in touch</h2>
                      <p className="contact-form-content">
                        Fill out this form for booking a consultant advising
                        session.
                      </p>
                    </div>
                    <div className='input-wrap mt-5'>
                      <Input
                        label="Name"
                        name="name"
                        placeholder="Your Name"
                        isRequired={true}
                        onChange={(e) => handleCharInputChange(e, formik)}
                      />
                    </div>
                    <div className='input-wrap'>
                      <Input
                        label="Email Id"
                        name="email"
                        placeholder="Your Email Id"
                        isRequired={true}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className='input-wrap'>
                      <Input
                        label="Phone Number"
                        name="phone"
                        placeholder="Your Phone Number"
                        isRequired={true}
                        onChange={formik.handleChange}
                        type='tel'
                      />
                    </div>
                    <div className='input-wrap'>
                      <label className="form-label required ">Message</label>
                      <textarea
                        className="form-control"
                        rows="2"
                        name="message"
                        placeholder="Your Message"
                        onChange={formik?.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.message}
                      />
                      <ErrorMessage
                        name="message"
                        component="div"
                        className="text-danger"
                      />
                    </div>


                    <div className='login-btn_wrapper'>
                      <button className='m-btn m-btn-hero text-center flex items-center gap-3' type='submit'>
                        Submit Message <i className="m-icon btn-icon-arrow"></i>
                      </button>
                    </div>

                    {toast?.show && (
                      <Toster text={toast?.text} variation={toast?.variation} />
                    )}

                  </div>
                </div>
              </form>
            </FormikProvider>
          </section>
        </div>
      </section>
      <div className="mt-[-100px]">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30765272.724829197!2d61.03023525887231!3d19.732775180396626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!5e0!3m2!1sen!2sin!4v1710777342018!5m2!1sen!2sin" height="600" style={{ width: '100%' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </>
  );
};

export default ContactDetails;