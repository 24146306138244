import { Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { getApi, postApi } from "../../helper/helper";
import { Card } from "@mui/material";
import Breadcrumb from "../../component/common/Breadcrumb";
import { notify, notifyErr } from "../../component/common/notify";
import { ToastContainer } from "react-toastify";
import apiPath from "../../../apipath";
import Loader from "../../../components/Loader";

const Setting = () => {
  const [pending, setPending] = useState(true);
  const [logo, setLogo] = useState("");
  const [banner_image, setBanner_image] = useState('');
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    phone: "",
    address: "",
    api_key: "",
    client_id: "",
    client_secret: "",
    rzapi_key: "",
    rzapi_secret: "",
    enq_email: "",
    smtp_email: "",
    smtp_password: "",
    logo: "",
    banner_image: "",
    GST_course: "",
    GST_membership: "",
    fb_url: "",
    twtr_url: "",
    insta_url: "",
    adminemail_receiver: "",
  });

  const fetchData = async () => {
    setPending(true);
    try {
      const result = await getApi(`/api/${apiPath.getSettings}`);
      const curData = result[0];
      if (curData) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ["id"]: curData.id,
          ["name"]: curData.name,
          ["address"]: curData.address,
          ["description"]: curData.description,
          ["phone"]: curData.phone,
          ["api_key"]: curData.api_key,
          ["client_id"]: curData.client_id,
          ["client_secret"]: curData.client_secret,
          ["rzapi_key"]: curData.rzapi_key,
          ["rzapi_secret"]: curData.rzapi_secret,
          ["enq_email"]: curData.enq_email,
          ["GST_course"]: curData.GST_course,
          ["GST_membership"]: curData.GST_membership,
          ["smtp_email"]: curData.smtp_email,
          ["smtp_password"]: curData.smtp_password,
          ["fb_url"]: curData.fb_url,
          ["twtr_url"]: curData.twtr_url,
          ["insta_url"]: curData.insta_url,
          ["adminemail_receiver"]: curData.insta_url,

        }));
        setLogo(curData.logo);
        setBanner_image(curData.banner_image);
        reset({
          phone: curData.phone,
          name: curData.name,
          address: curData.address,
          api_key: curData.api_key,
          client_id: curData.client_id,
          client_secret: curData.client_secret,
          rzapi_key: curData.rzapi_key,
          rzapi_secret: curData.rzapi_secret,
          enq_email: curData.enq_email,
          smtp_email: curData.smtp_email,
          smtp_password: curData.smtp_password,
          description: curData.description,
          GST_course: curData.GST_course,
          GST_membership: curData.GST_membership,
          // banner_image: curData.banner_image,
        
          fb_url: curData.fb_url,
          twtr_url: curData.twtr_url,
          insta_url: curData.insta_url,
          adminemail_receiver: curData.adminemail_receiver,
        })
        // setFooterLogo(curData.footer_logo);
        // setFooterImage(curData.footer_image);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async () => {
    setPending(true);
    try {
      const result = await postApi(apiPath.updateSetting, formData);
      notify("Setting Updated Successfully!");
      fetchData();
    } catch (error) {
      console.error(error);
      notifyErr("Something went wrong!");
    } finally {
      setPending(false);
    }
  };

  const breadcrumbItems = [
    { title: 'Setting', link: null }
  ];

  return (
    <section>

      <ToastContainer />
      {pending && <Loader />}
      <div className="mb-5">
        <Breadcrumb items={breadcrumbItems} />
      </div>

      <Card variant="outlined" className="pb-3">
        <dvi className="m-3">
          <Form className="row m-0" onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
              <Form.Label>Name</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Name"
                value={formData.name}
                {...register("name", {
                  required: false,
                  onChange: (e) => handleInputChange("name", e.target.value),
                })}
              />

              {/* {errors.name && (
                <div className="errMsg text-danger">Name is Required</div>
              )} */}
            </Form.Group>
            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
              <Form.Label>Enquiry Email</Form.Label>
              <Form.Control
                className=""
                type="email"
                placeholder="Enquiry Email"
                value={formData.enq_email}
                {...register("enq_email", {
                  required: "Email is required",
                  maxLength: { value: 30, message: 'Email must be at most 30 characters long' },
                  pattern: { value: /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/, message: 'Invalid email address' },
                  onChange: (e) =>
                    handleInputChange("enq_email", e.target.value),
                })}
              />

              {errors.enq_email && (
                <div className="errMsg text-danger">{errors.enq_email.message}</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                className=""
                type="tel"
                placeholder="Phone"
                value={formData.phone}
                {...register("phone", {
                  required: 'Phone number is required',
                  pattern: {
                    value: /^[6789]\d{9}$/,
                    message: 'Invalid phone number'
                  },
                  onChange: (e) => handleInputChange("phone", e.target.value),
                })}
              />

              {errors.phone && (
                <div className="errMsg text-danger">{errors.phone.message}</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
              <Form.Label>Api Key</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Api Key"
                value={formData.api_key}
                {...register("api_key", {
                  required: true,
                  onChange: (e) => handleInputChange("api_key", e.target.value),
                })}
              />

              {errors.api_key && (
                <div className="errMsg text-danger">Api Key is Required</div>
              )}
            </Form.Group>

            {/* rzapi_key */}

            {/* <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
              <Form.Label>Api Key</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Api Key"
                value={formData.api_key1}
                {...register("api_key1", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("api_key1", e.target.value),
                })}
              />

              {errors.api_key1 && (
                <div className="errMsg text-danger">Api Key is Required</div>
              )}
            </Form.Group> */}

            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
              <Form.Label>Client Id</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Client Secret"
                value={formData.client_id}
                {...register("client_id", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("client_id", e.target.value),
                })}
              />

              {errors.client_id && (
                <div className="errMsg text-danger">
                  Client Id is Required
                </div>
              )}
            </Form.Group>

            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
              <Form.Label>Client Secret</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Client Secret"
                value={formData.client_secret}
                {...register("client_secret", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("client_secret", e.target.value),
                })}
              />

              {errors.client_secret && (
                <div className="errMsg text-danger">
                  Client Secret is Required
                </div>
              )}
            </Form.Group>


            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
              <Form.Label>Razor api key</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Client Secret"
                value={formData.rzapi_key}
                {...register("rzapi_key", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("rzapi_key", e.target.value),
                })}
              />

              {errors.rzapi_key && (
                <div className="errMsg text-danger">
                  Razor api key is Required
                </div>
              )}
            </Form.Group>

            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
              <Form.Label>Razor api Secret</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Client Secret"
                value={formData.rzapi_secret}
                {...register("rzapi_secret", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("rzapi_secret", e.target.value),
                })}
              />

              {errors.rzapi_secret && (
                <div className="errMsg text-danger">
                  Razor api Secret is Required
                </div>
              )}
            </Form.Group>

          
   
      

            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
              <Form.Label htmlFor="GST_course">GST of Course</Form.Label>
              <Form.Control
                type="number"
                id="GST_course"
                min="1"
                placeholder="GST course"
                value={formData.GST_course}
                {...register("GST_course", {
                  required: true,
                  onChange: (e) => handleInputChange("GST_course", e.target.value),
                })}
              />
              {errors.GST_course && (
                <div className="errMsg text-danger">Please Enter GST</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
              <Form.Label htmlFor="GST_membership">GST of Membership</Form.Label>
              <Form.Control
                type="number"
                id="GST_membership"
                min="1"
                placeholder="GST membership"
                value={formData.GST_membership}
                {...register("GST_membership", {
                  required: true,
                  onChange: (e) => handleInputChange("GST_membership", e.target.value),
                })}
              />
              {errors.GST_membership && (
                <div className="errMsg text-danger">Please Enter GST</div>
              )}
            </Form.Group>

            {/* <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
              <Form.Label>Code</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Code"
                value={formData.code}
                {...register("code", {
                  required: true,
                  onChange: (e) => handleInputChange("code", e.target.value),
                })}
              />

              {errors.code && (
                <div className="errMsg text-danger">Code is Required</div>
              )}
            </Form.Group> */}

            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
              <Form.Label>SMTP Email</Form.Label>
              <Form.Control
                className=""
                type="email"
                placeholder="SMTP Email"
                value={formData.smtp_email}
                {...register("smtp_email", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("smtp_email", e.target.value),
                })}
              />

              {errors.smtp_email && (
                <div className="errMsg text-danger">Email is Required</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
              <Form.Label>SMTP Password</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="SMTP Password"
                value={formData.smtp_password}
                {...register("smtp_password", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("smtp_password", e.target.value),
                })}
              />

              {errors.smtp_password && (
                <div className="errMsg text-danger">Password is Required</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
              <Form.Label>Facebook URL</Form.Label>
              <Form.Control
                className=""
                type="url"
                placeholder="Facebook URL"
                value={formData.fb_url}
                {...register("fb_url", {
                  required: true,
                  onChange: (e) => handleInputChange("fb_url", e.target.value),
                })}
              />

              {errors.fb_url && (
                <div className="errMsg text-danger">URL is Required</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
              <Form.Label>Twitter URL</Form.Label>
              <Form.Control
                className=""
                type="url"
                placeholder="Twitter URL"
                value={formData.twtr_url}
                {...register("twtr_url", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("twtr_url", e.target.value),
                })}
              />

              {errors.twtr_url && (
                <div className="errMsg text-danger">URL is Required</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
              <Form.Label>Instagram URL</Form.Label>
              <Form.Control
                className=""
                type="url"
                placeholder="Instagram URL"
                value={formData.insta_url}
                {...register("insta_url", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("insta_url", e.target.value),
                })}
              />

              {errors.insta_url && (
                <div className="errMsg text-danger">URL is Required</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
              <Form.Label>Admin Emails</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="admin@gmail.com, admin1@gmail.com"
                value={formData.adminemail_receiver}
                {...register("adminemail_receiver", {
                  required: false,
                  onChange: (e) =>
                    handleInputChange("adminemail_receiver", e.target.value),
                })}
              />

              {errors.adminemail_receiver && (
                <div className="errMsg text-danger">Admin Emails is Required</div>
              )}
            </Form.Group>
            {/* <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12"></Form.Group> */}

            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
              <Form.Label>Address</Form.Label>
              <Form.Control
                className=""
                rows="5"
                as="textarea"
                placeholder="address"
                value={formData.address}
                {...register("address", {
                  required: false,
                  minLength: { value: 10, message: 'Address must be at least 10 characters.' },
                  onChange: (e) => handleInputChange("address", e.target.value),
                })}
              />
            </Form.Group>

            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
              <Form.Label>Description</Form.Label>
              <Form.Control
                className=""
                rows="5"
                as="textarea"
                placeholder="description"
                value={formData.description}
                {...register("description", {
                  required: false,
                  onChange: (e) => handleInputChange("description", e.target.value),
                })}
              />
            </Form.Group>


            <Form.Group className="mt-2">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <Form.Label>Logo</Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    {...register("logo", {
                      required: false,
                      onChange: (e) =>
                        handleInputChange("logo", e.target.files[0]),
                    })}
                  />

                  {errors.logo && (
                    <div className="errMsg text-danger">
                      Please Select Image
                    </div>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center mb-3 mt-3">
                  <Form.Label>Image Preview</Form.Label>
                  <div className="p-3">
                    <img
                      src={
                        formData?.logo == ""
                          ? logo != ""
                            ? `/api/assets/logo/${logo}`
                            : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/330px-No-Image-Placeholder.svg.png?20200912122019"
                          : URL.createObjectURL(formData?.logo)
                      }
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        margin: "auto",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Form.Group>

            <Form.Group className="mt-2">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <Form.Label>Common Banner</Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    {...register("banner_image", {
                      required: false,
                      onChange: (e) =>
                        handleInputChange("banner_image", e.target.files[0]),
                    })}
                  />

                  {errors.banner_image && (
                    <div className="errMsg text-danger">
                      Please Select Image
                    </div>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center mb-3 mt-3">
                  <Form.Label>Image Preview</Form.Label>
                  <div className="p-3">
                    <img
                      src={
                        formData?.banner_image == ""
                          ? banner_image != ""
                            ? `/api/assets/logo/${banner_image}`
                            : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/330px-No-Image-Placeholder.svg.png?20200912122019"
                          : URL.createObjectURL(formData?.banner_image)
                      }
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        margin: "auto",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Form.Group>

            {/* <Form.Group className="mt-2">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <Form.Label>Footer Image</Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    {...register("footer_image", {
                      required: true,
                      onChange: (e) =>
                        handleInputChange("footer_image", e.target.files[0]),
                    })}
                  />

                  {errors.footer_image && (
                    <div className="errMsg text-danger">
                      Please Select Image
                    </div>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center mb-3 mt-3">
                  <Form.Label>Image Preview</Form.Label>
                  <div className="p-3">
                    <img
                      src={
                        formData?.footer_image == ""
                          ? footerImage != ""
                            ? `/api/assets/logo/${footerImage}`
                            : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/330px-No-Image-Placeholder.svg.png?20200912122019"
                          : URL.createObjectURL(formData?.footer_image)
                      }
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        margin: "auto",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Form.Group> */}

            <div className="btn-d d-flex justify-content-center">
              <Button
                type="submit"
                className="text-white border-0 default-btn btn btn-primary"
              >
                Update
              </Button>
            </div>
          </Form>
        </dvi>
      </Card>
    </section>
  );
};

export default Setting;