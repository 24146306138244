import axios from "axios";
import { parseCookies } from "nookies";


// let authenticated = localStorage.getItem("auth");

const { adminToken } = parseCookies();

export const Axios = axios.create({
  //  baseURL: import.meta.env.VITE_APP_API_URL,
  headers: {
    token: "1fbf405e5f4af57b726f0c00b762d95a62c4930c",
    'Authorization': `Bearer ${adminToken}`,
    // 'Content-Type': 'application/json',
  },
});

// if (process.env.REACT_APP_ENV === "development")
//   axios.defaults.baseURL = process.env.REACT_APP_SERVER_DOMAIN;
// else {
//   axios.defaults.baseURL = process.env.REACT_APP_PROD_SERVER_DOMAIN;
// }

// admin login
// export const login = async (endpoint, credentials) => {
//   try {
//     const { data, status } = await Axios.post(endpoint, credentials, {
//       method: "POST",
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     });
//     if (status === 200) {
//       return Promise.resolve(data.data);
//     }
//   } catch (error) {
//     return Promise.reject(error);
//   }
// };

// // forgot password
// export const forgotPassword = async (endpoint, email) => {
//   try {
//     const { data, status } = await Axios.post(
//       endpoint,
//       { email: email },
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       }
//     );
//     if (status === 200) {
//       return Promise.resolve(data.data);
//     }
//   } catch (error) {
//     return Promise.reject(error);
//   }
// };

// update password
export const updatePassword = async (password) => {
  try {
    const response = await Axios.post("/", password, {
      method: "POST",
    });

    if (response.status === 200) {
    }
  } catch (error) {
    console.error(error);
  }
};

// get data
export const getApi = async (endpoint) => {
  try {
    const { data, status } = await Axios.get(endpoint, {
      method: "GET",
    });
    if (status === 200) {
      return Promise.resolve(data.data);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

// add data
export const postApi = async (endpoint, formData) => {
  try {
    const { data, status } = await Axios.post(endpoint, formData, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (status === 200) {
      return Promise.resolve(data.data);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

// export const getApi = async (endpoint) => {
//   try {
//     const { data, status } = await Axios.get(endpoint, {
//       method: "GET",
//     });

//     if (status === 200) {
//       return Promise.resolve(data.data);
//     }
//   } catch (error) {
//     return Promise.reject(error);
//   }
// };

// update data
// export const postApi = async (endpoint, formData) => {
//   try {
//     const { data, status } = await Axios.post(endpoint, formData, {
//       method: "POST",
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     });

//     if (status === 200) {
//       return Promise.resolve(data.data);
//     }
//   } catch (error) {
//     return Promise.reject(error);
//   }
// };

// get data

// export const hashString = async () => {
//   const crypto = window.crypto.subtle || window.crypto.webkitSubtle;

//   const buffer = await crypto.digest(
//     "SHA-256",
//     new TextEncoder().encode(input)
//   );
//   const hashArray = Array.from(new Uint8Array(buffer));
//   return hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");
// };
