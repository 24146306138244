import React, {
    useCallback,
    useMemo,
    useRef,
    useState,
    StrictMode,
    useEffect,
} from 'react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';


import { AgGridReact } from 'ag-grid-react';




const generateNumberColumn = (field) => ({
    field,
    type: 'numberColumn',
    filter: 'agNumberColumnFilter',
    filterParams: {
        filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
        suppressAndOrCondition: true,
    },
    valueGetter: params => parseFloat(params.data[field]?.replace(',', '')),
    valueFormatter: params => params.value ? params.value.toFixed(2) : '',
});

const generateDateColumn = (field) => ({
    field,
    type: 'dateColumn',
    width: 220,
    valueFormatter: (params) => params.value ? new Date(params.value).toLocaleDateString('en-GB') : '',
    filter: 'agDateColumnFilter',
    filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            const cellDate = new Date(cellValue);
            if (cellDate.setHours(0, 0, 0, 0) === filterLocalDateAtMidnight.getTime()) {
                return 0;
            } else if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else {
                return 1;
            }
        }
    },
});

const generateTextColumn = (field) => ({
    field,
    type: 'textColumn',
    filter: 'agTextColumnFilter',
    filterParams: {
        filterOptions: ['contains', 'notContains', 'equals', 'notEqual', 'startsWith', 'endsWith'],
        caseSensitive: false,
        suppressAndOrCondition: true,
    },
});

const generateBooleanColumn = (field) => ({
    field,
    headerName: field,
    filter: 'agSetColumnFilter', // Use agSetColumnFilter for boolean values
    filterParams: {
        values: ['Yes', 'No'],
        caseSensitive: false,
    },
});


const AgGrid = ({ data }) => {
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [rowData, setRowData] = useState();

    // if (!data || data.length === 0 || !data[0]) {
    //     return <div>No data available</div>;
    // }

    // const dynamicKeys = Object.keys(data[0]);

    // // Move the useMemo hook to the top level of the component
    // const columnDefs = useMemo(() => {
    //     const numberColumns = dynamicKeys.map(key => generateNumberColumn(key));
    //     const dateColumns = ['date'].map(key => generateDateColumn(key));
    //     const textColumns = dynamicKeys.map(key => generateTextColumn(key));

    //     return [...numberColumns, ...dateColumns, ...textColumns];
    // }, [data, dynamicKeys]);

    const [columnDefs, setColumnDefs] = useState([]);

    // useEffect(() => {
    //     // Check if data is not empty and the first element is defined
    //     if (!data || data.length === 0 || !data[0]) {
    //         return;
    //     }

    //     const dynamicKeys = Object.keys(data[0]);

    //     //   const numberColumns = dynamicKeys.map(key => generateNumberColumn(key));

    //     const textColumns = dynamicKeys.map(key => generateTextColumn(key));
    //     const dateColumns = ['date'].map(key => generateDateColumn(key));

    //     const allColumns = [...textColumns, ...dateColumns];

    //     setColumnDefs(allColumns);
    // }, [data]);

    useEffect(() => {
        // Check if data is not empty and the first element is defined
        if (!data || data.length === 0 || !data[0]) {
            return;
        }

        const dynamicKeys = Object.keys(data[0]);

        // Define columns to hide
        const columnsToHide = ['id', 'epoch', 'status', 'created_at', 'updated_at'];

        // const numberColumns = dynamicKeys.map(key => generateNumberColumn(key));

        const textColumns = dynamicKeys.filter(key => key !== 'date' && key !== 'tstdr' && key !== 'tmsfr' && key !== 'tgrfp' && key !== 'tgrfc').map(key => generateTextColumn(key));
        const booleanColumns = ['tstdr', 'tmsfr', 'tgrfp', 'tgrfc'].map(key => generateBooleanColumn(key));
        const dateColumns = ['date'].map(key => generateDateColumn(key));

        const allColumns = [...textColumns, ...booleanColumns, ...dateColumns];

        // Set hide property for columns to hide
        const updatedColumns = allColumns.map(column => ({
            ...column,
            hide: columnsToHide.includes(column.field),
        }));

        setColumnDefs(updatedColumns);
    }, [data]);

    // const [columnDefs, setColumnDefs] = useState([
    //     // using default ColDef
    //     { field: 'exchange' },
    //     { field: 'instrument' },
    //     // using number column type
    //     {
    //         field: 'high', type: 'numberColumn',
    //         filter: 'agNumberColumnFilter',
    //         filterParams: {
    //             // Specify additional filter parameters if needed
    //             filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
    //             suppressAndOrCondition: true, // Set to true if you want to suppress AND/OR conditions
    //         },
    //         valueGetter: params => parseFloat(params.data?.high?.replace(',', '')), // Convert string to number for proper filtering
    //         valueFormatter: params => params.value ? params.value.toFixed(2) : '', // Format number as needed for display
    //     },
    //     {
    //         field: 'low', type: 'numberColumn',
    //         filter: 'agNumberColumnFilter',
    //         filterParams: {
    //             // Specify additional filter parameters if needed
    //             filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
    //             suppressAndOrCondition: true, // Set to true if you want to suppress AND/OR conditions
    //         },
    //         valueGetter: params => parseFloat(params.data?.low?.replace(',', '')), // Convert string to number for proper filtering
    //         valueFormatter: params => params.value ? params.value.toFixed(2) : '', // Format number as needed for display
    //     },
    //     {
    //         field: 'open', type: 'numberColumn',
    //         filter: 'agNumberColumnFilter',
    //         filterParams: {
    //             // Specify additional filter parameters if needed
    //             filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
    //             suppressAndOrCondition: true, // Set to true if you want to suppress AND/OR conditions
    //         },
    //         valueGetter: params => parseFloat(params.data?.open?.replace(',', '')), // Convert string to number for proper filtering
    //         valueFormatter: params => params.value ? params.value.toFixed(2) : '', // Format number as needed for display
    //     },
    //     {
    //         field: 'close', type: 'numberColumn',
    //         filter: 'agNumberColumnFilter',
    //         filterParams: {
    //             // Specify additional filter parameters if needed
    //             filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
    //             suppressAndOrCondition: true, // Set to true if you want to suppress AND/OR conditions
    //         },
    //         valueGetter: params => parseFloat(params.data.close?.replace(',', '')), // Convert string to number for proper filtering
    //         valueFormatter: params => params.value ? params.value.toFixed(2) : '', // Format number as needed for display
    //     },
    //     // using date and non-editable column types
    //     {
    //         field: 'date', type: 'dateColumn', width: 220,
    //         valueFormatter: (params) => {
    //             // Format date for display
    //             return params.value ? new Date(params.value).toLocaleDateString('en-GB') : '';
    //         },
    //         filter: 'agDateColumnFilter', // Assuming you are using the built-in date filter
    //         filterParams: {
    //             comparator: (filterLocalDateAtMidnight, cellValue) => {
    //                 // Convert cell value (format 'yyyy-mm-dd') to a JavaScript Date object
    //                 const cellDate = new Date(cellValue);

    //                 // Compare dates, ignoring time
    //                 if (cellDate.setHours(0, 0, 0, 0) === filterLocalDateAtMidnight.getTime()) {
    //                     return 0; // Dates are equal
    //                 } else if (cellDate < filterLocalDateAtMidnight) {
    //                     return -1; // Cell date is before filter date
    //                 } else {
    //                     return 1; // Cell date is after filter date
    //                 }
    //             }
    //         }
    //     },
    //     // {
    //     //     headerName: 'Medals',
    //     //     groupId: 'medalsGroup',
    //     //     children: [
    //     //         // using medal column type
    //     //         { headerName: 'Gold', field: 'gold', type: 'medalColumn' },
    //     //         { headerName: 'Silver', field: 'silver', type: 'medalColumn' },
    //     //         { headerName: 'Bronze', field: 'bronze', type: 'medalColumn' },
    //     //         {
    //     //             headerName: 'Total',
    //     //             field: 'total',
    //     //             type: 'medalColumn',
    //     //             columnGroupShow: 'closed',
    //     //         },
    //     //     ],
    //     // },
    // ]);
    const defaultColDef = useMemo(() => {
        return {
            // set the default column width
            width: 150,
            // make every column editable
            editable: false,
            // make every column use 'text' filter by default
            filter: 'agTextColumnFilter',
            // enable floating filters by default
            floatingFilter: true,
            // disable cell data types
            cellDataType: false,
        };
    }, []);
    const defaultColGroupDef = useMemo(() => {
        return {
            marryChildren: true,
        };
    }, []);
    const columnTypes = useMemo(() => {
        return {
            numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
            medalColumn: { width: 100, columnGroupShow: 'open', filter: false },
            nonEditableColumn: { editable: false },
            dateColumn: {
                // specify we want to use the date filter
                filter: 'agDateColumnFilter',
                // add extra parameters for the date filter
                filterParams: {
                    // provide comparator function
                    comparator: (filterLocalDateAtMidnight, cellValue) => {
                        // In the example application, dates are stored as dd/mm/yyyy
                        // We create a Date object for comparison against the filter date
                        const dateParts = cellValue.split('/');
                        const day = Number(dateParts[0]);
                        const month = Number(dateParts[1]) - 1;
                        const year = Number(dateParts[2]);
                        const cellDate = new Date(year, month, day);
                        // Now that both parameters are Date objects, we can compare
                        if (cellDate < filterLocalDateAtMidnight) {
                            return -1;
                        } else if (cellDate > filterLocalDateAtMidnight) {
                            return 1;
                        } else {
                            return 0;
                        }
                    },
                },
            },
        };
    }, []);

    useEffect(() => {
        setRowData(data);
    }, [data])


    const onGridReady = useCallback((params) => {
        // fetch('https://www.ag-grid.com/example-assets/olympic-winners.json')
        //     .then((resp) => resp.json())
        //     .then((data) => 
        setRowData(data);
    }, [data]);

    if (rowData?.length > 0) {
        return (
            <div style={containerStyle}>
                <div style={{ height: '300px', boxSizing: 'border-box' }}>
                    <div
                        style={gridStyle}
                        className={
                            "ag-theme-quartz"
                        }
                    >
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            defaultColGroupDef={defaultColGroupDef}
                            columnTypes={columnTypes}
                            onGridReady={onGridReady}
                            rowSelection='multiple'
                            animateRows='true'
                        />
                    </div>
                </div>
            </div>
        )
    }

}

export default AgGrid