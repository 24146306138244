import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import { Link, useSearchParams } from 'react-router-dom'
import bradcom from '../icons/bradcome-arrow.svg'
import CourseCard from '../components/Course/CourseCard'
import { fetchDataFromApi } from '../libs/AllGlobalFunctions'
import apiPath from '../apipath'
import Loader from '../components/Loader'
import { difficultyOptions } from '../libs/AllGlobalFunctions'
import Select from 'react-select';
import { useImmer } from 'use-immer'
import { useSelector } from 'react-redux'
import useDebounce from '../hooks/useDebounce';
import { customStyles } from '../components/customStyles';
import NotFound from './NotFound';
import { setPageIndexOne } from '../libs/AllGlobalFunctions';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { handlePageClick } from '../libs/AllGlobalFunctions';
import { _banner_ } from '../libs/ImagePath'

export const CourseItemSkeleton = () => {
  return (
    <div className='h-pc-items-list'>
      <div className='h-pc-item-img'>
        <Skeleton height={200} width={300} />
      </div>
      <div className='h-pc-item-details'>
        <span className='h-pc-brand-name'>
          <Skeleton height={20} width={100} />
        </span>
        <h3 className='h-pc-item-title'>
          <Skeleton height={30} width={250} />
        </h3>
        <div className='h-pc-raiting'>
          <Skeleton height={20} width={100} />
          <p className='h-pc-raitting-name'>
            <Skeleton height={20} width={150} />
          </p>
        </div>
        <h4 className='h-pc-price'>
          <Skeleton height={20} width={100} />
        </h4>
        <div className='h-pc-lesson-wrap'>
          <p className='h-pc-lesson-item'>
            <Skeleton height={20} width={20} />
            <Skeleton height={20} width={150} />
          </p>
          <p className='h-pc-lesson-item'>
            <Skeleton height={20} width={20} />
            <Skeleton height={20} width={150} />
          </p>
        </div>
      </div>
    </div>
  );
}

export default function CourseCatalog() {

  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [courseData, setCourseData] = useState([]);
  const [banner, setBanner] = useState({})
  const categoryList = useSelector((state) => state?.basic?.categories);

  const banners = useSelector((state) => state?.banner?.banners);

  const [searchQuery, setSearchQuery] = useState("");
  let [filterData, setFilterData] = useImmer({
    difficulty_level: '',
    category_id: '',
    search: '',
    page: 1,
    per_page: 12
  });

  const debounceSearchQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    if (debounceSearchQuery) {
      setPageIndexOne(debounceSearchQuery, setFilterData, "search");
    } else {
      setPageIndexOne("", setFilterData, "search");
    }
  }, [debounceSearchQuery]);

  useEffect(() => {

    window.scrollTo(0, 0);
    if (searchParams?.get("category")) {
      let categoryId = searchParams?.get("category");
      if (categoryId) {
        fetchCourses(categoryId);
      }
    } else {
      fetchCourses();
    }

  }, [filterData, searchParams]);

  useEffect(() => {
    if (banners) {
      let [bannerData] = banners?.filter(i => i.page_id == 2);
      setBanner(bannerData);
    }
  }, [banners])


  const fetchCourses = (id) => {

    if (id) {
      filterData = { ...filterData, category_id: id };
    }

    setLoading(true);
    fetchDataFromApi(apiPath.getCourses, null, 'GET', filterData).then((data) => {
      if (data) {
        setCourseData(data)
      } else {
        setCourseData([]);
      }
      setLoading(false);
    }

    ).catch((error) => {
      console.log(error);
      setLoading(false);
    })

  }

  return (
    <>
      {/* {loading && <Loader />} */}
      <div className='pc-details-banner' style={{ backgroundImage: `url('${_banner_}/${banner?.banner_image}')` }}>
        <h2 className='pc-details-title'>Archives: Courses</h2>
        <div className='p-couse-bradcom-wrapper'>
          <Link to={'/'} className='p-bradcom-item'>
            Home
          </Link>
          <img src={bradcom} alt='' />
          <Link to={'/course'} className='p-bradcom-item'>
            Course
          </Link>
        </div>
      </div>

      <section className='section_spacing section_spacing-space'>
        <div className='site-container'>
          <div className='pc-filter-section mt-4'>
            <div className='pc-search'>
              <input
                name="title"
                type='text'
                className='pc-search-input'
                placeholder='Search Courses...'
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
              <i className='m-icon icon-search'></i>
            </div>
            <div className='pc-search'>
              <Select
                id="Difficulty_level"
                menuPortalTarget={document.body}
                classNames={{
                  control: (state) => 'pc-search-select',
                }}
                isClearable
                placeholder="Search By Difficulty Level"
                options={difficultyOptions}
                onChange={(e) => {
                  if (typeof e?.value == 'number') {
                    setFilterData((draft) => {
                      draft.difficulty_level = e?.value;
                      draft.page = 1;
                    });
                  } else {
                    setFilterData((draft) => {
                      draft.difficulty_level = "";
                    });
                  }
                }}
              />
            </div>
            <div className='pc-search'>
              <Select
                id="Category"
                menuPortalTarget={document.body}
                classNames={{
                  control: (state) => 'pc-search-select',
                }}
                isClearable
                placeholder="Search By Category"
                options={categoryList?.map((item) => {
                  return (
                    { value: item?.id, label: item?.name }
                  )
                })}
                onChange={(e) => {
                  if (e?.value) {
                    setFilterData((draft) => {
                      draft.category_id = e?.value;
                      draft.page = 1;
                    });
                    setSearchParams({ category_id: e?.value });
                  } else {
                    setFilterData((draft) => {
                      draft.category_id = "";
                    });
                    setSearchParams({});
                  }
                }}
              />
            </div>
          </div>
          <h2 className='pc-filter-title mt-4'>
            Showing <span> {(filterData?.page - 1) * filterData?.per_page + 1} - {filterData?.page * filterData?.per_page >  courseData?.data?.pagination?.total_data ? courseData?.data?.pagination?.total_data : filterData?.page * filterData?.per_page} </span> Of <span> {courseData?.data?.pagination?.total_data} </span> Results
          </h2>
          <div className={courseData?.data?.data?.length > 0 ? 'pc-courses-list-wrapper' : ''}>
            {loading ? (
              Array.from({ length: 3 }).map((_, index) => (
                <CourseItemSkeleton key={index} />
              ))
            ) : courseData?.data?.data?.length > 0 ? (
              courseData?.data?.data?.map((course) => (
                <CourseCard key={course.id} course={course} />
              ))
            ) : (
              !loading && <div className="empty-cart items-center" ><NotFound title={"Courses Are Not Found"} /></div>
            )}
          </div>
          {
            courseData?.data?.data?.length > 0 && <div className='p-pagenation'>
              <ReactPaginate
                previousLabel={
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height='24'
                    viewBox='0 -960 960 960'
                    width='24'
                  >
                    <path d='M360-200 80-480l280-280 56 56-183 184h647v80H233l184 184-57 56Z' />
                  </svg>
                }
                nextLabel={
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height='24'
                    viewBox='0 -960 960 960'
                    width='24'
                  >
                    <path d='m600-200-57-56 184-184H80v-80h647L544-704l56-56 280 280-280 280Z' />
                  </svg>
                }
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={courseData?.data?.pagination?.page}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(event) => handlePageClick(event, setFilterData)}
                containerClassName={'pagination'}
                activeClassName={'active'}
                forcePage={filterData?.page - 1}
              />
            </div>
          }
        </div>
      </section>
    </>
  )
}
