import React, { useState, useEffect } from "react";
import Breadcrumb from "../../component/common/Breadcrumb";
import DataTables from "../../datatable/DataTable";
import { FaEdit, FaTrash, FaEye } from "react-icons/fa";
import { getApi, postApi } from "../../helper/helper";
import { Button, Form, Modal } from "react-bootstrap";
import { Card } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  alert,
  alertErr,
  alertInfo,
  alertSuccess,
  notify,
  notifyErr,
} from "../../component/common/notify";
import {
  confirmDelete,
  confirmStatus,
} from "../../component/common/handleData";
import { Link, useParams } from "react-router-dom";
import Editor from "../../editor/Editor";
import apiPath from "../../../apipath";
import Loader from "../../../components/Loader";

const Portfolio = () => {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
  const [course, setCourse] = useState([]);
  const [formData, setFormData] = useState({});
  const [status, setStatus] = useState('0');
  const [formDataUpdate, setFormDataUpdate] = useState({});
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [code, setCode] = useState('');
  const [updateCode, setUpdateCode] = useState('');

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setFormData({});
    reset({
      title: "",
      description: "",
      short_description: "",
      category_type: "",
      research_type: "",
    });
  };

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleShowUpdate = (row) => {
    setShowUpdate(true);
    getIndividualData(row.id);
  };

  const handleCloseUpdate = () => {
    setShowUpdate(false);
    setFormDataUpdate({});
    reset1({
      title: "",
      description: "",
      short_description: "",
      category_type: "",
      research_type: "",
    });
  };

  const handleInputChange1 = (field, value) => {
    setFormDataUpdate({ ...formDataUpdate, [field]: value });
  };

  const fetchTableData = async () => {
    setPending(true);
    try {
      const result = await getApi(`/api/${apiPath.getPortfolio}/${status}`);
      setData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    fetchTableData();
  }, [status]);

  const handleStatusFilter = (value) => {
    setStatus(value);
  }

  const columns = [
    {
      name: <th className="fs-6 ">#</th>,
      cell: (row, index) => index + 1,
      width: "100px",
    },

    {
      name: <th className="fs-6 ">Title</th>,
      selector: (row) => row.title,
      width: "200px",
    },
    {
      name: <th className="fs-6">Description</th>,
      selector: (row) => row.description,
      width: "300px",
    },
    // {
    //   name: <th className="fs-6">Portfolio Type</th>,
    //   selector: (row) => row.research_type,
    //   width: "300px",
    // },
    // {
    //   name: <th className="fs-6">Category Type</th>,
    //   selector: (row) => row.category_type,
    //   width: "200px",
    // },
    {
      name: <th className="fs-6">Status</th>,
      cell: (row, index) =>
        row.status == 0 ? (
          <span
            className="bg-success text-white p-1 rounded cursor-pointer"
            onClick={() =>
              handleStatus("/api/updateResearchStatus", row.id, "1")
            }
          >
            Active
          </span>
        ) : row.status == 1 ? (
          <span
            className=" bg-warning text-white p-1 rounded cursor-pointer"
            onClick={() =>
              handleStatus("/api/updateResearchStatus", row.id, "0")
            }
          >
            In-Active
          </span>
        ) : (
          "-"
        ),
      width: "220px",
    },
    {
      name: <th className="fs-6 action">Action</th>,
      selector: (row) => (
        <div className="d-flex" style={{ gap: "10px" }}>
          {/* <Link to={`/admin/Portfolio/${row.id}`}>
            <Button
              className="text-white border-0 btn-view"
              style={{
                float: "right",
              }}
            >
              <FaEye />
            </Button>
          </Link>  */}

          <Link to={`/admin/portfolio/update-portfolio/${row.id}`}>
            <Button
              className="text-white border-0"
              style={{ float: "right", backgroundColor: "var(--primary)" }}
              onClick={() => handleShowUpdate(row)}
            >
              <FaEdit />
            </Button>
          </Link>

          <Button
            className="bg-danger text-white border-0"
            onClick={() => handleDelete(`${apiPath.deletePortfolio}/${row.id}`)}
          >
            <FaTrash />
          </Button>
        </div>
      ),
      right: true,
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: errors },
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm();

  const onSubmit = async () => {
    setPending(true);
    try {
      const result = await postApi(apiPath.addPortfolio, {
        ...formData,
        user_id: 1,
      });
      notify("Portfolio Added Successfully!");
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  const getIndividualData = async (id) => {
    setPending(true);
    try {
      const result = await getApi(`${apiPath.getSinglePortfolio}/${id}`);
      const data = result[0];
      if (data) {
        setFormDataUpdate((prevFormDataUpdate) => ({
          ...prevFormDataUpdate,
          ["id"]: data.id,
          ["title"]: data.title,
          ["description"]: data.description,
          ["short_description"]: data.short_description,
          ["category_type"]: data.category_type,
          ["research_type"]: data.research_type,
        }));
        reset1({
          title: data.title,
          description: data.description,
          short_description: data.short_description,
          category_type: data.category_type,
          research_type: data.research_type,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  const onUpdate = async () => {
    setPending(true);
    try {
      const result = await postApi(apiPath.updatePortfolio, {
        ...formDataUpdate,
      });

      handleCloseUpdate();
      notify("Portfolio Updated Successfully!");
      fetchTableData();

    } catch (error) {
      console.error(error);
      notifyErr("Something went wrong!");
    } finally {
      setPending(false);
    }
  };

  // handleDelete 
  const handleDelete = (endPoint) => {
    confirmDelete(endPoint)
      .then((data) => {
        fetchTableData();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // handleStatus 
  const handleStatus = (endpoint, id, status) => {
       confirmStatus(endpoint, { id: id, status: status})
      .then((message) => {
        fetchTableData();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const breadcrumbItems = [
    { title: 'Portfolio', link: null }
  ];

  return (
    <section>
      <ToastContainer />
      {pending && <Loader />}
      <div className="mb-5">
        <Breadcrumb items={breadcrumbItems} />
      </div>

      <Card variant="outlined" className="pb-3">
        <div className="btn-d flex flex-col sm:flex-row row-gap-3 justify-content-between m-3">
          <Form.Select value={status} className="filter-select  w-auto"
            onChange={(e) => handleStatusFilter(e.target.value)}
          >
            <option value="0">Active</option>
            <option value="1">In-Active</option>
            <option value="2">All</option>
          </Form.Select>
          <Link to={'/admin/portfolio/add-Portfolio'}>
            <Button
              className="text-white border-0 default-btn"
            // onClick={() => handleShow()}
            >
              New Portfolio
            </Button>
          </Link>
        </div>
        <div>
          <DataTables
            title={"Portfolio"}
            data={data}
            columns={columns}
            pending={pending}
            paginationServer={false}
            // totalRows={data?.length}
          />
        </div>
      </Card>

      {/* add */}
      {/* <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>
       
        <Modal.Header closeButton>
          <Modal.Title>Add Portfolio</Modal.Title>
        </Modal.Header>
        <Form
          className="row m-2"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="research_type">Portfolio Type</Form.Label>
              <Form.Select
                type="type"
                id="Portfolio_type"
                placeholder="Portfolio Type"
                value={formData.research_type}
                {...register("research_type", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("research_type", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                <option value="1">Articles</option>
                <option value="2">Case Studies</option>
                <option value="3">Market Report</option>
              </Form.Select>
              {errors.research_type && (
                <div className="errMsg text-danger">Please Select Type</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="category_type">Category Type</Form.Label>
              <Form.Select
                type="type"
                id="category_type"
                placeholder="course"
                value={formData.category_type}
                {...register("category_type", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("category_type", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                <option value="0">Trends</option>
                <option value="1">Techniques</option>
              </Form.Select>
              {errors.category_type && (
                <div className="errMsg text-danger">Please Select Type</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="title">Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                id="title"
                value={formData.title}
                {...register("title", {
                  required: true,
                  onChange: (e) => handleInputChange("title", e.target.value),
                })}
              />
              {errors.title && (
                <div className="errMsg text-danger">Please Enter Title</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="description">Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                id="description"
                placeholder="description"
                value={formData.description}
                {...register("description", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("description", e.target.value),
                })}
              />
              {errors.description && (
                <div className="errMsg text-danger">
                  Please Enter Description
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="content">Short Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                id="short_description"
                placeholder="Short Description"
                value={formData.short_description}
                {...register("short_description", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("short_description", e.target.value),
                })}
              />
              {errors.short_description && (
                <div className="errMsg text-danger">
                  Please Enter Short Description
                </div>
              )}
            </Form.Group>

            <Editor code={code} setCode={setCode} />

          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="contained"
              className="text-white btn-primary"
            >
              Add
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal> */}

      {/* update */}
      <Modal
        show={showUpdate}
        onHide={handleCloseUpdate}
        style={{ zIndex: "9999" }}
      >

        <Modal.Header closeButton>
          <Modal.Title>Update Portfolio</Modal.Title>
        </Modal.Header>
        <Form
          className="row m-2"
          onSubmit={handleSubmit1(onUpdate)}
          autoComplete="off"
        >
          <Modal.Body>


            <Form.Group className="mb-3">
              <Form.Label htmlFor="title">Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                id="title"
                value={formDataUpdate.title}
                {...register1("title", {
                  required: true,
                  onChange: (e) => handleInputChange1("title", e.target.value),
                })}
              />
              {errors1.title && (
                <div className="errMsg text-danger">Please Enter Title</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={5} placeholder="description" id="description" value={formDataUpdate.description}
                {...register("description", {
                  required: true,
                  onChange: (e) => handleInputChange1("description", e.target.value)
                })}
              />

              {errors.description && (
                <div className='errMsg text-danger'>description is required</div>
              )}
            </Form.Group>

            {/* <Editor code={updateCode} setCode={setUpdateCode} /> */}

          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="contained"
              className="text-white btn-primary"

            >
              Update
            </Button>
            <Button variant="secondary" onClick={handleCloseUpdate}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </section>
  );
};

export default Portfolio;
