import { Link } from "react-router-dom";
import apiPath from '../apipath';
import { getNoAuthToken } from '../libs/AllGlobalFunctions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Toster from '../components/Toster';
import useToast from '../hooks/useToast';
import { useState, useEffect } from "react";
import Loader from '../components/Loader';
import { _courseImg_ } from "../libs/ImagePath";
import { fetchCart } from "../redux/features/cartSlice";
import useAuthenticated from "../hooks/useAuthenticated";
import Coupon from "../components/Coupon";
import { calculateDiscountAmount } from "../components/calculateDescountAmount";
import { setAppliedCoupon, setCouponCode, setIsApplied } from "../redux/features/couponSlice";

const Cart = () => {

    const { userInfo, accessToken } = useSelector((state) => state?.auth);
    const [loading, setLoading] = useState(false);
    const isAuth = useAuthenticated();
    const cartItems = useSelector((state) => state?.cart?.cart);
    const { appliedCoupon } = useSelector((state) => state?.coupon);
    const { toast, showToast } = useToast();
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const removeCoupon = () => {
        dispatch(setCouponCode(''));
        dispatch(setAppliedCoupon(null));
        dispatch(setIsApplied(false));
    }

    const handleDeleteItem = async (id) => {
        setLoading(true);
        try {
            const response = await axios.delete(`${apiPath.deleteCart}/${id}/${userInfo?.id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'token': `${getNoAuthToken()}`
                }
            })

            if (response) {
                showToast(response?.data?.message, "success", 3000);
                if (userInfo && userInfo?.id && accessToken) {
                    dispatch(fetchCart({ userId: userInfo?.id, accessToken }));
                }

            } else {
                showToast(response?.data?.message, "error", 3000);
            }
            removeCoupon();
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false)
            removeCoupon();
            showToast("Something went wrong !", "error", 3000);
        }
    }

    useEffect(() => {
        if ((userInfo && userInfo?.id && isAuth) && (cartItems?.data?.length > 1)) {
            removeCoupon();
        }
    }, [cartItems])

    return (
        <>
            {loading && <Loader />}

            <section className="section_spacing section_spacing-space">
                <div className="site-container">
                    <Link to={"/course"} className="back-page flex w-fit gap-2">
                        <i className="m-icon icon_back"></i>Back to Course
                    </Link>
                    <h2 className="order-list-section_title secondary-title mb-4 mt-4">CART</h2>
                    {cartItems && cartItems?.data?.length > 0 ? (
                        <div className="cart-wrapper">
                            <div className="cart-left">
                                {cartItems?.data?.map((item) => {
                                    return (
                                        <div className="cart-list items-start" key={item?.id}>
                                            <div className="cart-products">
                                                <div>
                                                    <h4 className="cart-prd-title">Course</h4>
                                                    <div className="cart-list-dec">
                                                        <img src={`${_courseImg_}/${item?.image}`} className="w-20 h-20 ml-0 md:ml-6 rounded-lg" alt="" />
                                                        <p>{item?.course_name}</p>
                                                    </div>
                                                </div>
                                                <div className="cart-price-wrap">
                                                    <h4 className="cart-prd-title cart-price-title">Price</h4>
                                                    <h2 className="cart-list-price-dec">₹ {item?.price}</h2>
                                                </div>
                                            </div>
                                            <div>
                                                <div
                                                    className="cart-delet"
                                                    onClick={() => handleDeleteItem(item?.id)}
                                                >
                                                    <button className="cart-delet-btn">
                                                        DELETE <span>+</span>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    );
                                })}
                                {/* total details  */}
                                {toast?.show && (
                                    <Toster text={toast?.text} variation={toast?.variation} />
                                )}

                                <Coupon id={cartItems?.data?.length === 1 ? (cartItems?.data[0]?.course_id || appliedCoupon?.course_id) : ''} total={cartItems?.total_price} />

                          


                            </div>

                            <div className="cart-subtotal">
                                <div className="card_">
                                    <h4 className="cart-prd-title">SUBTOTAL</h4>
                                    <div>
                                        <div className="cart-total-wrapper">
                                            <span className="cart-tax">Subtotal</span>
                                            <p className="cart-total">
                                                <span>₹</span>
                                                {cartItems?.total_price - calculateDiscountAmount(appliedCoupon, cartItems?.total_price)}
                                            </p>
                                        </div>
                                        <Link
                                            to={"/checkout"}
                                            className="m-btn m-btn-hero cart-checkout-btn"
                                        >
                                            PROCEED TO CHECKOUT
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : !loading ? (
                        <div className="empty-cart order-placed-box" >
                            <i className="m-icon icon-emptycart"></i>
                            <h3 className="order-placed-title cart-empty">Your cart is empty</h3>
                            <p className="order-placed-details">Must add items to the cart before you proceed to checkout.</p>
                            <Link to={'/course'}>
                                <button className="m-btn m-btn-hero mt-6">Continue Shopping</button>
                            </Link>
                        </div>
                    ) : null}
                </div>
            </section>
        </>
    )
}

export default Cart;