import { useState } from 'react';

const useToast = () => {
    
    const [toast, setToast] = useState({
        show: false,
        text: null,
        variation: null
    });

    const showToast = (text, variation = 'success', duration = 2000) => {
        setToast({ show: true, text, variation });

        setTimeout(() => {
            setToast(prevToast => ({ ...prevToast, show: false }));
        }, duration);
    };

    return { toast, showToast };
}

export default useToast;
