import { useEffect, useState } from "react";
import apiPath from "../../apipath";
import { fetchDataFromApi } from "../../libs/AllGlobalFunctions";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Card = ({ title, dateTime, description }) => {

    const [expanded, setExpanded] = useState(false);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <div className="max-w-8xl mx-auto bg-white shadow-md rounded-md overflow-hidden mt-3">
            <div className="bg-teal text-white p-4">
                <h2 className="text-xl font-bold text-white">{title}</h2>
                <p className="text-xs text-white">{dateTime}</p>
            </div>
            <div className={`p-4 ${expanded ? '' : 'h-20 overflow-hidden'}`}>
                <div className="text-gray-800" dangerouslySetInnerHTML={{
                    __html: ` ${description}`
                }}></div>
            </div>
            {!expanded && (
                <button
                    onClick={toggleExpand}
                    className="text-blue-500 hover:underline focus:outline-none py-2 px-4 block w-full text-center bg-blue-100 hover:bg-blue-200 transition duration-300"
                >
                    Read More
                </button>
            )}
            {expanded && (
                <button
                    onClick={toggleExpand}
                    className="text-blue-500 hover:underline focus:outline-none py-2 px-4 block w-full text-center bg-blue-100 hover:bg-blue-200 transition duration-300"
                >
                    Read Less
                </button>
            )}
        </div>
    );

};

const UserPortfolio = () => {

    const [loading, setLoading] = useState(false);
    const [portfolioData, setPortfolioData] = useState([]);
    const { userInfo, accessToken } = useSelector((state) => state?.auth);

    useEffect(() => {
        fetchPortfolio();
        window.scrollTo(0, 0);
    }, [])

    const fetchPortfolio = () => {
        setLoading(true);
        fetchDataFromApi(`${apiPath.getPortfolio}/0`, null, "GET", {})
            .then((response) => {
                if (response?.status == 200 && response?.data) {
                    setPortfolioData(response?.data?.data);
                } else {
                    setPortfolioData([]);
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error)
            });
    };

    

    if (userInfo && userInfo?.membership_id === "1") {
        return (
            <section className='section_spacing'>
                <div className='site-container'>
                    <div className="w-full p-4 border-t-4 border-red-500 bg-gray-100 text-center rounded-b-lg">
                        <p> Oops !! For Portfolio page you need to be upgrade your membership
                            <Link to={'/dashboard/membership'} className="text-blue-500"> Check Your Membership</Link></p>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <div className="section_spacing section_spacing-space">
            {loading && <Loader />}
            <div className="site-container">
                {
                    portfolioData && portfolioData?.length > 0 && portfolioData?.map((item) => {
                        return (
                            <Card {...item} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default UserPortfolio;
