import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Input from '../../components/Form/Input';
import apiPath from '../../apipath';
import useToast from '../../hooks/useToast';
import Toster from '../../components/Toster';
import { useEffect, useState } from 'react';
import axiosProvider from '../../libs/axios/axiosProvider';
import Loader from '../../components/Loader';
import PageTitle from '../../css/PageTitle';
import { useNavigate } from 'react-router';
import { setCookie } from 'nookies';
import { setUser } from '../../redux/features/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCart } from '../../redux/features/cartSlice';
import bradcom from '../../icons/bradcome-arrow.svg'
import { Link } from 'react-router-dom';
import { _logo_ } from '../../libs/ImagePath';
import globalValidationSchema from '../../validations/globalValidations';

const UserLogin = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { toast, showToast } = useToast();
    const [loading, setLoading] = useState(false);
    const settings = useSelector((state) => state?.setting?.settings);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSubmit = async (values, { resetForm }) => {

        setLoading(true);
        try {

            const submitFormData = new FormData();
            Object.entries(values).forEach(([key, value]) => {
                submitFormData.append(key, value);
            });

            const response = await axiosProvider({
                method: "POST",
                endpoint: apiPath.login,
                data: submitFormData,
            });

            if (response.status === 200 && response?.data?.response_code == 200) {

                setCookie(null, 'userToken', response?.data?.token, {
                    maxAge: 1 * 24 * 60 * 60,
                    path: '/'
                });

                localStorage.setItem("currentUser", JSON.stringify(response?.data?.data));
                dispatch(setUser({ currentUser: response?.data?.data, accessToken: response?.data?.token }))
                showToast(response?.data?.message, "success", 3000);
                resetForm({ values: "" });

                if (response?.data?.data?.id && response?.data?.token) {
                    dispatch(fetchCart({ userId: response?.data?.data?.id, accessToken: response?.data?.token }));
                }

                setTimeout(() => navigate("/dashboard", { replace: true }), 1000);

            } else {
                showToast(response?.data?.message, "error", 3000);
            }
            setLoading(false)
        } catch (error) {
            console.log(error);
            showToast("Something went wrong !", "error", 3000);
            setLoading(false)
        }

    };

    const initialValues = {
        email: '',
        password: '',
    };

    const validationSchema = Yup.object({
        email: globalValidationSchema.email,
        password: Yup.string().required('Password is Required'),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema,
        onSubmit: handleSubmit
    });

    return (
        <>
            <div className='pc-details-banner' style={{ backgroundImage: `url('${_logo_}/${settings?.banner_image}')` }}>

                <h2 className='pc-details-title'>Sign In</h2>
                <div className='p-couse-bradcom-wrapper'>
                    <Link to={'/'} className='p-bradcom-item'>
                        Home
                    </Link>
                    <img src={bradcom} alt='' />
                    <Link to={'/auth/login'} className='p-bradcom-item'>
                        Sign In
                    </Link>
                </div>
            </div>
            <section className='section_spacing section_spacing-space'>
                {/* <PageTitle PageTitle={"Sign In"} /> */}
                <div className='flex items-center justify-center site-container'>
                    <FormikProvider value={formik}>
                        {loading && <Loader />}

                        <form onSubmit={formik.handleSubmit} id="loginForm" className='min-w-full sm:min-w-[24rem]'>
                            <div className="flex flex-col space-y-4">
                                <div className="flex flex-col ">
                                    <Input
                                        label="Email Id"
                                        name="email"
                                        placeholder="Email Id"
                                        isRequired={true}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <Input
                                        label="Password"
                                        name="password"
                                        placeholder="Password"
                                        autoComplete="new-password"
                                        isRequired={true}
                                        onChange={formik.handleChange}
                                        type="password"
                                    />
                                </div>

                                <button className='m-btn m-btn-hero justify-center text-center' type='submit'>
                                    Sign In
                                </button>
                            </div>



                            <div className="mt-4 flex justify-between max-w-md">
                                <p>
                                    <a href="#" className="text-blue-500" onClick={() => navigate("/auth/forget-password")}>
                                        Forgot Password?
                                    </a>
                                </p>
                                <p>
                                    New user?{' '}
                                    <a href="#" className="text-blue-500" onClick={() => navigate("/auth/signup")}>
                                        Sign Up
                                    </a>
                                </p>

                            </div>
                            {toast?.show && (
                                <Toster text={toast?.text} variation={toast?.variation} />
                            )}
                        </form>
                    </FormikProvider>

                </div>
            </section>
        </>
    );
};

export default UserLogin;
