import { useFormik, FormikProvider, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Input from '../../components/Form/Input';
import { Card, Button } from 'react-bootstrap';
import { useState } from 'react';
import axiosProvider from '../../libs/axios/axiosProvider';
import apiPath from '../../apipath';
import Toster from '../../components/Toster';
import useToast from '../../hooks/useToast';
import Loader from '../../components/Loader';
import PageTitle from '../../css/PageTitle';
import { useNavigate } from 'react-router';
import bradcom from '../../icons/bradcome-arrow.svg'
import { Link } from 'react-router-dom';
import { _logo_ } from '../../libs/ImagePath';
import { useSelector } from 'react-redux';
import globalValidationSchema from '../../validations/globalValidations';
import { handleCharInputChange } from '../../libs/AllGlobalFunctions';
import TextError from '../../components/Form/TextError';

const SignUp = () => {

    const navigate = useNavigate();
    const { toast, showToast } = useToast();
    const [loading, setLoading] = useState(false);
    const settings = useSelector((state) => state?.setting?.settings);

    const handleSubmit = async (values, { resetForm }) => {

        setLoading(true);

        const { termsAndConditions, confirm_password, ...formData } = values;

        try {
            const submitFormData = new FormData();
            Object.entries(formData).forEach(([key, value]) => {
                submitFormData.append(key, value);
            });

            const response = await axiosProvider({
                method: "POST",
                endpoint: apiPath.signUp,
                data: submitFormData,
            });

            if (response.status === 200 && response?.data?.response_code == 200) {
                showToast(response?.data?.message, "success", 3000);
                resetForm({ values: "" });
                // setTimeout(() => navigate('/auth/login'), 2000);
            } else {
                showToast(response?.data?.message, "error", 3000);
            }
            setLoading(false);

        } catch (error) {
            console.log(error);
            showToast("Something went wrong !", "error", 3000);
            setLoading(false)
        }

    };

    const initialValues = {
        email: '',
        password: '',
        name: '',
        phone: "",
        address: "",
        confirm_password: "",
        termsAndConditions: false
    };

    const validationSchema = Yup.object({
        email: globalValidationSchema.email,
        name: globalValidationSchema.name,
        phone: globalValidationSchema.phone,
        password: globalValidationSchema.password,
        confirm_password: globalValidationSchema.confirm_password,
        address: globalValidationSchema.address,
        termsAndConditions: Yup.boolean().oneOf([true], 'Terms and conditions must be accepted'),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema,
        onSubmit: handleSubmit
    });

    return (
        <> <div className='pc-details-banner' style={{ backgroundImage: `url('${_logo_}/${settings?.banner_image}')` }}>

            <h2 className='pc-details-title'>Sign Up</h2>
            <div className='p-couse-bradcom-wrapper'>
                <Link to={'/'} className='p-bradcom-item'>
                    Home
                </Link>
                <img src={bradcom} alt='' />
                <Link to={'/auth/signup'} className='p-bradcom-item'>
                    Sign Up
                </Link>
            </div>
        </div>

            <section className='section_spacing'>
                {loading && <Loader />}
                <div className='site-container'>
                    {/* <PageTitle PageTitle={"Sign Up"} /> */}
                    <div className='flex items-center justify-center'>
                        <FormikProvider value={formik}>
                            <form onSubmit={formik.handleSubmit} id="signUpForm" autoComplete="off">
                                <div className='registration-wrapper'>
                                    <div className='registration-col'>
                                        <img src="https://assets.website-files.com/62011da2e1be285c04bfb6c3/632c5ab7e323127dd9f37c1c_62bedf15afd45b365d3566a4_Frame%201-p-800.webp" alt="" width={450} />
                                    </div>
                                    <div className='registration-col'>
                                        <div className='input-wrap'>
                                            <Input
                                                label="Full Name"
                                                name="name"
                                                placeholder="Full Name"
                                                isRequired={true}
                                                onChange={(e) => handleCharInputChange(e, formik)}
                                            />
                                        </div>
                                        <div className='input-wrap'>
                                            <Input
                                                label="Phone Number"
                                                name="phone"
                                                placeholder="Phone Number"
                                                isRequired={true}
                                                onChange={formik.handleChange}
                                                type="tel"
                                            />
                                        </div>
                                        <div className='input-wrap'>
                                            <Input
                                                label="Email Id"
                                                name="email"
                                                placeholder="Email Id"
                                                isRequired={true}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className='input-wrap'>
                                            <Input
                                                label="Password"
                                                name="password"
                                                type="password"
                                                placeholder="Password"
                                                autoComplete="new-password"
                                                isRequired={true}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className='input-wrap'>
                                            <Input
                                                label="Re-enter Password"
                                                name="confirm_password"
                                                type="password"
                                                placeholder="Re-enter Password"
                                                autoComplete="new-password"
                                                isRequired={true}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className='input-wrap'>
                                            <Input
                                                label="Address"
                                                name="address"
                                                placeholder="Address"
                                                isRequired={true}
                                                onChange={formik.handleChange}
                                            />
                                        </div>

                                        <div className='flex items-baseline gap-2'>
                                            <Field
                                                name='termsAndConditions'
                                                type="checkbox"
                                                id="termsAndConditions"
                                                isRequired={true}
                                                checked={formik.values.termsAndConditions || false}
                                                onChange={formik.handleChange}
                                            />
                                            <label htmlFor='termsAndConditions'><span className='text-black'>Please confirm that you agree with our </span><Link className="text-blue-500 font-weight-bold" to={'/terms-condition'}>terms & conditions</Link></label>
                                        </div>
                                        <ErrorMessage name={'termsAndConditions'} component={TextError} />

                                        <div className='login-btn_wrapper'>
                                            <button className='m-btn m-btn-hero justify-center text-center' type='submit'>
                                                Create Account
                                            </button>
                                            <p className="login-signup">
                                                Already have an account?{' '}
                                                <a href="#" className="text-blue-500 font-weight-bold" onClick={() => navigate("/auth/login")}>
                                                    Sign In
                                                </a>
                                            </p>
                                        </div>

                                        {toast?.show && (
                                            <Toster text={toast?.text} variation={toast?.variation} />
                                        )}


                                    </div>
                                </div>
                            </form>
                        </FormikProvider>
                    </div>
                </div>
            </section></>
    );
};

export default SignUp;
