import Testimonials from '../components/Testimonials'
import Herosection from '../components/Herosection'
import CategorySection from '../components/CategorySection'
import AboutUs from '../components/AboutUs'
import PopularCourses from '../components/PopularCourses'
import CallToAction from '../components/CallToAction'
import { useEffect } from 'react'

const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Herosection />
      <PopularCourses />
      <AboutUs />
      <CategorySection />
      <Testimonials />
      <CallToAction />
    </>
  )
}

export default Home;
