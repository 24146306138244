import React from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const SuccessModal = (props) => {
    return (
        <Modal
            {...props}
            dialogClassName="modal-50w"
            animation={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className="py-10">
                {props.err ?
                    <div>
                        <div className="check-container mx-auto h-[5.5rem]">
                            <div class="circle-border"></div>
                            <div class="circle">
                                <div class="error"></div>
                            </div>
                            <div class="check-shadow err"></div>
                        </div>
                        <div>
                            <h2 class="mt-2 section-title err">Error!</h2>
                            <p class="text-center">{props.msg}</p>
                        </div>
                    </div>
                    :
                    <div>
                        <div class="check-container mx-auto h-[7.5rem]">
                            <div class="check-background">
                                <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div class="check-shadow"></div>
                        </div>
                        <div>
                            <h2 class="mt-2 section-title">Success!</h2>
                            <p class="text-center">{props.msg}.</p>
                            {props.err === false && <p class="text-center"><Link to='/auth/login' target="_blank" className="text-blue-500" >Login</Link></p>}
                        </div>
                    </div>
                }

            </Modal.Body>
        </Modal >
    )
}

export default SuccessModal