import React, { useState } from 'react'
import aboutimg1 from '../images/about-01.webp'
import aboutimg2 from '../images/about-img-2.png';
import apiPath from '../apipath';
import { useEffect } from 'react';
import { fetchDataFromApi } from '../libs/AllGlobalFunctions';
import { _page_ } from '../libs/ImagePath';
import aboutIcon from '../icons/about.svg';

export default function AboutUs() {

  const [aboutData, setAboutData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTopCategories();
  }, []);

  const fetchTopCategories = () => {
    setLoading(true);
    fetchDataFromApi(`${apiPath.getPageContent}/${1}`, null, 'GET', {}).then((response) => {

      if (response?.status == 200 && response?.data?.data) {
        setAboutData(response?.data?.data[0]);
      } else {
        setAboutData([])
      }
      setLoading(false);
    }
    ).catch((error) => {
      setLoading(false);
      console.log(error)
    })
  }

  return (
    <section className='section_spacing'>
      <div className='site-container'>
        <div className='h-aboutus-wrapper'>
          <div className='h-aboutus-left'>
            <div className='h-aboutus-left-img'>
              <img src={`${_page_}/${aboutData?.image}`} alt='about' width={420} height={370} />
            </div>
            {/* <div className='h-aboutus-small-img'>
              <img src={aboutimg2} alt='' />
            </div> */}
            <div className='about-award about-award-price'>
              <div className='award-icon'>
                <img src={aboutIcon} width={33} height={33} alt="icon" />
                {/* <i className='m-icon'>icon</i> */}
              </div>
              <h5 className='about-award-title'> <span className='font-semibold text-2xl'>29+</span>Years of Experience</h5>
            </div>
          </div>
          <div className='h-aboutus-right'>
            <h5 className='h-about-title'>ABOUT US</h5>
            <div className='section-title-wrap h-about-title-wrap'>
              <h2 className='section-title'>{aboutData?.title}</h2>
              <p className='section-title-contain'>
                {aboutData?.short_description}
              </p>
            </div>
            <ul className='h-about-item'>
              {
                aboutData?.description?.split('\n')?.map((item) => {
                  return (
                    <li className='h-about-item-list'>{item}</li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
