import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FiHome, FiSettings, FiLogOut, FiMenu, FiX } from 'react-icons/fi';
import { IoBookOutline } from "react-icons/io5";
import { FaRegCircleUser, FaAddressCard, FaKey } from "react-icons/fa6";
import { MdNotificationsActive, MdPayment } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../redux/features/authSlice';
import { setCart } from '../../redux/features/cartSlice';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';
import Toster from '../../components/Toster';
import { RxActivityLog } from "react-icons/rx";
import useToast from '../../hooks/useToast';
import { TbArrowNarrowLeft } from "react-icons/tb";
import { _logo_ } from '../../libs/ImagePath';
import Badge from '@mui/material/Badge';


const Sidebar = () => {

  const { toast, showToast } = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const settings = useSelector((state) => state?.setting?.settings);
  const { userInfo } = useSelector((state) => state?.auth);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = () => {

    setLoading(true);
    try {

      const response = new Promise((resolve) => {
        setTimeout(() => resolve({ code: 200 }), 1000);
      });

      response.then((res) => {
        if (res?.code == 200) {

          dispatch(logOut());
          dispatch(setCart([]));
          setLoading(false);
          showToast("Logout Successfully !", "success", 3000);
          navigate('/');
        }

      }).catch((error) => {
        console.log(error)
        setLoading(false);
      })

    } catch (error) {
      console.log(error);
      setLoading(false);
      showToast("Something went wrong !", "error", 3000);
    }

  }

  return (
    <>
      {loading && <Loader />}
      {/* {toast?.show && (
        <Toster text={toast?.text} variation={toast?.variation} />
      )} */}
      <div className="md:site-container flex justify-between items-center">
        <button
          className="p-4 text-white bg-darkgray overflow-y-auto md:hidden"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <FiX size={24} /> : <FiMenu size={24} />}
        </button>

        <Link to={"/"} className="ml-0 mt-[0px] md:ml-80 md:mt-3 text-[#ffffff] md:text-black flex gap-2 pr-3">
          <TbArrowNarrowLeft className="m-icon" />Back to Home
        </Link>
      </div>

      <div
        className={`fixed top-0 left-0 z-30 h-full w-72 bg-darkgray text-white overflow-y-auto transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'
          } md:translate-x-0`}
      >
        <button
          className="p-3 text-white md:hidden absolute top-0 left-0" // This button is only visible on small screens
          onClick={() => setIsOpen(false)} // Close the sidebar
        >
          <FiX size={24} />
        </button>

        {/* <h1 className="text-2xl font-semibold text-white text-center p-3"> */}
        <img src={`${_logo_}${settings?.logo}`} className="logo mx-auto" width={120} alt="logo" />
        {/* </h1> */}
        <div className="">
          <nav className="mt-6">
            <ul className="flex flex-col gap-1">
              <li>
                <NavLink end to="/dashboard" className="flex items-center py-3 px-6 transition duration-200 hover:bg-[#ffffff] hover:text-[#003763] text-lg" onClick={() => setIsOpen(false)}>
                  <FaRegCircleUser className="mr-4" size={24} />Profile
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/change-password" className="flex items-center py-3 px-6 transition duration-200 hover:bg-[#ffffff] hover:text-[#003763]  text-lg" onClick={() => setIsOpen(false)} >
                  <FaKey className="mr-4" size={24} />Password
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/membership" className="flex items-center py-3 px-6 transition duration-200 hover:bg-[#ffffff] hover:text-[#003763] text-lg" onClick={() => setIsOpen(false)} >
                  <FaAddressCard className="mr-4" size={24} />Membership
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/my-courses" className="flex items-center py-3 px-6 transition duration-200 hover:bg-[#ffffff] hover:text-[#003763]  text-lg" onClick={() => setIsOpen(false)} >
                  <IoBookOutline className="mr-4" size={24} />My Course
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/active-logs" className="flex items-center py-3 px-6 transition duration-200 hover:bg-[#ffffff] hover:text-[#003763]  text-lg" onClick={() => setIsOpen(false)} >
                  <RxActivityLog className="mr-4" size={24} />Active Logs
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/transaction-history" className="flex items-center py-3 px-6 transition duration-200 hover:bg-[#ffffff] hover:text-[#003763]  text-lg" onClick={() => setIsOpen(false)} >
                  <MdPayment className="mr-4" size={24} />Transaction History
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/notification" className="flex items-center py-3 px-6 transition duration-200 hover:bg-[#ffffff] hover:text-[#003763]  text-lg" onClick={() => setIsOpen(false)} >
                  <Badge className="mr-4" color="danger" badgeContent={userInfo?.notification_count} invisible={userInfo?.notification_count > 0 ? false : true}>
                    <MdNotificationsActive size={24} />
                  </Badge>Notifications
                </NavLink>
              </li>
              <li>
                <Link to="#" className="flex items-center py-3 px-6 transition duration-200 hover:bg-[#ffffff] hover:text-[#003763]  text-lg" onClick={handleLogout}>
                  <FiLogOut className="mr-4" size={24} />Logout
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Sidebar;







