import React, { useState, useEffect, memo } from "react";
import { useParams } from "react-router-dom";
import UpdateContent from "../../component/common/TabPanel/UpdateContent";
import { TabPanel } from "../../component/common/TabPanel/TabPanel";
import LessonDetails from "../lesson/LessonDetails";
import apiPath from "../../../apipath";
import { useDispatch, useSelector } from "react-redux";
import { fetchChapterData, fetchLessonData } from "../../../redux/features/basicSlice";



const Chapter = ({ moduleId, value }) => {

  const { courseId } = useParams();

  const dispatch = useDispatch();

  const chapterData = useSelector((state) => state?.basic?.chapterData);

  const fetchData = () => {
    dispatch(fetchChapterData(courseId));
    dispatch(fetchLessonData(courseId));
  }

  // useEffect(() => {
  //   fetchData();
  // }, [])


  return (
    <section>
      {chapterData?.filter(item => item.module_id == moduleId)?.map((chapter, index) => (
        <TabPanel value={value} index={`${moduleId}${chapter?.id}${index}`} type={'ch'} >
          <UpdateContent {...chapter} id={chapter.id} moduleId={moduleId} heading={"Chapter"} addLesson={"addLesson"} getPath={"getSingleChapter"} updatePath={"updateChapter"} deletePath={"deleteChapter"}
            fetchData={fetchData}
          />
        </TabPanel>
      ))}

      {chapterData?.filter(item => item.module_id == moduleId)?.map((chapter, index) => (
        <LessonDetails {...chapter} index={index} moduleId={moduleId} chapterId={chapter.id} value={value} />
      ))}

    </section>
  );
};

export default memo(Chapter);
