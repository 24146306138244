import React, { useState, useEffect } from "react";
import Breadcrumb from "../../component/common/Breadcrumb";
import DataTables from "../../datatable/DataTable";
import { FaEdit, FaTrash, FaEye } from "react-icons/fa";
import { getApi, postApi } from "../../helper/helper";
import { Button, Form, Modal } from "react-bootstrap";
import { Card } from "@mui/material";
import { ToastContainer } from "react-toastify";
import Rating from '@mui/material/Rating';
import { useForm } from "react-hook-form";
import {
  alert,
  alertErr,
  alertInfo,
  alertSuccess,
  notify,
  notifyErr,
} from "../../component/common/notify";
import {
  confirmDelete,
  confirmStatus,
} from "../../component/common/handleData";
import { Link, useParams } from "react-router-dom";
import apiPath from "../../../apipath";
import Loader from "../../../components/Loader";

const Testimonials = ({ course_id }) => {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
  const [course, setCourse] = useState([]);
  const [status, setStatus] = useState('0');
  const [formData, setFormData] = useState({});
  const [formDataUpdate, setFormDataUpdate] = useState({});
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setFormData({});
    reset({
      course_id: course_id,
      title: "",
      content: "",
      name: "",
      stars: "",
    });
  };

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleShowUpdate = (row) => {
    setShowUpdate(true);
    getIndividualData(row.id);
  };

  const handleCloseUpdate = () => {
    setShowUpdate(false);
    setFormDataUpdate({});
    reset1({
      course_id: course_id,
      title: "",
      content: "",
      name: "",
      stars: "",
    });
  };

  const handleInputChange1 = (field, value) => {
    setFormDataUpdate({ ...formDataUpdate, [field]: value });
  };

  const fetchTableData = async (course_id) => {
    setPending(true);
    try {
      const result = await getApi(`${apiPath.getTestimonialCourse}/${course_id}/${status}`);
      setData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }

  };

  useEffect(() => {
    if (course_id) {
      fetchTableData(course_id);
    }

  }, [course_id, status]);

  const fetchData = async () => {
    setPending(true);
    try {
      const result = await getApi(apiPath.getChapterCourse);
      setCourse(result);
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleStatusFilter = (value) => {
    setStatus(value);
  }

  const columns = [
    {
      name: <th className="fs-6 ">#</th>,
      cell: (row, index) => index + 1,
      width: "100px",
    },
    {
      name: <th className="fs-6 ">Name</th>,
      selector: (row) => row.name,
      width: "200px",
    },
    {
      name: <th className="fs-6">Course Name</th>,
      selector: (row) => row.course_title,
      width: "200px",
    },
    {
      name: <th className="fs-6 ">Title</th>,
      selector: (row) => row.title,
      width: "200px",
    },
    {
      name: <th className="fs-6">Content</th>,
      selector: (row) => row.content,
      width: "200px",
    },
    {
      name: <th className="fs-6">Rating</th>,
      cell: (row, index) => <Rating name="read-only" value={row.stars} readOnly />,
      width: "200px",
    },
    {
      name: <th className="fs-6">Status</th>,
      cell: (row, index) =>
        row.status == 0 ? (
          <span
            className="bg-info text-white p-1 rounded cursor-pointer"
            onClick={() =>
              handleStatus("/api/updatetestimonialStatus", row.id, "1")
            }
          >
            Pending
          </span>
        ) : row.status == 1 ? (
          <span
            className=" bg-success text-white p-1 rounded cursor-pointer"
            onClick={() =>
              handleStatus("/api/updatetestimonialStatus", row.id, "0")
            }
          >
            Approved
          </span>
        ) : (
          "-"
        ),
      width: "220px",
    },
    {
      name: <th className="fs-6 action">Action</th>,
      selector: (row) => (
        <div className="d-flex" style={{ gap: "10px" }}>

          <Button
            className="text-white border-0"
            style={{ float: "right", backgroundColor: "var(--primary)" }}
            onClick={() => handleShowUpdate(row)}
          >
            <FaEdit />
          </Button>

          <Button
            className="bg-danger text-white border-0"
            onClick={() => handleDelete(`${apiPath.deleteTestimonial}/${row.id}`)}
          >
            <FaTrash />
          </Button>
        </div>
      ),
      right: true,
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: errors },
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm();

  const onSubmit = async () => {
    setPending(true);
    try {
      const result = await postApi(apiPath.addTestimonial, {
        ...formData,
        course_id: course_id,
        user_id: 1,
      });
      handleClose();
      notify("Testimonial Added Successfully!");
      fetchTableData(course_id);
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  const getIndividualData = async (id) => {
    setPending(true);
    try {
      const result = await getApi(`${apiPath.getSingleTestimonial}/${id}`);
      const data = result[0];
      if (data) {
        setFormDataUpdate((prevFormDataUpdate) => ({
          ...prevFormDataUpdate,
          ["id"]: data.id,
          ["title"]: data.title,
          ["name"]: data.name,
          ["content"]: data.content,
          ["stars"]: data.stars,
          ["course_id"]: data.course_id,
        }));
        reset1({
          course_id: data.course_id,
          title: data.title,
          content: data.content,
          name: data.name,
          stars: data.stars,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  const onUpdate = async () => {
    setPending(true);
    try {
      const result = await postApi(apiPath.updateTestimonial, {
        ...formDataUpdate,
        course_id: course_id,
        user_id: 1,
      });
      handleCloseUpdate();
      notify("Testimonial Updated Successfully!");
      fetchTableData(course_id);
    } catch (error) {
      console.error(error);
      notifyErr("Something went wrong!");
    } finally {
      setPending(false);
    }
  };


  // handleDelete 
  const handleDelete = (endPoint) => {
    confirmDelete(endPoint)
      .then((data) => {
        fetchTableData();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // handleStatus 
  const handleStatus = (endpoint, id, status) => {
    confirmStatus(endpoint, { id: id, status: status })
      .then((message) => {
        setStatus('0');
        fetchTableData();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const breadcrumbItems = [
    { title: 'Testimonial', link: null }
  ];

  return (
    <section>

      {/* <div className="mb-5">
        <Breadcrumb items={breadcrumbItems} />
      </div> */}

      {pending && <Loader />}

      <Card variant="outlined" className="pb-3">
        <div className="btn-d flex flex-col sm:flex-row row-gap-3 justify-content-between m-3">
          <div className='d-flex gap-3'>
            <Form.Select value={status} className="filter-select  w-auto"
              onChange={(e) => handleStatusFilter(e.target.value)}
            >
              <option value="0">Pending</option>
              <option value="1">Approved</option>
              <option value="2">All</option>
            </Form.Select>
            {/* <Form.Select value={''} className="filter-select  w-auto"
              onChange={(e) => fetchFilteredData(e.target.value)}
            >
              <option value="" selected disabled>Select Course</option>

              <option value="1">Sub Admin</option>
            </Form.Select> */}
          </div>

          {/* <Button
            className="text-white border-0 default-btn"
            onClick={() => handleShow()}
          >
            Add Testimonial
          </Button> */}
        </div>
        <div>
          <DataTables
            title={"Testimonial"}
            data={data}
            columns={columns}
            pending={pending}
            paginationServer={false}
          // totalRows={data?.length}
          />
        </div>
      </Card>
      {/* add */}
      <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>

        <Modal.Header closeButton>
          <Modal.Title>Add Testimonial</Modal.Title>
        </Modal.Header>
        <Form

          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <Modal.Body className="row m-2">
            <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
              <Form.Label htmlFor="course_id">Course</Form.Label>
              <Form.Select
                type="type"
                id="course_id"
                placeholder="course"
                disabled
                value={course_id}
                {...register("course_id", {
                  required: false,
                  // onChange: (e) =>
                  //   handleInputChange("course_id", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                {course?.map((course, index) => (
                  <option key={index} value={course.id}>
                    {course.title}
                  </option>
                ))}
              </Form.Select>
              {errors.course_id && (
                <div className="errMsg text-danger">Please Select Course</div>
              )}
            </Form.Group>
            <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
              <Form.Label htmlFor="name">Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                id="name"
                value={formData.name}
                {...register("name", {
                  required: true,
                  onChange: (e) => handleInputChange("name", e.target.value),
                })}
              />
              {errors.name && (
                <div className="errMsg text-danger">Please Enter Name</div>
              )}
            </Form.Group>
            {/* <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
              <Form.Label htmlFor="position">Position</Form.Label>
              <Form.Control
                type="text"
                placeholder="Position"
                id="position"
                value={formData.position}
                {...register("position", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("position", e.target.value),
                })}
              />
              {errors.position && (
                <div className="errMsg text-danger">Please Enter Position</div>
              )}
            </Form.Group> */}
            <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
              <Form.Label htmlFor="stars">Rating</Form.Label>
              <Form.Select
                type="type"
                id="stars"
                placeholder="Rating"
                value={formData.stars}
                {...register("stars", {
                  required: true,
                  onChange: (e) => handleInputChange("stars", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                <option value="1">1 start</option>
                <option value="2">2 start</option>
                <option value="3">3 start</option>
                <option value="4">4 start</option>
                <option value="5">5 start</option>
              </Form.Select>
              {errors.stars && (
                <div className="errMsg text-danger">Please Select Rating</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="title">Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                id="title"
                value={formData.title}
                {...register("title", {
                  required: true,
                  onChange: (e) => handleInputChange("title", e.target.value),
                })}
              />
              {errors.title && (
                <div className="errMsg text-danger">Please Enter Title</div>
              )}
            </Form.Group>

            {/* <Form.Group className="mb-3">
              <Form.Label htmlFor="description">Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                id="description"
                placeholder="description"
                value={formData.description}
                {...register("description", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("description", e.target.value),
                })}
              />
              {errors.description && (
                <div className="errMsg text-danger">
                  Please Enter Description
                </div>
              )}
            </Form.Group> */}
            <Form.Group className="mb-3">
              <Form.Label htmlFor="content">Content</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                id="content"
                placeholder="content"
                value={formData.content}
                {...register("content", {
                  required: true,
                  onChange: (e) => handleInputChange("content", e.target.value),
                })}
              />
              {errors.content && (
                <div className="errMsg text-danger">Please Enter Content</div>
              )}
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="contained"
              className="text-white btn-primary"
            >
              Add
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* update */}
      <Modal
        show={showUpdate}
        onHide={handleCloseUpdate}
        style={{ zIndex: "9999" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Testimonial</Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={handleSubmit1(onUpdate)}
          autoComplete="off"
        >
          <Modal.Body className="row m-2">
            <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
              <Form.Label htmlFor="course_id">Course</Form.Label>
              <Form.Select
                disabled
                type="type"
                id="course_id"
                placeholder="course"
                value={formDataUpdate.course_id}
                {...register1("course_id", {
                  required: false,
                  // onChange: (e) =>
                  //   handleInputChange1("course_id", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                {course?.map((course, index) => (
                  <option key={index} value={course.id}>
                    {course.title}
                  </option>
                ))}
              </Form.Select>
              {errors1.course_id && (
                <div className="errMsg text-danger">Please Select Course</div>
              )}
            </Form.Group>
            <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
              <Form.Label htmlFor="name">Name</Form.Label>
              <Form.Control
                disabled
                type="text"
                placeholder="Name"
                id="name"
                value={formDataUpdate.name}
                {...register1("name", {
                  required: false,
                  // onChange: (e) => handleInputChange1("name", e.target.value),
                })}
              />
              {errors1.name && (
                <div className="errMsg text-danger">Please Enter Name</div>
              )}
            </Form.Group>
            {/* <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
              <Form.Label htmlFor="position">Position</Form.Label>
              <Form.Control
                type="text"
                placeholder="Position"
                id="position"
                value={formDataUpdate.position}
                {...register1("position", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange1("position", e.target.value),
                })}
              />
              {errors1.position && (
                <div className="errMsg text-danger">Please Enter Position</div>
              )}
            </Form.Group> */}
            <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
              <Form.Label htmlFor="stars">Rating</Form.Label>
              <Form.Select
                type="type"
                id="stars"
                placeholder="Rating"
                value={formDataUpdate.stars}
                {...register1("stars", {
                  required: true,
                  onChange: (e) => handleInputChange1("stars", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                <option value="1">1 start</option>
                <option value="2">2 start</option>
                <option value="3">3 start</option>
                <option value="4">4 start</option>
                <option value="5">5 start</option>
              </Form.Select>
              {errors1.stars && (
                <div className="errMsg text-danger">Please Select Rating</div>
              )}
            </Form.Group>
            {/* <Form.Group className="mb-3">
              <Form.Label htmlFor="titleUpdate">Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                id="titleUpdate"
                value={formDataUpdate.title}
                {...register1("titleUpdate", {
                  required: true,
                  onChange: (e) => handleInputChange1("title", e.target.value),
                })}
              />
              {errors1.titleUpdate && (
                <div className="errMsg text-danger">Please Enter Title</div>
              )}
            </Form.Group> */}
            {/* <Form.Group className="mb-3">
              <Form.Label htmlFor="description">Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                id="description"
                placeholder="Description"
                value={formDataUpdate.description}
                {...register1("description", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange1("description", e.target.value),
                })}
              />
              {errors1.description && (
                <div className="errMsg text-danger">
                  Please Enter Description
                </div>
              )}
            </Form.Group> */}
            <Form.Group className="mb-3">
              <Form.Label htmlFor="content">Content</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                id="content"
                placeholder="content"
                value={formDataUpdate.content}
                {...register1("content", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange1("content", e.target.value),
                })}
              />
              {errors1.content && (
                <div className="errMsg text-danger">Please Enter Content</div>
              )}
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="contained"
              className="text-white btn-primary"

            >
              Update
            </Button>
            <Button variant="secondary" onClick={handleCloseUpdate}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </section>
  );
};

export default Testimonials;
