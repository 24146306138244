import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Toaster = ({ variation, text }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(true)
    const timer = setTimeout(() => {
      setShow(false)
    }, 6000)

    return () => clearTimeout(timer)
  }, [])

  // const handleClose = () => {
  //   setShow(false)
  // }

  return (
    // <div className={`toaster-container ${show ? 'show' : ''}`}>
    //   <div
    //     className={`toaster-content ${show ? 'show' : ''}`}
    //     // onClick={handleClose}
    //   >
    //     <i className={`m-icon ${variation}`}></i>
    //     {text}
    //   </div>
    // </div>
    <Collapse in={show}>
      <Alert variant="filled" severity={variation}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setShow(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        {text}
      </Alert>
    </Collapse>

  )
}

export default Toaster
