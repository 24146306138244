import { Link } from "react-router-dom";

const NotFoundPage = ({ title = null, description = null, link = null, linkText = null }) => {
    return (
        <>
            <section className='sc-border'>
                <div className='site-container'>
                    <div className='section-border'></div>
                </div>
            </section>
            <section className='section_spacing'>
                <div className='error site-container'>
                    <div className='error-wrapper'>
                        <h2 className='error-oops'>ooops....</h2>
                        <h3 className='error-notfound'>
                            {title ? title : 'page not found'}
                        </h3>
                        <p className='errr-dec'>
                            {description
                                ? description
                                : 'The page you requested could not be found. Try refining your search, or use the navigation above to locate the post.'}
                        </p>
                        <div className='error-btn'>
                            <Link to={link ? link : '/'} className='m-btn m-btn-hero'>
                                {link ? linkText : 'Go to Home'}
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className='sc-border  usertype-border'>
                <div className='site-container'>
                    <div className='section-border'></div>
                </div>
            </section>
        </>
    )
}

export default NotFoundPage;