import React, { useState, useEffect } from 'react';
import { setAppliedCoupon, setCouponCode, setIsApplied, } from '../redux/features/couponSlice';
import { useDispatch, useSelector } from 'react-redux';
import useAuthenticated from '../hooks/useAuthenticated';
import { fetchDataFromApi } from '../libs/AllGlobalFunctions';
import { TiTick } from "react-icons/ti";
import apiPath from '../apipath';
import useToast from '../hooks/useToast';
import Toster from './Toster';
import { calculateDiscountAmount } from './calculateDescountAmount';


const Coupon = ({ id, total }) => {

    const [loading, setLoading] = useState(false);
    const [coupons, setCoupons] = useState([]);

    const { userInfo, accessToken } = useSelector((state) => state?.auth);
    const cartItems = useSelector((state) => state?.cart?.cart);
    const { appliedCoupon, isApplied, couponCode } = useSelector((state) => state?.coupon);

    const isAuth = useAuthenticated();
    const dispatch = useDispatch();
    const { toast, showToast } = useToast();

    useEffect(() => {
        if (userInfo && userInfo?.id && isAuth) {
            fetchCoupon();
        }
    }, [])

    const fetchCoupon = () => {
        fetchDataFromApi(apiPath.userCoupon, null, 'GET', {
            course_id: id,
        }).then((response) => {
            if (response && response?.data?.data) {
                let courseCoupon = response?.data?.data?.filter(coupon => coupon.course_id != '');
                setCoupons(courseCoupon);
            }
        }
        ).catch((error) => {
            console.log(error)
        })
    }

    const applicableCoupons = coupons && coupons?.length > 0 && coupons?.filter(coupon => {
        return cartItems?.data?.every(cartItem => {
            return cartItem.course_id.split(',').some(courseId => {
                return coupon.course_id.split(',').includes(courseId);
            });
        });
    });

    const handleChangeInput = (val) => {
        dispatch(setCouponCode(val));
        dispatch(setIsApplied(false));
    }

    const isCouponApplied = (dataArray) => {
        if (dataArray?.length === 1) {
            if (appliedCoupon && appliedCoupon?.coupon_code !== '' && dataArray[0].course_id != appliedCoupon?.course_id) {
                return true; // Coupon code exists
            }
        } else {
            for (const item of dataArray) {
                if (appliedCoupon && appliedCoupon?.coupon_code !== '' && item.course_id != appliedCoupon?.course_id) {
                    return true; // Coupon code exists
                }
            }
        }
        return false; 
    };

    const handleCouponChange = (val) => {

        const couponApplied = isCouponApplied(cartItems?.data);

        if (couponApplied) {
            showToast(`You have already applied a coupon to another course.`, "error", 3000);
        } else {
            dispatch(setCouponCode(val));
            if (coupons?.length > 0) {
                // Find the coupon in the coupon data list
                const coupon = coupons?.find(coupon => coupon.coupon_code === val);
                if (coupon && parseFloat(coupon.min_amount) <= parseFloat(total)) {
                    // Set coupon data if the coupon code is found
                    dispatch(setAppliedCoupon({
                        course_id: id,
                        coupon_code: coupon.coupon_code,
                        discount_type: coupon.type,
                        discount_amount: coupon.discount_amount,
                        discount_amount: coupon.discount_amount,
                        end_date: coupon.end_date
                    }))

                    dispatch(setIsApplied(true));
                    showToast(`Coupon applied successfully`, "success", 3000);
                } else if (coupon && parseFloat(coupon.min_amount) > parseFloat(total)) {
                    dispatch(setAppliedCoupon(null))
                    dispatch(setIsApplied(false));
                    showToast(`Amount must be grater then ₹${coupon.min_amount}`, "error", 3000);
                } else if (!coupon) {
                    // Handle case when the coupon code is not found
                    dispatch(setAppliedCoupon(null))
                    dispatch(setIsApplied(false));
                    showToast(`Invalid coupon code!`, "error", 3000);
                } else {
                    dispatch(setAppliedCoupon(null))
                    dispatch(setIsApplied(false));
                    showToast(`Something went wrong!`, "error", 3000);
                }
            } else {
                setAppliedCoupon(null);
                setIsApplied(false);
                showToast(`Invalid coupon code!`, "error", 3000);
            }
        }
    };

    const removeCoupon = () => {
        dispatch(setCouponCode(''));
        dispatch(setAppliedCoupon(null));
        dispatch(setIsApplied(false));
    }




    return (
        <>
            {userInfo && userInfo?.id && isAuth && <>

                <div className=''>
                    <p className='checkout-order-prdname'>Coupon</p>
                    <div class="coupon-card">
                        <di class="coupon-row mb-3">
                            <span id="cpnCode">
                                <input
                                    className="coupon-input bg-transparent w-full"
                                    name="coupon_code"
                                    placeholder="Enter coupon code"
                                    value={couponCode}
                                    onChange={(e) => handleChangeInput(e.target.value)}
                                />
                            </span>

                            <button id="cpnBtn" class="m-btn" type='button' disabled={isApplied}
                                onClick={() => handleCouponChange(couponCode)}
                            >{isApplied ? 'Applied' : 'Apply'}</button>

                        </di>
                        <div className='mb-3 text-sm text-success'>{appliedCoupon && appliedCoupon?.course_id === id && <span>Valid Till: {appliedCoupon?.end_date}</span>}</div>

                        {toast?.show && (
                            <Toster text={toast?.text} variation={toast?.variation} />
                        )}

                        {applicableCoupons && applicableCoupons?.length > 0 && <span>Available coupons for you</span>}

                        <div className="flex flex-wrap gap-3 mt-2">
                            {applicableCoupons && applicableCoupons?.map((coupon, index) => (
                                <div className="relative">
                                    <button key={index} className='coupon_code m-btn py-1 px-4 mb-3 bg-transparent text-black' type='button'
                                        disabled={(coupon.coupon_code == appliedCoupon?.coupon_code) || toast?.show}
                                        onClick={() => handleCouponChange(coupon.coupon_code)}
                                    >
                                        {coupon.coupon_code}
                                    </button>
                                    {coupon.coupon_code == appliedCoupon?.coupon_code &&
                                        <span className="tick_"><TiTick /></span>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className='checkout-order-border'>
                    {appliedCoupon && appliedCoupon?.course_id === id && <>
                        <div className=''>
                            <div className='checkout-order-col'>

                            </div>
                            <div className='checkout-order-col'>
                                <p className='checkout-order-prdname'>Discount Amount</p>
                                <div className="flex items-baseline justify-between">
                                    <h3
                                        className='checkout-order-pricetax text-success'
                                    >
                                        <span>-{appliedCoupon?.discount_type == 0 ? `${appliedCoupon?.discount_amount}%` : `₹ ${appliedCoupon?.discount_amount}`}</span>
                                    </h3>
                                    <button className="cart-delet-btn" onClick={() => removeCoupon()}>
                                        DELETE <span>+</span>
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div className='checkout-order-wrapper'>
                            <p className='checkout-order-prdname'>Subtotal</p>
                            <h2 className='checkout-total-price'>
                                <span>₹</span>
                                {total - calculateDiscountAmount(appliedCoupon, total)}
                            </h2>
                        </div>
                    </>}
                </div>

            </>}
        </>
    )
}

export default Coupon