import React, { useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import { Link } from 'react-router-dom';
import { _logo_ } from '../libs/ImagePath';
import { RxCross2 } from "react-icons/rx";
import { useImmer } from 'use-immer';
import apiPath from '../apipath';
import useDebounce from '../hooks/useDebounce';
import { fetchDataFromApi, setPageIndexOne } from '../libs/AllGlobalFunctions';
import NotFound from '../pages/NotFound';

const SearchDrawer = ({ open, setOpen, logo }) => {

    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [stockList, setStockList] = useState([]);
    let [filterData, setFilterData] = useImmer({
        search: ''
    });

    const debounceSearchQuery = useDebounce(searchQuery, 500);

    useEffect(() => {
        if (debounceSearchQuery) {
            setPageIndexOne(debounceSearchQuery, setFilterData, "search");
        } else {
            setPageIndexOne("", setFilterData, "search");
        }
    }, [debounceSearchQuery]);

    useEffect(() => {
        if (searchQuery?.length > 0) {
            fetchStockList();
        } else {
            setStockList([]);
        }
    }, [filterData]);

    const fetchStockList = () => {
        setLoading(true);
        setStockList([]);
        fetchDataFromApi(apiPath.getStockSearch, null, 'GET', filterData).then((response) => {
            const data = response?.data?.data;
            if (data) {
                setStockList(data)
            } else {
                setStockList([]);
            }
            setLoading(false);
        }

        ).catch((error) => {
            console.log(error);
            setLoading(false);
        })
    }

    return (
        <Drawer anchor={'top'} open={open}
        // onClose={toggleDrawer(false)}
        >
            <div className='flex justify-between items-center p-8' >
                <Link
                    to="/"
                    className="m_logo-link"
                >
                    {/* StockSmart <span>Academy</span> */}
                    <img src={`${_logo_}${logo}`} alt='' width={120} />
                </Link>
                <div>
                    <button
                        onClick={() => setOpen(false)}
                    >
                        <RxCross2 className="text-[24px]" />
                    </button>
                </div>
            </div>

            <section className="pt-0 p-8">
                <div className='pc-search w-100'>
                    <input
                        name="title"
                        type='text'
                        className='pc-search-input bg-transparent'
                        placeholder='Search Instruments...'
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                    />
                    <i className='m-icon icon-search'></i>
                </div>

                <div className={`${stockList?.length > 0 && 'h-[400px]'} overflow-y-auto`}>

                    {loading && <div className='flex justify-center items-center h-full text-center'>Loading...</div>}

                    {!loading && stockList?.length == 0 && searchQuery?.length > 0 && <div className='text-center'><NotFound title={"No Data Found!"} /></div>}

                    {stockList && stockList?.map((stock, index) => (
                        <li className="list-unstyled ml-3 my-3">
                            <Link key={index} to={`/instruments/${stock.subLabel}`} onClick={() => setOpen(false)}>
                                <h2 className="text-xl text-teal">{stock.label}</h2>
                                <span className='text-gray-400 text-sm'>
                                {stock.subLabel}
                                </span>
                                
                            </Link>
                        </li>
                    ))}

                </div>
            </section>
        </Drawer>
    )
}

export default SearchDrawer