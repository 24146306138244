import React, { useState, useEffect } from 'react'
import Breadcrumb from "../../component/common/Breadcrumb";
import DataTables from "../../datatable/DataTable";
import { FaEdit, FaTrash, FaEye } from "react-icons/fa";
import { getApi, postApi } from "../../helper/helper";
import { Button, Form, Modal } from "react-bootstrap";
import { Card } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import {
    alert,
    alertErr,
    alertInfo,
    alertSuccess,
    notify,
    notifyErr,
} from "../../component/common/notify";
import {
    confirmDelete,
    confirmStatus,
} from "../../component/common/handleData";
import { Link, useParams } from "react-router-dom";
import apiPath from "../../../apipath";
import Loader from '../../../components/Loader';




const StockHoliday = () => {

    const [data, setData] = useState([]);
    const [pending, setPending] = useState(true);
    const [formData, setFormData] = useState({});
    const [formDataUpdate, setFormDataUpdate] = useState({});
    const [show, setShow] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);

    const handleShow = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setFormData({});
        reset({
            date: ""
        });
    };

    const handleInputChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
    };

    const handleShowUpdate = (row) => {
        setShowUpdate(true);
        getIndividualData(row.id);
    };

    const handleCloseUpdate = () => {
        setShowUpdate(false);
        setFormDataUpdate({});
        reset1({
            date: ""
        });
    };

    const handleInputChange1 = (field, value) => {
        setFormDataUpdate({ ...formDataUpdate, [field]: value });
    };


    const fetchTableData = async () => {
        setPending(true);
        try {
            const result = await getApi(`/api/getStockHolidayList`);
            setData(result);
        } catch (error) {
            console.error(error);
        } finally {
            setPending(false);
        }
    };


    useEffect(() => {
        fetchTableData();
    }, [])

    const columns = [
        {
            name: <th className="fs-6 ">#</th>,
            cell: (row, index) => index + 1,
            width: "100px",
        },

        {
            name: <th className="fs-6 ">Date</th>,
            selector: (row) => row.date,
            width: "200px",
        },
        // {
        //     name: <th className="fs-6">Status</th>,
        //     cell: (row, index) =>
        //         row.status == 0 ? (
        //             <span
        //                 className="bg-info text-white p-1 rounded cursor-pointer"
        //             // onClick={() =>
        //             //   confirmStatus("/api/updateFaqStatus", row.id, "1")
        //             // }
        //             >
        //                 Active
        //             </span>
        //         ) : row.status == 1 ? (
        //             <span
        //                 className=" bg-success text-white p-1 rounded cursor-pointer"
        //             // onClick={() =>
        //             //   confirmStatus("/api/updateFaqStatus", row.id, "0")
        //             // }
        //             >
        //                 InActive
        //             </span>
        //         ) : (
        //             "-"
        //         ),
        //     width: "220px",
        // },
        {
            name: <th className="fs-6 action">Action</th>,
            selector: (row) => (
                <div className="d-flex" style={{ gap: "10px" }}>
                    {/* <Button
                        className="text-white border-0"
                        style={{ float: "right", backgroundColor: "var(--primary)" }}
                        onClick={() => handleShowUpdate(row)}
                    >
                        <FaEdit />
                    </Button> */}

                    <Button
                        className="bg-danger text-white border-0"
                        onClick={() => handleDelete(`${`/api/deleteStockHoliday`}/${row.id}`)}
                    >
                        <FaTrash />
                    </Button>
                </div>
            ),
            right: true,
        },
    ];

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors: errors },
    } = useForm();

    const {
        register: register1,
        handleSubmit: handleSubmit1,
        reset: reset1,
        formState: { errors: errors1 },
    } = useForm();

    const onSubmit = async () => {
        setPending(true);
        try {
            const result = await postApi(`/api/addStockHoliday`, formData);
            handleClose();
            notify("Stock Holiday Added Successfully!");
            fetchTableData();

        } catch (error) {
            console.error(error);
        } finally {
            setPending(false);
        }
    };

    const getIndividualData = async (id) => {
        setPending(true);
        try {
            const result = await getApi(`${`/api/`}/${id}`);
            const data = result[0];
            if (data) {
                setFormDataUpdate((prevFormDataUpdate) => ({
                    ...prevFormDataUpdate,
                    ["id"]: data.id,
                    ["date"]: data.date
                }));
                reset1({
                    date: data.date
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setPending(false);
        }
    };

    const onUpdate = async () => {
        setPending(true);
        try {
            const result = await postApi(`/api/`, formDataUpdate);
            handleCloseUpdate();
            notify("Stock Holiday Updated Successfully!");
            fetchTableData();

        } catch (error) {
            console.error(error);
            notifyErr("Something went wrong!");
        } finally {
            setPending(false);
        }
    };


    // handleDelete 
    const handleDelete = (endPoint) => {
        confirmDelete(endPoint)
            .then((data) => {
                fetchTableData();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    // handleStatus 
    // const handleStatus = (endpoint, id, status) => {
    //        confirmStatus(endpoint, { id: id, status: status})
    //         .then((message) => {
    //             fetchTableData();
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // }


    const breadcrumbItems = [
        { title: 'Stock Holiday', link: null }
    ];


    return (
        <>
            <section>

                {pending && <Loader />}

                <ToastContainer />
                <div className="mb-5">
                    <Breadcrumb items={breadcrumbItems} />
                </div>

                <Card variant="outlined" className="pb-3">
                    <div className="btn-d d-flex justify-content-end m-3">

                        <Button
                            className="text-white border-0 default-btn"
                            onClick={() => handleShow()}
                        >
                            Add Stock Holiday
                        </Button>
                    </div>
                    <div>
                        <DataTables
                            title={"Stock Holiday"}
                            data={data}
                            columns={columns}
                            pending={pending}
                            paginationServer={false}
                            // totalRows={data?.length}
                        />
                    </div>
                </Card>
                {/* add */}
                <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>

                    <Modal.Header closeButton>
                        <Modal.Title>Add Stock Holiday</Modal.Title>
                    </Modal.Header>
                    <Form
                        className="row m-2"
                        onSubmit={handleSubmit(onSubmit)}
                        autoComplete="off"
                    >
                        <Modal.Body>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="date">Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="date"
                                    id="date"
                                    value={formData.date}
                                    {...register("date", {
                                        required: true,
                                        onChange: (e) => handleInputChange("date", e.target.value),
                                    })}
                                />
                                {errors.date && (
                                    <div className="errMsg text-danger">Please Select Date</div>
                                )}
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="submit"
                                variant="contained"
                                className="text-white btn-primary"
                            >
                                Add
                            </Button>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

                {/* update */}
                <Modal
                    show={showUpdate}
                    onHide={handleCloseUpdate}
                    style={{ zIndex: "9999" }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Update Stock Holiday</Modal.Title>
                    </Modal.Header>
                    <Form
                        className="row m-2"
                        onSubmit={handleSubmit1(onUpdate)}
                        autoComplete="off"
                    >
                        <Modal.Body>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="date">Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="date"
                                    id="date"
                                    value={formDataUpdate.date}
                                    {...register1("date", {
                                        required: true,
                                        onChange: (e) => handleInputChange1("date", e.target.value),
                                    })}
                                />
                                {errors1.date && (
                                    <div className="errMsg text-danger">Please Select Date</div>
                                )}
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="submit"
                                variant="contained"
                                className="text-white btn-primary"
                            >
                                Update
                            </Button>
                            <Button variant="secondary" onClick={handleCloseUpdate}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </section>
        </>
    )
}

export default StockHoliday