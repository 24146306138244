import React, { useState, useEffect } from "react";
import Breadcrumb from "../../component/common/Breadcrumb";
import DataTables from "../../datatable/DataTable";
import { FaEdit, FaTrash, FaEye } from "react-icons/fa";
import { Axios, getApi, postApi } from "../../helper/helper";
import { Button, Form, Modal } from "react-bootstrap";
import { Card, Rating } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  notify,
  notifyErr,
} from "../../component/common/notify";
import { Link } from "react-router-dom";
import { useImmer } from "use-immer";
import {
  confirmDelete,
  confirmStatus,
} from "../../component/common/handleData";
import apiPath from "../../../apipath";
import Loader from "../../../components/Loader";


const Course = () => {
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  let [filterData, setFilterData] = useImmer({
    page: 1,
    per_page: 10
  });

  const [pending, setPending] = useState(true);
  const [status, setStatus] = useState('0');
  const [instructor, setInstructor] = useState([]);
  const [category, setCategory] = useState([]);
  const [formData, setFormData] = useState({
    image: "",
  });
  const [formDataUpdate, setFormDataUpdate] = useState({
    image: "",
  });
  const [image, setImage] = useState("");
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setFormData({
      image: "",
    });
    reset({
      title: "",
      description: "",
      price: "",
      category_id: "",
      instructor_id: "",
      image: "",
    });
  };

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    if (field === 'type' && value == 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        // price: 0,
      }));
    }
  };

  const handleShowUpdate = (row) => {
    setShowUpdate(true);
    getIndividualData(row.id);
  };

  const handleCloseUpdate = () => {
    setShowUpdate(false);
    setFormDataUpdate({
      image: "",
    });
    reset1({
      title: "",
      description: "",
      type: "",
      price: "",
      category_id: "",
      instructor_id: "",
      difficulty_level: "",
      featured: "",
      image: "",
    });
  };

  const handleInputChange1 = (field, value) => {
    setFormDataUpdate({ ...formDataUpdate, [field]: value });
    if (field === 'type' && value == 0) {
      setFormDataUpdate((prevFormData) => ({
        ...prevFormData,
        // price: 0,
      }));
    }
  };

  const fetchTableData = async (filteredData) => {
    setPending(true);
    try {
      const result = await Axios.get(`${apiPath.getCourse}/${status}`, {
        params: filteredData,
      });
      if (result) {
        setData(result?.data?.data);
        setTotalRows(result?.data?.pagination?.total_data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  }

  useEffect(() => {
    fetchTableData(filterData);
  }, [filterData, status])

  const handlePageChange = page => {
    fetchTableData({ ...filterData, page: page });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    // fetchTableData({ page: page, per_page: newPerPage });
    setFilterData({
      page: page,
      per_page: newPerPage
    })
  }

  const fetchData = async () => {
    setPending(true);
    try {
      const result = await getApi(`/api/${apiPath.getCourseCategory}`);
      setCategory(result);
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }

    try {
      const result = await getApi(apiPath.getCourseInstructor);
      setInstructor(result);
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleStatusFilter = (value) => {
    setStatus(value);
  }

  const columns = [
    {
      name: <th className="fs-6 ">#</th>,
      cell: (row, index) => index + 1,
      width: "100px",
    },

    {
      name: <th className="fs-6 ">Title</th>,
      selector: (row) => row.title,
      width: "200px",
    },
    {
      name: <th className="fs-6">Image</th>,
      cell: (row) => <img src={`/api/assets/course/${row.image}`} width="60px" height="60px" className="p-2" alt='Image' />,
      width: "200px",
    },
    {
      name: <th className="fs-6">Price</th>,
      selector: (row) => row.price,
      width: "200px",
    },
    {
      name: <th className="fs-6">Average Rating</th>,
      cell: (row, index) => <Rating name="half-rating-read" defaultValue={row?.average_rating} precision={0.5} readOnly />,
      width: "200px",
    },
    {
      name: <th className="fs-6">Status</th>,
      cell: (row, index) =>
        row.status == 0 ? (
          <span
            className="bg-success text-white p-1 rounded cursor-pointer"
            onClick={() =>
              handleStatus("/api/updateCourseStatus", row.id, "1")
            }
          >
            Active
          </span>
        ) : row.status == 1 ? (
          <span
            className=" bg-warning text-white p-1 rounded cursor-pointer"
            onClick={() =>
              handleStatus("/api/updateCourseStatus", row.id, "0")
            }
          >
            In-Active
          </span>
        ) : (
          "-"
        ),
      width: "220px",
    },
    {
      name: <th className="fs-6 action">Action</th>,
      selector: (row) => (
        <div className="d-flex" style={{ gap: "10px" }}>
          <Link to={`/admin/course/${row.id}`}>
            <Button
              className="text-white border-0 btn-view"
              style={{
                float: "right",
              }}
            >
              <FaEye />
            </Button>
          </Link>

          <Button
            className="text-white border-0"
            style={{ float: "right", backgroundColor: "var(--primary)" }}
            onClick={() => handleShowUpdate(row)}
          >
            <FaEdit />
          </Button>

          <Button
            className="bg-danger text-white border-0"
            onClick={() => handleDelete(`${apiPath.deleteCourse}/${row.id}`)}
          >
            <FaTrash />
          </Button>
        </div>
      ),
      right: true,
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: errors },
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm();

  const onSubmit = async () => {
    setPending(true);
    try {
      const result = await postApi(apiPath.addCourse, {
        ...formData,
        type: 1,

      });

      handleClose();
      notify("Course Added Successfully!");
      fetchTableData(filterData);

    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  const getIndividualData = async (id) => {
    setPending(true);
    try {
      const result = await getApi(`/api/${apiPath.getSingleCourse}/${id}`);
      const data = result[0];
      if (data) {
        setFormDataUpdate((prevFormDataUpdate) => ({
          ...prevFormDataUpdate,
          ["id"]: data.id,
          ["title"]: data.title,
          ["price"]: data.price,
          ["type"]: data.type,
          ["description"]: data.description,
          ["instructor_id"]: data.instructor_id,
          ["category_id"]: data.category_id,
          ["difficulty_level"]: data.difficulty_level,
          ["featured"]: data.featured,
        }));
        reset1({
          title: data.title,
          description: data.description,
          type: data.type,
          price: data.price,
          category_id: data.category_id,
          instructor_id: data.instructor_id,
          difficulty_level: data.difficulty_level,
          featured: data.featured,
          image: data.image,
        });
        setImage(data.image);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  const onUpdate = async () => {
    setPending(true);
    try {
      const result = await postApi(apiPath.updateCourse, formDataUpdate);

      handleCloseUpdate();
      notify("Course Updated Successfully!");
      fetchTableData(filterData);

    } catch (error) {
      console.error(error);
      notifyErr("Something went wrong!");
    } finally {
      setPending(false);
    }
  };

  // handleDelete 
  const handleDelete = (endPoint) => {
    confirmDelete(endPoint)
      .then((data) => {
        fetchTableData(filterData);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // handleStatus 
  const handleStatus = (endpoint, id, status) => {
    confirmStatus(endpoint, { id: id, status: status })
      .then((message) => {
        fetchTableData(filterData);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const breadcrumbItems = [
    { title: 'Course', link: null }
  ];

  return (
    <section>

      {pending && <Loader />}

      <ToastContainer />
      <div className="mb-5">
        <Breadcrumb items={breadcrumbItems} />
      </div>

      <Card variant="outlined" className="pb-3">
        <div className="btn-d flex flex flex-col sm:flex-row row-gap-3 justify-content-between m-3">
          <Form.Select value={status} className="filter-select w-auto"
            onChange={(e) => handleStatusFilter(e.target.value)}
          >
            <option value="0">Active</option>
            <option value="1">In-Active</option>
            <option value="2">All</option>
          </Form.Select>
          <Button
            className="text-white border-0 default-btn"
            onClick={() => handleShow()}
          >
            Add Course
          </Button>
        </div>
        <div>
          <DataTables
            title={"Course"}
            data={data}
            columns={columns}
            pending={pending}
            paginationServer={true}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
          />
        </div>
      </Card>

      {/* add */}
      <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>

        <Modal.Header closeButton>
          <Modal.Title>Add Course</Modal.Title>
        </Modal.Header>
        <Form
          className="row m-2"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="category_id">Category Name</Form.Label>
              <Form.Select
                type="type"
                id="category_id"
                placeholder="Category"
                value={formData.category_id}
                {...register("category_id", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("category_id", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                {category?.map((category, index) => (
                  <option key={index} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Form.Select>
              {errors.category_id && (
                <div className="errMsg text-danger">Please Select Type</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="instructor_id">Instructor</Form.Label>
              <Form.Select
                type="type"
                id="instructor_id"
                placeholder="Instructor"
                value={formData.instructor_id}
                {...register("instructor_id", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("instructor_id", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                {instructor?.map((instructor, index) => (
                  <option key={index} value={instructor.id}>
                    {instructor.instructor_name}
                  </option>
                ))}
              </Form.Select>
              {errors.instructor_id && (
                <div className="errMsg text-danger">Please Select Type</div>
              )}
            </Form.Group>
            {/* <Form.Group className="mb-3">
              <Form.Label htmlFor="type">Type</Form.Label>
              <Form.Select
                type="type"
                id="type"
                placeholder="type"
                value={formData.type}
                {...register("type", {
                  required: true,
                  onChange: (e) => handleInputChange("type", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                <option value="0">Free</option>
                <option value="1">Paid</option>
              </Form.Select>
              {errors.type && (
                <div className="errMsg text-danger">Please Select Type</div>
              )}
            </Form.Group> */}
            {/* {formData.type == 1 && */}
            <Form.Group className="mb-3">
              <Form.Label htmlFor="price">Price</Form.Label>
              <Form.Control
                type="number"
                id="price"
                min="1"
                placeholder="price"
                value={formData.price}
                {...register("price", {
                  required: true,
                  onChange: (e) => handleInputChange("price", e.target.value),
                })}
              />
              {errors.price && (
                <div className="errMsg text-danger">Please Enter Price</div>
              )}
            </Form.Group>
            {/* } */}
            <Form.Group className="mb-3">
              <Form.Label htmlFor="difficulty_level">Difficulty Level</Form.Label>
              <Form.Select
                type="type"
                id="difficulty_level"
                placeholder="Difficulty Level"
                value={formData.difficulty_level}
                {...register("difficulty_level", {
                  required: true,
                  onChange: (e) => handleInputChange("difficulty_level", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                <option value="0">Beginner</option>
                <option value="1">Intermediate</option>
                <option value="2">Advance</option>
              </Form.Select>
              {errors.difficulty_level && (
                <div className="errMsg text-danger">Please Select Type</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Featured</Form.Label>
              <br></br>
              <Form.Check
                inline
                label="Yes"
                name="featured"
                type="radio"
                id={`inline-radio-1`}
                value="0"
                {...register('featured', {
                  required: true,
                  onChange: (e) => handleInputChange("featured", e.target.value)

                })}
              />
              <Form.Check
                inline
                label="No"
                name="featured"
                type="radio"
                id={`inline-radio-2`}
                value="1"
                {...register('featured', {
                  required: true,
                  onChange: (e) => handleInputChange("featured", e.target.value)

                })}
              />
              {errors.featured && <div className="errMsg text-danger">This field is required</div>}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="title">Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                id="title"
                value={formData.title}
                {...register("title", {
                  required: true,
                  onChange: (e) => handleInputChange("title", e.target.value),
                })}
              />
              {errors.title && (
                <div className="errMsg text-danger">Please Enter Title</div>
              )}
            </Form.Group>


            <Form.Group className="mb-3">
              <Form.Label htmlFor="description">Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                id="description"
                placeholder="Description"
                value={formData.description}
                {...register("description", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange("description", e.target.value),
                })}
              />
              {errors.description && (
                <div className="errMsg text-danger">
                  Please Enter Description
                </div>
              )}
            </Form.Group>

            <Form.Group className="mt-2">
              <div className="row">
                <div className="col-12">
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    {...register("image", {
                      required: true,
                      onChange: (e) =>
                        handleInputChange("image", e.target.files[0]),
                    })}
                  />

                  {errors.image && (
                    <div className="errMsg text-danger">
                      Please Select Image
                    </div>
                  )}
                </div>

                <div className="col-12 text-center mb-3 mt-3">
                  <Form.Label>Image Preview</Form.Label>
                  <div className="p-3">
                    <img
                      src={
                        formData.image == ""
                          ? "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/330px-No-Image-Placeholder.svg.png?20200912122019"
                          : URL.createObjectURL(formData.image)
                      }
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        margin: "auto",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="contained"
              className="text-white btn-primary"
            >
              Add
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* update */}
      <Modal
        show={showUpdate}
        onHide={handleCloseUpdate}
        style={{ zIndex: "9999" }}
      >

        <Modal.Header closeButton>
          <Modal.Title>Update Course</Modal.Title>
        </Modal.Header>
        <Form
          className="row m-2"
          onSubmit={handleSubmit1(onUpdate)}
          autoComplete="off"
        >
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="category_id">Category</Form.Label>
              <Form.Select
                type="type"
                id="category_id"
                placeholder="Category"
                value={formDataUpdate.category_id}
                {...register1("category_id", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange1("category_id", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                {category?.map((category, index) => (
                  <option key={index} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Form.Select>
              {errors1.category_id && (
                <div className="errMsg text-danger">Please Select Type</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="instructor_id">Instructor</Form.Label>
              <Form.Select
                type="type"
                id="instructor_id"
                placeholder="Instructor"
                value={formDataUpdate.instructor_id}
                {...register1("instructor_id", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange1("instructor_id", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                {instructor?.map((instructor, index) => (
                  <option key={index} value={instructor.id}>
                    {instructor.instructor_name}
                  </option>
                ))}
              </Form.Select>
              {errors1.instructor_id && (
                <div className="errMsg text-danger">Please Select Type</div>
              )}
            </Form.Group>
            {/* <Form.Group className="mb-3">
              <Form.Label htmlFor="type">Type</Form.Label>
              <Form.Select
                type="type"
                id="type"
                placeholder="type"
                value={formDataUpdate.type}
                {...register1("type", {
                  required: true,
                  onChange: (e) => handleInputChange1("type", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                <option value="0">Free</option>
                <option value="1">Paid</option>
              </Form.Select>
              {errors1.type && (
                <div className="errMsg text-danger">Please Select Type</div>
              )}
            </Form.Group> */}
            {/* {formDataUpdate.type == 1 && */}
            <Form.Group className="mb-3">
              <Form.Label htmlFor="price">Price</Form.Label>
              <Form.Control
                type="number"
                id="price"
                min="1"
                placeholder="price"
                value={formDataUpdate.price}
                {...register1("price", {
                  required: true,
                  onChange: (e) => handleInputChange1("price", e.target.value),
                })}
              />
              {errors1.price && (
                <div className="errMsg text-danger">Please Enter Price</div>
              )}
            </Form.Group>
            {/* } */}
            <Form.Group className="mb-3">
              <Form.Label htmlFor="difficulty_level">Difficulty Level</Form.Label>
              <Form.Select
                type="type"
                id="difficulty_level"
                placeholder="Difficulty Level"
                value={formDataUpdate.difficulty_level}
                {...register1("difficulty_level", {
                  required: true,
                  onChange: (e) => handleInputChange1("difficulty_level", e.target.value),
                })}
              >
                <option value="">Select Type</option>
                <option value="0">Beginner</option>
                <option value="1">Intermediate</option>
                <option value="2">Advance</option>
              </Form.Select>
              {errors1.difficulty_level && (
                <div className="errMsg text-danger">Please Select Type</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Featured</Form.Label>
              <br></br>
              <Form.Check
                checked={formDataUpdate?.featured === '0'}
                inline
                label="Yes"
                name="featured"
                type="radio"
                id={`inline-radio-3`}
                value="0"
                {...register1('featured', {
                  required: true,
                  onChange: (e) => handleInputChange1("featured", e.target.value)

                })}
              />
              <Form.Check
                checked={formDataUpdate?.featured === '1'}
                inline
                label="No"
                name="featured"
                type="radio"
                id={`inline-radio-4`}
                value="1"
                {...register1('featured', {
                  required: true,
                  onChange: (e) => handleInputChange1("featured", e.target.value)

                })}
              />
              {errors1.featured && <div className="errMsg text-danger">This field is required</div>}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="title">Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                id="title"
                value={formDataUpdate.title}
                {...register1("title", {
                  required: true,
                  onChange: (e) => handleInputChange1("title", e.target.value),
                })}
              />
              {errors1.title && (
                <div className="errMsg text-danger">Please Enter Title</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="description">Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                id="description"
                placeholder="Description"
                value={formDataUpdate.description}
                {...register1("description", {
                  required: true,
                  onChange: (e) =>
                    handleInputChange1("description", e.target.value),
                })}
              />
              {errors1.description && (
                <div className="errMsg text-danger">
                  Please Enter Description
                </div>
              )}
            </Form.Group>

            <Form.Group className="mt-2">
              <div className="row">
                <div className="col-12">
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    {...register1("image", {
                      required: false,
                      onChange: (e) =>
                        handleInputChange1("image", e.target.files[0]),
                    })}
                  />

                  {errors1.image && (
                    <div className="errMsg text-danger">
                      Please Select Image
                    </div>
                  )}
                </div>

                <div className="col-12 text-center mb-3 mt-3">
                  <Form.Label>Image Preview</Form.Label>
                  <div className="p-3">
                    <img
                      src={
                        formDataUpdate.image == ""
                          ? image != ""
                            ? `/api/assets/course/${image}`
                            : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/330px-No-Image-Placeholder.svg.png?20200912122019"
                          : URL.createObjectURL(formDataUpdate.image)
                      }
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        margin: "auto",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="contained"
              className="text-white btn-primary"
            >
              Update
            </Button>
            <Button variant="secondary" onClick={handleCloseUpdate}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </section>
  );
};

export default Course;
