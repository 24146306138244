import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import staricon from '../icons/star.svg'
import Slider from './Slider'
import apiPath from '../apipath'
import { fetchDataFromApi } from '../libs/AllGlobalFunctions'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Rating } from '@mui/material'

const TestimonialSkeleton = () => {
  return (
    <div className='testimonial-slider'>
      <div className='testimonial-slider-item'>
        <div className='tst-user-img'>
          <Skeleton circle={true} height={60} width={60} />
        </div>
        <Skeleton count={3} />
        <Skeleton width={`60%`} height={20} style={{ margin: '10px 0' }} />
        <Skeleton width={`50%`} height={20} />
      </div>
    </div>
  );
};

const StarRating = ({ rating }) => {
  const stars = Array.from({ length: rating }, (_, index) => (
    <i key={index} className='star-icon'>
      <img src={staricon} alt='' />
    </i>
  ));
  return <div className='h-pc-raitting-items tst-raitting'>{stars}</div>;
};

export default function Testimonials() {

  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = () => {
    setLoading(true);
    fetchDataFromApi(apiPath.getTestimonials, null, 'GET', {}).then((response) => {
      if (response && response?.data?.data) {
        setTestimonials(response?.data?.data)
      } else {
        setTestimonials([]);
      }
      setLoading(false);
    }
    ).catch((error) => {
      setLoading(false);
      console.log(error)
    })
  }

  return (
    <>
      {
        loading ? <TestimonialSkeleton /> : <section className='section_spacing  '>
          <div className='site-container'>
            <div className='testimonial-wrapper'>
              <div className='testimonial-items'>
                <h5 className='h-about-title'>TESTIMONIALS</h5>
                <div className='section-title-wrap h-about-title-wrap'>
                  <h2 className='section-title'>
                    What Our Students Have <span>To Say</span>{' '}
                  </h2>
                  {/* <p className='section-title-contain'>
                    Empowering individuals worldwide with cutting-edge stock trading education, TradesMate is dedicated to your financial success. Discover, learn, and conquer the markets with us.
                  </p> */}
                </div>
              </div>
              {/* testimonial slider  */}
              <div className='testimonial-items'>
                <Slider
                  spaceBetween={0}
                  slidesPerView={1}
                  loop={true}
                  autoplay={true}
                  navigation={false}
                  pagination={false}
                  breakpoints={{
                    768: {
                      slidesPerView: 2
                    },
                  }}
                >
                  {
                    testimonials?.map((item) => {
                      return (
                        <div className='testimonial-slider h-full' key={item.id}>
                          <div className='testimonial-slider-item'>
                            <div className='tst-user-img'>
                            </div>
                            {/* <h2 className='tst-name-user mb-2'>{item?.title}s</h2> */}
                            <p className='section-title-contain tst-contain'>
                              {item?.content}
                            </p>
                            {/* <StarRating rating={item?.stars} /> */}
                            <Rating name="half-rating-read" defaultValue={item?.stars} precision={0.5} size="small" readOnly />
                            <h2 className='tst-name-user'>{item?.name}s</h2>
                            {/* <p className='section-title-contain  tst-contain'>
                              {item?.position}
                            </p> */}
                          </div>
                        </div>
                      )
                    })
                  }
                </Slider>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  )
}
