
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

const Slider = ({
  children,
  spaceBetween,
  loop,
  slidesPerView,
  speed,
  autoplay,
  breakpoints,
  navigationPrevClass,
  navigationNextClass,
  pagination,
  direction,
}) => {
  return (
    <Swiper

      direction={direction}
      spaceBetween={spaceBetween || 0}
      slidesPerView={slidesPerView || 1}
      loop={loop || false}
      speed={speed || 500}
      autoplay={autoplay ? { delay: 3000 } : false}
      // navigation={navigation ? true : false}
      pagination={pagination ? { clickable: true } : false}
      breakpoints={breakpoints}
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      navigation={{
        prevEl: `.${navigationPrevClass}`,
        nextEl: `.${navigationNextClass}`,
      }}
    >
      {Array.isArray(children) ? (
        children.map((child, index) => (
          <SwiperSlide key={index}>{child}</SwiperSlide>
        ))
      ) : (
        // Render a default component or handle the case where children is not an array
        // For example:
        <SwiperSlide>{children}</SwiperSlide>
      )}
    </Swiper>
  );
}

export default Slider;
