import { createSlice } from "@reduxjs/toolkit";
import { destroyCookie } from "nookies"

const initialState = {
    adminInfo: "",
    accessToken: ""
}

const adminSlice = createSlice({
    name: "admin",
    initialState: initialState,
    reducers: {
        setAdmin: (state, action) => {
            state.adminInfo = action.payload.currentAdmin
            state.accessToken = action.payload.accessToken
        },
        logout: (state) => {
            destroyCookie(null, 'adminToken', { path: '/admin' })
            localStorage.removeItem('adminData')
            state.adminInfo = null
            state.accessToken = null
        }
    }
})

export const { setAdmin, logout } = adminSlice.actions
export const { reducer: adminReducer } = adminSlice;

export const selectCurrentAdmin = (state) => state.admin.adminInfo;