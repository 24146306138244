import React, { useState, useEffect } from "react";
import Breadcrumb from "../../component/common/Breadcrumb";
import DataTables from "../../datatable/DataTable";
import { FaEdit, FaShare, FaTrash } from "react-icons/fa";
import { getApi, postApi } from "../../helper/helper";
import { Button, Form, Modal } from "react-bootstrap";
import { Card } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  alert,
  alertErr,
  alertInfo,
  alertSuccess,
  notify,
  notifyErr,
} from "../../component/common/notify";
import apiPath from "../../../apipath";
import Loader from "../../../components/Loader";

const Enquiry = () => {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
  const [status, setStatus] = useState('0');
  const [formData, setFormData] = useState({});
  const [showUpdate, setShowUpdate] = useState(false);

  const handleShowUpdate = (row) => {
    setShowUpdate(true);
    getIndividualData(row.id);
  };

  const handleCloseUpdate = () => {
    setShowUpdate(false);
    setFormData({});
    reset({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const fetchTableData = async () => {
    setPending(true);
    try {
      const result = await getApi(`${apiPath.getEnquiry}/${status}`);
      setData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    fetchTableData();
  }, [status])

  const handleStatusFilter = (value) => {
    setStatus(value);
  }

  const columns = [
    {
      name: <th className="fs-6 ">#</th>,
      cell: (row, index) => index + 1,
      width: "100px",
    },

    {
      name: <th className="fs-6 ">Name</th>,
      selector: (row) => row.name,
      width: "200px",
    },
    {
      name: <th className="fs-6">Email</th>,
      selector: (row) => row.email,
      width: "200px",
    },
    {
      name: <th className="fs-6">Phone</th>,
      selector: (row) => row.phone,
      width: "200px",
    },
    {
      name: <th className="fs-6">Course Name</th>,
      selector: (row) => row.course_title,
      width: "200px",
    },
    {
      name: <th className="fs-6">Status</th>,
      cell: (row, index) =>
        row.status == 0 ? (
          <span className="bg-info text-white p-1 rounded cursor-pointer"
            onClick={() => handleShowUpdate(row)}
          >Pending</span>
        ) : row.status == 1 ? (
          <span className=" bg-success text-white p-1 rounded cursor-pointer">Complete</span>
        ) : (
          "-"
        ),
      width: "220px",
    },
    {
      name: <th className="fs-6 action">Action</th>,
      selector: (row) => (
        <div className="d-flex" style={{ gap: "10px" }}>

          <Button
            className="text-white border-0" disabled={row.status == 1 && true}
            style={{ float: "right", backgroundColor: "var(--primary)" }}
            onClick={() => row.status == 0 && handleShowUpdate(row)}
          >
            <FaShare />
          </Button>

        </div >
      ),
      right: true,
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: errors },
  } = useForm();

  const getIndividualData = async (id) => {
    setPending(true);
    try {
      const result = await getApi(`${apiPath.getSingleEnquiry}/${id}`);
      const data = result[0];
      if (data) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ["id"]: data.id,
          ["name"]: data.name,
          ["phone"]: data.phone,
          ["email"]: data.email,
          ["message"]: data.message,
        }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  const onReply = async () => {
    setPending(true);
    try {
      const result = await postApi(apiPath.enquiryReply, {
        id: formData.id,
        name: formData.name,
        email: formData.email,
        reply: formData.reply,
      });

      handleCloseUpdate();
      notify("Reply Sent Successfully!");
      fetchTableData();

    } catch (error) {
      console.error(error);
      notifyErr("Something went wrong!");
    } finally {
      setPending(false);
    }
  };

  const breadcrumbItems = [
    { title: 'Enquiry', link: null }
  ];

  return (
    <section>
      {pending && <Loader />}
      <ToastContainer />
      <div className="mb-5">
        <Breadcrumb items={breadcrumbItems} />
      </div>

      <Card variant="outlined" className="pb-3">
        <div className="btn-d flex flex-col sm:flex-row row-gap-3 justify-content-between m-3">
          <Form.Select value={status} className="filter-select w-auto"
            onChange={(e) => handleStatusFilter(e.target.value)}
          >
            <option value="0">Pending</option>
            <option value="1">Complete</option>
            <option value="2">All</option>
          </Form.Select>
        </div>
        <div>
          <DataTables
            title={"Enquiry"}
            data={data}
            columns={columns}
            pending={pending}
            paginationServer={false}
            // totalRows={data?.length}
          />
        </div>
      </Card>

      {/* update Modal */}
      <Modal
        show={showUpdate}
        onHide={handleCloseUpdate}
        style={{ zIndex: "9999" }}
      >

        <Modal.Header closeButton>
          <Modal.Title>Enquiry Reply</Modal.Title>
        </Modal.Header>
        <Form
          className="row m-2"
          onSubmit={handleSubmit(onReply)}
          autoComplete="off"
        >
          <Modal.Body>
            {/* <Form.Group className="mb-3">
              <Form.Label htmlFor="name">Name</Form.Label>
              <Form.Control
                disabled
                type="text"
                id="name"
                placeholder="Name"
                value={formData.name}
                {...register("name", {
                  required: false,
                  // onChange: (e) => handleInputChange("name", e.target.value),
                })}
              />
              {errors.name && (
                <div className="errMsg text-danger">Please Enter Name</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="email">Email</Form.Label>
              <Form.Control
                disabled
                type="email"
                id="email"
                placeholder="Email"
                value={formData.email}
                {...register("email", {
                  required: "Email is required",
                  maxLength: { value: 30, message: 'Email must be at most 30 characters long' },
                  pattern: { value: /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i, message: 'Invalid email address' },
                  // onChange: (e) => handleInputChange("email", e.target.value),
                })}
              />
              {errors.email && (
                <div className="errMsg text-danger">{errors.email.message}</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="phone">Phone</Form.Label>
              <Form.Control
                disabled
                type="text"
                id="phone"
                placeholder="Phone"
                value={formData.phone}
                {...register("phone", {
                  required: false,
                  // onChange: (e) => handleInputChange("phone", e.target.value),
                })}
              />
              {errors.phone && (
                <div className="errMsg text-danger">Please Enter Phone</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="message">Message</Form.Label>
              <Form.Control
                disabled
                as="textarea"
                rows={4}
                type="text"
                id="message"
                placeholder="Message"
                value={formData.message}
                {...register("message", {
                  required: false,
                  // onChange: (e) => handleInputChange("message", e.target.value),
                })}
              />
              {errors.message && (
                <div className="errMsg text-danger">Please Enter Message</div>
              )}
            </Form.Group> */}
            <Form.Group className="mb-3">
              <Form.Label htmlFor="reply">Reply</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                id="reply"
                placeholder="Reply"
                value={formData.reply}
                {...register("reply", {
                  required: true,
                  onChange: (e) => handleInputChange("reply", e.target.value),
                })}
              />
              {errors.reply && (
                <div className="errMsg text-danger">
                  Please Enter Reply Message
                </div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="contained"
              className="text-white btn-primary"

            >
              Reply
            </Button>
            <Button variant="secondary" onClick={handleCloseUpdate}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </section>
  );
};

export default Enquiry;
