import React, { useState, useEffect } from "react";
import Breadcrumb from "../../component/common/Breadcrumb";
import DataTables from "../../datatable/DataTable";
import { FaEdit, FaTrash } from "react-icons/fa";
import { getApi, postApi } from "../../helper/helper";
import { Button, Form, Modal } from "react-bootstrap";
import { Card } from "@mui/material";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { confirmDelete, confirmStatus } from "../../component/common/handleData";
import { notify, notifyErr } from "../../component/common/notify";
import apiPath from "../../../apipath";
import Loader from "../../../components/Loader";


const MembershipPlan = () => {

    const [data, setData] = useState([]);
    const [status, setStatus] = useState('0')
    const [pending, setPending] = useState(true);
    const [formData, setFormData] = useState({});
    const [formDataUpdate, setFormDataUpdate] = useState({});
    const [show, setShow] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);

    const handleShow = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setFormData({});
        reset({
            name: "",
            price: "",
            duration: ""
        });
    };

    const handleInputChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
    };

    const handleShowUpdate = (row) => {
        setShowUpdate(true);
        getIndividualData(row.id);
    };

    const handleCloseUpdate = () => {
        setShowUpdate(false);
        setFormDataUpdate({});
        reset1({
            name: "",
            price: "",
            duration: ""
        });
    };

    const handleInputChange1 = (field, value) => {
        setFormDataUpdate({ ...formDataUpdate, [field]: value });
    };

    const fetchTableData = async () => {
        setPending(true);
        try {
            const result = await getApi(`${apiPath.getAdminMembership}/${status}`);
            setData(result);
        } catch (error) {
            console.error(error);
        } finally {
            setPending(false);
        }
    };


    useEffect(() => {
        fetchTableData();
    }, [status])

    const columns = [
        {
            name: <th className="fs-6 ">#</th>,
            cell: (row, index) => index + 1,
            width: "100px",
        },
        {
            name: <th className="fs-6 ">Id</th>,
            selector: (row) => row.id,
            width: "200px",
        },
        {
            name: <th className="fs-6">Name</th>,
            selector: (row) => row.name,
            width: "200px",
        },
        {
            name: <th className="fs-6">Price</th>,
            selector: (row) => row.price,
            width: "200px",
        },
        {
            name: <th className="fs-6">Duration</th>,
            cell: (row, index) => row.duration == 0 ? '-' : `${row.duration} Month`,
            width: "200px",
        },
        {
            name: <th className="fs-6">Status</th>,
            cell: (row, index) =>
                row.status == 0 ? (
                    <span
                        className="bg-success text-white p-1 rounded cursor-pointer"
                        onClick={() =>
                            handleStatus("/api/updateMembershipStatus", row.id, "1")
                        }
                    >
                        Active
                    </span>
                ) : row.status == 1 ? (
                    <span
                        className=" bg-warning text-white p-1 rounded cursor-pointer"
                        onClick={() =>
                            handleStatus("/api/updateMembershipStatus", row.id, "0")
                        }
                    >
                        In-Active
                    </span>
                ) : (
                    "-"
                ),
            width: "220px",
        },
        {
            name: <th className="fs-6 action">Action</th>,
            selector: (row) => (
                <div className="d-flex" style={{ gap: "10px" }}>
                    <Button
                        className="text-white border-0"
                        style={{ float: "right", backgroundColor: "var(--primary)" }}
                        onClick={() => handleShowUpdate(row)}
                    >
                        <FaEdit />
                    </Button>

                    <Button
                        className="bg-danger text-white border-0"
                        onClick={() => handleDelete(`${apiPath.deleteMembership}/${row.id}`)}
                    >
                        <FaTrash />
                    </Button>
                </div>
            ),
            right: true,
        },

    ];

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors: errors },
    } = useForm();

    const {
        register: register1,
        handleSubmit: handleSubmit1,
        reset: reset1,
        formState: { errors: errors1 },
    } = useForm();

    const onSubmit = async () => {
        setPending(true);
        try {
            const result = await postApi(apiPath.addMembership, formData);
            handleClose();
            notify("Membership Added Successfully!");
            fetchTableData();
        } catch (error) {
            console.error(error);
        } finally {
            setPending(false);
        }
    };

    const getIndividualData = async (id) => {
        setPending(true);
        try {
            const result = await getApi(`${apiPath.getSingleMembership}/${id}`);
            const data = result[0];
            if (data) {
                setFormDataUpdate((prevFormDataUpdate) => ({
                    ...prevFormDataUpdate,
                    ["id"]: data.id,
                    ["name"]: data.name,
                    ["price"]: data.price,
                    ["duration"]: data.duration

                }));
                reset1({
                    name: data.name,
                    price: data.price,
                    duration: data.duration

                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setPending(false);
        }
    };

    const onUpdate = async () => {
        setPending(true);
        try {
            const result = await postApi(apiPath.updateMembership, formDataUpdate);
            handleCloseUpdate();
            notify("Membership Updated Successfully!");
            fetchTableData();

        } catch (error) {
            console.error(error);
            notifyErr("Something went wrong!");
        } finally {
            setPending(false);
        }
    };

    // handleDelete 
    const handleDelete = (endPoint) => {
        confirmDelete(endPoint)
            .then((data) => {
                fetchTableData();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    // handleStatus 
    const handleStatus = (endpoint, id, status) => {
           confirmStatus(endpoint, { id: id, status: status})
            .then((message) => {
                fetchTableData();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const handleStatusFilter = (value) => {
        setStatus(value);
    }

    return (
        <>
            <section>
                {pending && <Loader />}
                <Card variant="outlined" className="pb-3">
                    <div className="btn-d flex flex-col sm:flex-row row-gap-3 justify-content-between m-3">
                        <Form.Select value={status} className="filter-select  w-auto"
                            onChange={(e) => handleStatusFilter(e.target.value)}
                        >
                            <option value="0">Active</option>
                            <option value="1">In-Active</option>
                            <option value="2">All</option>
                        </Form.Select>
                        <Button
                            className="text-white border-0 default-btn"
                            onClick={() => handleShow()}
                        >
                            Add Membership Plan
                        </Button>
                    </div>
                    <div>
                        <DataTables
                            title={"Membership Plan"}
                            data={data}
                            columns={columns}
                            pending={pending}
                            paginationServer={false}
                            // totalRows={data?.length}
                        />
                    </div>
                </Card>
                {/* add */}
                <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>

                    <Modal.Header closeButton>
                        <Modal.Title>Add Membership Plan</Modal.Title>
                    </Modal.Header>
                    <Form
                        className="row m-2"
                        onSubmit={handleSubmit(onSubmit)}
                        autoComplete="off"
                    >
                        <Modal.Body>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="name">Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    id="name"
                                    value={formData.name}
                                    {...register("name", {
                                        required: true,
                                        onChange: (e) => handleInputChange("name", e.target.value),
                                    })}
                                />
                                {errors.name && (
                                    <div className="errMsg text-danger">Please Enter Name</div>
                                )}
                            </Form.Group>

                            {/* <Form.Group className="mb-3">
                                <Form.Label htmlFor="type">Type</Form.Label>
                                <Form.Select
                                    type="type"
                                    id="type"
                                    placeholder="Type"
                                    value={formData.type}
                                    {...register("type", {
                                        required: true,
                                        onChange: (e) => handleInputChange("type", e.target.value),
                                    })}
                                >
                                    <option value="">Select Type</option>
                                    <option value="0">Free</option>
                                    <option value="1">Paid</option>
                                </Form.Select>
                                {errors.type && (
                                    <div className="errMsg text-danger">Please Select Type</div>
                                )}
                            </Form.Group> */}

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="price">Price</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="price"
                                    min="0"
                                    placeholder="price"
                                    value={formData.price}
                                    {...register("price", {
                                        required: true,
                                        onChange: (e) => handleInputChange("price", e.target.value),
                                    })}
                                />
                                {errors.price && (
                                    <div className="errMsg text-danger">Please Enter Price</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="duration">Duration Month</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Duration Month"
                                    id="duration"
                                    min="0"
                                    value={formData.duration}
                                    {...register("duration", {
                                        required: true,
                                        onChange: (e) => handleInputChange("duration", e.target.value),
                                    })}
                                />
                                {errors.duration && (
                                    <div className="errMsg text-danger">Please Enter Duration</div>
                                )}

                            </Form.Group>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="submit"
                                variant="contained"
                                className="text-white btn-primary"
                            >
                                Add
                            </Button>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

                {/* update */}
                <Modal
                    show={showUpdate}
                    onHide={handleCloseUpdate}
                    style={{ zIndex: "9999" }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Update Membership Plan</Modal.Title>
                    </Modal.Header>
                    <Form
                        className="row m-2"
                        onSubmit={handleSubmit1(onUpdate)}
                        autoComplete="off"
                    >
                        <Modal.Body>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="name">Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    id="name"
                                    value={formDataUpdate.name}
                                    {...register1("name", {
                                        required: true,
                                        onChange: (e) => handleInputChange1("name", e.target.value),
                                    })}
                                />
                                {errors1.name && (
                                    <div className="errMsg text-danger">Please Enter Name</div>
                                )}
                            </Form.Group>

                            {/* <Form.Group className="mb-3">
                                <Form.Label htmlFor="type">Type</Form.Label>
                                <Form.Select
                                    type="type"
                                    id="type"
                                    placeholder="type"
                                    value={formDataUpdate.type}
                                    {...register1("type", {
                                        required: true,
                                        onChange: (e) => handleInputChange1("type", e.target.value),
                                    })}
                                >
                                    <option value="">Select Type</option>
                                    <option value="0">Free</option>
                                    <option value="1">Paid</option>
                                </Form.Select>
                                {errors1.type && (
                                    <div className="errMsg text-danger">Please Select Type</div>
                                )}
                            </Form.Group> */}

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="price">Price</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="price"
                                    min="0"
                                    placeholder="price"
                                    value={formDataUpdate.price}
                                    {...register1("price", {
                                        required: true,
                                        onChange: (e) => handleInputChange1("price", e.target.value),
                                    })}
                                />
                                {errors1.price && (
                                    <div className="errMsg text-danger">Please Enter Price</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="duration">Duration Month</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Duration Month"
                                    id="duration"
                                    min="0"
                                    value={formDataUpdate.duration}
                                    {...register1("duration", {
                                        required: true,
                                        onChange: (e) => handleInputChange1("duration", e.target.value),
                                    })}
                                />
                                {errors1.duration && (
                                    <div className="errMsg text-danger">Please Enter Duration</div>
                                )}

                            </Form.Group>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="submit"
                                variant="contained"
                                className="text-white btn-primary"

                            >
                                Update
                            </Button>
                            <Button variant="secondary" onClick={handleCloseUpdate}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </section>

        </>
    )
}

export default MembershipPlan