import { Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { getApi, postApi } from "../../helper/helper";
import { Card } from "@mui/material";
import Breadcrumb from "../../component/common/Breadcrumb";
import { notify, notifyErr } from "../../component/common/notify";
import { ToastContainer } from "react-toastify";
import apiPath from "../../../apipath";
import Loader from "../../../components/Loader";


const Profile = () => {
  const [pending, setPending] = useState(true);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    email: "",
    phone: "",
    address: "",
  });

  const fetchData = async () => {
    setPending(true);
    try {
      const result = await getApi(apiPath.getAdminProfile);
      const curData = result[0];
      if (curData) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ["id"]: curData.id,
          ["email"]: curData.email,
          ["name"]: curData.name,
          ["address"]: curData.address,
          ["phone"]: curData.phone,
        }));
        reset({
          name: curData.name,
          email: curData.email,
          phone: curData.phone,
          address: curData.address,
        })
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async () => {
    setPending(true);
    try {
      const result = await postApi(apiPath.updateAdminProfile, formData);
      notify("Profile Updated Successfully!");
      fetchData();
    } catch (error) {
      console.error(error);
      notifyErr("Something went wrong!");
    } finally {
      setPending(false);
    }
  };

  const breadcrumbItems = [
    { title: 'Profile', link: null }
  ];

  return (
    <>
      <Helmet>
        <title>Profile</title>
      </Helmet>

      <ToastContainer />

      {pending && <Loader />}

      <section>
        <div className="mb-5">
          <Breadcrumb items={breadcrumbItems} />
        </div>

        <Card variant="outlined" className="pb-3">
          <dvi className="m-3">
            <Form className="row m-0" onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder="name"
                  value={formData.name}
                  {...register("name", {
                    required: "Name is Required",
                    pattern: {
                      value: /^[a-zA-Z\s]{3,}$/,
                      message: 'Invalid name. Please enter only letters and spaces'
                    },
                    maxLength: { value: 20, message: 'Name must be at most 20 characters long' },
                    validate: {
                      fullName: (value) => {
                        const names = value.trim().split(' ');
                        return names.length >= 1 ? undefined : 'Please enter name';
                      }
                    },
                    onChange: (e) => handleInputChange("name", e.target.value),
                  })}
                />

                {errors.name && (
                  <div className="errMsg text-danger">{errors.name.message}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  className=""
                  type="email"
                  placeholder="Email"
                  value={formData.email}
                  {...register("Email", {
                    required: "Email is required",
                    maxLength: { value: 30, message: 'Email must be at most 30 characters long' },
                    pattern: { value: /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/, message: 'Invalid email address' },
                    onChange: (e) => handleInputChange("email", e.target.value),
                  })}
                />

                {errors.Email && (
                  <div className="errMsg text-danger">{errors.Email.message}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  className=""
                  type="tel"
                  placeholder="Phone"
                  value={formData.phone}
                  {...register("phone", {
                    required: 'Phone number is required',
                    pattern: {
                      value: /^[6789]\d{9}$/,
                      message: 'Invalid phone number'
                    },
                    onChange: (e) => handleInputChange("phone", e.target.value),
                  })}
                />

                {errors.phone && (
                  <div className="errMsg text-danger">{errors.phone.message}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-1">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  className=""
                  rows="5"
                  as="textarea"
                  placeholder="address"
                  value={formData.address}
                  {...register("address", {
                    required: true,
                    minLength: { value: 10, message: 'Address must be at least 10 characters.' },
                    onChange: (e) =>
                      handleInputChange("address", e.target.value),
                  })}
                />
              </Form.Group>

              <div className="btn-d d-flex justify-content-center">
                <Button
                  type="submit"
                  className="text-white border-0 default-btn btn btn-primary"
                  variant="contained"
                >
                  Update
                </Button>
              </div>
            </Form>
          </dvi>
        </Card>
      </section>
    </>
  );
};

export default Profile;
