import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import heroIcon1 from '../icons/hero-icon-1.svg'
import heroIcon2 from '../icons/hero-icon-2.svg'
import heroIcon3 from '../icons/hero-icon-3.svg'
import heroIcon4 from '../icons/hero-icon-4.svg'
import { fetchDataFromApi } from '../libs/AllGlobalFunctions';
import apiPath from '../apipath';
import { _banner_ } from '../libs/ImagePath'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useSelector } from 'react-redux'

const HeroSectionSkeleton = () => {
  return (
    <>
      <div className='site-container'>
        <div className='hero-section-wrapper'>
          <div className='hero-left'>
            <Skeleton height={40} width={300} /> {/* Title */}
            <Skeleton height={20} width={250} count={2} style={{ marginTop: '10px' }} /> {/* Description */}
            <Skeleton height={45} width={150} style={{ marginTop: '20px' }} /> {/* Button */}
          </div>
          <div className='hero-right'>
            <Skeleton height={300} width={'100%'} style={{ marginBottom: '20px' }} /> 
            <Skeleton circle={true} height={60} width={60} style={{ marginRight: '10px' }} /> 
            <Skeleton height={20} width={100} />
          </div>
        </div>
      </div>
      <div className='hero-corse-list'>
        {Array(4).fill(0).map((_, index) => (
          <div className='hero-corse-item' key={index}>
            <div className='hero-corse-item-wrapper'>
              <Skeleton circle={true} height={50} width={50} style={{ marginBottom: '10px' }} /> {/* Course Icon */}
              <Skeleton height={20} width={100} count={2} /> {/* Course Name */}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default function Herosection() {

  const [homeBanner, setHomeBanner] = useState({});

  const banners = useSelector((state) => state?.banner?.banners);

  const [countData, setCountData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(banners){
     let [bannerData] = banners?.filter(i => i.page_id == 1);
      setHomeBanner(bannerData);
    }
  }, [banners]);

  // const fetchHomeBanner = () => {
  //   setLoading(true)
  //   fetchDataFromApi(apiPath.getHomeBanner, null, 'GET', {}).then((response) => {
  //     if (response?.status == 200 && response?.data) {
  //       setHomeBanner(response?.data?.data[0]);
  //       setCountData(response?.data?.count_data);
  //       setLoading(false);
  //     } else {
  //       setHomeBanner({});
  //       setCountData({})
  //     }
  //   }
  //   ).catch((error) => {
  //     setLoading(false);
  //     console.log(error)
  //   })
  // }

  return (
    <section className='hero-section relative' style={{ backgroundImage: `url('${_banner_}/${homeBanner?.banner_image}')` }}>
      {loading ? (
        <div>
          <HeroSectionSkeleton />
        </div>
      ) : (
        <>
          <div className='site-container relative z-2'>
            <div className='hero-section-wrapper min-h-[550px]' >
              <div className='hero-left'>
                <h1 className='hero-title'>
                  {homeBanner?.banner_title}
                </h1>
                <p className='hero-contains'>
                  {homeBanner?.banner_description}
                </p>
                <Link to={homeBanner?.btn_slug} className='m-btn m-btn-hero'>
                  {homeBanner?.btn_text}<i className='m-icon btn-icon-arrow'></i>
                </Link>
              </div>
              {/* <div className='hero-right'> */}
              {/* <div className='hero-right-bg'>
                  <img src={`${_banner_}/${homeBanner?.banner_image}`} alt='' />
                </div> */}

              {/* <div className='hero-instructor'>
                  <h3 className='hero-instructor-title'>Instrunctor</h3>
                  <div className='hero-instructor-wrap'>
                    <img src={intructor} alt='' className='hero-intructor-img' />
                    <p className='total-instactors'>
                      {' '}
                      <span>{countData?.instructor}</span> Instactors
                    </p>
                  </div>
                </div> */}
              {/* </div> */}
            </div>
          </div>

          <div className='hero-corse-list relative z-2'>
            <div className='hero-corse-item'>
              <div className='hero-corse-item-wrapper'>
                <div className='hero-corse-icon'>
                  <img src={heroIcon1} alt="" />
                </div>
                <p className='ml-5'>
                  {/* {countData?.course} <br /> */}
                  Start Your Trading Journey</p>
              </div>
            </div>
            <div className='hero-corse-item'>
              <div className='hero-corse-item-wrapper'>
                <div className='hero-corse-icon'>
                  <img src={heroIcon2} alt="" />
                </div>
                <p className='hero-corse-contain ml-5'>Unlock Market Insights</p>
              </div>
            </div>
            <div className='hero-corse-item'>
              <div className='hero-corse-item-wrapper'>
                <div className='hero-corse-icon'>
                  <img src={heroIcon3} alt="" />
                </div>
                <p className='hero-corse-contain ml-5'>Explore Trading Strategies</p>
              </div>
            </div>
            <div className='hero-corse-item'>
              <div className='hero-corse-item-wrapper'>
                <div className='hero-corse-icon'>
                  <img src={heroIcon4} alt="" />
                </div>
                <p className='hero-corse-contain ml-5'>
                  {/* {countData?.membership} */}
                  Join Our Trading Community</p>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  )
}
