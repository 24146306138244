import { Link } from "react-router-dom";
import apiPath from "../apipath";
import { fetchDataFromApi } from "../libs/AllGlobalFunctions";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const CallToAction = () => {

    const [loading, setLoading] = useState(false);

   const settings = useSelector((state) => state?.setting?.settings);

    return (
        <section className='section_spacing pt-0'>
            <div className='site-container'>
                <div className='call-to-action-wrapper'>
                    <div className='cta-items'>
                        <h5>Get In Touch:</h5>
                        <a className='cta-items-mail' href={`mailto:${settings?.enq_email}`} >
                            {settings?.enq_email}
                        </a>
                    </div>
                    <div className=' cta-items cta-or-ditem'>
                        <h4 className='cta-or-title'>or</h4>
                    </div>

                    <div className='cta-items'>
                        <h5>Get In Touch:</h5>
                        <Link to={`tel:${settings?.phone}`} className='cta-items-mail'>
                            {settings?.phone}
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CallToAction;