const Loader = () => {
  return (
    <>
      <div className='m-loader'>
        <div className='m-loader--rounds'>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </>
  )
}

export default Loader;
