import React, { useState, useEffect } from "react";
import Breadcrumb from "../../component/common/Breadcrumb";
import DataTables from "../../datatable/DataTable";
import { FaEdit, FaTrash } from "react-icons/fa";
import { getApi, postApi } from "../../helper/helper";
import { Button, Form, Modal } from "react-bootstrap";
import { Card } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import {
    notify,
    notifyErr,
} from "../../component/common/notify";
import {
    confirmDelete,
    confirmStatus,
} from "../../component/common/handleData";
import apiPath from "../../../apipath";
import Loader from "../../../components/Loader";

const Coupon = () => {

    const [data, setData] = useState([]);
    const [userList, setUserList] = useState([]);
    const [activeCourse, setActiveCourse] = useState([]);
    const [membership, setMembership] = useState([])
    const [pending, setPending] = useState(true);
    // const [selectedLesson, setSelectedLesson] = useState([]);
    const [status, setStatus] = useState('0');
    const [show, setShow] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [formData, setFormData] = useState({
        user_id: [],
        course_id: [],
        membership_id: []
    });
    const [formDataUpdate, setFormDataUpdate] = useState({
        user_id: [],
        course_id: [],
        membership_id: []
    });

    const handleShow = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setFormData({
            user_id: [],
            course_id: [],
            membership_id: []
        });
        reset({
            coupon_code: '',
            description: '',
            discount_amount: "",
            min_amount: "",
            type: "",
            course_id: [],
            membership_id: [],
            user_limit: "",
            max_discount_amount: "",
            frequency: "",
            start_date: "",
            end_date: "",
            user_id: []
        });
    };

    const handleInputChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
    };

    const handleShowUpdate = (row) => {
        setShowUpdate(true);
        getIndividualData(row.id);
    };

    const handleCloseUpdate = () => {
        setShowUpdate(false);
        setFormDataUpdate({
            user_id: [],
            course_id: [],
            membership_id: []
        });
        reset1({
            coupon_code: '',
            description: '',
            discount_amount: "",
            min_amount: "",
            type: "",
            course_id: [],
            membership_id: [],
            user_limit: "",
            max_discount_amount: "",
            frequency: "",
            start_date: "",
            end_date: "",
            user_id: []
        });
    };

    const handleInputChange1 = (field, value) => {
        setFormDataUpdate({ ...formDataUpdate, [field]: value });
    };

    const fetchData = async () => {
        setPending(true);
        try {
            const result = await getApi(`/api/activeUser`);
            setUserList(result);
        } catch (error) {
            console.error(error);
        } finally {
            setPending(false);
        }

        try {
            const result = await getApi(`${apiPath.getAdminMembership}/${status}`);
            setMembership(result);
        } catch (error) {
            console.error(error);
        } finally {
            setPending(false);
        }

        try {
            const result = await getApi(`/api/getActiveCourse`);
            setActiveCourse(result);
        } catch (error) {
            console.error(error);
        } finally {
            setPending(false);
        }


    };

    const fetchTableData = async () => {
        setPending(true);
        try {
            const result = await getApi(`${apiPath.allCoupon}/${status}`);
            setData(result);
        } catch (error) {
            console.error(error);
        } finally {
            setPending(false);
        }
    };

    useEffect(() => {
        fetchTableData();
    }, [status]);

    useEffect(() => {
        fetchData();
    }, [])


    const handleStatusFilter = (value) => {
        setStatus(value);
    }

    const handleChange = (event, field, list) => {
        
        const {
            target: { value },
        } = event;
        if (value[value.length - 1] === "all") {
            setFormData({ ...formData, [field]: formData[field]?.length === list?.length ? [] : list?.map(id => id.id), });
            return;
        }
        setFormData({ ...formData, [field]: typeof value === 'string' ? value.split(',') : value, });
    };

    const handleChange1 = (event, field, list) => {
        const {
            target: { value },
        } = event;
        if (value[value.length - 1] === "all") {
            setFormDataUpdate({ ...formDataUpdate, [field]: formDataUpdate[field]?.length === list?.length ? [] : list?.map(id => id.id), });
            return;
        }
        setFormDataUpdate({ ...formDataUpdate, [field]: typeof value === 'string' ? value.split(',') : value, });
    };

    const columns = [
        {
            name: <th className="fs-6 ">#</th>,
            cell: (row, index) => index + 1,
            width: "100px",
        },
        {
            name: <th className="fs-6 ">Coupon Code</th>,
            selector: (row) => row.coupon_code,
            width: "200px",
        },
        {
            name: <th className="fs-6">Discount Type</th>,
            cell: (row, index) =>
                row.type === "0" ? "Discount Percentage" : row.type === "1" ? "Discount Price" : "-",
            width: "250px",
        },
        {
            name: <th className="fs-6 ">Discount Amount</th>,
            selector: (row) => row.discount_amount,
            width: "200px",
        },
        {
            name: <th className="fs-6 ">Minimum Amount</th>,
            selector: (row) => row.min_amount,
            width: "200px",
        },
        {
            name: <th className="fs-6">Frequency</th>,
            selector: (row) =>
                row.frequency,
            width: "150px",
        },
        {
            name: <th className="fs-6">Applied Count</th>,
            selector: (row) =>
                row.applied_count,
            width: "150px",
        },
        {
            name: <th className="fs-6 ">End Date</th>,
            selector: (row) => row.end_date,
            width: "300px",
        },
        {
            name: <th className="fs-6">Status</th>,
            cell: (row, index) =>
                row.status == 0 ? (
                    <span
                        className="bg-success text-white p-1 rounded cursor-pointer"
                        onClick={() =>
                            handleStatus(apiPath.couponStatus, row.id, "1")
                        }
                    >
                        Active
                    </span>
                ) : row.status == 1 ? (
                    <span
                        className=" bg-warning text-white p-1 rounded cursor-pointer"
                        onClick={() =>
                            handleStatus(apiPath.couponStatus, row.id, "0")
                        }
                    >
                        In-Active
                    </span>
                ) : (
                    "-"
                ),
            width: "320px",
        },
        {
            name: <th className="fs-6 action">Action</th>,
            selector: (row) => (
                <div className="d-flex" style={{ gap: "10px" }}>
                    {/*  */}
                    <Button
                        className="text-white border-0"
                        style={{ float: "right", backgroundColor: "var(--primary)" }}
                        onClick={() => handleShowUpdate(row)}
                    >
                        <FaEdit />
                    </Button>
                    {/* } */}
                    <Button
                        className="bg-danger text-white border-0"
                        onClick={() => handleDelete(`${apiPath.deleteCoupon}/${row.id}`)}
                    >
                        <FaTrash />
                    </Button>
                </div>
            ),
            // width: "220px",
            right: true,
        },
    ];

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors: errors },
    } = useForm();

    const {
        register: register1,
        handleSubmit: handleSubmit1,
        reset: reset1,
        formState: { errors: errors1 },
    } = useForm();


    // handleDelete 
    const handleDelete = (endPoint) => {
        confirmDelete(endPoint)
            .then((data) => {
                fetchTableData();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    // handleStatus 
    const handleStatus = (endpoint, id, status) => {
        confirmStatus(endpoint, { id: id, status: status })
            .then((message) => {
                fetchTableData();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const onSubmit = async () => {
        setPending(true);
        try {
            const result = await postApi(apiPath.addCoupon, {
                ...formData,
                user_id: formData?.user_id?.toString(),
                course_id: formData?.course_id?.toString(),
                membership_id: formData?.membership_id?.toString()
            });

            handleClose();
            notify("Coupon Added Successfully!");
            fetchTableData();

        } catch (error) {
            console.error(error);
            notifyErr("Something went wrong!");
        } finally {
            setPending(false);
        }
    };

    const getIndividualData = async (id) => {
        setPending(true);
        try {
            const result = await getApi(`${apiPath.singleCoupon}/${id}`);
            const data = result[0];
            if (data) {
                setFormDataUpdate((prevFormDataUpdate) => ({
                    ...prevFormDataUpdate,
                    ["id"]: data.id,
                    ["description"]: data.description,
                    ["coupon_code"]: data.coupon_code,
                    ["discount_amount"]: data.discount_amount,
                    ["min_amount"]: data.min_amount,
                    ["type"]: data.type,
                    ["course_id"]: data.course_id.split(','),
                    ["membership_id"]: data.membership_id.split(','),
                    ["user_limit"]: data.user_limit,
                    ["max_discount_amount"]: data.max_discount_amount,
                    ["frequency"]: data.frequency,
                    ["start_date"]: data.start_date,
                    ["end_date"]: data.end_date,
                    ["user_id"]: data.user_id.split(','),

                }));
                reset1({
                    coupon_code: data.coupon_code,
                    description: data.description,
                    discount_amount: data.discount_amount,
                    min_amount: data.min_amount,
                    type: data.type,
                    course_id: data.course_id.split(','),
                    membership_id: data.membership_id.split(','),
                    user_limit: data.user_limit,
                    max_discount_amount: data.max_discount_amount,
                    start_date: data.start_date,
                    frequency: data.frequency,
                    end_date: data.end_date,
                    user_id: data.user_id.split(','),
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setPending(false);
        }
    };

    const onUpdate = async () => {
        setPending(true);
        try {
            const result = await postApi(apiPath.updateCoupon, {
                ...formDataUpdate,
                user_id: formDataUpdate?.user_id?.toString(),
                course_id: formDataUpdate?.course_id?.toString(),
                membership_id: formDataUpdate?.membership_id?.toString()
            });

            handleCloseUpdate();
            notify("Coupon Updated Successfully!");
            fetchTableData();

        } catch (error) {
            console.error(error);
            notifyErr("Something went wrong!");
        } finally {
            setPending(false);
        }
    };

    const breadcrumbItems = [
        { title: 'Coupon', link: null }
    ];

    return (
        <>
            <section>

                {pending && <Loader />}

                <ToastContainer />
                <div className="mb-5">
                    <Breadcrumb items={breadcrumbItems} />
                </div>

                <Card variant="outlined" className="pb-3">
                    <div className="btn-d flex flex-col sm:flex-row row-gap-3 justify-content-between m-3">
                        <Form.Select value={status} className="filter-select  w-auto"
                            onChange={(e) => handleStatusFilter(e.target.value)}
                        >
                            <option value="0">Active</option>
                            <option value="1">In-Active</option>
                            <option value="2">All</option>
                        </Form.Select>
                        <Button
                            className="text-white border-0 default-btn"
                            onClick={() => handleShow()}
                        >
                            Add Coupon
                        </Button>
                    </div>
                    <div>
                        <DataTables
                            title={"Coupon"}
                            data={data}
                            columns={columns}
                            pending={pending}
                            paginationServer={false}
                        // totalRows={data?.length}
                        />
                    </div>
                </Card>

                {/* add */}
                <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>

                    <Modal.Header closeButton>
                        <Modal.Title>Add Coupon</Modal.Title>
                    </Modal.Header>
                    <Form
                        className="row m-2"
                        onSubmit={handleSubmit(onSubmit)}
                        autoComplete="off"
                    >
                        <Modal.Body>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="coupon_code">
                                    Coupon code
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    id="coupon_code"
                                    placeholder="Coupon code"
                                    value={formData.coupon_code}
                                    {...register("coupon_code", {
                                        required: 'Please Enter Coupon code',
                                        pattern: {
                                            value: /^[a-zA-Z0-9]{6,10}$/,
                                            message: "Please enter a valid coupon code",
                                        },
                                        onChange: (e) =>
                                            handleInputChange("coupon_code", e.target.value),
                                    })}
                                />
                                {errors.coupon_code && (
                                    <div className="errMsg text-danger">
                                        {errors.coupon_code.message}
                                    </div>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="description">
                                    Coupon Description
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    type="text"
                                    id="description"
                                    placeholder="Description"
                                    value={formData.description}
                                    {...register("description", {
                                        required: true,
                                        onChange: (e) =>
                                            handleInputChange("description", e.target.value),
                                    })}
                                />
                                {errors.description && (
                                    <div className="errMsg text-danger">
                                        Please Enter Description
                                    </div>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="type">Discount Type</Form.Label>
                                <Form.Select
                                    type="type"
                                    id="type"
                                    placeholder="type"
                                    value={formData.type}
                                    {...register("type", {
                                        required: true,
                                        onChange: (e) => handleInputChange("type", e.target.value),
                                    })}
                                >
                                    <option value="">Select Type</option>
                                    <option value="0">Percentage</option>
                                    <option value="1">Price</option>
                                </Form.Select>
                                {errors.type && (
                                    <div className="errMsg text-danger">Please Select Discount Type</div>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="discount_amount">Discount Amount / Percentage</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="discount_amount"
                                    min="1"
                                    placeholder="Discount amount / percentage"
                                    value={formData.discount_amount}
                                    {...register("discount_amount", {
                                        required: true,
                                        onChange: (e) => handleInputChange("discount_amount", e.target.value),
                                    })}
                                />
                                {errors.discount_amount && (
                                    <div className="errMsg text-danger">Please Enter Amount</div>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="start_date">Validity Start Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Validity Start date"
                                    id="start_date"
                                    min={new Date().toISOString().split('T')[0]}
                                    max={formData.end_date || undefined}
                                    value={formData.start_date}
                                    {...register("start_date", {
                                        required: true,
                                        onChange: (e) => handleInputChange("start_date", e.target.value),
                                    })}
                                />
                                {errors.start_date && (
                                    <div className="errMsg text-danger">Please Select Start Date</div>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="end_date">Validity End Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Validity End date"
                                    id="end_date"
                                    min={formData.start_date || undefined}
                                    // max={new Date('2099-12-31').toISOString().split('T')[0]}
                                    value={formData.end_date}
                                    {...register("end_date", {
                                        required: true,
                                        onChange: (e) => handleInputChange("end_date", e.target.value),
                                    })}
                                />
                                {errors.end_date && (
                                    <div className="errMsg text-danger">Please Select End Date</div>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="min_amount">Minimum Purchase Amount</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="min_amount"
                                    min="1"
                                    placeholder="min amount"
                                    value={formData.min_amount}
                                    {...register("min_amount", {
                                        required: true,
                                        onChange: (e) => handleInputChange("min_amount", e.target.value),
                                    })}
                                />
                                {errors.min_amount && (
                                    <div className="errMsg text-danger">Please Enter Minimum Purchase Amount</div>
                                )}
                            </Form.Group>
                            {/* <Form.Group className="mb-3">
                                <Form.Label htmlFor="max_discount_amount">Maximum Discount Amount</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="max_discount_amount"
                                    min="1"
                                    max={formData.discount_amount}
                                    placeholder="max discount"
                                    value={formData.max_discount_amount}
                                    {...register("max_discount_amount", {
                                        required: true,
                                        onChange: (e) => handleInputChange("max_discount_amount", e.target.value),
                                    })}
                                />
                                {errors.max_discount_amount && (
                                    <div className="errMsg text-danger">Please Enter Max discount Amount</div>
                                )}
                            </Form.Group> */}

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="frequency">Usage Limit Per Coupon</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="frequency"
                                    min="1"
                                    placeholder="Usage limit per coupon"
                                    value={formData.frequency}
                                    {...register("frequency", {
                                        required: true,
                                        onChange: (e) => handleInputChange("frequency", e.target.value),
                                    })}
                                />
                                {errors.frequency && (
                                    <div className="errMsg text-danger">Please Enter Usage Limit Per Coupon</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="user_limit">Usage Limit Per User</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="user_limit"
                                    min="1"
                                    placeholder="Usage limit per user"
                                    value={formData.user_limit}
                                    {...register("user_limit", {
                                        required: true,
                                        onChange: (e) => handleInputChange("user_limit", e.target.value),
                                    })}
                                />
                                {errors.user_limit && (
                                    <div className="errMsg text-danger">Please Enter Usage Limit Per User</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3 p-0">
                                <Form.Label htmlFor="course_id">Select Course</Form.Label>

                                <Select
                                    multiple
                                    displayEmpty
                                    value={formData.course_id}
                                    className="form-control"
                                    // onChange={(e) => handleInputChange("user_id", e.target.value)}
                                    input={<OutlinedInput />}
                                    {...register("course_id", {
                                        required: false,
                                        onChange: (e) => handleChange(e, 'course_id', activeCourse),
                                    })}
                                    renderValue={(selected) => {
                                        if (selected?.length === 0) {
                                            return <span>Select Course</span>;
                                        }
                                        return (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {activeCourse?.filter((item) => selected?.includes(item.id))?.map((value) => {
                                                    return (
                                                        <Chip key={value.id} label={value.title} />
                                                    )
                                                })}
                                            </Box>
                                        )
                                    }}
                                >
                                    <MenuItem disabled value="">
                                        <span>Select Course</span>
                                    </MenuItem>
                                    <MenuItem
                                        value="all"
                                    >
                                        Select All
                                    </MenuItem>
                                    {activeCourse?.map((course) => (
                                        <MenuItem
                                            key={course.id}
                                            value={course.id}
                                        >
                                            {course.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.course_id && (
                                    <div className="errMsg text-danger">Please Select course</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3 p-0">
                                <Form.Label htmlFor="membership_id">Select Membership</Form.Label>

                                <Select
                                    multiple
                                    displayEmpty
                                    value={formData.membership_id}
                                    className="form-control"
                                    // onChange={(e) => handleInputChange("user_id", e.target.value)}
                                    input={<OutlinedInput />}
                                    {...register("membership_id", {
                                        required: false,
                                        onChange: (e) => handleChange(e, 'membership_id', membership),
                                    })}
                                    renderValue={(selected) => {
                                        if (selected?.length === 0) {
                                            return <span>Select Membership</span>;
                                        }
                                        return (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {membership?.filter((item) => selected?.includes(item.id))?.map((value) => {
                                                    return (
                                                        <Chip key={value.id} label={value.name} />
                                                    )
                                                })}
                                            </Box>
                                        )
                                    }}
                                >
                                    <MenuItem disabled value="">
                                        <span>Select Membership</span>
                                    </MenuItem>
                                    <MenuItem
                                        value="all"
                                    >
                                        Select All
                                    </MenuItem>
                                    {membership?.map((item) => (
                                        <MenuItem
                                            key={item.id}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.membership_id && (
                                    <div className="errMsg text-danger">Please Select Membership</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3 p-0">
                                <Form.Label htmlFor="user_id">Selected User</Form.Label>

                                <Select
                                    multiple
                                    displayEmpty
                                    value={formData.user_id}
                                    className="form-control"
                                    // onChange={(e) => handleInputChange("user_id", e.target.value)}
                                    input={<OutlinedInput />}
                                    {...register("user_id", {
                                        required: false,
                                        onChange: (e) => handleChange(e, 'user_id', userList),
                                    })}
                                    renderValue={(selected) => {
                                        if (selected?.length === 0) {
                                            return <span>Select User</span>;
                                        }
                                        return (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {userList?.filter((item) => selected?.includes(item.id))?.map((value) => {
                                                    return (
                                                        <Chip key={value.id} label={value.name} />
                                                    )
                                                })}
                                            </Box>
                                        )
                                    }}
                                >
                                    <MenuItem disabled value="">
                                        <span>Select User</span>
                                    </MenuItem>
                                    <MenuItem
                                        value="all"
                                    >
                                        Select All
                                    </MenuItem>
                                    {userList?.map((user) => (
                                        <MenuItem
                                            key={user.id}
                                            value={user.id}
                                        >
                                            {user.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.user_id && (
                                    <div className="errMsg text-danger">Please Select User</div>
                                )}
                            </Form.Group>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="submit"
                                variant="contained"
                                className="text-white btn-primary"
                            >
                                Add
                            </Button>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

                {/* update */}
                <Modal
                    show={showUpdate}
                    onHide={handleCloseUpdate}
                    style={{ zIndex: "9999" }}
                >

                    <Modal.Header closeButton>
                        <Modal.Title>Update Coupon</Modal.Title>
                    </Modal.Header>
                    <Form
                        className="row m-2"
                        onSubmit={handleSubmit1(onUpdate)}
                        autoComplete="off"
                    >
                        <Modal.Body>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="coupon_code">
                                    Coupon code
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    id="coupon_code"
                                    placeholder="Coupon code"
                                    value={formDataUpdate.coupon_code}
                                    {...register1("coupon_code", {
                                        required: 'Please Enter Coupon code',
                                        pattern: {
                                            value: /^[a-zA-Z0-9]{6,10}$/,
                                            message: "Please enter a valid coupon code",
                                        },
                                        onChange: (e) =>
                                            handleInputChange1("coupon_code", e.target.value),
                                    })}
                                />
                                {errors1.coupon_code && (
                                    <div className="errMsg text-danger">
                                        {errors1.coupon_code.message}
                                    </div>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="description">
                                    Coupon Description
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    type="text"
                                    id="description"
                                    placeholder="Description"
                                    value={formDataUpdate.description}
                                    {...register1("description", {
                                        required: true,
                                        onChange: (e) =>
                                            handleInputChange1("description", e.target.value),
                                    })}
                                />
                                {errors1.description && (
                                    <div className="errMsg text-danger">
                                        Please Enter Description
                                    </div>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="type">Discount Type</Form.Label>
                                <Form.Select
                                    type="type"
                                    id="type"
                                    placeholder="type"
                                    value={formDataUpdate.type}
                                    {...register1("type", {
                                        required: true,
                                        onChange: (e) => handleInputChange1("type", e.target.value),
                                    })}
                                >
                                    <option value="">Select Type</option>
                                    <option value="0">Percentage</option>
                                    <option value="1">Price</option>
                                </Form.Select>
                                {errors1.type && (
                                    <div className="errMsg text-danger">Please Select Type</div>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="discount_amount">Discount Amount</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="discount_amount"
                                    min="1"
                                    placeholder="discount amount"
                                    value={formDataUpdate.discount_amount}
                                    {...register1("discount_amount", {
                                        required: true,
                                        onChange: (e) => handleInputChange1("discount_amount", e.target.value),
                                    })}
                                />
                                {errors1.discount_amount && (
                                    <div className="errMsg text-danger">Please Enter Amount</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="start_date">Start Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Start date"
                                    id="start_date"
                                    // min={new Date().toISOString().split('T')[0]}
                                    max={formDataUpdate.end_date || undefined}
                                    value={formDataUpdate.start_date}
                                    {...register1("start_date", {
                                        required: true,
                                        onChange: (e) => handleInputChange1("start_date", e.target.value),
                                    })}
                                />
                                {errors1.start_date && (
                                    <div className="errMsg text-danger">Please Select Start Date</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="end_date">End Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="End date"
                                    id="end_date"
                                    min={formDataUpdate.start_date || undefined}
                                    value={formDataUpdate.end_date}
                                    {...register1("end_date", {
                                        required: true,
                                        onChange: (e) => handleInputChange1("end_date", e.target.value),
                                    })}
                                />
                                {errors1.end_date && (
                                    <div className="errMsg text-danger">Please Select End Date</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="min_amount">Minimum Purchase Amount</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="min_amount"
                                    min="1"
                                    placeholder="min amount"
                                    value={formDataUpdate.min_amount}
                                    {...register1("min_amount", {
                                        required: true,
                                        onChange: (e) => handleInputChange1("min_amount", e.target.value),
                                    })}
                                />
                                {errors1.min_amount && (
                                    <div className="errMsg text-danger">Please Purchase Enter Amount</div>
                                )}
                            </Form.Group>

                            {/* <Form.Group className="mb-3">
                                <Form.Label htmlFor="max_discount_amount">Maximum Discount Amount</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="max_discount_amount"
                                    min="1"
                                    max={formDataUpdate.discount_amount}
                                    placeholder="min discount"
                                    value={formDataUpdate.max_discount_amount}
                                    {...register1("max_discount_amount", {
                                        required: true,
                                        onChange: (e) => handleInputChange1("max_discount_amount", e.target.value),
                                    })}
                                />
                                {errors1.max_discount_amount && (
                                    <div className="errMsg text-danger">Please Enter Discount Amount</div>
                                )}
                            </Form.Group> */}
                           
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="frequency">Usage Limit Per Coupon</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="frequency"
                                    min="1"
                                    placeholder="frequency"
                                    value={formDataUpdate.frequency}
                                    {...register1("frequency", {
                                        required: true,
                                        onChange: (e) => handleInputChange1("frequency", e.target.value),
                                    })}
                                />
                                {errors1.frequency && (
                                    <div className="errMsg text-danger">Please Enter Frequency</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="user_limit">Usage Limit Per User</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="user_limit"
                                    min="1"
                                    placeholder="user_limit"
                                    value={formDataUpdate.user_limit}
                                    {...register1("user_limit", {
                                        required: true,
                                        onChange: (e) => handleInputChange1("user_limit", e.target.value),
                                    })}
                                />
                                {errors1.user_limit && (
                                    <div className="errMsg text-danger">Please Enter User limit</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3 p-0">
                                <Form.Label htmlFor="course_id">Select Course</Form.Label>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={formDataUpdate.course_id}
                                    className="form-control"
                                    input={<OutlinedInput />}
                                    {...register1("course_id", {
                                        required: false,
                                        onChange: (e) => handleChange1(e, 'course_id', activeCourse),
                                    })}
                                    renderValue={(selected) => {
                                        if (selected?.length === 0) {
                                            return <span>Select Course</span>;
                                        }
                                        return (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {activeCourse?.filter((item) => selected?.includes(item.id))?.map((value) => {
                                                    return (
                                                        <Chip key={value.id} label={value.title} />
                                                    )
                                                })}
                                            </Box>
                                        )
                                    }}
                                >
                                    <MenuItem disabled value="">
                                        <span>Select Course</span>
                                    </MenuItem>
                                    <MenuItem
                                        value="all"
                                    >
                                        Select All
                                    </MenuItem>
                                    {activeCourse?.map((course) => (
                                        <MenuItem
                                            key={course.id}
                                            value={course.id}
                                        >
                                            {course.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors1.course_id && (
                                    <div className="errMsg text-danger">Please Select course</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3 p-0">
                                <Form.Label htmlFor="membership_id">Select Membership</Form.Label>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={formDataUpdate.membership_id}
                                    className="form-control"
                                    input={<OutlinedInput />}
                                    {...register1("membership_id", {
                                        required: false,
                                        onChange: (e) => handleChange1(e, 'membership_id', membership),
                                    })}
                                    renderValue={(selected) => {
                                        if (selected?.length === 0) {
                                            return <span>Select Membership</span>;
                                        }
                                        return (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {membership?.filter((item) => selected?.includes(item.id))?.map((value) => {
                                                    return (
                                                        <Chip key={value.id} label={value.name} />
                                                    )
                                                })}
                                            </Box>
                                        )
                                    }}
                                >
                                    <MenuItem disabled value="">
                                        <span>Select Membership</span>
                                    </MenuItem>
                                    <MenuItem
                                        value="all"
                                    >
                                        Select All
                                    </MenuItem>
                                    {membership?.map((item) => (
                                        <MenuItem
                                            key={item.id}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors1.membership_id && (
                                    <div className="errMsg text-danger">Please Select membership</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3 p-0">
                                <Form.Label htmlFor="title">Selected User</Form.Label>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={formDataUpdate.user_id}
                                    className="form-control"
                                    // onChange={(e) => handleInputChange("user_id", e.target.value)}
                                    input={<OutlinedInput />}
                                    {...register1("user_id", {
                                        required: false,
                                        onChange: (e) => handleChange1(e, 'user_id', userList),
                                    })}
                                    renderValue={(selected) => {
                                        if (selected?.length === 0) {
                                            return <span>Select User</span>;
                                        }
                                        return (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {userList?.filter((item) => selected?.includes(item.id))?.map((value) => {
                                                    return (
                                                        <Chip key={value.id} label={value.name} />
                                                    )
                                                })}
                                            </Box>
                                        )
                                    }}
                                >
                                    <MenuItem disabled value="">
                                        <span>Select User</span>
                                    </MenuItem>
                                    <MenuItem
                                        value="all"
                                    >
                                        Select All
                                    </MenuItem>
                                    {userList?.map((user) => (
                                        <MenuItem
                                            key={user.id}
                                            value={user.id}
                                        >
                                            {user.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors1.user_id && (
                                    <div className="errMsg text-danger">Please Select User</div>
                                )}
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="submit"
                                variant="contained"
                                className="text-white btn-primary"

                            >
                                Update
                            </Button>
                            <Button variant="secondary" onClick={handleCloseUpdate}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </section>
        </>
    )
}

export default Coupon