import { useState } from "react";
import apiPath from "../../../apipath";
import { fetchDataFromApi, getNoAuthToken } from "../../../libs/AllGlobalFunctions";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { handlePageClick } from "../../../libs/AllGlobalFunctions";
import { useImmer } from "use-immer";
import Loader from "../../../components/Loader";
import { useSelector } from "react-redux";
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

const ActiveLogs = () => {

    const [loading, setLoading] = useState(false);
    const { userInfo } = useSelector((state) => state?.auth);
    const [activeLogs, setActiveLogs] = useState([]);
    let [filterData, setFilterData] = useImmer({
        page: 1,
        per_page: 5
    });

    useEffect(() => {
        fetchActiveLogs();
        window.scrollTo(0, 0);
    }, []);

    const fetchActiveLogs = (id) => {
        setLoading(true);
        fetchDataFromApi(apiPath.getActivityLogs, null, 'GET', filterData).then((response) => {
            const data = response?.data?.data;
            if (data) {
                setActiveLogs(data); // Append new data to existing data
            } else {
                setActiveLogs([]);
            }
            setLoading(false);
        }
        ).catch((error) => {
            console.log(error);
            setLoading(false);
        })
    }


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        const month = monthNames[date.getMonth()];
        const day = date.getDate();
        return `${month} ${day}`;
    };

    return (
        <section className='section_spacing w-full section_spacing-space'>
            <div className=''>
                <h2 className="mb-3 text-2xl">Activity Logs</h2>
                {loading && <Loader />}
                <Timeline
                    sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                        },
                    }}
                >
                    {activeLogs.map((log, index) => (
                        <TimelineItem key={index}>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <h6 className="text-sm">{formatDate(log.action_time)}</h6>
                                <h1 className="text-lg">{log.action}</h1>
                            </TimelineContent>
                        </TimelineItem>
                    ))}

                </Timeline>

                {loading && <p>Loading...</p>}

                {/* <div className="overflow-x-auto relative">
                    <div className="overflow-x-auto">
                        <table className="min-w-full leading-normal">
                            <thead>
                                <tr>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                        Name
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                        email
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                        total_amount
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                        type
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                        Transaction status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {activeLogs?.data?.data.map((item) => (
                                    <tr key={item.id}>
                                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            {item.name}
                                        </td>
                                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            {item.email}
                                        </td>
                                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            {item.total_amount}
                                        </td>
                                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            {item.type}
                                        </td>
                                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            {item.transaction_status}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='p-pagenation'>
                    <ReactPaginate
                        previousLabel={
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                height='24'
                                viewBox='0 -960 960 960'
                                width='24'
                            >
                                <path d='M360-200 80-480l280-280 56 56-183 184h647v80H233l184 184-57 56Z' />
                            </svg>
                        }
                        nextLabel={
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                height='24'
                                viewBox='0 -960 960 960'
                                width='24'
                            >
                                <path d='m600-200-57-56 184-184H80v-80h647L544-704l56-56 280 280-280 280Z' />
                            </svg>
                        }
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={activeLogs?.data?.pagination?.page}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={(event) => handlePageClick(event, setFilterData)}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        forcePage={filterData?.page - 1}
                    />
                    </div> */}
            </div>
        </section>
    )
}

export default ActiveLogs;